import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { makeStyles } from "@mui/styles";

interface IConfirm {
  title: string;
  message?: string;
  open: boolean;
  handleClose: () => void;
  handleAccept: () => void;
  declineTitle?: string;
  acceptTitle?: string;
  children?: React.ReactElement | null;
}

const useStyles = makeStyles({
  container: {
    paper: {
      backgroundColor: "#000",
    },
  },
});

export const Confirm = (props: IConfirm) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.container}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        {props.children}
        {!!props.message && (
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="secondary">
          {props.declineTitle || "No"}
        </Button>
        <Button onClick={props.handleAccept} color="primary" autoFocus>
          {props.acceptTitle || "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
