import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface IMessageGridItem {
  children: React.ReactNode | React.ReactNode[];
}

const useStyles = makeStyles({
  root: {
    padding: 12,
    paddingBottom: 24,
    width: `calc(100% / 6 - 24px)`,
    "@media (max-width: 2300px)": {
      width: `calc(100% / 5 - 24px)`,
    },
    "@media (max-width: 2200px)": {
      width: `calc(100% / 4 - 24px)`,
    },
    "@media (max-width: 1770px)": {
      width: `calc(100% / 3 - 24px)`,
    },
    "@media (max-width: 1390px)": {
      width: `calc(100% / 2 - 24px)`,
    },
    "@media (max-width: 700px)": {
      width: `calc(100% / 1 - 24px)`,
    },
  },
});

export const MessageGridItem = (props: IMessageGridItem) => {
  const classes = useStyles();
  return <Box className={classes.root}>{props.children}</Box>;
};
