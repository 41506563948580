import { gql, useQuery } from "@apollo/client";
import {
  MarketplaceListingsCategoriesQuery,
  MarketplaceListingsCategoriesQueryVariables,
} from "../../../gql";

const MARKETPLACE_LISTINGS_CATEGORIES = gql`
  query MarketplaceListingsCategoriesQuery($boardId: String!) {
    marketplaceListingsCategories {
      id
      title
    }
    board(id: $boardId) {
      id
      pausedUntil
      subscriptionSet {
        channels {
          id
          configurationUrl
          title
          icon
          muted
          description
          created
          marketplaceListing {
            id
            free
          }
        }
      }
    }
  }
`;

export const useMarketplaceListingsCategories = (boardId: string) =>
  useQuery<
    MarketplaceListingsCategoriesQuery,
    MarketplaceListingsCategoriesQueryVariables
  >(MARKETPLACE_LISTINGS_CATEGORIES, {
    variables: {
      boardId,
    },
  });
