import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Icon,
  Modal,
  Spacer,
  SubTitle,
  responsive,
} from "@vestaboard/installables";
import { useState } from "react";

const useStyles = makeStyles({
  modalContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    height: "100%",
  },
  modalBtnGroup: {
    display: "flex",
    gap: 24,
    justifyContent: "space-between",
    marginTop: 24,
    width: "100%",
  },
  pinTimeContainer: {
    padding: "0px 20px 40px",
    textAlign: "center",
    maxWidth: 460,
  },
  pinFor: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    color: "#f5f5f7",
  },
  inputRow: {
    display: "flex",
    flexDirection: "row",
  },
  timeBox: {
    width: 87,
    maxWidth: "30%",
  },
  input: {
    width: "calc(100% - 16px)",
    paddingLeft: 16,
    height: 48,
    borderRadius: 6,
    border: "solid 1px #f5f5f7",
    fontSize: 34,
    fontWeight: 500,
    background: "transparent",
    textAlign: "center",
    color: "#f5f5f7",
    outline: "none",
    [`@media(max-width: ${responsive.MOBILE}px)`]: {
      fontSize: 28,
    },
  },
  label: {
    margin: "7px 0px 0 0px",
    opacity: 0.75,
    fontSize: 14,
    letterSpacing: 0.2,
    textAlign: "center",
    color: "#f5f5f7",
  },
  colon: {
    width: 12,
    height: 40,
    margin: "2px 8px 12px 12px",
    fontSize: 34,
    fontWeight: 500,
    textAlign: "center",
    color: "#f5f5f7",
  },
});

export const PinMessageModal = (props: {
  visible: boolean;
  onClose: () => void;
  onSend: (seconds: number) => void;
}) => {
  const classes = useStyles();
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);

  return (
    <Modal fullScreenMobile visible={props.visible} onClose={props.onClose}>
      <Box className={classes.modalContent}>
        <Icon type="pin" size={38} color="white" />
        <Spacer size="large" />
        <SubTitle>Pin this message on Vestaboard</SubTitle>
        <Spacer size="large" />
        <Typography className={classes.pinFor}>
          No automated message will overwrite it for:
        </Typography>
        <Box className={classes.pinTimeContainer}>
          <Spacer size="large" />
          <Box className={classes.inputRow}>
            <Box className={classes.timeBox}>
              <input
                step={1}
                min={0}
                type="number"
                className={classes.input}
                value={hour}
                onChange={(e) => setHour(+e.target.value)}
              />
              <Typography className={classes.label}>Hours</Typography>
            </Box>
            <Box className={classes.colon}>:</Box>
            <Box className={classes.timeBox}>
              <input
                step={1}
                min={0}
                max={59}
                type="number"
                className={classes.input}
                value={minute}
                onChange={(e) => setMinute(+e.target.value)}
              />
              <Typography className={classes.label}>Minutes</Typography>
            </Box>
            <Box className={classes.colon}>:</Box>
            <Box className={classes.timeBox}>
              <input
                step={1}
                min={0}
                max={59}
                type="number"
                className={classes.input}
                value={second}
                onChange={(e) => setSecond(+e.target.value)}
              />
              <Typography className={classes.label}>Seconds</Typography>
            </Box>
          </Box>
        </Box>
        <Spacer size="extraLarge" />
        <Box className={classes.modalBtnGroup}>
          <Button onClick={props.onClose} buttonType="outline">
            Cancel
          </Button>
          <Button
            onClick={() => {
              props.onSend(second + minute * 60 + hour * 60 * 60);
            }}
            buttonType="white"
          >
            Send &nbsp;
            <Icon type="send" size={18} color="black" />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
