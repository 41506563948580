import * as React from "react";

import {
  Select,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  TextField,
  Box,
} from "@mui/material";

import { history } from "../../providers/AppRouterProvider";
import { useCreateBoardV2 } from "../../hooks/useCreateBoardV2";
import { useToasts } from "@vestaboard/installables";
import { Select as CompSelect } from "../../components/Select";

import {
  MY_BOARDS_ADMIN_QUERY_V2,
  MY_BOARDS_QUERY_V2,
} from "../../hooks/useMyBoardsV2";
import { Field } from "../../components/Field";
import { makeStyles } from "@mui/styles";
import { useIsMobileMediaQuery } from "../../hooks/media-queries";
import { Spacer } from "../../components/Spacer";

interface ICreateBoardPageProps {}

type CreateBoardPageProps = ICreateBoardPageProps;

const useStyles = makeStyles({
  form: {
    display: "flex",
    "@media(max-width: 600px)": {
      flexDirection: "column",
    },
  },
  buttons: {
    display: "flex",
  },
  button: {
    minWidth: 150,
    marginTop: 26,
  },
  container: {
    maxWidth: 736,
  },
});

export const CreateBoardPageV2: React.FC<CreateBoardPageProps> = (props) => {
  const [title, setTitle] = React.useState("");
  const [boardStyle, setBoardStyle] = React.useState("black");
  const [country, setCountry] = React.useState("America");
  const [timezone, setTimezone] = React.useState("America/New_York");

  const [createBoardV2] = useCreateBoardV2();
  const { addToast } = useToasts();
  const classes = useStyles();

  const submit = async ({
    title,
    boardStyle,
    timezone
  }: {
    title: string;
    boardStyle: string;
    timezone: string;
  }) => {
    const created = await createBoardV2({
      variables: {
        input: {
          title,
          boardStyle,
          timezone
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: MY_BOARDS_ADMIN_QUERY_V2,
        },
        {
          query: MY_BOARDS_QUERY_V2,
        },
      ],
    });
    const id =
      created?.data?.createBoardV2?.__typename === "BoardV2"
        ? created.data.createBoardV2.id
        : null;
    if (!id) {
      addToast("Error creating virtual Vestaboard", { appearance: "error" });
      return;
    }
    history.push(`/v2/board/${id}/live`);
  };

  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const isMobile = useIsMobileMediaQuery();

  const submitDisabled = !title || !checkboxValue;

  const countries = React.useMemo(
    () =>
      Array.from(
        new Set(
          Intl.supportedValuesOf("timeZone").map((tz) => tz.split("/")?.[0])
        )
      ),
    []
  );
  const timezones = React.useMemo(
    () =>
      Intl.supportedValuesOf("timeZone").filter((tz) => tz.includes(country)),
    [country]
  );

  return (
    <div>
      <h3>Create a new Vestaboard</h3>
      <p>
        Create and name your new Vestaboard so that you can begin sending
        messages.
      </p>
      <div className="mt4 w-50-ns mt3 mb3">
        <TextField
          label={"Vestaboard name"}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          multiline
          variant="filled"
          fullWidth
        />
      </div>
      <div className="mt4 w-50-ns mt3 mb3">
        <InputLabel>Display Type</InputLabel>
        <Select
          label={"Display Type"}
          value={boardStyle}
          onChange={(e) => setBoardStyle(e.target.value as string)}
          variant="filled"
          fullWidth
        >
          {["Black", "White"].map((style) => (
            <MenuItem key={style} value={style.toLocaleLowerCase()}>
              {style}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Box className={classes.form}>
          <Field label="Country">
            <CompSelect
              value={country}
              onValueChange={setCountry}
              options={countries.map((country) => ({
                key: country,
                value: country,
              }))}
              fullWidth
            />
          </Field>
          {isMobile ? <Spacer height={20} /> : <Spacer width={24} />}
          <Field label="Timezone">
            <CompSelect
              value={timezone}
              onValueChange={setTimezone}
              options={timezones.map((timezone: string) => ({
                key: timezone,
                value: timezone,
              }))}
              fullWidth
            />
          </Field>
        </Box>
        <Spacer height={20} /> 
      <div className="w-50-md mb4 mb4">
        <div className="f6 mb3">
          <i>
            The use of the Vestaboard Website, Mobile Applications, Web Services
            and API is conditioned on your agreement with us to the Terms of
            Service for the Vestaboard Service and Privacy Policy found at{" "}
            <a href="https://www.vestaboard.com/legal">
              https://www.vestaboard.com/legal
            </a>
          </i>
        </div>
  
        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxValue}
              onChange={(e) => setCheckboxValue(e.target.checked)}
              color={"primary"}
            />
          }
          label="I agree"
        />
      </div>
      <Button
        variant={"contained"}
        color={"primary"}
        disabled={submitDisabled}
        onClick={() =>
          submit({
            title,
            boardStyle,
            timezone
          })
        }
      >
        Create Vestaboard
      </Button>
    </div>
  );
};
