import { Box, ButtonBase, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { TabContent } from "../../components/TabContent";
import { BREAKPOINT_EXTRA_EXTRA_SMALL } from "../../config";
import { useAppState } from "../../hooks/global";
import { ScrollToTop } from "../../utils/scroll";
import { CardsContainer } from "../Installables/components/CardsContainer";
import { InstallableTile } from "../Installables/components/InstallableTile";
import {
  MarketplaceCategroyQuery,
  MarketplaceCategroyQueryVariables,
  MarketplaceListingSortBy,
  MarketplaceListingSortOrder,
  MarketplaceListingsByCategroyQuery,
  MarketplaceListingsByCategroyQueryVariables,
} from "../../gql";
import { DiscoverCategorySortByDropdown } from "./DiscoverCategorySortByDropdown";
import { InstallableCategoryPopover } from "./InstallableCategoryPopover";

const MARKETPLACE_LISTING_CATEGORY_QUERY = gql`
  query MarketplaceCategroyQuery($id: String!) {
    marketplaceListingsCategory(id: $id) {
      id
      title
    }
  }
`;


const MARKETPLACE_LISTING_QUERY = gql`
  query MarketplaceListingsByCategroyQuery(
    # needed for elastic search filtering
    $categoryTitle: String!
    $boardId: String!
    $sortBy: MarketplaceListingSortBy
    $sortOrder: MarketplaceListingSortOrder

  ) {
    board(id: $boardId) {
      id
      subscriptionSet {
        channels {
          id
          muted
          marketplaceListing {
            id
          }
        }
      }
    }
    searchMarketPlaceListings(
      input: { categories: [$categoryTitle], sortBy: $sortBy, sortOrder: $sortOrder }
    ) {
      id
      free
      releaseDate
      installable {
        id
        created
        hideFromInstallablesList
        installationsAreSingleton
        category {
          id
          title
        }
        developer {
          id
          title
        }
      }
      stagingOnly
      icon
      title
      description
      shortDescription
    }
  }
`;

interface IInstallableCategoryPageProps {}

interface IRouteMatch {
  // subscriptionId: string;
  boardId: string;
  category: string;
}

const useStyles = makeStyles({
  header: {
    padding: "20px 18px",
    borderBottom: "solid 1px #2b2b2b",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 18,
    fontFamily: "HelveticaNeue",
    fontWeight: 500,
    lineHeight: 1.44,
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "30%",
  },
  headerCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerRight: {
    width: "30%",
    display: "flex",
    justifyContent: "flex-end",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 810,
    flexGrow: 1,
    width: "100%",
  },
  installable: {
    marginBottom: 48,
    [`@media(max-width: ${BREAKPOINT_EXTRA_EXTRA_SMALL})`]: {
      marginRight: 24,
      width: "100%",
      maxWidth: 350,
    },
  },
});

export const InstallableCategoryPage: React.FC<
  IInstallableCategoryPageProps
> = (_props) => {
  const { boardId, category } = useParams<IRouteMatch>();

  const classes = useStyles();
  const history = useHistory();
  const { tenantId } = useAppState();
  const [sortBy, setSortBy] = useState<MarketplaceListingSortBy>(
    "releaseDate" as MarketplaceListingSortBy
  );

  const isProUser = false;

  const mplCategoryQuery = useQuery<
    MarketplaceCategroyQuery,
    MarketplaceCategroyQueryVariables
  >(MARKETPLACE_LISTING_CATEGORY_QUERY, {
    variables: {
      id: category,
    },
  });

  const categoryTitle =
    mplCategoryQuery.data?.marketplaceListingsCategory.title;

  const { data, loading } = useQuery<
    MarketplaceListingsByCategroyQuery,
    MarketplaceListingsByCategroyQueryVariables
  >(MARKETPLACE_LISTING_QUERY, {
    variables: {
      categoryTitle: categoryTitle ?? "",
      boardId,
      sortBy,
      sortOrder: sortBy === 'title' ?  "asc"  as MarketplaceListingSortOrder : 'desc' as MarketplaceListingSortOrder,
    },
    skip: !tenantId || !categoryTitle,
  });

  const items = data?.searchMarketPlaceListings || [];
  return (
    <>
      <ScrollToTop />
      <Box className={classes.header}>
        <Box className={classes.headerLeft}>
          <ButtonBase
            onClick={() => {
              history.push(`/board/${boardId}/installables`);
            }}
          >
            <img src="/icons/icon-back.svg" alt="Back" />
          </ButtonBase>
        </Box>
        <Box className={classes.headerCenter}>
           <InstallableCategoryPopover title={categoryTitle} />
        </Box>
        <Box className={classes.headerRight}>
          <DiscoverCategorySortByDropdown
            options={[
              {
                id: "releaseDate",
                label: "Most Recently Added",
              },
              {
                id: "title",
                label: "Alphabetically",
              },
              {
                id: "subscriptionsCount",
                label: "Most Popular",
              },
            ]}
            handleOnSelectOption={(sort) => {
              setSortBy(sort as MarketplaceListingSortBy);
            }}
            selected={sortBy as string}
          />
        </Box>
      </Box>
      <TabContent>
        <Box className={classes.contentWrapper}>
          <Box className={classes.content}>
            <CardsContainer>
              {!!data && items.length > 0 && !loading ? (
                items.map((listing) => {
                  const existingSubscription =  data?.board.subscriptionSet.channels.filter(
                        (c) => c?.marketplaceListing?.id == listing.id
                      )[0] ?? null;
                  const existingSubscriptionId = existingSubscription?.id ?? null;

                  return (
                    <InstallableTile
                      isPlus={isProUser}
                      canAdd={true}
                      key={listing.id}
                      free={listing.free ?? false}
                      id={listing.id}
                      title={listing.title}
                      icon={listing.icon}
                      description={listing.shortDescription || null}
                      installed={false}
                      enabled={true}
                      className={classes.installable}
                      subscriptionId={existingSubscriptionId ?? null}
                      installableIsSingleton={
                        listing.installable.installationsAreSingleton ?? true
                      }
                      subscriptionMuted={
                        existingSubscription
                          ? existingSubscription.muted ??
                            false
                          : false
                      }
                    />
                  );
                })
              ) : (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </CardsContainer>
          </Box>
        </Box>
      </TabContent>
    </>
  );
};
