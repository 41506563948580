import * as React from "react";

import {
  DeleteApiCredentialMutation,
  DeleteApiCredentialMutationVariables,
} from "../../../gql";

import { Confirm } from "../../../components/Confirm";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const deleteApiCredentialMutation = gql`
  mutation DeleteApiCredentialMutation($apiCredentialId: String!) {
    deleteApiCredential(input: { id: $apiCredentialId }) {
      deleted
    }
  }
`;

interface IDeleteApiKeyModalProps {
  apiCredentialId: string | null;
  key: string;
  shown: boolean;
  onCancel: () => any;
  onDelete: () => any;
}

type DeleteApiKeyModalProps = IDeleteApiKeyModalProps;

export const DeleteApiKeyModal: React.FC<DeleteApiKeyModalProps> = (props) => {
  const [mutate] = useMutation<
    DeleteApiCredentialMutation,
    DeleteApiCredentialMutationVariables
  >(deleteApiCredentialMutation);

  const deleteCredential = async () => {
    if (props.apiCredentialId) {
      await mutate({ variables: { apiCredentialId: props.apiCredentialId } });
      props.onDelete();
    }
  };

  if (!props.apiCredentialId) return <span />;

  return (
    <Confirm
      open={props.shown}
      title="Delete API credential"
      declineTitle="Cancel"
      acceptTitle="Delete API Credential"
      message="Are you sure you would like to delete this API credential? It will no longer be usable by your integrations, but you can always
    create another API Credential on this screen."
      handleClose={props.onCancel}
      handleAccept={() => {
        deleteCredential();
        props.onCancel();
      }}
    />
  );
};
