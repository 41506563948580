import React, { useCallback, useContext, useState } from "react";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  BoardPreview,
  BoardPreviewMask,
  IBoard,
  IBoardColor,
  IBoardStyle,
  useToasts,
} from "@vestaboard/installables";
import {
  GetQuietHoursQuery,
  ResendMessageMutation,
  ResendMessageMutationVariables,
} from "../../gql";

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import { usePersonFavoritesList } from "../../hooks/usePersonFavoritesList";
import { Favorite } from "./Favorite";
import { useGetBoardStyle } from "../../hooks/useGetBoardStyle";
import { usePinMessage } from "../../hooks/usePinMessages";
import { useHideMessageHistory } from "../../hooks/useHideMessageHistory";
import { BOARD_PINNED_QUERY } from "../../hooks/useGetPinnedMessage";
import { ConfirmationModal } from "../ConfirmationModal";
import { calculateInQuietHourRange } from "../../utils/time";
import { Share } from "../../providers/ShareProvider";
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

interface IMessageCard {
  isFavorite: boolean;
  id: string;
  appearenceId?: string; // history id
  text: string;
  boardId: string;
  borderColor?: IBoardColor;
  formatted: IBoard;
  created: string;
  sendAt?: string;
  onSend?: () => void;
  author: string;
  onShare?: () => void;
  isDraft?: boolean;
  handleDiscard?: () => void;
  hasPinnedMessage?: boolean;
  hasQuietHours?: boolean;
  quietHoursData?: GetQuietHoursQuery;
  iAmOwner?: boolean;
}

const useStyles = makeStyles({
  boardContainer: {
    width: "100%",
    position: "relative",
    marginBottom: 42,
  },
  actions: {
    paddingTop: 8,
    display: "flex",
    paddingBottom: 42,
    justifyContent: "center",
  },
  icon: {
    height: 20,
  },
  codeContainer: {
    position: "relative",
    paddingTop: 50,
  },
  code: {
    backgroundColor: "#171818",
    padding: "19px 24px",
    fontSize: 16,
    width: "100%",
    borderRadius: 6,
  },
  codeButtons: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingTop: 40,
  },
  cancelButton: {
    fontSize: 12,
    textTransform: "none",
  },
  boardPreview: {
    width: 320,
    position: "absolute",
    top: -131,
    left: `calc(50% - 160px)`,
  },
  fromNow: {
    color: "#f5f5f7",
    fontSize: 10,
    position: "absolute",
    bottom: 8,
    width: "100%",
    textAlign: "center",
  },
});

const resendMessageMutation = gql`
  mutation ResendMessageMutation(
    $text: String
    $characters: [[Int!]!]
    $messageId: String
    $boardId: String!
    $forced: Boolean
    $forcePinnedMessageOverride: Boolean
  ) {
    createMessage2(
      input: {
        text: $text
        characters: $characters
        messageId: $messageId
        boardId: $boardId
        forced: $forced
        forcePinnedMessageOverride: $forcePinnedMessageOverride
      }
    ) {
      created
      message {
        id
      }
    }
  }
`;

export enum MessageActions {
  pin = "pin",
  resend = "resend",
  send = "send",
  schedule = "schedule",
}

const calculateSecondsFromFormState = (
  hourValue: string,
  minuteValue: string,
  secondValue: string
) =>
  (parseInt(hourValue) || 0) * 3600 +
  (parseInt(minuteValue) || 0) * 60 +
  (parseInt(secondValue) || 0);

export const MessageCard: React.FC<IMessageCard> = (props) => {
  const { data: boardStyleData } = useGetBoardStyle();

  const classes = useStyles();
  const history = useHistory();
  const { addFavoritedMessage, removeFavoritedMessage } =
    usePersonFavoritesList();

  const [mutatePinMessage] = usePinMessage();
  const [hourValue, setHourValue] = useState("0");
  const [minuteValue, setMinuteValue] = useState("0");
  const [secondValue, setSecondValue] = useState("0");

  const [alreadyPinnedModal, setAlreadyPinnedModal] = useState(false);
  const [currentAction, setCurrentAction] = useState(MessageActions.resend);
  const [mutateHideHistory] = useHideMessageHistory();

  const [showQuietHourModal, setShowQuietHourModal] = useState(false);
  const { setShareMessage } = useContext(Share);

  const { addToast } = useToasts();
  const [mutateCreateMessage] = useMutation<
    ResendMessageMutation,
    ResendMessageMutationVariables
  >(resendMessageMutation);

  const isInQuietHoursRange = useCallback(() => {
    if (props.quietHoursData?.board.quietHoursBegin) {
      const { board } = props.quietHoursData!;
      const { quietHoursBegin, quietHoursEnd } = board;
      return calculateInQuietHourRange(
        quietHoursBegin as string,
        quietHoursEnd as string
      );
    }
    return false;
  }, [props.quietHoursData]);

  const resendMessage = useCallback(async (characters) => {
    try {
      await mutateCreateMessage({
        variables: {
          characters,
          boardId: props.boardId,
          forced: true,
          forcePinnedMessageOverride: true,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: BOARD_PINNED_QUERY,
            variables: { boardId: props.boardId },
          },
        ],
      }).then(async ({ data }) => {
        if (data) {
          addToast(`Message sent`, {
            appearance: "success",
            autoDismiss: true,
          });

          if (props.onSend) {
            await props.onSend();
          }
          return;
        }

        addToast(`There was an error resending your message`, {
          appearance: "error",
          autoDismiss: true,
        });
      });
    } catch (err) {
      addToast(`There was a server error resending your message`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, []);

  const pinMessage = useCallback(async (characters, pinFor: number) => {
    try {
      await mutatePinMessage({
        variables: {
          input: {
            pinFor,
            characters,
            boardId: props.boardId,
            forced: true,
            forcePinnedMessageOverride: true,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: BOARD_PINNED_QUERY,
            variables: { boardId: props.boardId },
          },
        ],
      }).then(async ({ data }) => {
        if (data) {
          addToast(`Message sent`, {
            appearance: "success",
            autoDismiss: true,
          });
          if (props.onSend) {
            await props.onSend();
          }
          return;
        }

        addToast(`There was an error pinning your message`, {
          appearance: "error",
          autoDismiss: true,
        });
      });
    } catch (err) {
      addToast(`There was a server error pinning your message`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, []);

  const deleteMessage = useCallback(async (appearanceId: string) => {
    try {
      await mutateHideHistory({
        variables: {
          input: {
            appearance: appearanceId,
          },
        },
      }).then(async ({ data }) => {
        if (data) {
          addToast(`Message deleted`, {
            appearance: "success",
            autoDismiss: true,
          });

          if (props.onSend) {
            await props.onSend();
          }
          return;
        }
        addToast(`There was an error deleting your message`, {
          appearance: "error",
          autoDismiss: true,
        });
      });
    } catch (err) {
      addToast(`There was a server error deleting your message`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, []);

  const date = props.sendAt ? dayjs(+props.sendAt) : dayjs(+props.created);
  const dateFromNow = date.fromNow();
  const fromNow = dateFromNow.includes("month")
    ? date.format("D MMM YYYY, hh:mm A")
    : date.fromNow();

  return (
    <>
      <Box className={classes.boardContainer}>
        <BoardPreviewMask
          boardId={props.boardId}
          hourValue={hourValue}
          onHourValueChange={setHourValue}
          minuteValue={minuteValue}
          onMinuteValueChange={setMinuteValue}
          secondValue={secondValue}
          onSecondValueChange={setSecondValue}
          handlePin={async () => {
            if (props.hasQuietHours) {
              const inQuietHourRange = isInQuietHoursRange();
              setCurrentAction(MessageActions.pin);
              if (inQuietHourRange) return setShowQuietHourModal(true);
            }
            if (props.hasPinnedMessage) {
              setCurrentAction(MessageActions.pin);
              setAlreadyPinnedModal(true);
            } else {
              const pinFor = calculateSecondsFromFormState(
                hourValue,
                minuteValue,
                secondValue
              );
              await pinMessage(props.formatted, pinFor);
            }
          }}
          handleSend={async () => {
            if (props.hasQuietHours) {
              const inQuietHourRange = isInQuietHoursRange();
              setCurrentAction(MessageActions.resend);
              if (inQuietHourRange) return setShowQuietHourModal(true);
            }
            if (props.hasPinnedMessage) {
              setCurrentAction(MessageActions.resend);
              setAlreadyPinnedModal(true);
            } else {
              await resendMessage(props.formatted);
            }
          }}
          handleDelete={
            props.appearenceId && props.iAmOwner
              ? () => {
                  props.appearenceId && deleteMessage(props.appearenceId);
                }
              : undefined
          }
          handleShare={async () => {
            setShareMessage({
              id: props.id,
              characters: props.formatted,
            });

            if (props.onShare) {
              props.onShare();
            }
          }}
          handleDuplicate={() => {
            history.push(
              `/board/${props.boardId}/compose/duplicate/${JSON.stringify(
                props.formatted
              )}`
            );
          }}
          isDraft={props.isDraft}
          handleDiscard={props.handleDiscard}
          handleEdit={
            props.isDraft
              ? () => {
                  history.push(
                    `/board/${props.boardId}/compose/duplicate/${JSON.stringify(
                      props.formatted
                    )}`
                  );
                }
              : undefined
          }
        >
          <>
            {props.isDraft ? null : (
              <Favorite
                isFavorite={props.isFavorite}
                addFavorite={() => addFavoritedMessage(props.id)}
                removeFavorite={() => removeFavoritedMessage(props.id)}
              />
            )}

            <BoardPreview
              characters={props.formatted}
              borderColor={props.borderColor}
              boardStyle={boardStyleData?.board?.boardStyle as IBoardStyle}
              isFavorite={props.isFavorite}
              handleFavorite={() => {
                if (!props.isFavorite) {
                  addFavoritedMessage(props.id);
                } else {
                  removeFavoritedMessage(props.id);
                }
              }}
            >
              {[
                props.sendAt || props.created ? fromNow : "",
                props.author || "",
              ]}
            </BoardPreview>
          </>
        </BoardPreviewMask>
      </Box>
      {alreadyPinnedModal && (
        <ConfirmationModal
          key={`${props.id}-pinmodal`}
          onConfirm={async () => {
            switch (currentAction) {
              case MessageActions.pin: {
                const pinFor = calculateSecondsFromFormState(
                  hourValue,
                  minuteValue,
                  secondValue
                );
                await pinMessage(props.formatted, pinFor);
                break;
              }
              case MessageActions.resend: {
                await resendMessage(props.formatted);
                break;
              }
              default: {
                break;
              }
            }
            setAlreadyPinnedModal(false);
          }}
          visible={alreadyPinnedModal}
          confirmButtonText="Send"
          icon="pin"
          message="A message is currently pinned by you or another user of your account."
          subMessage="Are you sure you want to send a message?"
          onCancel={() => {
            setAlreadyPinnedModal(false);
          }}
        />
      )}
      {showQuietHourModal && (
        <ConfirmationModal
          confirmButtonText="Send"
          icon="icon-quiet-white.svg"
          message="This Vestaboard is currently set to quiet hours by the administrator of the account."
          subMessage="Are you sure you want to send a message?"
          onCancel={() => {
            setShowQuietHourModal(false);
          }}
          onConfirm={async () => {
            // resend or pin
            switch (currentAction) {
              case MessageActions.pin: {
                const pinFor = calculateSecondsFromFormState(
                  hourValue,
                  minuteValue,
                  secondValue
                );
                await pinMessage(props.formatted, pinFor);
                break;
              }
              case MessageActions.resend: {
                await resendMessage(props.formatted);
                break;
              }
              default: {
                break;
              }
            }
            setShowQuietHourModal(false);
          }}
          visible={showQuietHourModal}
        />
      )}
    </>
  );
};
