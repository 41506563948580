import { Box } from "@mui/material";
import { WifiTethering } from "@mui/icons-material";
import {
  Spacer,
  SubTitle2,
  Button,
  ModalDialog,
} from "@vestaboard/installables";
import { SelectMenu } from "@vestaboard/installables/lib/components/SelectMenu";
import { useSelectBoards } from "../../../hooks/useSelectBoards";

interface IBroadcastModal {
  visible: boolean;
  onClose: () => void;
  boardId: string;
  handleBroadcast: (boardIds: string[]) => void;
}

export const BroadcastMessageModalV2 = (props: IBroadcastModal) => {
  const { options, selectedBoards, selectAllBoards, clearBoards, selectBoard } =
    useSelectBoards(props.boardId);

  return (
    <ModalDialog
      visible={props.visible}
      onClose={props.onClose}
      fullScreenMobile
      header={
        <>
          <WifiTethering style={{ paddingRight: 4 }} />
          <Spacer />
          <SubTitle2>Broadcast Message To</SubTitle2>
        </>
      }
      footer={
        <>
          <Button buttonType="outline" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            buttonType="white"
            disabled={selectedBoards.length === 0}
            onClick={async () => {
              props.onClose();
              await props.handleBroadcast(selectedBoards);
            }}
          >
            Broadcast Now
          </Button>
        </>
      }
    >
      <Box
        style={{
          width: "100%",
        }}
      >
        <SelectMenu
          options={options || []}
          onPress={selectBoard}
          selected={selectedBoards}
          selectAll={selectAllBoards}
          clear={clearBoards}
        />
      </Box>
    </ModalDialog>
  );
};
