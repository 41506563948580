import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  Body,
  Button,
  Medium,
  Modal,
  Pagination,
  Spacer,
  SubTitle,
  SubTitle2,
  Title,
  colors,
} from "@vestaboard/installables";
import { useState } from "react";
import { useDisableBodyScroll } from "../../hooks/useDisableBackgroundScroll";
import { useMarketPlaceFeatureTypes } from "../../hooks/useMarketPlaceFeatureTypes";
import Slider from "../Slider";
import { DiscoverFeatureTypeTagSmall } from "../../pages/Installables/tabs/components/DiscoverFeatureTypeTag";
import {
  BREAKPOINT_EXTRA_SMALL,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_SMALL,
} from "../../config";
import { useMediaQuery } from "react-responsive";
import { Logo } from "./Logo";

const useStyles = makeStyles({
  modalContent: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 24,
    paddingRight: 24,
    alignContent: "center",
    height: "90%",
    textAlign: "center",
  },
  logo: {
    height: 40,
    marginBottom: 23,
  },
  installableIconContainer: {
    borderColor: colors.grey,
    borderRadius: 6,
  },
  installableIcon: {
    borderRadius: 6,
  },
  iconRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    maxWidth: 220,
    paddingBottom: 10,
  },
});

const steps = [
  {
    title: "Thanks for activating",
    body1:
      "You now have access to a full library of automated channels for your Vestaboard. Two essentials have been pre-installed to get you started.",
    body2: "",
    images: ["/blended@3x.png", "/channel-creator-icon@2x.png"],
  },
  {
    title: "Channel Creator",
    body1:
      "Build your own channel of messages to send on a schedule and sort order of your choosing.",
    body2:
      "Add messages manually or import from a spreadsheet, favorites or history.",
    images: ["/channel-creator-icon@2x.png"],
  },
  {
    title: "Blended",
    body1:
      "Blend a variety of topics you like and have Vestaboard surprise you on a schedule of your choosing.",
    body2:
      "Start with a few suggestions and continue curating as you discover more.",
    images: ["/blended@3x.png"],
  },
  {
    title: "Discover More",
    body1:
      "Explore the Discover section to find more than 300 channels to keep your Vestaboard connected with what matters most to you.",
    body2: "",
    images: [],
  },
];

interface IPostActivatePlusModalsVisible {
  step: number;
  setStep: (step: number) => void;
}

const PostActivatePlusModalsVisible = ({
  step,
  setStep,
}: IPostActivatePlusModalsVisible) => {
  const classes = useStyles();
  const { data: featureTypeData } = useMarketPlaceFeatureTypes();
  const isExtraSmallScreen = useMediaQuery({
    query: `(max-width: ${BREAKPOINT_EXTRA_SMALL})`,
  });
  const isSmall = useMediaQuery({
    query: `(max-width: ${BREAKPOINT_SMALL})`,
  });
  const isMedium = useMediaQuery({
    query: `(max-width: ${BREAKPOINT_MEDIUM})`,
  });
  const lastStep = step >= steps.length - 1;

  return (
    <Box className={classes.modalContent}>
      <Box className={classes.logo}>
        <Logo />
      </Box>
      <SubTitle2>{steps[step].title}</SubTitle2>
      <Spacer size="medium" />
      {steps[step].images.length === 1 && (
        <Box className={classes.iconRow}>
          <Box className={classes.installableIconContainer} border={1}>
            <img
              src={steps[step].images[0]}
              alt={"vestaboar+"}
              width={"88px"}
              className={classes.installableIcon}
            />
          </Box>
        </Box>
      )}
      {step === steps.length - 1 && (
        <div
          style={{
            height: 80,
            flexDirection: "row",
            width: "100%",
            maxWidth:
              isSmall || isExtraSmallScreen ? 400 : isMedium ? 650 : 800,
          }}
        >
          <Slider
            speed={500}
            animate={true}
            noArrows={true}
            slidesToShow={isSmall || isExtraSmallScreen ? 2 : 4}
            slidesToScroll={1}
            responsive={[
              {
                breakpoint: 100,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {featureTypeData?.marketplaceListingFeatureTypes.map(
              (featureType, index) => {
                return (
                  <DiscoverFeatureTypeTagSmall
                    key={featureType.id}
                    index={index}
                    onClick={() => {}}
                    title={featureType.title}
                  />
                );
              }
            )}
          </Slider>
        </div>
      )}
      <Box style={{ maxWidth: 400 }}>
        <Medium color={colors.smoke}>{steps[step].body1}</Medium>
        <Spacer size="medium" />
        {steps[step]?.body2 && (
          <>
            <Medium color={colors.smoke}>{steps[step]?.body2}</Medium>
            <Spacer size="medium" />
          </>
        )}
      </Box>
      {step === 0 && (
        <Box className={classes.iconRow}>
          <Box className={classes.installableIconContainer} border={1}>
            <img
              src={steps[step].images[0]}
              alt={"vestaboar+"}
              width={"88px"}
              className={classes.installableIcon}
            />
          </Box>
          <Box>
            <Spacer size={"medium"} />
          </Box>
          <Box className={classes.installableIconContainer} border={1}>
            <img
              src={steps[step].images[1]}
              alt={"vestaboar+"}
              width={"88px"}
              className={classes.installableIcon}
            />
          </Box>
        </Box>
      )}
      <Spacer size="medium" />
      <Pagination
        numberOfPages={steps.length}
        currentPage={step}
        setPage={setStep}
      />
      <Spacer size="medium" />
      <Spacer size="medium" />
      <Box
        style={{
          maxWidth: 650,
          width: "100%",
        }}
      >
        <Button
          buttonType={lastStep ? "white" : "outline"}
          onClick={() => {
            if (lastStep) {
              window.location.href = window.location.host.includes("localhost")
                ? `http://${window.location.host}/installed`
                : `https://${window.location.host}/installed`;
            } else {
              setStep(step + 1);
            }
          }}
        >
          {lastStep ? "Get Started" : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export const PostActivatePlusModals = (props: {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  boardId?: string;
}) => {
  const [step, setStep] = useState(0);

  useDisableBodyScroll(props.visible);

  return (
    <Modal
      visible={props.visible}
      transparent={true}
      blur={true}
      fullScreenMobile={true}
      fullScreenDesktop={true}
      goBack={
        step > 0
          ? () => {
              setStep(step - 1);
            }
          : undefined
      }
    >
      {props.visible ? (
        <PostActivatePlusModalsVisible step={step} setStep={setStep} />
      ) : null}
    </Modal>
  );
};
