import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  CreateFeedItemV2Mutation,
  CreateFeedItemV2MutationVariables,
} from "../gql";

const MUTATION = gql`
  mutation CreateFeedItemV2($input: CreateFeedItemInputV2!) {
    createFeedItem: createFeedItemV2(input: $input) {
      ... on FeedItemV2 {
        id
        personId
        subscriptionId
        attribution
        likeCount
        moderated
        created
        message {
          id
          text
          characters
        }
      }
      ... on FeedItemErrorV2 {
        type
        error
      }
    }
  }
`;

export const useCreateFeedItemV2 = () => {
  return useMutation<
    CreateFeedItemV2Mutation,
    CreateFeedItemV2MutationVariables
  >(MUTATION);
};
