import { Tabs } from "../../components/Tabs";
import { Tab } from "../../components/Tab";
import { makeStyles } from "@mui/styles";
import {
  useHistory,
  useParams,
  useLocation,
  Switch,
  Route,
  Redirect,
} from "react-router";
import { useFeatures } from "../../hooks/useFeatures";
import { HistoryProvided } from "../History";
import { FavoritesPage } from "../Favorites";
import { Drafts } from "../Drafts";
import { MessageSearch } from "../MessageSearch";
import { TabContent } from "../../components/TabContent";
import { TodaysPicks } from "../TodaysPicks.tsx";
export interface IRouteParams {
  boardId: string;
}

const useStyles = makeStyles({
  searchIcon: {
    width: 20,
    color: "#f5f5f7",
    margin: "5px 10px 20px 10px",
    opacity: 0.5,
  },
  labelContainer: {
    width: "auto",
    padding: 0,
  },
  iconLabelWrapper: {
    flexDirection: "row-reverse",
  },
});


export const Messages = () => {
  const params = useParams<IRouteParams>();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { features } = useFeatures();

  const tabs = [
    {
      key: "history",
      label: "History",
      enabled: features.history,
    },
    {
      key: "favorites",
      label: "Favorites",
      enabled: features.favorites,
    },
    {
      key: "drafts",
      label: "Drafts",
      enabled: features.drafts,
    },
    {
      key: "picks",
      label: "Picks",
      enabled: features.picks,
    },
    {
      key: "shared",
      label: "Shared",
      enabled: features.shared,
    },
    {
      key: "search",
      label: "Search",
      enabled: true,
    },
  ].filter((tab) => tab.enabled);

  const isSearching = location.pathname.includes("search");

  return (
    <>
      {!isSearching ? (
        <>
          <Tabs
            onChange={(tab) => {
              if (tabs[tab].key === "categories") {
                return;
              }
              history.push(
                `/board/${params.boardId}/messages/${tabs[tab].key}`
              );
            }}
            value={tabs.reduce((prev, current, index) => {
              return location.pathname.includes(current.key) ? index : prev;
            }, 0)}
          >
            {tabs.map((tab) => {
              if (tab.key === "search") {
                return (
                  <Tab
                    key={tab.key}
                    label={tab.label}
                    classes={{
                      wrapped: classes.iconLabelWrapper,
                    }}
                  />
                );
              }
              return <Tab key={tab.key} label={tab.label} />;
            })}
          </Tabs>

          <TabContent>
            <Switch>
              <Route
                path="/board/:boardId/messages/history"
                component={HistoryProvided}
              />
              <Route
                path="/board/:boardId/messages/favorites"
                component={FavoritesPage}
              />
              <Route
                path="/board/:boardId/messages/drafts"
                component={Drafts}
              />
              <Route
                path="/board/:boardId/messages/picks"
                component={TodaysPicks}
              />
              <Redirect to={`/board/${params.boardId}/messages/history`} />
            </Switch>
          </TabContent>
        </>
      ) : (
        <MessageSearch />
      )}
    </>
  );
};
