import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  BoardMessageAppearancesSearchQuery,
  BoardMessageAppearancesSearchQueryVariables,
} from "../gql";

const MESSAGE_APPEARANCES_SEARCH_QUERY = gql`
  query BoardMessageAppearancesSearchQuery(
    $boardId: String!
    $page: Int!
    $term: String!
  ) {
    board(id: $boardId) {
      id
      boardStyle
      history(page: $page, query: $term, perPage: 50) {
        id
        appeared
        message {
          id
          text
          formatted
          created
          authorFormatted
          sendAt
        }
      }
    }
  }
`;

interface IUseMessageHistorySearch {
  boardId: string;
  page?: number;
  term: string;
}

export const useMessageHistorySearch = ({
  boardId,
  page,
  term,
}: IUseMessageHistorySearch) => {
  return useQuery<
    BoardMessageAppearancesSearchQuery,
    BoardMessageAppearancesSearchQueryVariables
  >(MESSAGE_APPEARANCES_SEARCH_QUERY, {
    skip: !boardId,
    variables: {
      boardId: boardId || "",
      page: page || 0,
      term,
    },
    fetchPolicy: "network-only",
  });
};
