import * as React from "react";

import { Button, ButtonProps } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles<undefined, { color: string }>({
  button: {
    border: ({ color }) => `solid 1px ${color}`,
    backgroundColor: "transparent",
    color: ({ color }) => `${color} !important`,
    fontSize: 16,
    textTransform: "none",
    padding: "8px 32px",
    "&:disabled": {
      opacity: 0.25,
    },
  },
});

interface OutlinedButtonProps extends Omit<ButtonProps, "color"> {
  color?: string;
}

export const OutlinedButton = ({ color, ...props }: OutlinedButtonProps) => {
  const classes = useStyles({ color: color ?? "#fff" });
  return (
    <Button
      {...props}
      className={[classes.button, props.className].filter((e) => !!e).join(" ")}
    >
      {props.children}
    </Button>
  );
};
