import * as React from "react";
import { Box, CircularProgress } from "@mui/material";
import { FavoritesList } from "./components/FavoritesList";
import { useParams } from "react-router";
import {
  usePersonFavoritesList,
  UsePersonFavoritesListResponse,
} from "../../hooks/usePersonFavoritesList";
import { SimplePagination } from "../../components/SimplePagination";
import { Spacer } from "@vestaboard/installables";

interface IFavoritesPageProps {
  boardId: string;
  favoritesResponse: UsePersonFavoritesListResponse;
}

type FavoritesPageProps = IFavoritesPageProps;

interface IRouteMatch {
  boardId: string;
}

export const FavoritesPage: React.FC = () => {
  const params = useParams<IRouteMatch>();
  const { boardId } = params;

  const [currentPage, setCurrentPage] = React.useState(1);

  const favoritesResponse = usePersonFavoritesList(currentPage - 1);

  if (!boardId) {
    return <CircularProgress />;
  }

  const pagination = (
    <Box
      alignSelf="center"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      paddingTop={"40px"}
    >
      <SimplePagination
        disablePrevious={currentPage === 1}
        previous={() => {
          setCurrentPage((page) => page - 1);
        }}
        next={() => {
          setCurrentPage((page) => page + 1);
        }}
        disableNext={
          !(
            favoritesResponse.data?.viewer?.account?.person.favorites.length ||
            0
          )
        }
      />
      <Spacer size="extraLarge" />
    </Box>
  );

  return (
    <>
      <FavoritesList boardId={boardId} favoritesResponse={favoritesResponse} />
      {pagination}
    </>
  );
};

// This was made to potentially supply the favorites data from higher in the component stack
// The current implementation of usePersonFavorites causes many re-renders, affecting neighbor component stacks.
export const FavoritesProvided: React.FC<FavoritesPageProps> = (props) => {
  if (props.boardId) {
    return <CircularProgress />;
  }

  return (
    <>
      <FavoritesList
        boardId={props.boardId}
        favoritesResponse={props.favoritesResponse}
      />
    </>
  );
};
