import { leftPad } from './left-pad'
import dayjs from "dayjs";

export const parseHourMinutes = (input: string): { hoursMilitary: string, minutes: string } => {
    const minutes = `${input.substr(2, 1) || "0"}${input.substr(3, 1) || "0"}`;
    const hoursMilitary = `${input.substr(0, 1) || "0"}${
        input.substr(1, 1) || "0"
    }`;
    return {
        hoursMilitary,
        minutes
    }
}

export const getFormattedTime = (input: string) => {
    const { minutes, hoursMilitary } = parseHourMinutes(input)
    const hour =
        parseInt(hoursMilitary, 10) > 12
            ? leftPad(parseInt(hoursMilitary, 10) - 12)
            : hoursMilitary;
    const amPm =
        parseInt(hoursMilitary, 10) === 24
            ? "AM"
            : parseInt(hoursMilitary, 10) === 12
            ? "PM"
            : parseInt(hoursMilitary, 10) > 12
                ? "PM"
                : "AM";

    return {
        minutes,
        hour,
        amPm,
    };
};

export const calculateInQuietHourRange = (start: string, end: string): boolean => {
    const {
        hoursMilitary: startHour,
        minutes: startMinute
    } = parseHourMinutes(start)

    const {
        hoursMilitary: endHour,
        minutes: endMinute
    } = parseHourMinutes(end)

    const now = dayjs().format('HHmm')
    const {
        hoursMilitary: nowHour,
        minutes: nowMinute
    } = parseHourMinutes(now)

    if (nowHour > startHour && nowHour < endHour) return true
    if (nowHour === startHour && nowMinute >= startMinute) return true
    if (nowHour === endHour && nowMinute < endMinute) return true
    return false
}
