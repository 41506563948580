import { GetViewerRoleQuery } from "../gql";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const GET_VIEWER = gql`
  query GetViewerRole {
    viewer {
      id
      superadmin {
        id
      }
      account {
        id
        emailAddress
        person {
          id
          firstName
          lastName
          tenants {
            id
            name
          }
          tenantAssociations {
            id
            role
          }
        }
      }
    }
  }
`;

export const usePermissions = () => {
  const { data, loading, error } = useQuery<GetViewerRoleQuery>(GET_VIEWER);

  const hasRole = (role: string): boolean =>
    !!data &&
    !!data.viewer &&
    !!data.viewer.account &&
    !!data.viewer.account.person.tenantAssociations.length &&
    !!data.viewer.account.person.tenantAssociations.reduce(
      (prev: any, current: any) => {
        return prev || current.role === role;
      },
      false
    );

  return {
    isAdmin: () => hasRole("Admin"),
    isOwner: () => hasRole("Owner"),
    isUser: () => hasRole("User"),
    isSuperAdmin: () => !!data?.viewer?.superadmin?.id,
    tenants: data?.viewer?.account?.person?.tenants || [],
    loading,
    data,
    error,
  };
};
