import React from 'react';
import {Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  button: {
    border: 'none',
    // padding: '5px 5px',
    borderRadius: 200,
    '-webkit-backdrop-filter': 'blur(10px)',
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(125, 135, 142, 0.2)',
    fontFamily: 'HelveticaNeue',
    fontSize: 28,
    fontWeight: 'normal',
    lineHeight: '22px',
    color: '#f5f5f7',
    textTransform: 'none',
    boxShadow: 'none',
    minWidth: 42,
    minHeight: 42,
    '&:focus': {
      backgroundColor: 'rgba(125, 135, 142, 0.2)',
    }
  }
})

export interface CircleBlurredButtonProps {
  left?: boolean;
  right?: boolean;
  direction?: 'left' | 'right';
  onClick?(): void;
  className?: string;
}

const CircleBlurredButton: React.FC<CircleBlurredButtonProps> = props => {
  const classes = useStyles();

  return (
    <Button className={[classes.button, props.className].filter(e => !!e).join(" ")} onClick={props.onClick}>
      {props.children}
    </Button>
  );
};

export default CircleBlurredButton;
