import * as React from "react";

import { Button, ButtonProps, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface IFlatButton extends ButtonProps {}

const useStyles = makeStyles({
  button: {
    textTransform: "none",
    fontSize: 13,
    borderRadius: 3,
    backgroundColor: "#000",
    boxShadow: "none",
    fontFamily: "HelveticaNeue",
    padding: "9px 26px",
    color: "#f5f5f7",
    "&:hover": {
      backgroundColor: "#262A2D",
      color: "#FFF",
    },
  },
  buttonText: {
    fontSize: 12,
    opacity: 0.5,
  },
});

export const FlatButton = (props: IFlatButton) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      variant="contained"
      color="secondary"
      className={[classes.button, props.className].filter(e => !!e).join(" ")}
    >
      <Typography className={classes.buttonText}>{props.children}</Typography>
    </Button>
  );
};
