import dayjs from "dayjs";
import dayjsduration from 'dayjs/plugin/duration'
dayjs.extend(dayjsduration)
interface IDurationResult {
  hours: number;
  minutes: number;
}

export const duration = (
  startTime: string,
  endTime: string,
): IDurationResult => {
  const baseDate = new Date();

  const startDate = dayjs(baseDate).hour(parseInt(startTime.substring(0, 2))).minute(parseInt(startTime.substring(2, 4)))
  const endDate = dayjs(baseDate).hour(parseInt(endTime.substring(0, 2))).minute(parseInt(endTime.substring(2, 4)))


  const duration = dayjs.duration(endDate.diff(startDate));
  const durationHours = duration.hours();
  const durationMinutes = duration.minutes();

  return {
    hours: parseInt(
      durationHours === 0 && durationMinutes < 0
        ? `23`
        : `${durationHours < 0 ? 24 + durationHours : durationHours}`,
      10
    ),
    minutes: parseInt(
      `${durationMinutes < 0 ? 60 + durationMinutes : durationMinutes}`,
      10
    ),
  };
};
