import gql from "graphql-tag";
import {
  GetColorTemplateV2Query,
  GetColorTemplateV2QueryVariables,
} from "../gql";
import { useQuery } from "@apollo/client";

const QUERY = gql`
  query GetColorTemplateV2($id: String!) {
    colorTemplate: colorTemplateV2(id: $id) {
      __typename
      ... on ColorTemplateV2 {
        id
        title
        createdAt
        messages {
          id
          text
          characters
        }
      }
      ... on ColorTemplateErrorV2 {
        type
        error
      }
    }
  }
`;

export const useGetColorTemplateV2 = (id: string) => {
  return useQuery<GetColorTemplateV2Query, GetColorTemplateV2QueryVariables>(
    QUERY,
    {
      variables: {
        id,
      },
    }
  );
};
