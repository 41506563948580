import {
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables,
} from "../../../gql";

import gql from "graphql-tag";
import { useGetInstallableSubscriptions } from "./useGetInstallableSubscriptions";
import { useMutation } from "@apollo/client";
import { useToasts } from "@vestaboard/installables";
import { GET_INSTALLED_INSTALLABLES } from "../tabs/Installed";

const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription(
    $installationId: String
    $installableId: String
    $boardId: String!
    $messageSet: String
    $marketplaceListing: String
  ) {
    createSubscription(
      input: {
        installationId: $installationId
        installableId: $installableId
        boardId: $boardId
        messageSetId: $messageSet
        marketplaceListingId: $marketplaceListing
      }
    ) {
      created
      subscription {
        id
      }
    }
  }
`;

interface IUseCreateSubscription {
  boardId: string;
  tenantId: string;
}

interface ICreateSubscription {
  marketplaceListingId: string;
}

export const useCreateSubscription = (props: IUseCreateSubscription) => {
  const [createSubscriptionMutation] = useMutation<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >(CREATE_SUBSCRIPTION);
  const { addToast } = useToasts();

  const createSubscription = async (input: ICreateSubscription) => {
    try {
      const subscriptionResponse = await createSubscriptionMutation({
        variables: {
          boardId: props.boardId,
          marketplaceListing: input.marketplaceListingId,
        },
        refetchQueries: [
          {
            query: GET_INSTALLED_INSTALLABLES,
            variables: {
              boardId: props.boardId,
            },
          },
          {
            query: useGetInstallableSubscriptions.query,
            variables: {
              boardId: props.boardId,
              tenantId: props.tenantId,
            },
          },
        ],
      });

      addToast(`New channel created`, {
        appearance: "success",
        autoDismiss: true,
      });

      return subscriptionResponse.data?.createSubscription?.subscription?.id;
    } catch (err) {
      addToast(`Error creating new channel`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return {
    createSubscription,
  };
};
