import { useMutation } from "@apollo/client";
import { Box } from "@mui/material";
import { Button, Spacer, SubTitle, Title } from "@vestaboard/installables";
import gql from "graphql-tag";
import * as React from "react";
import { useParams } from "react-router-dom";
import { BoardDropdown } from "../../components/BoardSelector";
import { OutlinedButton } from "../../components/OutlinedButton";
import {
  UpsertSingletonChannelForListingMutation,
  UpsertSingletonChannelForListingMutationVariables,
} from "../../gql";

type ConfigureListingProps = {};

const UPSERT_SINGLETON_CHANNEL_FOR_LISTING = gql`
  mutation UpsertSingletonChannelForListing(
    $listing: String!
    $board: String!
    $data: String
  ) {
    upsertSingletonChannelForListing(
      input: { board: $board, listing: $listing, data: $data }
    ) {
      channel {
        id
        configurationToken
      }
    }
  }
`;

const useUpsertSingletonChannelForListingMutation = () =>
  useMutation<
    UpsertSingletonChannelForListingMutation,
    UpsertSingletonChannelForListingMutationVariables
  >(UPSERT_SINGLETON_CHANNEL_FOR_LISTING);

export const ConfigureListingView: React.FC<ConfigureListingProps> = (
  props
) => {
  const [selectedBoard, setSelectedBoard] = React.useState("");
  const { listing, data } = useParams<{ listing: string; data: string }>();

  const { state, redirect_uri } = JSON.parse(atob(data));

  const [upsertSingletonChannelForListing] =
    useUpsertSingletonChannelForListingMutation();

  const save = async () => {
    const response = await upsertSingletonChannelForListing({
      variables: {
        board: selectedBoard,
        listing,
      },
    });

    if (!response.data) throw Error();

    const token =
      response.data.upsertSingletonChannelForListing.channel.configurationToken;
    window.location.href = `${redirect_uri}#state=${state}&access_token=${token}&token_type=Bearer`;
  };

  return (
    <Box padding={5}>
      <SubTitle>Which board would you like to link?</SubTitle>
      <Spacer size="large" />
      <BoardDropdown value={selectedBoard} onChange={setSelectedBoard} />
      <Spacer size="large" />
      <Button
        disabled={!redirect_uri || !state || !selectedBoard}
        onClick={() => save()}
        width={200}
        buttonType="white"
      >
        Link Board
      </Button>
    </Box>
  );
};
