import { Box, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMediaQuery } from "react-responsive";

import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import gql from "graphql-tag";
import {
  BoardPurchasesQuery,
  BoardPurchasesQueryVariables,
  OptOutOfRenewalMutation,
  OptOutOfRenewalMutationVariables,
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables,
} from "../../gql";
import {
  Body,
  SubTitle,
  colors,
  Spacer,
  Button,
  Medium,
  Modal,
  Input,
  useToasts,
} from "@vestaboard/installables";
import dayjs from "dayjs";
import { useGetRole } from "../../hooks/useGetRole";
import { useState } from "react";
import { SelectMenu } from "@vestaboard/installables/lib/components/SelectMenu";
import { BREAKPOINT_EXTRA_SMALL } from "../../config";
import { GET_PLUS_CTA } from "../Installables";
import { CTABox, CTAMessage } from "../Installables/tabs/Discover";
import { usePaywall } from "../../providers/PaywallProvider";

export const BOARD_PURCHASES_QUERY = gql`
  query BoardPurchases($id: String!) {
    board(id: $id) {
      id
      #   // productApplications is an array, so you'll need to filter out anything that has a nextBillingDate that has already passed and take the first one
      productApplications {
        id
        nextBillingDate
        ends
        optOutOfAutoRenewal
        created # Activation Date
        activationCode {
          id
          code
        }
        pricing {
          id
          title
        }
        purchaseLineItem {
          id
          purchase {
            id
            emailAddress
            purchaseSession {
              id
              paymentMethod {
                id
                company
                number
              }
            }
          }
        }
      }
    }
  }
`;

const UPDATE_PAYMENT_MUTATION = gql`
  mutation UpdatePaymentMethod($productApplicationId: String!) {
    updateShopifyPaymentMethod(
      input: { productApplicationId: $productApplicationId }
    ) {
      success
    }
  }
`;

const OPT_OUT_OF_RENEWAL_MUTATION = gql`
  mutation OptOutOfRenewal($productApplicationId: String!, $reason: String!) {
    optOutOfRenewal(
      input: { productApplicationId: $productApplicationId, reason: $reason }
    ) {
      id
      optOutOfAutoRenewal
      optOutReason
      optOutDate
    }
  }
`;

const useStyles = makeStyles({
  grayBox: {
    backgroundColor: "#181919",
    padding: 16,
    paddingLeft: 28,
    paddingRight: 28,
    height: 100,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  installButton: {
    width: 140,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 810,
    flexGrow: 1,
    width: "100%",
  },
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    alighItems: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  greenDot: {
    height: "8px",
    marginLeft: "8px",
    width: "8px",
    backgroundColor: colors.green,
    borderRadius: "50%",
    display: "inline-block",
    marginTop: 8,
  },
  spacedRow: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  divider: {
    background: colors.grey,
    marginTop: 16,
    marginBottom: 16,
  },
  billingInfoRow: {
    padding: 16,
  },
});

interface IBillingProps {
  boardId: string;
}
export const Billing = (props: IBillingProps) => {
  const [openCancel, setOpenCancel] = useState(false);
  const [openUpdatePayment, setOpenUpdatePayment] = useState(false);

  const { isPlus, setShown } = usePaywall();

  const classes = useStyles();
  const { boardId } = props;

  const { data, loading } = useQuery<
    BoardPurchasesQuery,
    BoardPurchasesQueryVariables
  >(BOARD_PURCHASES_QUERY, {
    variables: {
      id: boardId,
    },
  });

  const [optOutMutation] = useMutation<
    OptOutOfRenewalMutation,
    OptOutOfRenewalMutationVariables
  >(OPT_OUT_OF_RENEWAL_MUTATION);

  const [updatePaymentMutation] = useMutation<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >(UPDATE_PAYMENT_MUTATION);

  const filteredProducts = data?.board.productApplications?.filter((product) =>
    dayjs().isBefore(dayjs(product.nextBillingDate))
  ) as BoardPurchasesQuery["board"]["productApplications"];

  const productApplication =
    filteredProducts && filteredProducts.length === 1
      ? filteredProducts[0]
      : data?.board.productApplications[0];

  //   special case for Back Wall, no payment
  const hasNextBilling = productApplication?.nextBillingDate;

  const optedOut = productApplication?.optOutOfAutoRenewal;
  const { addToast } = useToasts();

  const isExtraSmallScreen = useMediaQuery({
    query: `(max-width: ${BREAKPOINT_EXTRA_SMALL})`,
  });

  const { iAmOwner, emailAddress } = useGetRole(boardId);

  const iAmCardHolder =
    emailAddress ===
    productApplication?.purchaseLineItem?.purchase?.emailAddress;

  const ends = productApplication?.ends
    ? dayjs(productApplication?.ends).format("MMMM DD, YYYY")
    : "-";

  const optOutOfRenewal = async (
    reason: string,
    productApplicationId: string
  ) => {
    try {
      await optOutMutation({
        variables: {
          reason,
          productApplicationId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: BOARD_PURCHASES_QUERY,
            variables: {
              id: props.boardId,
            },
          },
        ],
      });
      addToast("Subscription cancelled", {
        appearance: "success",
        autoDismiss: true,
      });
      setOpenCancel(false);
    } catch (err) {
      addToast("Error cancelling subcription", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  if (loading) {
    return (
      <Box className={classes.contentWrapper}>
        <Box className={classes.centerContainer}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.contentWrapper}>
        <Box className={classes.content}>
          {!isPlus ? (
            <CTABox
              id={""}
              subtitle={CTAMessage}
              cta={GET_PLUS_CTA}
              images={{
                "@1x": `${
                  isExtraSmallScreen
                    ? "/plus-ad-mobile/plus-ad-mobile@1x.png"
                    : "/plus-ad-desktop/plus-ad-desktop@1x.png"
                }`,
                "@2x": `${
                  isExtraSmallScreen
                    ? "/plus-ad-mobile/plus-ad-mobile@2x.png"
                    : "/plus-ad-desktop/plus-ad-desktop@2x.png"
                }`,
                "@3x": `${
                  isExtraSmallScreen
                    ? "/plus-ad-mobile/plus-ad-mobile@3x.png"
                    : "/plus-ad-desktop/plus-ad-desktop@3x.png"
                }`,
              }}
              onCTA={() => setShown(true, null)}
              hasLogo
            />
          ) : (
            <>
              <Box className={classes.grayBox}>
                {!productApplication ? (
                  <></>
                ) : (
                  <>
                    <Box>
                      <Box className={classes.row}>
                        <Medium>Active Plan</Medium>
                        <span className={classes.greenDot} />
                      </Box>
                      <Spacer size={"small"} />
                      <Box>{productApplication?.pricing?.title}</Box>
                    </Box>
                    <Box
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Box>
                        <Medium>
                          {optedOut || !hasNextBilling
                            ? "Expiration Date"
                            : "Next Billing Date"}
                        </Medium>
                      </Box>
                      <Spacer size={"small"} />
                      <Box>{ends}</Box>
                    </Box>
                  </>
                )}
              </Box>
              {/* END HEADER BOX */}
              <Box style={{ padding: 8 }}>
                {productApplication?.created && (
                  <Box className={classes.billingInfoRow}>
                    <Medium>Activation Date</Medium>
                    <Spacer size={"small"} />
                    <Body>
                      {dayjs(productApplication?.created).format(
                        "MMMM DD, YYYY"
                      )}
                    </Body>
                  </Box>
                )}
                {productApplication?.activationCode?.code && (
                  <>
                    <Divider className={classes.divider} />
                    <Box className={classes.billingInfoRow}>
                      <Medium>Activation Code</Medium>
                      <Spacer size={"small"} />
                      <Body>{productApplication?.activationCode?.code}</Body>
                    </Box>
                  </>
                )}
                {productApplication?.purchaseLineItem?.purchase?.purchaseSession
                  ?.paymentMethod?.number && (
                  <>
                    <Divider className={classes.divider} />
                    <Box className={classes.billingInfoRow}>
                      <Medium>Payment Method</Medium>

                      <Box className={classes.spacedRow}>
                        <Body>
                          {
                            productApplication?.purchaseLineItem?.purchase
                              ?.purchaseSession?.paymentMethod?.company
                          }
                          {" **"}
                          {
                            productApplication?.purchaseLineItem?.purchase
                              ?.purchaseSession?.paymentMethod?.number
                          }
                        </Body>
                        {(iAmOwner || iAmCardHolder) &&
                          !optedOut &&
                          hasNextBilling && (
                            <Box style={{ height: 40 }}>
                              <Button
                                buttonType="ghost"
                                onClick={() => {
                                  setOpenUpdatePayment(true);
                                }}
                              >
                                Change
                              </Button>
                            </Box>
                          )}
                      </Box>
                    </Box>
                  </>
                )}

                <Divider className={classes.divider} />
                <Box className={classes.billingInfoRow}>
                  {!!productApplication && (
                    <Medium>
                      {optedOut || !hasNextBilling
                        ? `Your membership will expire at the end of your current
                billing period. Never miss a Vestaboard+ message by
                restarting your membership below.`
                        : `Your membership will auto-renew on the next billing date above.
                Membership fees may take a few days after the billing date to
                appear on your account. Sales tax may apply.`}
                    </Medium>
                  )}
                  {(iAmOwner || iAmCardHolder) &&
                    !optedOut &&
                    hasNextBilling && (
                      <Box style={{ width: 300, paddingTop: 32 }}>
                        <Button
                          buttonType="outline"
                          onClick={() => {
                            setOpenCancel(true);
                          }}
                        >
                          Cancel Membership
                        </Button>
                      </Box>
                    )}
                  {(optedOut || !hasNextBilling) && (
                    <Box style={{ width: 300, paddingTop: 32 }}>
                      <Button
                        buttonType="white"
                        onClick={() => {
                          setShown(true, null);
                        }}
                      >
                        {!productApplication
                          ? "Activate Vestaboard+"
                          : "Keep Vestaboard+"}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
      {/* Rerender component on open close, to reset cancel flow */}

      {openCancel && (
        <CancelModal
          visible={openCancel}
          onClose={() => setOpenCancel(false)}
          nextBillingDate={ends}
          optOutOfRenewal={async (reason) =>
            productApplication &&
            (await optOutOfRenewal(reason, productApplication.id))
          }
        />
      )}
      <UpdatePaymentMethodModal
        visible={openUpdatePayment}
        onClose={() => setOpenUpdatePayment(false)}
        nextBillingDate={ends}
        updatePayment={async () => {
          productApplication &&
            (await updatePaymentMutation({
              variables: {
                productApplicationId: productApplication.id,
              },
            }));
        }}
      />
    </>
  );
};

export const CancelModal = (props: {
  visible: boolean;
  onClose: () => void;
  nextBillingDate: string;
  optOutOfRenewal: (reason: string) => void;
}) => {
  const [proceedToCancel, setProceedToCancel] = useState(false);
  const [cancelReason, setCancelReason] = useState<string | undefined>();
  const [otherReason, setOtherReason] = useState("");

  return (
    <Modal visible={props.visible} onClose={props.onClose} fullScreenMobile>
      {proceedToCancel ? (
        <Box style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8 }}>
          <Box>
            <SubTitle>
              Please help us improve the experience for other customers. Which
              of these describes your Vestaboard+ experience?{" "}
            </SubTitle>
          </Box>
          <Spacer size={"large"} />
          <SelectMenu
            options={[
              {
                id: "Too expensive",
                name: "Too expensive",
              },
              {
                id: "Subscribed by accident",
                name: "Subscribed by accident",
              },
              {
                id: "Only need occasionally",
                name: "Only need occasionally",
              },
              {
                id: "Too hard to learn",
                name: "Too hard to learn",
              },
              {
                id: "Technical issue(s)",
                name: "Technical issue(s)",
              },
              {
                id: "Didn't find valuable",
                name: "Didn't find valuable",
              },
            ]}
            onPress={(reason) => {
              setCancelReason(reason);
            }}
            selected={cancelReason ? [cancelReason] : ["Other"]}
          />
          <Spacer size={"large"} />
          <Input
            value={otherReason}
            onValueChange={setOtherReason}
            label="Other"
            multiline
          />
          <Spacer size={"extraLarge"} />
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              buttonType="outline"
              disabled={!cancelReason && !otherReason}
              onClick={async () => {
                await props.optOutOfRenewal(
                  otherReason
                    ? `${
                        cancelReason ? `${cancelReason} -` : "Other -"
                      } ${otherReason}`
                    : cancelReason || ""
                );
              }}
              width={300}
            >
              Cancel Membership
            </Button>
          </Box>
        </Box>
      ) : (
        <Box style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8 }}>
          <SubTitle>
            We're sorry you're thinking of not continuing with Vestaboard+.
          </SubTitle>
          <Spacer size={"large"} />
          <Body>
            Want to get more out of your membership? Schedule a video chat with
            our team so we can help create a stream of engaging content that
            caters to your Vestaboard needs.
          </Body>
          <Spacer size={"extraLarge"} />
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              buttonType="white"
              onClick={() => {
                window.location.href =
                  "mailto:support@vestaboard.com?subject=Schedule a Vestaboard+ Video Chat";
              }}
              width={300}
            >
              Schedule a Video Chat
            </Button>
          </Box>
          <Spacer size={"extraLarge"} />
          <Body>
            If you choose to cancel your membership renewal, you can continue
            using Vestaboard+ until the end of your current billing period on{" "}
            {props.nextBillingDate}.
          </Body>
          <Spacer size={"extraLarge"} />
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              buttonType="outline"
              onClick={() => {
                setProceedToCancel(true);
              }}
              width={200}
            >
              Proceed to Cancel
            </Button>
          </Box>
          <Spacer size={"large"} />
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              buttonType="ghost"
              onClick={() => {
                props.onClose();
              }}
              width={200}
            >
              Keep Vestaboard+
            </Button>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export const UpdatePaymentMethodModal = (props: {
  visible: boolean;
  onClose: () => void;
  nextBillingDate: string;
  updatePayment: () => void;
}) => {
  const [emailSent, setEmailSent] = useState(false);
  const classes = useStyles();
  return (
    <Modal visible={props.visible} onClose={props.onClose} fullScreenMobile>
      <Box style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8 }}>
        <SubTitle>Update your payment method</SubTitle>
        <Spacer size="large" />

        <Body>
          Your Vestaboard+ membership is set to auto-renew on{" "}
          {props.nextBillingDate} via your previous payment method.
        </Body>
        <Spacer size="medium" />
        <Body>
          Never miss a Vestaboard+ message by keeping your preferred payment
          details up-to-date. Click the button below to trigger an email to the
          original card holder to update payment information.
        </Body>
        <Spacer size="extraLarge" />
        {emailSent ? (
          <>
            <Box className={classes.grayBox}>
              <Box style={{ textAlign: "center" }}>
                <Medium>
                  An email with the link to update your payment information has
                  been sent to the original card holder.
                </Medium>
              </Box>
            </Box>
            <Spacer size="medium" />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                buttonType="ghost"
                onClick={async () => {
                  await props.updatePayment();
                }}
                width={200}
              >
                Send Email Again
              </Button>
            </Box>
          </>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              buttonType="white"
              onClick={async () => {
                await props.updatePayment();
                setEmailSent(true);
              }}
              width={300}
            >
              Update Payment Information
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
