import * as React from "react";

import { Button, ButtonProps } from "@mui/material";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles({
  button: {
    textTransform: "none",
    fontSize: 13,
    borderRadius: 3,
    backgroundColor: "#393b3e",
    boxShadow: "none",
    fontFamily: "HelveticaNeue",
    padding: "3px 26px",
  },
  dangerButton: {
    backgroundColor: "#d52819",
  },
});

interface IActionButton extends ButtonProps {
  buttonColor?: "danger";
}

export const ActionButton = (props: IActionButton) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      variant="contained"
      color="secondary"
      className={[
        classes.button,
        ...(props.buttonColor === "danger" ? [classes.dangerButton] : []),
      ].join(" ")}
    >
      {props.children}
    </Button>
  );
};
