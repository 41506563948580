import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  CreateScheduledMessageV2Mutation,
  CreateScheduledMessageV2MutationVariables,
} from "../gql";

const MUTATION = gql`
  mutation CreateScheduledMessageV2($input: CreateScheduledMessageInputV2!) {
    createScheduledMessage: createScheduledMessageV2(input: $input) {
      __typename
      ... on ScheduledMessageV2 {
        id
        sendAt
        attribution
        message {
          id
          text
          characters
        }
      }
      ... on ScheduledMessageErrorV2 {
        type
        error
      }
    }
  }
`;

export const useCreateScheduledMessageV2 = () => {
  return useMutation<
    CreateScheduledMessageV2Mutation,
    CreateScheduledMessageV2MutationVariables
  >(MUTATION);
};
