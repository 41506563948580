import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 700,
  },
});

export const LoadMask = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CircularProgress />
    </Box>
  );
};
