import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  CreateBoardMessageMutation,
  CreateBoardMessageMutationVariables,
} from "../gql";

const CREATE_MESSAGE_MUTATION = gql`
  mutation CreateBoardMessageMutation(
    $text: String
    $characters: [[Int!]!]
    $messageId: String
    $boardId: String!
  ) {
    createMessage(
      input: {
        text: $text
        characters: $characters
        messageId: $messageId
        boardId: $boardId
      }
    ) {
      created
      message {
        id
      }
    }
  }
`;

export const useCreateMessage = () => {
  return useMutation<
    CreateBoardMessageMutation,
    CreateBoardMessageMutationVariables
  >(CREATE_MESSAGE_MUTATION);
};
