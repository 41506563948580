import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  TransferOwnershipV2Mutation,
  TransferOwnershipV2MutationVariables,
} from "../gql";

const TRANSFER_OWNERSHIP_V2_MUTATION = gql`
  mutation TransferOwnershipV2(
    $toInput: UpdateRoleInputV2!
    $fromInput: UpdateRoleInputV2!
  ) {
    transferOwnershipTo: updateRoleV2(input: $toInput) {
      ... on RoleV2 {
        id
        permission
        hasPendingInvite
      }
      ... on RoleErrorV2 {
        type
        error
      }
    }
    transferOwnershipFrom: updateRoleV2(input: $fromInput) {
      ... on RoleV2 {
        id
        permission
        hasPendingInvite
      }
      ... on RoleErrorV2 {
        type
        error
      }
    }
  }
`;

export const useTransferOwnershipV2 = () => {
  return useMutation<
    TransferOwnershipV2Mutation,
    TransferOwnershipV2MutationVariables
  >(TRANSFER_OWNERSHIP_V2_MUTATION);
};
