import * as React from "react";

import { Button, ButtonProps } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  button: {
    backgroundColor: "#FFF",
    color: "#1e2022 !important",
    fontSize: 16,
    textTransform: "capitalize",
    padding: "8px 32px",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
});

export const FilledButton = (props: ButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      className={[classes.button || "", props.className || ""].join(" ")}
    >
      {props.children}
    </Button>
  );
};
