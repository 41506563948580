import * as React from "react";

import { MenuItem, Select as MuiSelect } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface IOption {
  key: string;
  value: string;
}

interface ISelect {
  onValueChange: (value: string) => void;
  value: string;
  options: Array<IOption>;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
}

const useStyles = makeStyles({
  container: {},
});

export const Select = (props: ISelect) => {
  const classes = useStyles();

  return (
    <MuiSelect
      className={classes.container}
      variant="filled"
      value={props.value}
      onChange={(e) => props.onValueChange(e.target.value as string)}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    >
      {!props.required && <MenuItem value="">--</MenuItem>}
      {props.options.map((option) => (
        <MenuItem value={option.key} key={option.key}>
          {option.value}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};
