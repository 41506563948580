import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  GetDiscoverCategoriesQuery,
  GetDiscoverCategoriesQueryVariables,
} from "../gql";

export const GET_DISCOVER_CATEGORIES = gql`
  query GetDiscoverCategories {
    marketplaceListingsCategories {
      id
      title
    }
  }
`;

export const useDiscoverCategories = () => {
  return useQuery<
    GetDiscoverCategoriesQuery,
    GetDiscoverCategoriesQueryVariables
  >(GET_DISCOVER_CATEGORIES);
};
