import * as React from "react";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import MuiSwitch, { SwitchProps } from "@mui/material/Switch";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  checkedContainer: {
    position: "relative",
    opacity: 1,
  },
  check: {
    position: "absolute",
    top: 35,
    left: 80,
    zIndex: 1,
    height: 14,
  },
  checkSmall: {
    position: "absolute",
    top: 29,
    right: 29,
    zIndex: 1,
    height: 7,
  },
  root: {
    width: 110,
    height: 68,
    margin: theme.spacing(1),
   
  },
  rootSmall: {
    width: 70,
    height: 50,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 14,
    height: 48,
    color: "black",
    "& + .MuiSwitch-track": {
      backgroundColor: "#5c5f64",
      borderColor: "#5c5f64",
    },
    "&$checked": {
      transform: "translateX(44px)",
      "& + .MuiSwitch-track": {
        backgroundColor: "white",
        borderColor: "white",
      },
    },
  },
  switchBaseSmall: {
    padding: 14,
    height: 42,
    color: "black",
    "& + .MuiSwitch-track": {
      backgroundColor: "#5c5f64",
      borderColor: "#5c5f64",
    },
    "&$checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: "white",
        borderColor: "white",
      },
    },
  },
  thumb: {
    width: 32,
    height: 32,
    position: "relative",
    top: 10,
    left: 4,
  },
  thumbSmall: {
    width: 20,
    height: 20,
    position: "relative",
    top: 4,
    left: 2,
  },
  track: {
    borderRadius: 32,
    borderWidth: 1,
    borderStyle: "solid",
    transition: theme.transitions.create(["background-color", "border"]),
    opacity: "1 !important",
  },
  trackSmall: {
    borderRadius: 18,
    borderWidth: 1,
    borderStyle: "solid",
    transition: theme.transitions.create(["background-color", "border"]),
    opacity: "1 !important",
  },
  checked: {},
}));

interface ISwitch extends Omit<SwitchProps, "color"> {
  size?: "small" | "medium";
}

export const Switch = (props: ISwitch) => {
  const classes = useStyles();
  const { size, ...switchProps } = props;

  const smallToggle = size === "small";

  return (
    <Box
      className={[
        props.checked ? classes.checkedContainer : classes.container,
        `switch-${size || "medium"}`,
      ].join(" ")}
    >
      {props.checked && (
        <img
          className={smallToggle ? classes.checkSmall : classes.check}
          src="/icons/icon-switch-check.svg"
          alt="Check"
        />
      )}
      <MuiSwitch
        {...switchProps}
        color={"default"}
        classes={{
          root: smallToggle ? classes.rootSmall : classes.root,
          switchBase: smallToggle
            ? classes.switchBaseSmall
            : classes.switchBase,
          thumb: smallToggle ? classes.thumbSmall : classes.thumb,
          track: smallToggle ? classes.trackSmall : classes.track,
          checked: classes.checked,
        }}
      />
    </Box>
  );
};
