import { Tabs } from "../../components/Tabs";
import { Tab } from "../../components/Tab";
import { TabContent } from "../../components/TabContent";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { FeedPageV2 } from "../FeedV2";
import { MyMessagesPage } from "../MyMessagesV2";
import { ButtonBase } from "@mui/material";
import { Search } from "@mui/icons-material";
import { MessageSearchV2Page } from "../MessageSearchV2";

export const MessagesPageV2 = () => {
  const params = useParams<{ boardId: string }>();
  const history = useHistory();
  const isSearch = history.location.pathname.includes("messages/search");
  const tabValue = history.location.pathname.includes("messages/feed") ? 0 : 1;

  return isSearch ? (
    <MessageSearchV2Page />
  ) : (
    <>
      <Tabs
        value={tabValue}
        onChange={(value) => {
          if (value === 0) {
            history.push(`/board/${params.boardId}/messages/feed`);
          } else {
            history.push(`/board/${params.boardId}/messages/my/history`);
          }
        }}
        endAdornment={
          <ButtonBase
            style={{
              padding: 12,
            }}
            onClick={() => {
              history.push(`/board/${params.boardId}/messages/search`);
            }}
          >
            <Search />
          </ButtonBase>
        }
      >
        <Tab label="Inspiration" />
        <Tab label="My Messages" />
      </Tabs>
      <TabContent>
        <Switch>
          <Route
            path="/board/:boardId/messages/my"
            component={MyMessagesPage}
          />
          <Route path="/board/:boardId/messages/feed" component={FeedPageV2} />
          <Route
            path="/board/:boardId/messages/my"
            component={MyMessagesPage}
          />
          <Redirect to={`/board/${params.boardId}/messages/feed`} />
        </Switch>
      </TabContent>
    </>
  );
};
