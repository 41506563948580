import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

interface ISimplePagination {
  disableNext: boolean;
  disablePrevious: boolean;
  next: () => void;
  previous: () => void;
}

const useStyles = makeStyles({
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
  },
  pagination: {
    padding: 14,
    display: "flex",
    width: 220,
    justifyContent: "space-between",
  },
  disabled: {
    color: "#666",
  },
  text: {
    color: '#ffffff'
  }
});

export const SimplePagination = (props: ISimplePagination) => {
  const classes = useStyles();

  return (
    <Box className={classes.paginationContainer}>
      <Box className={classes.pagination}>
        <Button
          size="small"
          disabled={props.disablePrevious}
          onClick={props.previous}
        >
          <NavigateBeforeIcon
            className={props.disablePrevious ? classes.disabled : ""}
          />{" "}
          <Typography className={props.disablePrevious ? classes.disabled : classes.text}>
            Previous
          </Typography>
        </Button>
        <Button size="small" onClick={props.next} disabled={props.disableNext}>
          <Typography className={props.disableNext ? classes.disabled : classes.text}>
            Next
          </Typography>{" "}
          <NavigateNextIcon
            className={props.disableNext ? classes.disabled : ""}
          />
        </Button>
      </Box>
    </Box>
  );
};
