import * as React from "react";

import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Spacer } from "./Spacer";

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  field: {
    color: "#f5f5f7",
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    fontWeight: 500,
  },
});

interface IField {
  children: React.ReactElement | React.ReactElement[];
  label: string;
  rightIcon?: React.ReactElement;
}

export const Field = (props: IField) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography>
        {props.label} {props.rightIcon}
      </Typography>
      <Spacer height={4} />
      {props.children}
    </Box>
  );
};
