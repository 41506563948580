import React from "react";
import { useAppState } from "../hooks/global";
import { usePaywallQuery } from "../hooks/usePaywallQuery";
import { makeStyles } from "@mui/styles";
import { BREAKPOINT_EXTRA_SMALL, BREAKPOINT_SMALL } from "../config";
import { Modal } from "../components/Modal";
import ArrowButton from "../components/ArrowButton";
import CircleBlurredButton from "../components/CircleBlurredButton";
import {
  InstallablesPlusActivateModalContents,
  InstallablesPlusUpgradeModalContents,
  InstallablesPlusVirtualBoardModalContents,
} from "../components/InstallablesPlusUpgradeModal";
import { PostActivatePlusModals } from "../components/InstallablesPlusUpgradeModal/PostActivatePlusModals";
import { Close } from "@mui/icons-material";

interface IPaywallContext {
  setMode: (mode: EPaywallMode) => void;
  setShown: (v: boolean, contextCopy: string | null) => void;
  close: () => void;
  isPlus: boolean | null;
  setInitialCode: (v: string) => any;
  initialCode: string;
}

const PaywallContext = React.createContext<IPaywallContext>({
  setMode: () => {},
  setShown: () => {},
  close: () => {},
  isPlus: null,
  setInitialCode: () => {},
  initialCode: "",
});

export const usePaywall = () => React.useContext(PaywallContext);

export enum EPaywallMode {
  Buying = "Buying",
  Activating = "Activating",
  VirtualBoard = "Virtual Board",
}

const useStyles = makeStyles({
  modal: {
    backgroundColor: "#f5f5f7",
    borderRadius: "8px",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      borderRadius: 0,
      minHeight: "100vh",
    },
  },
  closeBtn: {
    position: "absolute",
    top: 20,
    right: 18,
    zIndex: 2,
    "&:hover": {
      backgroundColor: "rgba(125, 135, 142, 0.5)",
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      display: "none",
    },
  },
  backBtn: {
    display: "none",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      display: "block",
      position: "absolute",
      top: 20,
      left: 24,
      zIndex: 2,
      marginBottom: 28,
    },
  },
  content: {
    zIndex: 1,
    padding: "56px 164px 30px 164px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "60vh",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      padding: 30,
    },
  },
});

export const PaywallProvider: React.FC = (props) => {
  const [getPlusModalShown, setGetPlusModalShown] = React.useState(false);
  const [contextCopy, setContextCopy] = React.useState<string | null>(null);
  const { boardId } = useAppState();

  const { data } = usePaywallQuery(boardId ?? null);
  const [isPlus, setIsPlus] = React.useState(false);
  const [mode, setMode] = React.useState(EPaywallMode.Buying);
  const [isVirtualBoard, setIsVirtualBoard] = React.useState(false);
  const classes = useStyles();
  const [initialCode, setInitialCode] = React.useState("");
  const [postActivateModalOpen, setPostActivateModalOpen] =
    React.useState(false);

  React.useEffect(() => {
    if (data && data.board) {
      setIsPlus(
        data.board.productApplications.find(
          (pa) => pa.pricing?.product.isPlus
        ) != null
      );

      setIsVirtualBoard(data.board.devices.length === 0);
    }
  }, [data]);

  const close = () => {
    setGetPlusModalShown(false);
  };

  return (
    <PaywallContext.Provider
      value={{
        setMode,
        close,
        setShown: (shown, copy) => {
          setGetPlusModalShown(shown);
          setContextCopy(copy);
        },
        isPlus,
        setInitialCode,
        initialCode,
      }}
    >
      <Modal
        shown={getPlusModalShown}
        onClose={close}
        className={[classes.modal].filter((e) => !!e).join(" ")}
      >
        <div className={classes.content}>
          <ArrowButton
            aria-label="Go back"
            className={classes.backBtn}
            arrowType="arrow"
            direction="left"
            background="transparent"
            onClick={close}
          />
          <CircleBlurredButton className={classes.closeBtn} onClick={close}>
            <Close />
          </CircleBlurredButton>
          {isVirtualBoard ? (
            <InstallablesPlusVirtualBoardModalContents
              shown={getPlusModalShown}
              onClose={() => setGetPlusModalShown(false)}
            />
          ) : (
            <>
              {mode == EPaywallMode.Buying && (
                <InstallablesPlusUpgradeModalContents
                  shown={getPlusModalShown}
                  onClose={() => setGetPlusModalShown(false)}
                  contextCopy={contextCopy ?? null}
                />
              )}
              {mode == EPaywallMode.Activating && (
                <InstallablesPlusActivateModalContents
                  shown={getPlusModalShown}
                  code={initialCode}
                  onClose={() => setGetPlusModalShown(false)}
                  openPostActivateModal={() => {
                    setPostActivateModalOpen(true);
                  }}
                />
              )}
            </>
          )}
        </div>
      </Modal>
      <PostActivatePlusModals
        visible={postActivateModalOpen}
        setVisible={setPostActivateModalOpen}
      />
      {props.children}
    </PaywallContext.Provider>
  );
};
