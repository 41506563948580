import * as React from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";

import { BREAKPOINT_SMALL } from "../../../config";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import { useFeatures } from "../../../hooks/useFeatures";

interface IRouteMatch {
  boardId: string;
}

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: 104,
    paddingTop: 80,

    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      paddingTop: 0,
    },

    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tab: {
    fontSize: "12px",
    textTransform: "none",
    minWidth: 104,
    maxWidth: 104,
    height: 90,
    color: "#FFF !important",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      height: 80,
    },
  },
  activeTab: {
    color: "#FFF !important",
    fontSize: "12px",
    textTransform: "none",
    minWidth: 104,
    maxWidth: 104,
    height: 90,
    backgroundColor: "#252729",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      height: 80,
    },
  },
  icon: {
    width: "24px",
    height: "24px",
  },
  beta: {
    fontSize: 7,
    letterSpacing: 1.68,
  },
}));

interface ISidebarNavItems {
  onClick: () => void;
}

export const SidebarNavItems = (props: ISidebarNavItems) => {
  const classNames = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = useParams<IRouteMatch>();
  const { boardId } = params;
  const { features } = useFeatures();
  const [activeTab, setActiveTab] = React.useState(0);

  const tabs: Array<{
    route?: string;
    url?: string;
    label: string;
    icon: string;
    beta?: boolean;
  }> = [
    ...(features.live
      ? [
          {
            route: `/live`,
            label: "Live",
            icon: "live",
          },
        ]
      : []),
    ...(features.compose
      ? [
          {
            route: `/compose`,
            label: "Compose",
            icon: "compose",
          },
        ]
      : []),
    ...(features.messages
      ? [
          {
            route: `/messages`,
            label: "Messages",
            icon: "messages",
          },
        ]
      : []),
    ...(features.installables
      ? [
          {
            route: "/installables",
            label: "Plus",
            icon: "plus-logo",
          },
        ]
      : []),
    ...(features.api
      ? [
          {
            route: "/developer",
            label: "API",
            icon: "api",
          },
        ]
      : []),
    ...(features.settings
      ? [
          {
            route: "/settings",
            label: "Settings",
            icon: "settings",
          },
        ]
      : []),
    ...(features.helpCenter
      ? [
          {
            url: "https://www.vestaboard.com/help",
            label: "Help Center",
            icon: "help",
          },
        ]
      : []),
  ];
  return (
    <div className={classNames.sidebar}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        aria-label="tabs"
        value={activeTab}
      >
        {tabs.map((tab, index) => (
          <Tab
            onClick={() => {
              setActiveTab(index);
              const tab = tabs[index];
              if (tab.url) {
                return (window.location.href = tab.url);
              }

              history.push(`/board/${boardId}${tab.route}`);

              props.onClick();
            }}
            key={index}
            className={
              tab.route &&
              location.pathname.includes(`/board/${boardId}${tab.route}`)
                ? classNames.activeTab
                : classNames.tab
            }
            label={
              <>
                {tab.label}
                {tab.beta && <Box className={classNames.beta}>BETA</Box>}
              </>
            }
            icon={
              <img
                alt={tab.label}
                src={`/icons/icon-${tab.icon}-${
                  tab.route && location.pathname.includes(tab.route)
                    ? "active"
                    : "inactive"
                }.svg`}
                className={classNames.icon}
              />
            }
          />
        ))}
      </Tabs>
    </div>
  );
};
