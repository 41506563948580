import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  UnPinMessageFromBoardV2Mutation,
  UnPinMessageFromBoardV2MutationVariables,
} from "../gql";

const MUTATION = gql`
  mutation UnPinMessageFromBoardV2($input: RemovePinnedMessageInputV2!) {
    unpinMessageFromBoard: unpinMessageFromBoardV2(input: $input) {
      ... on PinnedMessageV2 {
        id
        boardId
        message {
          id
        }
      }
      ... on PinnedMessageErrorV2 {
        type
        error
      }
    }
  }
`;

export const useUnpinMessageFromBoardV2 = () => {
  return useMutation<
    UnPinMessageFromBoardV2Mutation,
    UnPinMessageFromBoardV2MutationVariables
  >(MUTATION);
};
