import * as React from "react";

import {
  BoardOnlineOfflineStatus,
  useBoardOnlineOfflineStatus,
} from "../hooks/useBoardOnlineOfflineStatus";
import {
  Box,
  Button,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

import { Confirm } from "./Confirm";
import { FaCheckCircle } from "react-icons/fa";
import { PureQueryOptions } from "@apollo/client";
import { useFeatures } from "../hooks/useFeatures";
import { useHistory } from "react-router";
import { useModal } from "../hooks/useModal";
import { useToasts } from "@vestaboard/installables";
import { BREAKPOINT_SMALL } from "../config";
import { useAppState } from "../hooks/global";
import { useGetBoardV2 } from "../hooks/useGetBoardV2";
import { BoardV2 } from "../gql";
import { useRenameBoardV2 } from "../hooks/useRenameBoardv2";
import { useDeleteBoardV2 } from "../hooks/useDeleteBoardV2";
import { useLeaveBoardV2 } from "../hooks/useLeaveBoardV2";
import { useGetMyRoleV2 } from "../hooks/useGetRolesV2";
import {
  MY_BOARDS_ADMIN_QUERY_V2,
  MY_BOARDS_QUERY_V2,
} from "../hooks/useMyBoardsV2";

interface IDevice {
  id: string;
}

interface IBoard {
  id: string;
  title: string;
  friendlyIdentifier: string;
  devices: Array<IDevice>;
}

interface IBoardSettings {
  isSelected?: boolean;
  boardId: string;
  refetchQueries?: Array<PureQueryOptions>;
  transparent?: boolean;
  enableDelete: boolean;
  personTenantAssociationId?: string;
}

interface IStyle {
  transparent?: boolean;
}

const useStyles = makeStyles(
  createStyles({
    container: (props) => ({
      width: props.transparent ? 484 : 544,
      maxWidth: "100%",
      marginBottom: props.transparent ? 0 : 40,
      position: "relative",
      display: "flex",
    }),
    containerButton: (props: IStyle) => ({
      display: "flex",
      padding: props.transparent ? "0px" : "27px 32px 32px",
      borderRadius: 6,
      backgroundColor: props.transparent
        ? "transparent"
        : "rgb(125, 135, 142, 0.15)",
      alignItems: "center",
      textTransform: "none",
      width: "100%",
      justifyContent: "space-between",
      flexWrap: "wrap",
      [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    }),
    containerSelected: {
      backgroundColor: "#171818",
      border: "solid 1.5px #f5f5f7",
    },
    title: {
      fontFamily: "HelveticaNeue",
      fontSize: 18,
      paddingLeft: 8,
    },
    actionButtons: {
      display: "flex",
    },
    actionButtonWrapper: (props) => ({
      width: 80,
      height: 80,
      backgroundColor: props.transparent
        ? "transparent"
        : "rgb(126, 135, 142, 0.1)",
      marginLeft: 16,
      "&:first-child": {
        marginLeft: 0,
      },
      [`@media(max-width: 500px)`]: {
        width: 44,
      },
    }),
    disabled: {
      opacity: 0.5,
      cursor: "not-allowed !important",
    },
    actionButton: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
    },
    actionButtonIcon: {
      height: 20,
      display: "block",
      marginBottom: 8,
    },
    actionButtonText: {
      fontSize: 12,
      textTransform: "none",
      display: "block",
      color: "#ffffff",
    },
    connectivity: {
      display: "flex",
      alignItems: "center",
      paddingTop: 4,
      paddingBottom: 8,
    },
    onlineDot: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "#1f9a44",
      marginRight: 16,
      position: "relative",
      left: 8,
    },
    unknownDot: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "#1976d2",
      marginRight: 16,
      position: "relative",
      left: 8,
    },
    offlineDot: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "#0a0a0b",
      marginRight: 16,
      position: "relative",
      left: 8,
    },
    connectivityText: {
      fontSize: 12,
    },
    copyId: {
      height: 24,
      padding: "5px 13px 5px 8px",
      borderRadius: 3,
      backgroundColor: "rgb(126, 135, 142, 0.1)",
      fontSize: 12,
      display: "flex",
      justifyContent: "flex-start",
      color: "rgb(245, 245, 247, 0.6)",
    },
    copyIcon: {
      width: 10,
    },
    checkCircle: {
      position: "absolute",
      left: -10,
      top: 50,
      fontSize: 24,
      backgroundColor: "#0a0a0b",
      borderRadius: "50%",
    },
    mainButton: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
    },
    leftContainer: {
      marginBottom: 16,
      marginTop: 16,
    },
    "@keyframes flicker": {
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0.2,
      },
    },
    flicker: {
      animationName: "$flicker",
      animationDuration: "700ms",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
      animationTimingFunction: "ease-in-out",
    },
  })
);

const FocusTextField = (props: TextFieldProps) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useLayoutEffect(() => {
    if (ref.current) {
      const input = ref.current.querySelector("input");

      if (input) {
        input.focus();
        input.setSelectionRange(0, input.value.length);
      }
    }
  }, [ref]);

  return <TextField ref={ref} {...props} />;
};

export const BoardSettingsV2 = (props: IBoardSettings) => {
  const classes = useStyles({
    transparent: props.transparent,
  });
  const history = useHistory();
  const { addToast } = useToasts();
  const { data } = useGetBoardV2(props.boardId);
  const [isRenamingBoard, setIsRenamingBoard] = React.useState(false);
  const board = data?.BoardV2 as BoardV2 | undefined;
  const [boardTitle, setBoardTitle] = React.useState(board?.title);
  const {
    showModal: showDeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();
  const {
    showModal: showLeaveModal,
    openModal: openLeaveModal,
    closeModal: closeLeaveModal,
  } = useModal();
  const [deleteBoardV2] = useDeleteBoardV2();
  const [leaveBoardV2] = useLeaveBoardV2();
  const [renameBoardV2] = useRenameBoardV2();
  const { data: viewerData } = useGetMyRoleV2(props.boardId);
  const myRole =
    viewerData?.viewerV2?.__typename === "PersonV2"
      ? viewerData?.viewerV2?.myRoleForBoard
      : undefined;
  const iAmOwner = myRole?.permission === "Owner";
  const { features } = useFeatures();
  // TODO: use v2 websockets?
  const onlineOfflineStatus = useBoardOnlineOfflineStatus(props.boardId);

  const saveBoardTitle = async () => {
    const response = await renameBoardV2({
      variables: {
        input: {
          id: props.boardId,
          title: boardTitle,
        },
      },
    });

    if (!response?.data) {
      addToast(`There was an error saving your board name`, {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setIsRenamingBoard(false);

    addToast(`The board was renamed successfully`, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const isVirtual = board?.listFlagshipDevices?.flagshipDevices?.length === 0;
  const isUnknown = onlineOfflineStatus === BoardOnlineOfflineStatus.Unknown;
  const isOnline =
    onlineOfflineStatus &&
    onlineOfflineStatus === BoardOnlineOfflineStatus.Online;
  const myPersonId =
    viewerData?.viewerV2?.__typename == "PersonV2"
      ? viewerData?.viewerV2?.id
      : "";

  return (
    <>
      <Confirm
        title="Leave Board"
        message={`Leaving "${board?.title}" will mean you no longer have access to this Vestaboard. Are you sure?`}
        open={showLeaveModal}
        handleClose={closeLeaveModal}
        handleAccept={async () => {
          try {
            await leaveBoardV2({
              variables: {
                input: {
                  boardId: props.boardId as string,
                  personId: myPersonId,
                },
              },
              awaitRefetchQueries: true,
              refetchQueries: [
                {
                  query: MY_BOARDS_QUERY_V2,
                },
                {
                  query: MY_BOARDS_ADMIN_QUERY_V2,
                },
              ],
            });

            addToast(`The board was left successfully`, {
              appearance: "success",
              autoDismiss: true,
            });
            if (props.transparent) {
              history.push(`/`);
            }
          } catch (err) {
            addToast(`There was an error leaving "${board?.title}"`, {
              appearance: "error",
              autoDismiss: true,
            });
          }
          closeLeaveModal();
        }}
      />
      <Confirm
        title="Delete Board"
        message={`You are the owner of this Vestaboard. Removing "${board?.title}" will mean it is deleted and all users will lose access. Are you sure?`}
        open={showDeleteModal}
        handleClose={closeDeleteModal}
        handleAccept={async () => {
          try {
            await deleteBoardV2({
              variables: {
                input: {
                  id: props.boardId,
                },
              },
              awaitRefetchQueries: true,
              refetchQueries: [
                {
                  query: MY_BOARDS_QUERY_V2,
                },
                {
                  query: MY_BOARDS_ADMIN_QUERY_V2,
                },
              ],
            });

            addToast(`The board was deleted successfully`, {
              appearance: "success",
              autoDismiss: true,
            });

            if (props.transparent) {
              history.push(`/`);
            }
          } catch (err) {
            addToast(`There was an error deleting "${board?.title}"`, {
              appearance: "error",
              autoDismiss: true,
            });
          }
          closeDeleteModal();
        }}
      />
      <Box className={classes.container}>
        <Box
          className={
            props.isSelected
              ? [classes.containerButton, classes.containerSelected].join(" ")
              : classes.containerButton
          }
        >
          {!props.transparent && (
            <Button
              className={classes.mainButton}
              disabled={isRenamingBoard}
              onClick={
                props.transparent
                  ? undefined
                  : () => {
                      // setBoardIdContext(props.boardId);
                      history.push(`/board/${props.boardId}/compose`);
                    }
              }
            />
          )}
          <Box className={classes.leftContainer}>
            <Box>
              {isRenamingBoard ? (
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await saveBoardTitle();
                  }}
                >
                  <FocusTextField
                    value={boardTitle}
                    onChange={(e) => {
                      setBoardTitle(e.target.value);
                    }}
                    onBlur={saveBoardTitle}
                  />
                </form>
              ) : (
                <Typography className={classes.title}>
                  {board?.title}
                </Typography>
              )}

              {!isVirtual && (
                <Box className={classes.connectivity}>
                  {!isUnknown ? (
                    <>
                      <Box
                        className={
                          isOnline ? classes.onlineDot : classes.offlineDot
                        }
                      />{" "}
                      <Typography className={classes.connectivityText}>
                        {isOnline ? "Online" : "Offline"}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Box
                        className={`${classes.unknownDot} ${classes.flicker}`}
                      />{" "}
                    </>
                  )}
                </Box>
              )}
              {isVirtual && (
                <Box className={classes.connectivity}>
                  <Box className={`${classes.unknownDot}`} />{" "}
                  <Typography className={classes.connectivityText}>
                    Virtual Board
                  </Typography>
                </Box>
              )}

              <Button
                className={classes.copyId}
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(props.boardId);
                    addToast(`Copied the board id to your clipboard.`, {
                      appearance: "success",
                      autoDismiss: true,
                    });
                  } catch (err) {
                    addToast(
                      `Unable to copy ${props.boardId} to your clipboard.`,
                      {
                        appearance: "error",
                        autoDismiss: true,
                      }
                    );
                  }
                }}
              >
                ID: {props.boardId}&nbsp;{" "}
                <img
                  className={classes.copyIcon}
                  src="/icons/icon-copy.svg"
                  alt="Copy"
                />
              </Button>
            </Box>
          </Box>
          {props.isSelected && (
            <FaCheckCircle className={classes.checkCircle} />
          )}
          <Box className={classes.actionButtons}>
            {features.renameBoard && (
              <Button
                className={classes.actionButtonWrapper}
                onClick={() => {
                  setIsRenamingBoard(true);
                }}
              >
                <Box className={classes.actionButton}>
                  <img
                    src="/icons/icon-edit.svg"
                    className={classes.actionButtonIcon}
                    alt="Rename"
                  />
                  <Typography className={classes.actionButtonText}>
                    Rename
                  </Typography>
                </Box>
              </Button>
            )}
            {!iAmOwner ? (
              <Button
                className={[
                  classes.actionButtonWrapper,
                  ...(!props.enableDelete ? [classes.disabled] : []),
                ].join(" ")}
                onClick={() => {
                  if (props.enableDelete) {
                    openLeaveModal();
                  }
                }}
              >
                <Box className={classes.actionButton}>
                  <img
                    src="/icons/icon-leave.svg"
                    className={classes.actionButtonIcon}
                    alt="Leave"
                  />
                  <Typography className={classes.actionButtonText}>
                    Leave
                  </Typography>
                </Box>
              </Button>
            ) : (
              <Button
                className={[
                  classes.actionButtonWrapper,
                  ...(!props.enableDelete ? [classes.disabled] : []),
                ].join(" ")}
                onClick={() => {
                  if (props.enableDelete) {
                    openDeleteModal();
                  }
                }}
              >
                <Box className={classes.actionButton}>
                  <img
                    src="/icons/icon-trash.svg"
                    className={classes.actionButtonIcon}
                    alt="Delete"
                  />
                  <Typography className={classes.actionButtonText}>
                    Delete
                  </Typography>
                </Box>
              </Button>
            )}
            <Button
              className={classes.actionButtonWrapper}
              onClick={() => {
                history.push(`/board/${props.boardId}/publish`);
              }}
            >
              <Box className={classes.actionButton}>
                <img
                  src="/icons/icon-publish.svg"
                  className={classes.actionButtonIcon}
                  alt="Publish"
                />
                <Typography className={classes.actionButtonText}>
                  Publish
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
