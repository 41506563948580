import {
  InstallablesAndSubscriptionsQuery,
  InstallablesAndSubscriptionsQueryVariables,
} from "../../../gql";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const INSTALLABLES_AND_SUBSCRIPTION_QUERY = gql`
  query InstallablesAndSubscriptionsQuery(
    $boardId: String!
    $tenantId: String!
  ) {
    installables(tenantId: $tenantId) {
      id
      title
      icon
      installationsAreSingleton
      hideFromInstallablesList
      description
      category {
        id
        title
      }
      developer {
        id
        title
      }
    }
    board(id: $boardId) {
      id
      title
      tenant {
        installations {
          id
          created
          installable {
            id
            title
            icon
            installationsAreSingleton
            hideFromInstallablesList
            description
            category {
              id
              title
            }
            developer {
              id
              title
            }
          }
        }
      }
      subscriptionSet {
        subscriptions {
          id
          muted
          configurationUrl
          configurationToken
          installation {
            id
            installable {
              id
              title
              icon
              public
              installationsAreSingleton
              hideFromInstallablesList
              description
              category {
                id
                title
              }
              developer {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;

interface IUseGetInstallbleSubscriptions {
  boardId: string;
  tenantId: string;
}

export const useGetInstallableSubscriptions = (
  variables: IUseGetInstallbleSubscriptions
) => {
  return useQuery<
    InstallablesAndSubscriptionsQuery,
    InstallablesAndSubscriptionsQueryVariables
  >(INSTALLABLES_AND_SUBSCRIPTION_QUERY, {
    fetchPolicy: "network-only",
    variables,
  });
};

useGetInstallableSubscriptions.query = INSTALLABLES_AND_SUBSCRIPTION_QUERY;
