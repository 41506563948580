import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useParams } from "react-router-dom";
import { GrayFilledButton } from "../../../components/GrayFilledButton";
import { InstallableIcon } from "../../../components/InstallableIcon";
import Slider from "../../../components/Slider";
import { BREAKPOINT_SMALL } from "../../../config";
import { ScrollToTop } from "../../../utils/scroll";
import { InstallButtonOrToggleSwitch } from "../../Installables/components/InstallableListItem";
import InstallablesSection from "../../Installables/components/InstallablesSection";
import { useBlendMessageSet } from "../../../hooks/useBlendMessageSet";
import { useToasts } from "@vestaboard/installables";
import { useFlags } from "launchdarkly-react-client-sdk";

interface IInfo {
  data: {
    id: string;
    free: boolean;
    title: string;
    icon: string;
    category: string;
    description: string;
    shortDescription: string;
    author: string;
    tagline: string;
    images: Array<{
      id: string;
      url: string;
      alt?: string;
    }>;
    metadata: Array<{
      id: string;
      label: string;
      value: string;
    }>;
    installed: boolean;
    categoryId: string;
    tenantId: string | null;
    otherInCategory: any[];
    installableIsSingleton: boolean | null;
    subscriptionId?: string | null;
    subscriptionMuted?: boolean | null;
    messageSetId?: string;
  };

  isProUser: boolean;
  paywall: () => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 50,
  },
  content: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // maxWidth: 452,
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 38,
  },
  headerInfo: {
    flexGrow: 1,
    marginLeft: 28,
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  title: {
    fontFamily: "HelveticaNeue",
    fontSize: 34,
    fontWeight: 500,
    lineHeight: 1.24,
    color: "#f5f5f7",
    marginBottom: 4,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      fontSize: 20,
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    right: "5%",
  },
  category: {
    fontFamily: "HelveticaNeue",
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "0.15px",
    color: "#f5f5f7",
    marginBottom: 10,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      fontSize: 14,
    },
  },
  author: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#7e878e",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      fontSize: 14,
    },
  },
  tagline: {
    fontSize: 16,
    color: "#f5f5f7",
    // textAlign: "center",
    fontFamily: "HelveticaNeue",
    lineHeight: 1.25,
    fontWeight: "bold",
    letterSpacing: "normal",
    marginBottom: 24,
  },
  description: {
    fontSize: 16,
    color: "#7e878e",
    // textAlign: "center",
    fontFamily: "HelveticaNeue",
    lineHeight: 1.5,
    letterSpacing: "normal",
    marginBottom: 39,
  },
  shortDescription: {
    fontSize: 16,
    color: "#ffffff",
    fontWeight: "bold",
    // textAlign: "center",
    fontFamily: "HelveticaNeue",
    lineHeight: 1.5,
    letterSpacing: "normal",
    marginBottom: 5,
  },
  images: {
    marginBottom: 45,
    "& .slick-list": {
      // margin: '0 -7px',
      "& .slick-slide > div": {
        padding: "0 20px",
      },
    },
  },
  imageContainer: {},
  image: {
    width: 376,
    height: 212,
    borderRadius: 8,
    objectFit: "contain",
    margin: "0 auto",
  },
  metadataContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 66,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      flexWrap: "wrap",
      alignItems: "center",
    },
  },
  metadata: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 2,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      marginBottom: 20,
    },
  },
  contact: {
    flexGrow: 1,
  },
  metadataItem: {
    marginRight: 30,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      "&:not(:last-child)": {
        marginRight: 20,
        fontSize: 14,
      },
    },
  },
  metadataLabel: {
    fontFamily: "HelveticaNeue",
    fontSize: 10,
    lineHeight: 1.7,
    color: "#f5f5f7",
  },
  metadataValue: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#fff",
  },
  blendedLoader: {
    paddingTop: 8,
  },
});

export const Info = (props: IInfo) => {
  const classes = useStyles();
  const { boardId } = useParams<{ boardId: string }>();
  const {
    addMessageSetToBlend,
    blendedMessageSets,
    navigateToBlended,
    loadingBlended,
  } = useBlendMessageSet(boardId);
  const { addToast } = useToasts();
  const flags = useFlags();

  return (
    <>
      <ScrollToTop />
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Box className={classes.header}>
            <InstallableIcon
              size="large"
              title={props.data.title}
              icon={props.data.icon}
            />
            <Box className={classes.headerInfo}>
              <Box className={classes.titleRow}>
                <Typography className={classes.title}>
                  {props.data.title}
                </Typography>
                <Box className={classes.buttonContainer}>
                  <InstallButtonOrToggleSwitch
                    contextCopy={props.data.title}
                    free={props.data.free}
                    marketplaceListingId={props.data.id}
                    muted={props.data.subscriptionMuted ?? false}
                    boardId={boardId}
                    tenantId={props.data.tenantId ?? ""}
                    subscriptionId={props.data.subscriptionId ?? ""}
                    installableIsSingleton={
                      props.data.installableIsSingleton ?? false
                    }
                    showButtonInsteadOfToggle
                    installText={"Install"}
                  />
                  {flags.blendedV1 &&
                  props.data?.messageSetId &&
                  !loadingBlended ? (
                    <GrayFilledButton
                      style={{
                        marginTop: 8,
                      }}
                      onClick={async () => {
                        if (
                          props.data?.messageSetId &&
                          blendedMessageSets?.includes(props.data.messageSetId)
                        ) {
                          return navigateToBlended();
                        }
                        try {
                          props.data?.messageSetId &&
                            (await addMessageSetToBlend(
                              props.data?.messageSetId
                            ));
                          navigateToBlended();
                        } catch (e) {
                          addToast(`Error Blending ${props.data.title}`, {
                            appearance: "error",
                          });
                        }
                      }}
                      // not rendered, children rendered instead... TODO: fix in component library
                      title={
                        blendedMessageSets?.includes(props.data.messageSetId)
                          ? "Blended"
                          : "Blend"
                      }
                    >
                      {blendedMessageSets?.includes(props.data.messageSetId)
                        ? "Blended"
                        : "Blend"}
                    </GrayFilledButton>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>

              <Typography className={classes.category}>
                {props.data.category}
              </Typography>
              <Typography className={classes.author}>
                {props.data.author}
              </Typography>
            </Box>
          </Box>
          <Typography className={classes.shortDescription}>
            {props.data.shortDescription}
          </Typography>
          <Typography className={classes.description}>
            {props.data.description}
          </Typography>
          <Box className={classes.images}>
            <Slider
              speed={500}
              slidesToShow={3}
              slidesToScroll={3}
              responsive={[
                {
                  breakpoint: 1310,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {props.data.images.map((image) => (
                <div className={classes.imageContainer}>
                  <img
                    key={image.id}
                    src={image.url}
                    alt={image.alt}
                    className={classes.image}
                  />
                </div>
              ))}
            </Slider>
          </Box>
          <Box className={classes.metadataContainer}>
            <Box className={classes.metadata}>
              {props.data.metadata.map((metadata) => (
                <Box key={metadata.id} className={classes.metadataItem}>
                  <Typography className={classes.metadataLabel}>
                    {metadata.label}
                  </Typography>
                  <Typography className={classes.metadataValue}>
                    {metadata.value}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box className={classes.contact}>
              <GrayFilledButton
                onClick={() =>
                  (window.location.href = `mailto:plus-developers@vestaboard.com&subject=Feedback about Marketplace Listing - ${props.data.title}`)
                }
              >
                Contact Developer
              </GrayFilledButton>
            </Box>
          </Box>
          <Box>
            <InstallablesSection
              id={props.data.categoryId}
              title={`More in ${props.data.category}`}
              items={props.data.otherInCategory
                .filter((mpl) => mpl.id !== props.data.id)
                .sort(() => (Math.random() > 0.5 ? 1 : -1))
                .slice(0, 6)
                .map((mpl) => ({
                  id: mpl.id,
                  title: mpl.title,
                  icon: mpl.icon,
                  free: mpl.free ?? false,
                  description: mpl.shortDescription,
                  canAdd: true,
                  installableIsSingleton:
                    mpl.installable?.installationsAreSingleton,
                  subscriptionId: null,
                  subscriptionMuted: false,
                }))}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
