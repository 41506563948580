import {
  BoardEditor,
  Drafts,
  IBoard,
  ScheduleMessage,
  createBoard,
  useToasts,
  IBoardStyle,
} from "@vestaboard/installables";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React, { useEffect, useMemo, useState } from "react";
import gql from "graphql-tag";

import dayjs from "dayjs";
import { useCreateMessage } from "./hooks/useCreateMessage";
import { useGetDraftsLazy } from "../../hooks/useGetDrafts";
import { useParams } from "react-router";
import { useSaveDraft } from "./hooks/useSaveDraft";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import {
  BoardMessageQuery,
  BoardMessageQueryVariables,
  UnscheduleMessageMutation,
  UnscheduleMessageMutationVariables,
} from "../../gql";
import { formatTime } from "../../utils/time/formatTime";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { calculateInQuietHourRange } from "../../utils/time";
import { useGetBoardStyle } from "../../hooks/useGetBoardStyle";
import {
  BOARD_PINNED_QUERY,
  useGetPinnedMessage,
} from "../../hooks/useGetPinnedMessage";
import { Schedule, WifiTethering } from "@mui/icons-material";
import { BroadcastMessageModal } from "../../components/BroadcastMessageModal";
import { MessageActions } from "../../components/MessageCard";
import { useQuietHours } from "../../hooks/useQuietHours";
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

interface IBoardPageProps {}

type BoardPageProps = IBoardPageProps;

interface IRouteMatch {
  boardId: string;
  message?: string;
}

const useStyles = makeStyles({
  container: {
    justifyContent: "center",
    display: "flex",
    background: "#181919",

    flex: 1,
  },
  board: {
    marginTop: 50,
    width: 820,
    paddingBottom: 50,
    maxWidth: "100%",
    "@media(max-width: 1400px)": {
      width: 700,
      marginTop: 14,
    },
    "@media(max-width: 960px)": {
      width: 600,
    },
    "@media(max-width: 640px)": {
      paddingBottom: 60,
      width: "100vw",
    },
  },
});

const boardMessageQuery = gql`
  query BoardMessageQuery($boardId: String!) {
    board(id: $boardId) {
      id
      boardStyle
      sendLaterMessage {
        id
        text
        sendAt
      }
    }
  }
`;
const unscheduleMessageMutation = gql`
  mutation UnscheduleMessageMutation($messageId: String!) {
    unscheduleMessage(input: { messageId: $messageId }) {
      unscheduled
    }
  }
`;

export const ComposePage: React.FC<BoardPageProps> = (props) => {
  const { data: boardStyleData } = useGetBoardStyle();
  const { addToast } = useToasts();
  const history = useHistory();
  const params = useParams<IRouteMatch>();
  const { quietHoursData, hasQuietHours, loading } = useQuietHours(
    params.boardId
  );
  const [showQuietHourModal, setShowQuietHourModal] = useState(false);
  const [isSchedulingLater, setIsSchedulingLater] = useState(false);

  const [isBroadcastOpen, setIsBroadcastOpen] = useState(false);
  const [boardValue, setBoardValue] = useState<IBoard>(
    params.message ? JSON.parse(params.message) : createBoard()
  );
  const [showDrafts, setShowDrafts] = useState(false);
  const [fetchDrafts, draftsResult] = useGetDraftsLazy();
  const [createMessage, { error }] = useCreateMessage();
  const [saveDraft] = useSaveDraft();
  const { boardId } = params;
  const { data, refetch } = useQuery<
    BoardMessageQuery,
    BoardMessageQueryVariables
  >(boardMessageQuery, {
    variables: {
      boardId,
    },
  });

  const [unscheduleMessage] = useMutation<
    UnscheduleMessageMutation,
    UnscheduleMessageMutationVariables
  >(unscheduleMessageMutation);

  const { data: pinnedMessageData, loading: loadingPinnedMessage } =
    useGetPinnedMessage();
  const pinnedMessageId = pinnedMessageData?.board?.pinnedMessage?.message?.id;
  const hasPinnedMessage = pinnedMessageId
    ? dayjs(pinnedMessageData?.board?.pinnedMessage?.pinnedUntil) > dayjs()
    : false;
  const [alreadyPinnedModal, setAlreadyPinnedModal] = React.useState(false);
  const [alreadyPinnedAction, setAlreadyPinnedAction] = React.useState(
    MessageActions.send
  );
  const [sendAtTime, setSendAtTime] = React.useState([0, 0, 0]);

  const classes = useStyles();

  const lastScheduledMessageSendAt = data?.board?.sendLaterMessage?.sendAt;
  const sendAtDate = lastScheduledMessageSendAt
    ? dayjs(+lastScheduledMessageSendAt)
    : undefined;
  const now = dayjs();
  const scheduledMessage = useMemo(
    () => sendAtDate && now.diff(sendAtDate) < 0,
    [lastScheduledMessageSendAt]
  );

  useEffect(() => {
    // when scheduling prevents flashing of the warning message
    if (isSchedulingLater && lastScheduledMessageSendAt) {
      // you can only refetch when we have data for lastScheduledMessageSendAt
      refetch();
    }
  }, [isSchedulingLater, lastScheduledMessageSendAt]);

  useEffect(() => {
    if (error) {
      const message = error?.message.includes("fingerprint")
        ? "The message you sent is already on your Vestaboard"
        : error?.message.includes("rate limited")
        ? "You cannot send more than one message every 15 seconds"
        : "There was an error creating your message";
      addToast(message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [error]);

  if (!boardId || loading || loadingPinnedMessage) {
    return <CircularProgress />;
  }

  const cancelScheduledMessage = async () => {
    if (scheduledMessage) {
      const messageId = data?.board?.sendLaterMessage?.id!;
      await unscheduleMessage({
        variables: {
          messageId,
        },
        refetchQueries: [
          {
            query: boardMessageQuery,
            variables: {
              boardId,
            },
          },
        ],
      });
    }
  };

  const sendMessage = async () => {
    try {
      await createMessage({
        variables: {
          input: {
            boardId,
            characters: boardValue,
            forced: true,
            forcePinnedMessageOverride: true,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: BOARD_PINNED_QUERY,
            variables: { boardId },
          },
        ],
      });

      addToast("Message created", {
        appearance: "success",
      });

      history.push(`/board/${params.boardId}/messages`);
    } catch (err) {}
  };

  const closeQuietHoursModal = () => setShowQuietHourModal(false);

  const onQuietHoursConfirmation = async () => {
    closeQuietHoursModal();
    await sendMessage();
  };

  const scheduleMessage = async (
    hours: number,
    minutes: number,
    seconds: number
  ) => {
    const sendAt = new Date();
    sendAt.setHours(sendAt.getHours() + hours);
    sendAt.setMinutes(sendAt.getMinutes() + minutes);
    sendAt.setSeconds(sendAt.getSeconds() + seconds);
    try {
      await createMessage({
        variables: {
          input: {
            boardId,
            characters: boardValue,
            sendAt: sendAt.getTime(),
            forcePinnedMessageOverride: true,
            forced: true,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: BOARD_PINNED_QUERY,
            variables: { boardId },
          },
        ],
      });

      addToast(
        `Message will be sent in ${hours} hours, ${minutes} minutes and ${seconds} seconds`,
        {
          appearance: "success",
        }
      );
      setIsSchedulingLater(false);
    } catch (err) {
      addToast("There was an error scheduling your message", {
        appearance: "error",
      });
    }
  };

  return (
    <div className={classes.container}>
      <>
        <ConfirmationModal
          confirmButtonText="Send"
          icon="icon-quiet-white.svg"
          message="This Vestaboard is currently set to quiet hours by the administrator of the account."
          subMessage="Are you sure you want to send a message?"
          onCancel={closeQuietHoursModal}
          onConfirm={onQuietHoursConfirmation}
          visible={showQuietHourModal}
        />
        <ConfirmationModal
          onConfirm={async () => {
            switch (alreadyPinnedAction) {
              case MessageActions.send: {
                await sendMessage();
                break;
              }
              case MessageActions.schedule: {
                const [hours, minutes, seconds] = sendAtTime;
                await scheduleMessage(hours, minutes, seconds);
                break;
              }
              default: {
                break;
              }
            }
            setAlreadyPinnedModal(false);
          }}
          visible={alreadyPinnedModal}
          confirmButtonText="Send"
          icon="pin"
          message="A message is currently pinned by you or another user of your account."
          subMessage="Are you sure you want to send a message?"
          onCancel={() => {
            setAlreadyPinnedModal(false);
          }}
        />
      </>
      <div className={classes.board}>
        {showDrafts && (
          <Drafts
            onSelect={(value) => {
              setBoardValue(value);
              setShowDrafts(false);
            }}
            drafts={draftsResult.map((draft) => ({
              message: (draft.message?.formatted || createBoard()) as IBoard,
              timeStamp: dayjs(new Date(+draft.message?.created)).fromNow(),
            }))}
            onClose={() => {
              setShowDrafts(false);
            }}
          />
        )}

        {isSchedulingLater && (
          <ScheduleMessage
            visible={isSchedulingLater}
            scheduledMessage={
              scheduledMessage
                ? {
                    text: data?.board?.sendLaterMessage?.text!,
                    sendAtFormatted: formatTime(
                      data?.board?.sendLaterMessage?.sendAt!
                    ),
                    onCancelScheduledMessage: cancelScheduledMessage,
                  }
                : undefined
            }
            onSend={async (hours, minutes, seconds) => {
              if (hasPinnedMessage) {
                setSendAtTime([hours, minutes, seconds]);
                setAlreadyPinnedAction(MessageActions.schedule);
                setAlreadyPinnedModal(true);
              } else {
                scheduleMessage(hours, minutes, seconds);
              }
            }}
            onCancel={() => {
              setIsSchedulingLater(false);
            }}
          />
        )}

        <BoardEditor
          boardValue={boardValue}
          mode={params.message ? "type" : undefined}
          setBoardValue={setBoardValue}
          boardStyle={boardStyleData?.board?.boardStyle as IBoardStyle}
          onSend={async () => {
            if (hasQuietHours) {
              const { board } = quietHoursData!;
              const { quietHoursBegin, quietHoursEnd } = board;
              const inQuietHourRange = calculateInQuietHourRange(
                quietHoursBegin as string,
                quietHoursEnd as string
              );
              if (inQuietHourRange) return setShowQuietHourModal(true);
            }

            if (hasPinnedMessage) {
              setAlreadyPinnedAction(MessageActions.send);
              setAlreadyPinnedModal(true);
              return;
            }

            return await sendMessage();
          }}
          dropdownActions={[
            {
              onPress: () => {
                setIsSchedulingLater(true);
              },
              icon: <Schedule />,
              title: "Schedule",
            },
            {
              onPress: () => {
                setIsBroadcastOpen(true);
              },
              icon: <WifiTethering />,
              title: "Broadcast",
            },
          ]}
          onSaveDraft={async () => {
            try {
              const message = await createMessage({
                variables: {
                  input: {
                    characters: boardValue,
                  },
                },
              });

              const messageId = message?.data?.createMessage2?.message?.id;

              if (!messageId) {
                addToast("There was an error creating your draft", {
                  appearance: "error",
                });
                return;
              }

              await saveDraft({
                variables: {
                  input: {
                    messageId,
                  },
                },
              });

              addToast("Draft saved", {
                appearance: "success",
              });
            } catch (err) {
              addToast("There was an error creating your draft", {
                appearance: "error",
              });
            }
          }}
          onSelectDraft={() => {
            setShowDrafts(true);
            fetchDrafts({ fetchPolicy: "network-only" });
          }}
          onTemplatesClick={() => {
            history.push(`/board/${boardId}/templates`);
          }}
        />
        {isBroadcastOpen && (
          <BroadcastMessageModal
            visible={isBroadcastOpen}
            setVisible={setIsBroadcastOpen}
            boardValue={boardValue}
            boardId={params.boardId}
          />
        )}
      </div>
    </div>
  );
};
