import { Box, ButtonBase, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { IBoard, ToastProvider } from "@vestaboard/installables";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { usePicks, usePicksCategories } from "../../hooks/usePicks";
import { BORDER_COLORS, BREAKPOINT_SMALL } from "../../config";
import { useGetRole } from "../../hooks/useGetRole";
import { usePersonFavoritesList } from "../../hooks/usePersonFavoritesList";
import { useQuietHours } from "../../hooks/useQuietHours";
import { useGetPinnedMessage } from "../../hooks/useGetPinnedMessage";
import dayjs from "dayjs";
import { MessageCard } from "../../components/MessageCard";
import { usePaywall } from "../../providers/PaywallProvider";
import { PlusCTA } from "../Templates/PlusCTA";
import { useAppState } from "../../hooks/global";
import { CatgegoriesPopover } from ".";
import { MessagesContainer } from "../../components/MessagesContainer";
import { HistoryContainer } from "../../components/HistoryContainer";
import { SimplePagination } from "../../components/SimplePagination";
import { EndOfList } from "../../components/EndOfList";

const useStyles = makeStyles({
  header: {
    padding: "20px",
    borderBottom: "solid 1px #2b2b2b",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  headerColumn: {
    flex: 1,
    display: "flex",
  },
  headerColumnCenter: {
    flex: 1,
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    // icon and padding offset
    left: -65,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      left: -22,
    },
  },
});

export const PickCategory = () => {
  const classes = useStyles();
  const params = useParams() as { boardId: string; categoryId: string };
  const history = useHistory();
  const [page, setPage] = useState(0);
  const { isPlus } = usePaywall();

  const perPage = 15;
  const { data: picksData, loading } = usePicks(
    params.categoryId,
    page,
    perPage
  );
  const { data: categoriesData } = usePicksCategories();

  const categories =
    categoriesData?.picksCategories.map((category) => ({
      name: category.title,
      id: category.id,
    })) ?? [];

  const { iAmOwner } = useGetRole(params.boardId);

  const { favorites } = usePersonFavoritesList();
  const { quietHoursData, hasQuietHours } = useQuietHours(params.boardId);
  const { data: pinnedMessageData } = useGetPinnedMessage();
  const pinnedMessageId = pinnedMessageData?.board?.pinnedMessage?.message?.id;
  const pinnedUntil = pinnedMessageData?.board?.pinnedMessage?.pinnedUntil;
  const hasPinnedMessage = useMemo(() => {
    return pinnedMessageId && pinnedUntil
      ? dayjs(pinnedUntil).isAfter(dayjs())
      : false;
  }, [pinnedUntil, pinnedMessageId]);

  const messages =
    picksData?.pickCategory.picks.map((pick) => pick.message) || [];

  const category =
    categories.find((cat) => cat.id === params.categoryId) || undefined;

  // set app state for board id so usePaywall hook will work // TODO: add effect to usePaywall?
  const { setBoardId } = useAppState();
  useEffect(() => {
    setBoardId(params.boardId);
    params.boardId && localStorage.setItem("lastBoardId", params.boardId);
  }, [params]);

  if (loading) {
    return (
      <Box className={classes.header}>
        <Box className={classes.headerColumnCenter}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <ToastProvider offset={80}>
      <>
        <Box className={classes.header}>
          <Box className={classes.headerColumn}>
            <ButtonBase
              onClick={() => {
                history.push(`/board/${params.boardId}/messages/picks`);
              }}
            >
              <img src="/icons/icon-back.svg" alt="Back" />
            </ButtonBase>
          </Box>
          <Box className={classes.headerColumnCenter}>
            <CatgegoriesPopover title={category?.name} />
          </Box>
          {/* Display Flex three items to center the title */}
          <Box className={classes.headerColumn}></Box>
        </Box>
        <Box>
          <HistoryContainer>
            {!messages.length ? (
              <>
                <EndOfList>No Messages</EndOfList>
              </>
            ) : null}
            {messages.map((message, index: number) => {
              const isFavorite = favorites.includes(message!.id);
              return (
                <MessagesContainer key={message.id}>
                  <MessageCard
                    borderColor={BORDER_COLORS[index % BORDER_COLORS.length]}
                    isFavorite={isFavorite}
                    text={message.text || ""}
                    formatted={message.formatted as IBoard}
                    id={message.id}
                    key={message.id}
                    boardId={params.boardId}
                    onSend={async () => {}}
                    hasPinnedMessage={hasPinnedMessage}
                    quietHoursData={quietHoursData}
                    hasQuietHours={hasQuietHours}
                    iAmOwner={iAmOwner}
                    created={""}
                    author={""}
                  />
                </MessagesContainer>
              );
            })}
            {/* Fix spacing if there are two cards on a row */}
            {messages && 3 ? <MessagesContainer /> : null}
            <PlusCTA />
          </HistoryContainer>
        </Box>

        <Box
          alignSelf="center"
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          paddingBottom={"100px"}
        >
          {isPlus && (
            <SimplePagination
              next={() => {
                setPage(page + 1);
              }}
              previous={() => {
                setPage(page - 1);
              }}
              disableNext={!messages.length}
              disablePrevious={!page}
            />
          )}
        </Box>
      </>
    </ToastProvider>
  );
};
