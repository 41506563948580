
import { Box, Button } from "@mui/material";
import { makeStyles} from '@mui/styles'

interface INavBarLeft {
  setMobileNavMenuOpen: (isOpen: boolean) => void;
  isOpened: boolean;
}

const useStyles = makeStyles({
  button: {
    marginLeft: 12,
  },
  path: {
    stroke: "#f5f5f7",
    transition: ".2s",
  },
  group: {
    transition: "opacity 0s .2s;",
  },
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
  line1: {
    transition: "transform 0s .2s",
    transform: "translateY(0px)",
  },
  line2: {
    transition: "transform .2s",
    transform: "rotate(0)",
    transformOrigin: "center",
  },
  lineTransformed3: {
    transform: "rotate(45deg)",
  },
  lineTransformed4: {
    transform: "rotate(-45deg)",
  },
  lineTransformed2: {
    transform: "translateY(-7px)",
    transition: "transform .2s",
  },
  lineTransformed1: {
    transform: "translateY(7px)",
    transition: "transform .2s",
  },
});

export const Hamburger = (props: INavBarLeft) => {
  const classes = useStyles();

  return (
    <Box className={"db dn-l"}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => props.setMobileNavMenuOpen(!props.isOpened)}
        className={classes.button}
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 28 28"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className={classes.path}
        >
          <g
            className={[
              classes.group,
              classes[props.isOpened ? "hidden" : "visible"],
            ].join(" ")}
          >
            <line
              x1="0"
              y1="8"
              x2="28"
              y2="8"
              strokeWidth="1.5"
              className={
                props.isOpened
                  ? [classes.line1, classes.lineTransformed1].join(" ")
                  : classes.line1
              }
            ></line>
            <line
              x1="0"
              y1="20"
              x2="28"
              y2="20"
              strokeWidth="1.5"
              className={
                props.isOpened
                  ? [classes.line1, classes.lineTransformed2].join(" ")
                  : classes.line1
              }
            ></line>
          </g>
          <g
            className={[
              classes.group,
              classes[props.isOpened ? "visible" : "hidden"],
            ].join(" ")}
          >
            <line
              x1="0"
              y1="14"
              x2="28"
              y2="14"
              strokeWidth="1.5"
              className={
                props.isOpened
                  ? [classes.line2, classes.lineTransformed3].join(" ")
                  : classes.line2
              }
            ></line>
            <line
              x1="0"
              y1="14"
              x2="28"
              y2="14"
              strokeWidth="1.5"
              className={
                props.isOpened
                  ? [classes.line2, classes.lineTransformed4].join(" ")
                  : classes.line2
              }
            ></line>
          </g>
        </svg>
      </Button>
    </Box>
  );
};
