import { useQuery } from "@apollo/client";
import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  Button,
  Modal,
  SubTitle2,
  Spacer,
  useToasts,
  ModalDialog,
} from "@vestaboard/installables";
import { SelectMenu } from "@vestaboard/installables/lib/components/SelectMenu";
import gql from "graphql-tag";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { BoardSourcesQuery, BoardSourcesQueryVariables } from "../../../gql";

interface ISourcesFilterModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  sources?: Array<string>;
  setSources?: (source: Array<string>) => void;
  setCurrentPage: (page: number) => void;
}

const useStyles = makeStyles({
  modalBtnGroup: {
    display: "flex",
    gap: 16,
    justifyContent: "space-between",
    marginTop: 24,
    width: "100%",
  },
  userContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
    flexDirection: "row",
    flex: 1,
  },
  userButton: {
    width: "100%",
    height: 50,
  },
});

export const BOARD_SOURCES_QUERY = gql`
  query BoardSourcesQuery($boardId: String!) {
    board(id: $boardId) {
      id
      subscriptionSet {
        id
        messageSources {
          id
          title
        }
      }
    }
  }
`;

export const SourcesFilterModal = (props: ISourcesFilterModal) => {
  const { addToast } = useToasts();

  const params = useParams<{
    boardId: string;
  }>();

  const { data: boardData, loading } = useQuery<
    BoardSourcesQuery,
    BoardSourcesQueryVariables
  >(BOARD_SOURCES_QUERY, {
    variables: {
      boardId: params.boardId,
    },
  });
  const classes = useStyles();
  const boardSources = useMemo(
    () =>
      boardData?.board?.subscriptionSet.messageSources
        .map((source) => ({
          id: source.id,
          name: source.title,
        }))
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        ) || [],
    [params.boardId, boardData?.board?.subscriptionSet.messageSources]
  );

  const [modalSources, setModalSources] = useState<Array<string>>([]);
  const allSourceIds = useMemo(
    () => boardSources?.map((source) => source.id),
    [boardSources, params.boardId]
  );

  const allOption = {
    name: "All",
    id: "all",
  };

  const selectModalAuthor = useCallback(
    (source: string) => {
      if (source === allOption.id && modalSources.includes(source)) {
        setModalSources([]);
      } else if (
        source !== allOption.id &&
        modalSources.includes(allOption.id)
      ) {
        setModalSources([source]);
      } else if (modalSources.includes(source)) {
        const removedArray = modalSources.filter((id) => id !== source);
        setModalSources([...removedArray]);
      } else {
        setModalSources([...modalSources, source]);
      }
    },
    [modalSources]
  );

  useEffect(() => {
    if (
      props.sources &&
      props.sources?.length > 0 &&
      modalSources.length === 0
    ) {
      setModalSources(props.sources);
    } else if (props.sources?.length === 0 && modalSources.length === 0) {
      setModalSources([allOption.id]);
    }
    // fire once on mount
  }, []);

  return (
    <ModalDialog
      visible={props.visible}
      onClose={() => props.setVisible(false)}
      fullScreenMobile={true}
      header={<SubTitle2>Source</SubTitle2>}
      footer={
        <Box className={classes.modalBtnGroup}>
          <Button
            buttonType="outline"
            onClick={() => {
              props.setVisible(false);
            }}
          >
            Cancel
          </Button>
          <Button
            buttonType="white"
            onClick={async () => {
              if (modalSources.includes(allOption.id)) {
                props.setSources && props.setSources([]);
              } else {
                props.setSources && props.setSources(modalSources);
              }
              await addToast("Filter added", {
                appearance: "success",
                autoDismiss: true,
              });
              props.setCurrentPage(0);
              props.setVisible(false);
            }}
          >
            Apply
          </Button>
        </Box>
      }
    >
      {loading ? (
        <Box
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <SelectMenu
          key={`source-filter-options-${params.boardId}`}
          options={[allOption, ...boardSources]}
          selected={
            modalSources.includes(allOption.id)
              ? [allOption.id, ...allSourceIds]
              : modalSources
          }
          onPress={selectModalAuthor}
        />
      )}
    </ModalDialog>
  );
};
