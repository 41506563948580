import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { usePaywall } from "../../../providers/PaywallProvider";
import { Button, Spacer } from "@vestaboard/installables";

const useStyles = makeStyles({
  mask: {
    zIndex: 10,
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(30, 32, 34, 0.9)",
  },
  modal: {
    position: "fixed",
    width: 600,
    height: 400,
    background: `#000 url(/plusAd2@3x.png) right top no-repeat`,
    backgroundSize: "contain",
    right: `calc(50% - 300px)`,
    top: `calc(50% - 100px)`,
    borderRadius: 18,
  },
  content: {
    width: 220,
    padding: 48,
  },
});

export const PlusMask = () => {
  const classes = useStyles();
  const { setShown } = usePaywall();

  return (
    <Box className={classes.mask}>
      <Box className={classes.modal}>
        <Box className={classes.content}>
          <img src="/vestaboard-plus-logo-white.svg" alt="Vestaboard Plus" />
          <Spacer size="extraLarge" />
          <Typography>
            Take your Vestaboard experience to the next level with a growing
            library of automated content and integrations
          </Typography>
          <Spacer size="extraLarge" />
          <Button buttonType="outline" onClick={() => setShown(true, null)}>
            Activate
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
