import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { BoardPinnedQuery, BoardQueryVariables } from "../gql";

interface IRouteMatch {
  boardId: string;
}

export const BOARD_PINNED_QUERY = gql`
  query BoardPinnedQuery($boardId: String!) {
    board(id: $boardId) {
      id
      pinnedMessage {
        pinnedUntil
        message {
          id
          text
          pinnedUntil
        }
      }
    }
  }
`;

export const useGetPinnedMessage = () => {
  const { boardId } = useParams<IRouteMatch>();
  const result = useQuery<BoardPinnedQuery, BoardQueryVariables>(
    BOARD_PINNED_QUERY,
    {
      variables: {
        boardId,
      },
    }
  );

  return result;
};
