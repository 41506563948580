import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { InstallablesPlusUpgradeModalContents } from "./components/InstallablesPlusUpgradeModal";
import { BREAKPOINT_EXTRA_SMALL, BREAKPOINT_SMALL } from "./config";

const useStyles = makeStyles({
  modal: {
    backgroundColor: "#f5f5f7",
    borderRadius: "8px",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      borderRadius: 0,
      minHeight: "100vh",
    },
  },
  content: {
    zIndex: 1,
    padding: "56px 164px 30px 164px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "60vh",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      padding: 30,
    },
  },
});

export const PricingApp: React.FC = () => {
  const classes = useStyles();

  useEffect(() => {
    document.body.classList.add("transparent-body");
  }, []);

  return (
    <div className={[classes.modal].filter((e) => !!e).join(" ")}>
      <div className={classes.content}>
        <InstallablesPlusUpgradeModalContents
          shown={true}
          onClose={() => {}}
          contextCopy={null}
          hideActivationCodeButton
          openLinksInNewWindow
        />
      </div>
    </div>
  );
};
