import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";

import { AppTemplate } from "./templates/AppTemplate";
import { ComposePage } from "./pages/Compose";
import { ConfigureInstallable } from "./pages/ConfigureInstallable";
import { CreateBoardPage } from "./pages/CreateBoard";
import { DeveloperPage } from "./pages/Api";
import { FactoryPage } from "./pages/Factory";
import { FavoritesPage } from "./pages/Favorites";
import { HistorySelfLoading } from "./pages/History";
import { InstallableDetailPage } from "./pages/InstallableDetail";
import { InstallableDetailPage as NewInstallableDetailPage } from "./pages/InstallableDetail2";
import { Live } from "./pages/Live";
import { LoggedOutSimulatorView } from "./pages/LoggedOutSimulatorView";
import { PaddedTemplate } from "./templates/PaddedTemplate";
import { Profile } from "./pages/Profile";
import { PublishPage } from "./pages/Publish";
import React, { useCallback, useEffect } from "react";
import { RedirectToBoard } from "./pages/RedirectToBoard";
import { SelectBoardPage } from "./pages/SelectBoard";
import { SettingsPage } from "./pages/Settings";
import { ViewBoard } from "./pages/ViewBoard";
import { WysiwygView } from "./pages/WysiwygView";
import { createBrowserHistory } from "history";
import { useAuth0 } from "@auth0/auth0-react";
import { Messages } from "./pages/Messages";
import { InstallableCategoryPage } from "./pages/InstallableCategory";
import { PlusCheckoutWidget } from "./components/plus-checkout-widget";
import { EPaywallMode, usePaywall } from "./providers/PaywallProvider";
import { ConfigureListingView } from "./pages/Settings/ConfigureListing";
import { ShareComponent } from "./components/Share";
import {
  InstallableRedirect,
  PlusRedirect,
  InstallablesPage,
  InstallableInstallRedirect,
  Install,
} from "./pages/Installables";
import { Templates } from "./pages/Templates";
import { TempalteCategory } from "./pages/Templates/TemplateCategory";
import { PickCategory } from "./pages/TodaysPicks.tsx/PickCategory";
import { DiscoverFeatureType } from "./pages/Installables/tabs/Discover/DiscoverFeatureType";
import { ComposeRedirect } from "./pages/WysiwygView/Redirects";
import { ShareRedirect } from "./pages/WysiwygView/ShareRedirect";
import { ComposePageV2 } from "./pages/ComposeV2";
import { MessagesPageV2 } from "./pages/MessagesV2";
import { TemplatesV2 } from "./pages/TemplatesV2";
import { TemplateCategoryV2 } from "./pages/TemplatesV2/TemplateCategory";
import { LaunchDarklyProvider } from "./providers/LaunchDarklyProvider";
import { usePermissions } from "./hooks/usePermissions";
import { FullLoader } from "./components/FullLoader";
import { FeatureFlags } from "./providers/FeatureFlags";
import { PublishPageV2 } from "./pages/PublishV2";
import { LiveV2 } from "./pages/LiveV2/LiveV2";
import { SettingsPageV2 } from "./pages/SettingsV2";
import { MigrateBoardV2 } from "./pages/MigrateBoardV2";
import { SelectBoardPageV2 } from "./pages/SelectBoardV2";
import { CreateBoardPageV2 } from "./pages/CreateBoardV2";
import { ProfileV2 } from "./pages/ProfileV2";

export const history = createBrowserHistory();

export interface IPrivateRouteProps
  extends Omit<Omit<RouteProps, "render">, "component"> {
  redirectTo?: string;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  path,
  children,
  redirectTo,
  ...rest
}) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: redirectTo || window.location.pathname },
      });
    };
    fn();
  }, [isLoading, isAuthenticated, loginWithRedirect, path, redirectTo]);

  const enableIfAuthenticated = <T extends unknown>(e: T): T | null => {
    return isAuthenticated ? e : null;
  };

  return isLoading ? (
    <FullLoader />
  ) : (
    <Route
      path={path}
      children={children && enableIfAuthenticated(children)}
      {...rest}
    />
  );
};

const LaunchDarklyBoardScope = (props: { children: React.ReactNode }) => {
  const { data } = usePermissions();
  const { boardId } = useParams<{ boardId?: string }>();
  const personId = data?.viewer?.account?.person.id || "";

  return (
    <LaunchDarklyProvider
      key={`${boardId}}`}
      personId={personId}
      boardId={boardId}
    >
      {props.children}
    </LaunchDarklyProvider>
  );
};

export const Routes: React.FC = () => {
  const { setShown, setMode, setInitialCode } = usePaywall();
  const history = useHistory();

  useEffect(() => {
    const targetUrl = localStorage.getItem("targetUrl");

    if (targetUrl) {
      history.push(targetUrl);
      localStorage.removeItem("targetUrl");
    }
  }, [history]);

  const asyncLoad = useCallback(async () => {
    if (window.location.href.indexOf("/activate-plus") > -1) {
      const urlParts = window.location.href.split("/");
      const token = urlParts[urlParts.length - 1];
      const data = await fetch(
        `https://platform.vestaboard.com/v3.0/shopify/plus-purchase-session/${token}`
      );
      const json = await data.json();
      const codes = json.purchase.items.map((item: any) => item.code);
      setMode(EPaywallMode.Activating);
      setInitialCode(codes[0]);
      setShown(true, null);
    }
  }, [setInitialCode, setMode, setShown]);

  React.useEffect(() => {
    asyncLoad();
  }, [asyncLoad]);

  return (
    <Switch>
      <PrivateRoute path="/view/:boardId">
        <AppTemplate>
          <ViewBoard />
        </AppTemplate>
      </PrivateRoute>
      <PrivateRoute path="/new-board">
        <AppTemplate>
          <PaddedTemplate>
            <CreateBoardPage />
          </PaddedTemplate>
        </AppTemplate>
      </PrivateRoute>
      <PrivateRoute path="/select-board">
        <AppTemplate>
          <PaddedTemplate>
            <SelectBoardPage />
          </PaddedTemplate>
        </AppTemplate>
      </PrivateRoute>
      <PrivateRoute path="/board/:boardId/new">
        <AppTemplate>
          <PaddedTemplate>
            <CreateBoardPage />
          </PaddedTemplate>
        </AppTemplate>
      </PrivateRoute>
      <PrivateRoute path="/v2/board/:boardId/new">
        <AppTemplate>
          <PaddedTemplate>
            <CreateBoardPageV2 />
          </PaddedTemplate>
        </AppTemplate>
      </PrivateRoute>
      <PrivateRoute path="/_factory">
        <AppTemplate>
          <PaddedTemplate>
            <FactoryPage />
          </PaddedTemplate>
        </AppTemplate>
      </PrivateRoute>
      <PrivateRoute path="/board/:boardId/installables/subscription/:subscriptionId">
        <AppTemplate>
          <InstallableDetailPage />
        </AppTemplate>
      </PrivateRoute>
      <Route path={"/buy-plus"}>
        <h1>Plus landing page</h1>
      </Route>
      <Route path={"/buy-plus/:checkoutToken"}>
        <h1>Plus landing page with Checkout Token</h1>
      </Route>
      <Route path={"/plus-checkout-widget/:token"}>
        <PlusCheckoutWidget />
      </Route>
      <Route path={"/visual-editor"}>
        <WysiwygView />
      </Route>
      <Route path={"/billing"}>
        <PlusRedirect tab="billing" section="settings" />
      </Route>
      <Route path={"/plus"}>
        <PlusRedirect tab="discover" />
      </Route>
      <Route path={"/installed"}>
        <PlusRedirect tab="installed" />
      </Route>
      <PrivateRoute path={"/compose/share/:token"}>
        <ShareRedirect />
      </PrivateRoute>
      <Route path={"/compose/encoded/:token"} component={ComposeRedirect} />
      <Route path={"/slack"}>
        <InstallableRedirect
          marketplaceListing={"aa4dfa73-787a-4ce4-aa5c-5eb28be990ae"}
        />
      </Route>
      <Route exact path={"/marketplace-listing/:marketplaceid"}>
        <InstallableRedirect />
      </Route>
      <Route
        exact
        path="/marketplace-listing/:marketplaceListingId/install"
        component={InstallableInstallRedirect}
      />
      <Route path={"/simulator/:boardId"}>
        <LoggedOutSimulatorView />
      </Route>
      <PrivateRoute path="/configure-listing/:listing/:data">
        <ConfigureListingView />
      </PrivateRoute>
      <PrivateRoute path="/board/:boardId/live" exact>
        <AppTemplate>
          <Live />
        </AppTemplate>
      </PrivateRoute>
      <PrivateRoute path="/v2/board/:boardId/live" exact>
        <AppTemplate>
          <LiveV2 />
        </AppTemplate>
      </PrivateRoute>
      <PrivateRoute path="/v2/board/:boardId/migrate">
        <AppTemplate>
          <MigrateBoardV2 />
        </AppTemplate>
      </PrivateRoute>
      <PrivateRoute path="/board/:boardId">
        <LaunchDarklyBoardScope>
          <FeatureFlags>
            {(flags) => (
              <>
                <AppTemplate>
                  <Switch>
                    <Redirect
                      exact
                      from="/board/:boardId"
                      to="/board/:boardId/live"
                    />
                    <Route path="/board/:boardId/compose/duplicate/:message">
                      {flags.messagesV2 ? <ComposePageV2 /> : <ComposePage />}
                    </Route>
                    <Route path="/board/:boardId/compose">
                      {flags.messagesV2 ? <ComposePageV2 /> : <ComposePage />}
                    </Route>
                    <Route path="/board/:boardId/templates/:category">
                      {flags.messagesV2 ? (
                        <TemplateCategoryV2 />
                      ) : (
                        <TempalteCategory />
                      )}
                    </Route>
                    <Route path="/board/:boardId/templates">
                      {flags.messagesV2 ? <TemplatesV2 /> : <Templates />}
                    </Route>
                    <Route path="/board/:boardId/history">
                      {flags.messagesV2 ? (
                        <MessagesPageV2 />
                      ) : (
                        <HistorySelfLoading />
                      )}
                    </Route>
                    <Route path="/board/:boardId/favorites">
                      <FavoritesPage />
                    </Route>
                    <Route path="/board/:boardId/publish">
                      {flags.simulatorV2 ? <PublishPageV2 /> : <PublishPage />}
                    </Route>
                    <Route path="/board/:boardId/config-installable/:subscriptionId">
                      <ConfigureInstallable />
                    </Route>
                    <Route path="/board/:boardId/installables/category/:category">
                      <InstallableCategoryPage />
                    </Route>
                    <Route path="/board/:boardId/installables/discover/:featureType">
                      <DiscoverFeatureType />
                    </Route>
                    <Route path="/board/:boardId/installables">
                      <InstallablesPage />
                    </Route>
                    {/* FIXME: move somewhere else */}
                    <Route path="/board/:boardId/installable/:installableId">
                      <NewInstallableDetailPage />
                    </Route>
                    <Route
                      exact
                      path="/board/:boardId/marketplace-listing/:marketplaceListingId/install"
                      component={Install}
                    />

                    <Route path="/board/:boardId/settings">
                      <SettingsPage />
                    </Route>
                    <Route path="/v2/board/:boardId/settings">
                      <SettingsPageV2 />
                    </Route>

                    <Route path="/board/:boardId/profile">
                      <Profile />
                    </Route>
                    <Route path="/board/:boardId/select">
                      <PaddedTemplate>
                        <SelectBoardPage />
                      </PaddedTemplate>
                    </Route>
                    <Route path="/board/:boardId/developer">
                      <DeveloperPage />
                    </Route>
                    <Route
                      path="/board/:boardId/messages/picks/:categoryId"
                      component={PickCategory}
                    />
                    <Route path="/board/:boardId/messages">
                      {flags.messagesV2 ? <MessagesPageV2 /> : <Messages />}
                    </Route>
                  </Switch>
                  <ShareComponent />
                </AppTemplate>
              </>
            )}
          </FeatureFlags>
        </LaunchDarklyBoardScope>
      </PrivateRoute>

      <PrivateRoute path="/v2/board/:boardId">
        <LaunchDarklyBoardScope>
          <FeatureFlags>
            {(flags) => (
              <>
                <AppTemplate>
                  <Switch>
                    <Redirect
                      exact
                      from="/v2/board/:boardId"
                      to="/v2/board/:boardId/live"
                    />
                    <Route path="/v2/board/:boardId/settings">
                      <SettingsPageV2 />
                    </Route>
                    <Route path="/v2/board/:boardId/profile">
                      <ProfileV2 />
                    </Route>
                    <Route path="/v2/board/:boardId/select">
                      <PaddedTemplate>
                        <SelectBoardPageV2 />
                      </PaddedTemplate>
                    </Route>
                    <Route path="/v2/board/:boardId/developer">
                      <DeveloperPage />
                    </Route>
                  </Switch>
                  <ShareComponent />
                </AppTemplate>
              </>
            )}
          </FeatureFlags>
        </LaunchDarklyBoardScope>
      </PrivateRoute>
      <PrivateRoute>
        <RedirectToBoard />
      </PrivateRoute>
    </Switch>
  );
};
