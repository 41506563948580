import { ExpandMore } from "@mui/icons-material";
import {
  Alert,
  Box,
  ButtonBase,
  CircularProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Button,
  FlexHorizontal,
  ModalDialog,
  Spacer,
  SubTitle2,
} from "@vestaboard/installables";
import { useCallback, useState } from "react";
import { useListPickCategoriesV2 } from "../../../hooks/usePickCategoriesV2";

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
  button: {
    backgroundColor: "#303537",
    padding: 12,
    borderRadius: 8,
    display: "flex",
    paddingLeft: 18,
    paddingRight: 18,
  },
  tagOn: {
    backgroundColor: "#FFF",
    border: "1px solid #FFF",
    padding: 8,
    borderRadius: 8,
    display: "flex",
    paddingLeft: 18,
    paddingRight: 18,
    marginRight: 12,
    marginBottom: 12,
    color: "#000",
  },
  tagOff: {
    backgroundColor: "transparent",
    border: "1px solid #FFF",
    padding: 8,
    borderRadius: 8,
    display: "flex",
    paddingLeft: 18,
    paddingRight: 18,
    marginRight: 12,
    marginBottom: 12,
    color: "#FFF",
  },
  modalBtnGroup: {
    display: "flex",
    gap: 16,
    justifyContent: "space-between",
    marginTop: 24,
    width: "100%",
  },
  tags: {
    display: "flex",
    marginTop: 12,
    flexWrap: "wrap",
  },
  title: {
    width: "100%",
  },
  tagActions: {
    marginTop: 12,
    display: "flex",
    width: 300,
  },
});

interface ISourcesFilter {
  tags: Array<string> | null;
  setTags: (source: Array<string> | null) => void;
  sortBy?: string;
  setSortBy?: (sortBy: string) => void;
}

function onlyUnique(value: string, index: number, array: string[]) {
  return array.indexOf(value) === index;
}

export const SourcesFilter = (props: ISourcesFilter) => {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(props.tags);
  const classes = useStyles();
  const [sortBy, setSortBy] = useState(props.sortBy || "date");
  const { data, loading, error } = useListPickCategoriesV2({
    input: {},
  });
  const tags = (data?.listPickCategories?.pickCategories || [])
    .map((source) => source.title)
    .filter(onlyUnique);

  const selectSource = useCallback(
    (source) => {
      if (selected?.includes(source)) {
        setSelected(selected?.filter((s) => s !== source));
      } else {
        setSelected([...(selected || []), source]);
      }
    },
    [selected]
  );

  return (
    <Box className={classes.container}>
      <ButtonBase onClick={() => setVisible(true)} className={classes.button}>
        <Typography>Filter</Typography>
        <ExpandMore />
      </ButtonBase>
      <ModalDialog
        header={null}
        visible={visible}
        onClose={() => setVisible(false)}
        fullScreenMobile={true}
        footer={
          <Box className={classes.modalBtnGroup}>
            <Button
              buttonType="outline"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              buttonType="white"
              onClick={async () => {
                setVisible(false);
                if (selected?.length === tags.length) {
                  props.setTags(null);
                } else {
                  props.setTags(selected || null);
                }

                if (props.setSortBy) {
                  props.setSortBy(sortBy);
                }
              }}
            >
              Apply
            </Button>
          </Box>
        }
      >
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">
            {error.message || "There was an error loading your message sources"}
          </Alert>
        ) : (
          <Box>
            <SubTitle2>Sort By</SubTitle2>
            <Spacer size="medium" />
            <FlexHorizontal>
              <Button
                buttonType={sortBy === "likes" ? "white" : "outline"}
                onClick={() => setSortBy("likes")}
              >
                Popularity
              </Button>
              <Box style={{ width: 24 }} />
              <Button
                buttonType={sortBy === "date" ? "white" : "outline"}
                onClick={() => setSortBy("date")}
              >
                Most Recent
              </Button>
            </FlexHorizontal>
            <Spacer size="extraLarge" />
            <Box className={classes.title}>
              <SubTitle2>Tags</SubTitle2>
            </Box>
            <Box className={classes.tags}>
              {tags.map((title) => (
                <ButtonBase
                  key={title}
                  className={
                    selected?.includes(title) ? classes.tagOn : classes.tagOff
                  }
                  onClick={() => {
                    selectSource(title);
                  }}
                >
                  {title}
                </ButtonBase>
              ))}
            </Box>
            <Box className={classes.tagActions}>
              <Button
                buttonType="outline"
                onClick={() => {
                  setSelected(tags);
                }}
              >
                Select All
              </Button>
              <Box style={{ width: 24 }} />
              <Button
                buttonType="outline"
                onClick={() => {
                  setSelected(null);
                }}
              >
                Clear All
              </Button>
            </Box>
          </Box>
        )}
      </ModalDialog>
    </Box>
  );
};
