import * as React from "react";

import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface IFavorite {
  isFavorite: boolean;
  addFavorite: () => void;
  removeFavorite: () => void;
}

const useStyles = makeStyles({
  container: {
    position: "absolute",
    top: -7,
    right: -12,
  },
  icon: {
    height: 18,
  },
});

export const Favorite = (props: IFavorite) => {
  const { isFavorite, addFavorite, removeFavorite } = props;
  const classes = useStyles();

  return (
    <Button
      className={classes.container}
      variant="text"
      color="secondary"
      onClick={() => (!isFavorite ? addFavorite() : removeFavorite())}
    >
      <img
        src={`/icons/icon-favorites-${isFavorite ? "active" : "inactive"}.svg`}
        alt={isFavorite ? "Unfavorite" : "Favorite"}
        className={classes.icon}
      />
    </Button>
  );
};
