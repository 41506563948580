import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { CreateDraftV2Mutation, CreateDraftV2MutationVariables } from "../gql";

const MUTATION = gql`
  mutation CreateDraftV2($input: CreateDraftInputV2!) {
    createDraft: createDraftV2(input: $input) {
      __typename
      ... on DraftV2 {
        id
        created
        attribution
        message {
          id
          text
          characters
        }
      }
      ... on DraftErrorV2 {
        type
        error
      }
    }
  }
`;

export const useCreateDraftV2 = () => {
  return useMutation<CreateDraftV2Mutation, CreateDraftV2MutationVariables>(
    MUTATION
  );
};
