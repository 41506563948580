import { UpdateBoardV2Mutation, UpdateBoardV2MutationVariables } from "../gql";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const UPDATE_BOUARD_MUTATION_V2 = gql`
  mutation UpdateBoardV2($input: UpdateBoardInputV2!) {
    updateBoardV2(input: $input) {
      ... on BoardV2 {
        id
        title
        timezone
        quietHours {
          start
          end
        }
      }
      ... on BoardErrorV2 {
        type
        error
      }
    }
  }
`;

export const useUpdateBoardV2 = () => {
  return useMutation<UpdateBoardV2Mutation, UpdateBoardV2MutationVariables>(
    UPDATE_BOUARD_MUTATION_V2
  );
};
