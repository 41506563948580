import gql from "graphql-tag";
import { UpdatePersonV2Mutation, UpdatePersonV2MutationVariables } from "../gql";
import { useMutation } from "@apollo/client";

const UPDATE_PERSON_V2_MUTATION = gql`
  mutation UpdatePersonV2($input: UpdatePersonInputV2!) {
    updatePersonV2(input: $input) {
      ... on PersonV2 {
        id
        firstName
        lastName
        emailAddress
      }
      ... on PersonErrorV2 {
        type
        error
      }
    }
  }
`;


export const useUpdatePersonV2 = () => {
    return useMutation<UpdatePersonV2Mutation, UpdatePersonV2MutationVariables>(UPDATE_PERSON_V2_MUTATION);
}
