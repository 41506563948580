import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

import InfiniteScroll from "react-infinite-scroller";
import { InstallableTile } from "../../Installables/components/InstallableTile";
import { EndOfList } from "../../../components/EndOfList";
import { MessagesContainer } from "../../../components/MessagesContainer";
import { HistoryContainer } from "../../../components/HistoryContainer";

export interface IInstallablesListProps {
  data: {
    id: string;
    title: string;
    free: boolean;
    icon: string;
    description: string;
    isSingleton: boolean;
    existingSubscriptionId: string | null;
    existingSubscriptionMuted: boolean;
  }[];
}

const CenteredLoading = () => (
  <Box
    alignSelf="center"
    alignItems="center"
    alignContent="center"
    display="flex"
    justifyContent="center"
    width="100%"
  >
    <CircularProgress />
  </Box>
);

export const InstallablesList: React.FC<IInstallablesListProps> = (props) => {
  const loading = false;
  const data = {
    installables: props.data,
  };
  const [limit, setLimit] = React.useState(9);

  return (
    <>
      {!(!loading && data) ? (
        <HistoryContainer>
          {!loading ? null : <CenteredLoading />}
          {!loading && data && data.installables.length === 0 && (
            <EndOfList>No Installables</EndOfList>
          )}
        </HistoryContainer>
      ) : (
        <>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => {
              setLimit(limit + 9);
            }}
            hasMore={data.installables.length > limit}
            loader={<CenteredLoading />}
          >
            <HistoryContainer>
              {data.installables.map((installable, index) => {
                return (
                  <MessagesContainer key={installable.id}>
                    <InstallableTile
                      {...installable}
                      installed={false}
                      enabled={false}
                      isPlus={true}
                      canAdd={true}
                      installableIsSingleton={installable.isSingleton}
                      subscriptionId={installable.existingSubscriptionId}
                      subscriptionMuted={installable.existingSubscriptionMuted}
                      free={installable.free}
                    />
                  </MessagesContainer>
                );
              })}
              {data.installables && 3 ? <MessagesContainer /> : null}
            </HistoryContainer>
          </InfiniteScroll>
        </>
      )}
    </>
  );
};
