import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { GetBoardRolesQuery, GetBoardRolesQueryVariables } from "../gql";

export const BOARD_ROLES_QUERY = gql`
  query GetBoardRoles($boardId: String!) {
    viewer {
      id
      account {
        id
      }
    }
    board(id: $boardId) {
      id
      title
      tenant {
        id
        members {
          id
          role
          person {
            id
            account {
              id
              emailAddress
            }
          }
        }
      }
    }
  }
`;

export const useGetRole = (boardId: string) => {
  const { loading, data, refetch } = useQuery<
    GetBoardRolesQuery,
    GetBoardRolesQueryVariables
  >(BOARD_ROLES_QUERY, {
    variables: {
      boardId: boardId,
    },
  });

  const myId = data?.viewer && data.viewer.account && data.viewer.account.id;

  const accountRecord = data?.board.tenant.members.find(
    (tenantMember) => myId === tenantMember.person.account.id
  );
  const iAmAdmin =
    accountRecord &&
    (accountRecord.role === "Owner" || accountRecord.role === "Admin");

  const iAmOwner = accountRecord && accountRecord.role === "Owner";

  return {
    emailAddress: accountRecord?.person.account.emailAddress,
    iAmOwner,
    iAmAdmin,
    loading,
    refetch,
  };
};
