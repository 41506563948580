import * as React from "react";

import { BREAKPOINT_SMALL } from "../../../config";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  navbarLeft: {
    marginLeft: 42,
    display: "flex",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      marginLeft: -15,
    },
  },
}));

interface INavBarLeft {
  children: Array<React.ReactElement>;
}

export const NavBarLeft = (props: INavBarLeft) => {
  const classNames = useStyles();

  return <div className={classNames.navbarLeft}>{props.children}</div>;
};
