import {
  DeleteSubscriptionMutation,
  DeleteSubscriptionMutationVariables,
} from "../gql";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const DELETE_SUBSCRIPTION = gql`
  mutation DeleteSubscription($subscriptionId: String!) {
    deleteSubscription(input: { id: $subscriptionId }) {
      deleted
    }
  }
`;

export const useDeleteSubscription = () => {
  return useMutation<
    DeleteSubscriptionMutation,
    DeleteSubscriptionMutationVariables
  >(DELETE_SUBSCRIPTION);
};
