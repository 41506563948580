import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { UnFavoriteV2Mutation, UnFavoriteV2MutationVariables } from "../gql";
import { v4 as uuid } from "uuid";

const MUTATION = gql`
  mutation UnFavoriteV2($input: RemoveFavoriteInputV2!) {
    removeFavorite: removeFavoriteV2(input: $input) {
      ... on FavoriteV2 {
        id
        message {
          id
          isFavorited
        }
      }
      ... on FavoriteErrorV2 {
        type
        error
      }
    }
  }
`;

export const useRemoveFavoriteV2 = () => {
  return useMutation<UnFavoriteV2Mutation, UnFavoriteV2MutationVariables>(
    MUTATION
  );
};

useRemoveFavoriteV2.optimistic = (messageId: string) =>
  ({
    removeFavorite: {
      id: uuid(),
      message: {
        id: messageId,
        isFavorited: false,
        __typename: "MessageV2",
      },
      __typename: "FavoriteV2",
    },
  } as UnFavoriteV2Mutation);
