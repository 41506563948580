import { Box, ButtonBase } from "@mui/material";
import { makeStyles } from "@mui/styles";

export enum FilterType {
  Inspiration,
  MyMessages,
  VestaboardPlus,
}

interface IFilters {
  filterTypes: FilterType[];
  setFilterTypes: (filterTypes: FilterType[]) => void;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    paddingLeft: 32,
    paddingTop: 24,
  },
  activeButton: {
    border: "solid 1px #383b3f",
    backgroundColor: "#383b3f",
    color: "#FFF",
    marginRight: 12,
    padding: 6,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 12,
  },
  inactiveButton: {
    border: "solid 1px #383b3f",
    backgroundColor: "transparent",
    color: "#383b3f",
    marginRight: 12,
    padding: 6,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 12,
  },
});

const buttons = [
  {
    title: "Inspiration",
    filterType: FilterType.Inspiration,
  },
  {
    title: "My Messages",
    filterType: FilterType.MyMessages,
  },
  {
    title: "Vestaboard Plus",
    filterType: FilterType.VestaboardPlus,
  },
];

export const Filters = (props: IFilters) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {buttons.map((button) => (
        <ButtonBase
          key={button.filterType}
          className={
            props.filterTypes.includes(button.filterType)
              ? classes.activeButton
              : classes.inactiveButton
          }
          onClick={() => {
            if (props.filterTypes.includes(button.filterType)) {
              props.setFilterTypes(
                props.filterTypes.filter(
                  (filterType) => filterType !== button.filterType
                )
              );
            } else {
              props.setFilterTypes([...props.filterTypes, button.filterType]);
            }
          }}
        >
          {button.title}
        </ButtonBase>
      ))}
    </Box>
  );
};
