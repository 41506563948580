import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { OutlinedButton } from "../../../components/OutlinedButton";
import {
  BREAKPOINT_EXTRA_EXTRA_SMALL,
  BREAKPOINT_SMALL,
} from "../../../config";
import { InstallButton } from "./InstallableListItem";

interface IRouteMatch {
  boardId: string;
}

const useStyles = makeStyles({
  container: {
    width: 376,
    height: 200,
    boxSizing: "border-box",
    display: "flex",
    objectFit: "cover",
    padding: "29px 41px",
    borderRadius: 8,
    aspectRatio: "2/1",
    [`@media(max-width: ${BREAKPOINT_EXTRA_EXTRA_SMALL})`]: {
      width: "100%",
      maxWidth: 376,
      padding: "18px 24px",
    },
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      margin: "0 auto",
    },
  },
  details: {
    flexGrow: 1,
  },
  titleBar: {
    position: "relative",
    flexGrow: 1,
    marginBottom: 7,
  },
  action: {
    // display: 'flex',
    // justifyContent: 'flex-end'
  },
  title: {
    paddingTop: 3,
    fontFamily: "HelveticaNeue",
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: 1.44,
    letterSpacing: "normal",
    textAlign: "left",
    maxHeight: 26,
    overflowY: "hidden",
    textDecoration: "none !important",
  },
  description: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 1.5,
    maxHeight: 48,
    overflowY: "hidden",
    marginBottom: 26,
  },
});

interface IFeaturedInstallable {
  id: string;
  title: string;
  description: string | null;
  backgroundImage: string;
  textColor: string;
  installed: boolean;
  className?: string;
  installableIsSingleton: boolean;
  subscriptionId: string | null;
}

export const FeaturedInstallable: React.FC<IFeaturedInstallable> = (props) => {
  const classes = useStyles();
  const params = useParams<IRouteMatch>();
  const { boardId } = params;
  const history = useHistory();
  const url = `/board/${boardId}/installable/${props.id}`;
  const settingsUrl = `/board/${boardId}/installables/subscription/${props.subscriptionId}`;

  return (
    <div
      className={[classes.container, props.className]
        .filter((e) => !!e)
        .join(" ")}
      style={{
        color: props.textColor,
        backgroundImage: props.backgroundImage,
        backgroundSize: "contain",
      }}
    >
      <Box className={classes.details}>
        <Box className={classes.titleBar}>
          <Link
            to={url}
            className={classes.title}
            style={{ color: props.textColor }}
          >
            {props.title}
          </Link>
        </Box>
        <Box className={classes.description}>{props.description}</Box>
        <Box className={classes.action}>
          <InstallButton
            free={false}
            marketplaceListingId={props.id}
            navigateToDetail={() => history.push(url)}
            render={(disabled, click, spinning) => (
              <OutlinedButton
                disabled={disabled}
                color={props.textColor}
                size={"small"}
                onClick={() => {
                  if (props.subscriptionId && props.installableIsSingleton) {
                    history.push(settingsUrl);
                  } else {
                    click();
                  }
                }}
              >
                {spinning ? (
                  <CircularProgress />
                ) : props.subscriptionId && props.installableIsSingleton ? (
                  "Settings"
                ) : (
                  "Add"
                )}
              </OutlinedButton>
            )}
          />
        </Box>
      </Box>
    </div>
  );
};
