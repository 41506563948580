import * as React from "react";

import {
  ButtonBase,
  Modal as MaterialModal,
} from "@mui/material";
import {
  makeStyles,
} from '@mui/styles'

type ModalProps = IModalProps;

interface IModalProps {
  shown: boolean;
  onClose?: () => void;
  customStyles?: any
  className?: string
}

const useStyles = makeStyles({
  modal: {
    backgroundColor: "transparent",
    color: "#fff",
    minWidth: 600,
    "@media(max-width: 700px)": {
      minWidth: 400,
    },
    "@media(max-width: 500px)": {
      minWidth: 300,
    },
    " > *:not(:first-child)": {
      marginLeft: 10,
    },
  },
  backdrop: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    left: 0,
    top: 0,
    '-webkit-backdrop-filter': 'blur(10px)',
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(23, 24, 24, 0.5)'
  },
});

const BackdropComponent = (
  props: ModalProps,
  classes: Record<string, string>
) => () => {
  return <ButtonBase className={classes.backdrop} onClick={props.onClose} />;
};

export const BlurModal: React.FC<ModalProps> = (props) => {
  const classes = useStyles();

  return (
    <MaterialModal
      BackdropComponent={BackdropComponent(props, classes)}
      open={props.shown}
      onClose={props.onClose}
      className="modal-container"
    >
      <div
        style={{
          position: "absolute",
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`,
          outline: 0,
          ...props.customStyles
        }}
        className={`${classes.modal} flex flex-column br2` + ` ${props.className}`}
      >
        {props.children}
      </div>
    </MaterialModal>
  );
};
