import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  CreateInvitationMutation,
  CreateInvitationMutationVariables,
  Direction,
} from "../../../gql";
import { useState } from "react";

import { BorderContainer } from "../../../components/BorderContainer";
import { Field } from "../../../components/Field";
import { FilledButton } from "../../../components/FilledButton";
import InfoIcon from "@mui/icons-material/Info";
import { Input } from "@vestaboard/installables";
import { OutlinedButton } from "../../../components/OutlinedButton";
import { ROLE_TYPES } from "../../../config";
import { Select } from "../../../components/Select";
import { Spacer } from "../../../components/Spacer";
import gql from "graphql-tag";
import { useFeatures } from "../../../hooks/useFeatures";
import { useMutation } from "@apollo/client";
import { useToasts } from "@vestaboard/installables";
import { useSelectAdminOwnerBoards } from "../../../hooks/useSelectBoards";
import { SelectMenu } from "@vestaboard/installables/lib/components/SelectMenu";
import { useIsMobileMediaQuery } from "../../../hooks/media-queries";
import { useInviteRoleV2 } from "../../../hooks/useInviteRoleV2";
import { useSelectAdminOwnerBoardsV2 } from "../../../hooks/useSelectBoardsV2";
import { GET_ROLES_QUERY_V2 } from "../../../hooks/useGetRolesV2";

interface IAddUser {
  handleClose: () => void;
  boardId: string;
  refetchUsers: () => void;
  openInfoModal: () => void;
  showInfoModal: boolean;
  iAmAdmin: boolean;
}

const useStyles = makeStyles({
  container: {
    paddingBottom: 24,
    padding: 0,
  },
  emailRoleContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "11px 26px",
  },
  button: {
    minWidth: 100,
    marginTop: 26,
  },
  infoIconContainer: {
    padding: 0,
    minWidth: "auto",
  },
  infoIcon: {
    fontSize: 20,
    color: "#6B6E6E",
  },
  selectMenuContainer: {
    paddingTop: 12,
    backgroundColor: `rgba(33,35,35)`,
    paddingLeft: 26,
    paddingRight: 26,
    paddingBottom: 24,
  },
  bottomButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: "11px 26px",
  },
});

export const AddUserV2 = (props: IAddUser) => {
  const { features } = useFeatures();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("User");
  const [createInvitation] = useInviteRoleV2();

  const { addToast } = useToasts();

  const { options, selectedBoards, selectAllBoards, clearBoards, selectBoard } =
    useSelectAdminOwnerBoardsV2(props.iAmAdmin ? props.boardId : undefined);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        if (!email.length || !selectedBoards.length) {
          addToast(`There was an error sending your invitation`, {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }

        try {
          await Promise.all(
            selectedBoards.map(async (board) => {
              return await createInvitation({
                variables: {
                  input: {
                    email: email,
                    boardId: board,
                    permission:
                      role === "Owner"
                        ? Direction.Owner
                        : role === "Admin"
                        ? Direction.Admin
                        : Direction.User,
                  },
                },
                refetchQueries: [
                  {
                    query: GET_ROLES_QUERY_V2,
                    variables: {
                      boardId: board,
                    },
                  },
                ],
              });
            })
          );

          addToast(`Invitation sent successfully`, {
            appearance: "success",
            autoDismiss: true,
          });

          props.handleClose();
        } catch (err) {
          addToast(`There was an error sending your invitation`, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }}
    >
      <BorderContainer
        className={classes.container}
        active
        backgroundColor={"rgb(43,45,46)"}
      >
        <Box className={classes.emailRoleContainer}>
          <Box style={{ width: "100%", maxWidth: 500 }}>
            <Input
              label="User Email"
              onValueChange={(value) => {
                setEmail(value);
              }}
              value={email}
            />
          </Box>
          <Spacer width={32} />
          <Field
            label={`Role`}
            rightIcon={
              <Button
                className={classes.infoIconContainer}
                onClick={props.openInfoModal}
              >
                <InfoIcon className={classes.infoIcon} />
              </Button>
            }
          >
            <Select
              required
              value={role}
              onValueChange={setRole}
              options={ROLE_TYPES.filter(
                (role) => role !== "Owner" || features.manageOwners
              ).map((key) => ({
                key,
                value: key,
              }))}
            />
          </Field>
        </Box>
        <Box className={classes.selectMenuContainer}>
          <SelectMenu
            options={options || []}
            onPress={selectBoard}
            selected={selectedBoards}
            selectAll={selectAllBoards}
            clear={clearBoards}
          />
        </Box>
        <Spacer width={32} />
        <Box className={classes.bottomButtonContainer}>
          <OutlinedButton
            type="button"
            onClick={props.handleClose}
            className={classes.button}
          >
            Cancel
          </OutlinedButton>
          <Spacer width={16} />
          <FilledButton
            variant="contained"
            className={classes.button}
            disabled={!email.length || !selectedBoards.length}
            type="submit"
          >
            Add User
          </FilledButton>
        </Box>
      </BorderContainer>
    </form>
  );
};
