import * as React from "react";
import { useMediaQuery } from "react-responsive";

import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory, useParams } from "react-router";
import {
  GetDiscoverQuery,
  GetDiscoverQueryVariables,
  GetDiscoverMarketPlaceListingsQuery,
  GetDiscoverMarketPlaceListingsQueryVariables,
} from "../../../../gql";

import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import gql from "graphql-tag";
import { GET_PLUS_CTA } from "../..";
import { usePaywall } from "../../../../providers/PaywallProvider";
import { OutlinedButton } from "../../../../components/OutlinedButton";
import {
  BREAKPOINT_EXTRA_EXTRA_SMALL,
  BREAKPOINT_EXTRA_SMALL,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_SMALL,
} from "../../../../config";
import { useAppState } from "../../../../hooks/global";
import FeaturedInstallablesSection from "../../components/FeaturedInstallablesSection";
import { InstallButton } from "../../components/InstallableListItem";
import InstallablesSection from "../../components/InstallablesSection";
import { DiscoverFeatureTypeTag } from "../components/DiscoverFeatureTypeTag";
import { useMarketPlaceFeatureTypes } from "../../../../hooks/useMarketPlaceFeatureTypes";
import Slider from "../../../../components/Slider";
import { useBlendMessageSet } from "../../../../hooks/useBlendMessageSet";
import { colors } from "@vestaboard/installables";

export const GET_DISCOVER = gql`
  query GetDiscover($boardId: String!) {
    featuredMarketplaceListings {
      image
      description
      marketplaceListing {
        id
        title
        free
        installable {
          id
          installationsAreSingleton
        }
      }
    }
    featuredMarketplaceChannel {
      image
      description
      imageMedia {
        id
        cdnUrl
      }
      imageVariantMedia {
        id
        cdnUrl
      }
      marketplaceListing {
        id
        title
        installable {
          id
          installationsAreSingleton
        }
      }
    }
    board(id: $boardId) {
      id
      subscriptionSet {
        channels {
          id
          marketplaceListing {
            id
          }
          muted
        }
      }
    }
  }
`;

// we can cache this client side since this list updates infrequently
// splitting the query up will make the entire page load faster and less often
export const GET_DISCOVER_MPLS = gql`
  query GetDiscoverMarketPlaceListings {
    marketplaceListingsCategories {
      id
      title
      marketplaceListings {
        id
        releaseDate
        free
        installable {
          id
          created
          title
          icon
          description
          hideFromInstallablesList
          installationsAreSingleton
          category {
            id
            title
          }
          developer {
            id
            title
          }
        }
        icon
        messageSet {
          id
          title
        }
        title
        shortDescription
        description
        stagingOnly
      }
    }
  }
`;

const useStyles = makeStyles({
  installButton: {
    width: 140,
  },
  discoverCategoryContainer: {
    paddingBottom: 16,
  },
  discoverCategoryTags: {},
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 810,
    flexGrow: 1,
    width: "100%",
  },
  channelOfTheWeekContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 810,
    flexGrow: 1,
    width: "100%",
    paddingTop: 48,
  },
  installablesContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 810,
    flexGrow: 1,
    width: "100%",
  },
});

interface IRouteMatch {
  boardId: string;
}

interface IDiscover {
  category: string | undefined;
}

export const useDiscoverQuery = (boardId: string) => {
  const { tenantId } = useAppState();

  return useQuery<GetDiscoverQuery, GetDiscoverQueryVariables>(GET_DISCOVER, {
    fetchPolicy: "network-only",
    skip: !tenantId,
    variables: {
      boardId: boardId,
    },
  });
};

export const useDiscoverMPLsQuery = () => {
  return useQuery<
    GetDiscoverMarketPlaceListingsQuery,
    GetDiscoverMarketPlaceListingsQueryVariables
  >(GET_DISCOVER_MPLS);
};

export const CTAMessage =
  "Take your Vestaboard experience to the next level with a growing library of automated content and integrations";

export const Discover = (props: IDiscover) => {
  const classes = useStyles();
  const params = useParams<IRouteMatch>();
  const history = useHistory();
  const { setShown, isPlus } = usePaywall();
  const isExtraSmallScreen = useMediaQuery({
    query: `(max-width: ${BREAKPOINT_EXTRA_SMALL})`,
  });
  const isSmall = useMediaQuery({
    query: `(max-width: ${BREAKPOINT_SMALL})`,
  });
  const isMedium = useMediaQuery({
    query: `(max-width: ${BREAKPOINT_MEDIUM})`,
  });
  const { data, loading } = useDiscoverQuery(params.boardId);

  const { data: featureTypeData } = useMarketPlaceFeatureTypes();

  const { data: mplData, loading: mplLoading } = useDiscoverMPLsQuery();

  // Pre fetch to prevent blend button loading state
  useBlendMessageSet(params.boardId);

  if (!data || loading) {
    return <CircularProgress />;
  }

  const sections = mplData?.marketplaceListingsCategories;

  const featuredMarketplaceListings = data.featuredMarketplaceListings.map(
    (fml) => {
      const existingSubscriptionId = data.board.subscriptionSet.channels.filter(
        (c) => c.marketplaceListing?.id == fml.marketplaceListing.id
      )[0]?.id;

      return {
        id: fml.marketplaceListing.id,
        title: fml.marketplaceListing.title,
        description: fml.description,
        textColor: "#ffffff",
        backgroundImage: `url("${fml.image}")`,
        subscriptionId: existingSubscriptionId,
        installableIsSingleton:
          fml.marketplaceListing.installable.installationsAreSingleton ?? false,
      };
    }
  );

  return (
    <Box className={classes.contentWrapper}>
      <Box className={classes.content}>
        {!isPlus && (
          <CTABox
            id={""}
            subtitle={CTAMessage}
            cta={GET_PLUS_CTA}
            images={{
              "@1x": `${
                isExtraSmallScreen
                  ? "/plus-ad-mobile/plus-ad-mobile@1x.png"
                  : "/plus-ad-desktop/plus-ad-desktop@1x.png"
              }`,
              "@2x": `${
                isExtraSmallScreen
                  ? "/plus-ad-mobile/plus-ad-mobile@2x.png"
                  : "/plus-ad-desktop/plus-ad-desktop@2x.png"
              }`,
              "@3x": `${
                isExtraSmallScreen
                  ? "/plus-ad-mobile/plus-ad-mobile@3x.png"
                  : "/plus-ad-desktop/plus-ad-desktop@3x.png"
              }`,
            }}
            onCTA={() => setShown(true, null)}
            hasLogo
          />
        )}
        <Box className={classes.discoverCategoryContainer}>
          <div className={classes.discoverCategoryTags}>
            <Slider
              speed={500}
              slidesToShow={
                isExtraSmallScreen ? 2 : isSmall || isMedium ? 4 : 5
              }
              slidesToScroll={
                isExtraSmallScreen ? 2 : isSmall || isMedium ? 4 : 5
              }
              responsive={[
                {
                  breakpoint: 300,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {featureTypeData?.marketplaceListingFeatureTypes.map(
                (featureType, index) => {
                  return (
                    <div key={index}>
                      <DiscoverFeatureTypeTag
                        key={featureType.id}
                        index={index}
                        onClick={() => {
                          history.push(
                            `/board/${params.boardId}/installables/discover/${featureType.id}`
                          );
                        }}
                        title={featureType.title}
                      />
                    </div>
                  );
                }
              )}
            </Slider>
          </div>
        </Box>
        <Box className={classes.channelOfTheWeekContainer}>
          <FeaturedInstallablesSection
            items={featuredMarketplaceListings.map((listing) => ({
              ...listing,
              description: listing.description || null,
            }))}
          />
        </Box>
        <Box className={classes.installablesContainer}>
          {!sections ? (
            <>
              <CircularProgress />
            </>
          ) : (
            sections.map((marketplaceListingCategory) => {
              return (
                <InstallablesSection
                  key={marketplaceListingCategory.id}
                  id={marketplaceListingCategory.id}
                  title={marketplaceListingCategory.title}
                  items={marketplaceListingCategory.marketplaceListings.map(
                    (listing) => {
                      const existingSubscription =
                        data.board.subscriptionSet.channels.filter(
                          (c) => c.marketplaceListing?.id === listing.id
                        )[0];

                      return {
                        id: listing.id,
                        title: listing.title,
                        free: listing.free ?? false,
                        icon: listing.icon ?? listing.installable.icon,
                        description:
                          listing.shortDescription ||
                          listing.description ||
                          null,
                        canAdd: true,
                        installableIsSingleton:
                          listing.installable.installationsAreSingleton || null,
                        subscriptionId: existingSubscription?.id,
                        subscriptionMuted: existingSubscription?.muted ?? false,
                      };
                    }
                  )}
                />
              );
            })
          )}
        </Box>
      </Box>
    </Box>
  );
};

const useCTABoxStyles = makeStyles({
  box: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 8,
    marginBottom: 36,
    width: "100%",
    flexGrow: 1,
    overflow: "hidden",
    position: "relative",
    aspectRatio: "16/9",
    [`@media(max-width: ${BREAKPOINT_EXTRA_EXTRA_SMALL})`]: {
      marginBottom: 40,
      aspectRatio: "1/1",
    },
  },
  boxText: {
    padding: "50px 60px 50px 60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      padding: "50px 40% 50px 35px",
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      padding: "30px 40% 30px 20px",
    },
  },
  logo: {
    height: 40,
    marginBottom: 23,
  },
  overline: {
    color: "#1e2022",
    marginBottom: 16,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      marginBottom: 8,
    },
  },
  header: {
    marginBottom: 20,
    fontFamily: "HelveticaNeue",
    fontSize: "3rem",
    lineHeight: 1.2,
    letterSpacing: "-1px",
    fontWeight: "bold",
    color: "#1e2022",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      fontSize: "2.5rem",
    },
  },
  subtitle: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    opacity: 0.6,
    maxWidth: 312,
    marginBottom: 25,
  },
  imgContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    top: 0,
  },
  upsellImg: {
    height: "100%",
  },
  getPlusBtn: {
    textTransform: "unset",
    backgroundColor: colors.white,
    color: colors.woodSmoke,
  },
});

export interface CTABoxProps {
  header?: React.ReactNode;
  subtitle: React.ReactNode;
  cta: string;
  id: string;
  background?: string;
  darkLogo?: boolean;
  textColor?: string;
  outlineCta?: boolean;
  images: {
    "@1x": string;
    "@2x": string;
    "@3x": string;
  };
  onCTA(): void;
  hasLogo?: boolean;
}

export const CTABox: React.FC<CTABoxProps> = (props) => {
  const classes = useCTABoxStyles();

  return (
    <div
      className={classes.box}
      style={{
        background: props.background ?? "#323437",
        backgroundSize: "contain",
        color: `${props.textColor ?? "#f5f5f7"} !important`,
      }}
    >
      <Box className={classes.boxText}>
        <div>
          {props.hasLogo ? (
            <img
              src={
                !props.darkLogo
                  ? "/vestaboard-plus-logo-white.svg"
                  : "/vestaboard-plus-logo-black.svg"
              }
              height={17}
              className={classes.logo}
              alt="Vestaboard+ logo"
            />
          ) : (
            <Typography
              variant={"body1"}
              className={classes.overline}
              paragraph
            >
              Featured Channel
            </Typography>
          )}
          {props.header ? (
            <Typography variant={"h2"} className={classes.header}>
              {props.header}
            </Typography>
          ) : null}
          <Typography
            variant={"h3"}
            className={classes.subtitle}
            style={{ color: props.textColor }}
          >
            {props.subtitle}
          </Typography>
        </div>
        {!props.outlineCta ? (
          <InstallButton
            free={false}
            marketplaceListingId={props.id}
            render={(disabled, click, spinning) => (
              <Button
                variant={"contained"}
                size={"large"}
                disabled={disabled}
                onClick={click}
                className={classes.getPlusBtn}
              >
                {spinning ? <CircularProgress /> : props.cta}
              </Button>
            )}
          />
        ) : (
          <InstallButton
            free={false}
            marketplaceListingId={props.id}
            render={(disabled, click, spinning) => (
              <OutlinedButton
                size={"large"}
                color={props.textColor}
                disabled={disabled}
                onClick={click}
                className={classes.getPlusBtn}
              >
                {spinning ? <CircularProgress /> : props.cta}
              </OutlinedButton>
            )}
          />
        )}
      </Box>
      {!props.background && (
        <Box className={classes.imgContainer}>
          <img
            src={`${props.images["@2x"]}`}
            srcSet={`${props.images["@1x"]} 395w,
             ${props.images["@2x"]} 790w,
             ${props.images["@3x"]} 1185w`}
            sizes={`(max-width: 500px) 395px,
             (max-width: 850px) 790px,
             1185px`}
            alt={"Featured Channel"}
            className={classes.upsellImg}
          />
        </Box>
      )}
    </div>
  );
};
