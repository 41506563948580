//deleteSimulatorToken

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  DeleteSimulatorTokenMutation,
  DeleteSimulatorTokenMutationVariables,
} from "../../../gql";

const MUTATION = gql`
  mutation DeleteSimulatorToken($token: String!) {
    deleteSimulatorToken: deleteSimulatorTokenV2(token: $token) {
      ... on SimulatorTokenV2 {
        id
      }
      ... on SimulatorTokenErrorV2 {
        type
        error
      }
    }
  }
`;

export const useDeleteSimulatorToken = () =>
  useMutation<
    DeleteSimulatorTokenMutation,
    DeleteSimulatorTokenMutationVariables
  >(MUTATION);
