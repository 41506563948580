import * as React from "react";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { BREAKPOINT_SMALL } from "../config";

interface ISectionHeader {
  children: React.ReactChild | Array<React.ReactChild>;
}

const useStyles = makeStyles({
  header: {
    width: "100%",
    background: "#171818",
    display: "flex",
    alignItems: "center",
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 88,
    position: "relative",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      flexDirection: "column",
      alignItems: "flex-start",
      paddingLeft: 24,
    },
  },
});

export const SectionHeader = (props: ISectionHeader) => {
  const classes = useStyles();
  return <Box className={classes.header}>{props.children}</Box>;
};
