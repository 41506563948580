import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { DeleteRoleV2Mutation, DeleteRoleV2MutationVariables } from "../gql";

const DELETE_ROLE_MUTATION_V2 = gql`
  mutation DeleteRoleV2($input: DeleteRoleInputV2!) {
    deleteRoleV2(input: $input) {
      ... on RoleV2 {
        id
        hasPendingInvite
      }
      ... on RoleErrorV2 {
        type
        error
      }
    }
  }
`;

export const useRevokRoleV2 = () => {
  return useMutation<DeleteRoleV2Mutation, DeleteRoleV2MutationVariables>(
    DELETE_ROLE_MUTATION_V2
  );
};
