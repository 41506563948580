import { CircularProgress, createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material";

type MaterialThemeProviderProps = {};

const theme = createTheme({
  typography: {
    fontFamily: [
      "HelveticaNeue",
      "Helvetica",
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "none",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#7e878e",
        },
        filled: {
          paddingTop: 12,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
        input: {
          padding: 12,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1e2022",
          },
        },
        notchedOutline: {
          border: "solid 1px #7e878e",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: "#171818",
          border: "solid 1px #242829",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "#191A1A",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "solid 1px #242829",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "#191A1A",
          border: "solid 1px #FFF",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#6C757D",
    },
    text: {
      primary: "#FFF",
      secondary: "#FFF",
    },
    info: {
      main: "#000",
      contrastText: "#FFF",
      dark: "#000",
    },
  },
});

export const MaterialThemeProvider: React.FC<MaterialThemeProviderProps> = (
  props
) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
