import * as React from "react";
import {
  Button,
  ButtonBase,
  Input,
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useHistory, useParams } from "react-router";
import { IRouteParams } from "../Messages";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useBoardMessageSearch } from "../../templates/BoardViewTemplate";
import { MessagesSearchList } from "./components/MessagesSearchList";
import { useLayoutEffect } from "react";
import { useDebounce } from "use-debounce/lib";

const useStyles = makeStyles({
  searchBarIcon: {
    width: 40,
    color: "#f5f5f7",
    margin: "20px",
    opacity: 0.5,
    alignSelf: "flex-start",
    objectFit: "contain",
  },
  deleteRecentSearchIcon: {
    width: 25,
    height: 25,
    color: "#f5f5f7",
    opacity: 0.5,
    alignSelf: "flex-end",
    alignItems: "flex-end",
    justifyContent: "center",
    objectFit: "contain",
  },
  deleteRecentSearchButton: {
    height: "48px",
    marginLeft: "30px",
  },
  searchBarIconContainer: {
    margin: "5px",
    // flex: 1,
    alignSelf: "flex-start",
    justifyContent: "center",
    backgroundColor: "transparent",
    border: "none",
  },
  searchBarContainer: {
    width: "100%",
    height: "90px",
    // alignItems: 'flex-start',
    borderBottom: "solid 1px #2b2b2b",
    flexDirection: "row",
    // marginTop: -10,
  },
  search: {
    border: "none",
    // border: '1px solid white',
    // width: '83vw',
    height: "94%",
    paddingBottom: "10px",
    margin: "-08px 0px 0px 30px",
    fontSize: "24px",
  },
  cancel: {
    textColor: "#f5f5f7",
    textTransform: "none",
    backgroundColor: "transparent",
    fontSize: "14px",
    textAlign: "right",
    fontFamily: "HelveticaNeue",
    justifyContent: "center",
    marginTop: "30px",
    // margin: '32px 28px 28px 28px'
  },
  clearAll: {
    textColor: "#f5f5f7",
    textTransform: "none",
    backgroundColor: "transparent",
    fontSize: "14px",
    textAlign: "right",
    fontFamily: "HelveticaNeue",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
  recentSearches: {
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    textColor: "#f5f5f7",
    textTransform: "none",
    fontFamily: "HelveticaNeue",
    textAlign: "left",
    fontSize: "16px",
    lineHeight: 1.5,
  },
  recentSearch: {
    fontFamily: "HelveticaNeue",
    fontSize: "16px",
    lineHeight: 1.5,
    textColor: "#c3c3c3",
    color: "#c3c3c3",
    alignSelf: "flex-start",
    alignContent: "flex-start",
    paddingTop: "12px",
    textAlign: "left",
    justifyContent: 'flex-start'
  },
  recentSearchContainer: {
    width: '85%',
    paddingLeft: '8%',
    alignContent: "flex-start",
    alignItems: "flex-start",
    justifyContent: 'flex-start',
    flexDirection: "row",
    // right: '10px',
    backgroundColor: "transparent",
    borderRadius: "6px",
    height: "48px",
    textAlign: "left",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  recentSearchButton: {
    color: "transparent",
  },
});

const SearchBarIcon = () => {
  const classes = useStyles();
  return (
    <img
      className={classes.searchBarIcon}
      src={"/icons/icon-search.svg"}
      alt="Search"
    />
  );
};

const DeleteRecentSearchButton = (props: { onClick: () => void }) => {
  const classes = useStyles();
  return (
    <Button
      onClick={props.onClick}
      className={classes.deleteRecentSearchButton}
    >
      <img
        className={classes.deleteRecentSearchIcon}
        src={"/icons/icon-close.svg"}
        alt="Delete"
      />
    </Button>
  );
};

type RecentSearchesProps = {
  recentSearches: string[];
  setRecentSearches: (searches: string[]) => void;
  onClearSearch: (search: string) => void;
  setSearchTerm: (search: string) => void;
};

const RecentSearches: React.FC<RecentSearchesProps> = (props) => {
  const classes = useStyles();

  const onDelete = (search: string) => {
    props.onClearSearch(search);
  };

  if (props.recentSearches.length) {
    return (
      <>
        <Box display="flex" flexDirection="column" marginTop="20px">
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            marginLeft="6vw"
            justifyContent={"space-between"}
          >
            <Typography className={classes.recentSearches}>
              Recent Searches
            </Typography>
          </Box>
          {props.recentSearches.map((search: string, index) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                marginTop="5px"
                key={index}
              >
                <ButtonBase
                  className={classes.recentSearchContainer}
                  onClick={() => props.setSearchTerm(search)}
                >
                  <Typography className={classes.recentSearch}>
                    {search}
                  </Typography>
                </ButtonBase>
                <DeleteRecentSearchButton onClick={() => onDelete(search)} />
              </Box>
            );
          })}
        </Box>
        <Box marginTop="20px" borderBottom="solid 1px #2b2b2b" />
      </>
    );
  }

  return null;
};

export const MessageSearch: React.FC<any> = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<IRouteParams>();
  const messageSearchResult = useBoardMessageSearch(params.boardId);
  const { searchTerm, setSearchTerm } = messageSearchResult;
  const [recentSearches, setRecentSearches] = useLocalStorage<string[]>(
    "recentHistorySearches",
    []
  );
  const [searchValue, setSearchValue] = React.useState<string>("");

  const cancelPressed = () => {
    history.goBack();
  };

  const submitSearch = (keyCode: string) => {
    if (keyCode === "Enter" && searchValue) {
      setSearchTerm(searchValue);
    }
  };

  const onClearSearch = (search: string) => {
    setRecentSearches(
      recentSearches.filter((recentSearch) => recentSearch !== search)
    );
  };

  const onRecentSearchClicked = (search: string) => {
    setSearchValue(search); // Input Field
    setSearchTerm(search); // Search Hook
  };

  const [debouncedSearchTerm] = useDebounce(searchValue, 1000)

  useLayoutEffect(() => {
    debouncedSearchTerm && setSearchTerm(debouncedSearchTerm)
  }, [debouncedSearchTerm])

  return (
    <>
      <Box flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          className={classes.searchBarContainer}
        >
          <Box className={classes.searchBarIconContainer}>
            <SearchBarIcon />
          </Box>
          <Box
            flexGrow={1}
            height="90px"
            justifyContent="flex-start"
            alignContent="flex-start"
            alignSelf="flex-start"
            margin="15px 25px 0px 0px"
          >
            <Input
              value={searchValue}
              autoFocus
              onKeyDown={(e) => submitSearch(e.code)}
              onChange={(e) => setSearchValue(e.target.value)}
              className={classes.search}
              multiline={false}
              fullWidth
              disableUnderline
            />
          </Box>
          <Box
            height="100%"
            justifyContent="center"
            alignContent="flex-end"
            flexShrink={0}
            paddingRight={"25px"}
          >
            <Button onClick={cancelPressed} className={classes.cancel}>
              Cancel
            </Button>
          </Box>
        </Box>
        {!debouncedSearchTerm && <RecentSearches
          recentSearches={recentSearches}
          setRecentSearches={setRecentSearches}
          onClearSearch={onClearSearch}
          setSearchTerm={onRecentSearchClicked}
        />}
      </Box>
      {searchTerm && (
        <MessagesSearchList boardId={params.boardId} term={searchTerm} setRecentSearches={setRecentSearches} recentSearches={recentSearches}/>
      )}
    </>
  );
};
