import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  TransferOwnershipMutation,
  TransferOwnershipMutationVariables,
} from "../gql";

const TransferOwnership = gql`
  mutation TransferOwnership($input: TransferOwnershipMutationInput!) {
    transferOwnership(input: $input) {
      successful
    }
  }
`;

export const useTransferOwnership = () => {
  return useMutation<
    TransferOwnershipMutation,
    TransferOwnershipMutationVariables
  >(TransferOwnership);
};
