import { GetLiveBoardQuery, GetLiveQuery } from "../gql";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const GET_LIVE = gql`
  query GetLive {
    viewer {
      id
      account {
        id
        person {
          id
        }
      }
    }
    tenants {
      id
      members {
        id
        isCurrentMember
        role
        person {
          id
        }
      }
      boards {
        id
        friendlyIdentifier
        pausedUntil
        title
        boardStyle
        devices {
          id
        }
        history(page: 0, perPage: 1) {
          id
          message {
            id
            formatted
            authorFormatted
            created
            sendAt
          }
        }
      }
    }
  }
`;

// fetch other board not in the list for product demos to customers, superadmins will be able to access other boards
const GET_LIVE_BOARD_SUPERADMIN = gql`
  query GetLiveBoard($id: String!) {
    viewer {
      id
      account {
        id
        person {
          id
        }
      }
    }
    board(id: $id) {
      id
      friendlyIdentifier
      pausedUntil
      title
      boardStyle
      devices {
        id
      }
      history(page: 0, perPage: 1) {
        id
        message {
          id
          formatted
          authorFormatted
          created
          sendAt
        }
      }
    }
  }
`;

export const useGetLiveBoardSuperadmin = (boardId: string, isSuperAdmin: boolean) => {
  return useQuery<GetLiveBoardQuery>(GET_LIVE_BOARD_SUPERADMIN, {
    fetchPolicy: "network-only",
    skip: !isSuperAdmin,
    variables: {
      id: boardId,
    },
  });
};

export const useGetLive = () => {
  return useQuery<GetLiveQuery>(GET_LIVE, {
    fetchPolicy: "network-only",
  });
};
