import * as React from "react";
import {
  ClickAwayListener,
  Grow,
  Popper,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  ButtonBase,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import CheckIcon from "@mui/icons-material/Check";

interface IDiscoverSortByDropdownProps {
  options: Array<{
    id: string;
    label: string;
  }>;
  handleOnSelectOption: (v: string) => any;
  selected: string;
}

const useDropdownStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    width: 100,
  },
  btn: {
    textTransform: "unset",
  },
  icon: {
    width: 18,
    color: "#f5f5f7",
    opacity: 0.5,
    marginBottom: "0px !important",
    transform: "rotate(180deg)",
  },
  popover: {
    marginTop: 20,
    color: "#1e2022",
    borderRadius: 8,
    boxShadow: "0 12px 12px 0 rgba(23, 24, 24, 0.39)",
    backgroundColor: "#f5f5f7",
    padding: "1.25rem 1rem 1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    maxWidth: 300,
  },
  formLabel: {
    color: "#1e2022",
    fontWeight: "bold",
    marginLeft: 30,
    "&.Mui-focused": {
      color: "#1e2022",
    },
  },
  radioLabel: {
    display: "block",
    color: "#1e2022",
    marginBottom: 0,
  },
});

export const DiscoverCategorySortByDropdown: React.FC<IDiscoverSortByDropdownProps> = (
  props
) => {
  const classes = useDropdownStyles();
  const [openBtnRef, setOpenBtnRef] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(openBtnRef);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    openBtnRef ? setOpenBtnRef(null) : setOpenBtnRef(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setOpenBtnRef(null);
  };

  return (
    <>
      <ButtonBase onClick={handleClick} aria-label="sort">
        <img src="/icons/icon-sort.svg" alt="sort" width={"28pt"} />
      </ButtonBase>
      <ClickAwayListener onClickAway={handleClose}>
        <Popper open={open} anchorEl={openBtnRef} transition>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={350}>
              <div className={classes.popover}>
                <FormControl component="fieldset">
                  <FormLabel className={classes.formLabel} component="legend">
                    Sort by:
                  </FormLabel>
                  <RadioGroup
                    aria-label="sort by"
                    defaultValue={props.selected}
                    name="radio-buttons-group"
                  >
                    {props.options.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.id}
                        control={
                          <Radio
                            onChange={(e) => {
                              if (e.target.checked) {
                                props.handleOnSelectOption(option.id);
                              } else {
                                props.handleOnSelectOption(
                                  props.options.filter(
                                    (o) => o.id !== option.id
                                  )[0]?.id
                                );
                              }
                            }}
                            checkedIcon={
                              <CheckIcon
                                style={{
                                  color: "black",
                                }}
                              />
                            }
                          />
                        }
                        label={option.label}
                        className={classes.radioLabel}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </>
  );
};