import { Box, ButtonBase, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  InstallableDetailQuery,
  InstallableDetailQueryVariables,
} from "../../gql";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import ReconnectingWebsocket from "reconnecting-websocket";
import { InstallableIcon } from "../../components/InstallableIcon";
import { Spacer } from "../../components/Spacer";
import { SquareButton } from "../../components/SquareButton";
import { BREAKPOINT_SMALL } from "../../config";
import { useAppState } from "../../hooks/global";
import { getTitle } from "../../utils/installableUtils";
import { DeleteInstallable } from "../Installables/screens/DeleteInstallable";
import { EnabledToggle } from "../Installables/components/MutedToggle";
import { Iframe } from "../../components/Iframe";
import { Settings } from "./tabs/Settings";
import { useReferralContext } from "../../templates/AppTemplate/components/ReferralBar";

interface IInstallableDetailPageProps {}

interface IRouteMatch {
  subscriptionId: string;
  boardId: string;
}

const INSTALLABLE_DETAIL = gql`
  query InstallableDetail($subscriptionId: String!) {
    subscription(id: $subscriptionId) {
      id
      title
      icon
      muted
      configurationUrl
      description
      created
      requiresConfiguration
      marketplaceListing {
        id
        title
        icon
        free
      }
      installation {
        id
        installable {
          id
          created
          title
          description
          installationsAreSingleton
          subscriptionsCanBeRenamed
          hideFromInstallablesList
          coverPhoto
          icon
          published
          public
          confirmedBody
          confirmedMessage
          setupCallToActionUrl
          setupMessage
          setupBody
          setupCallToActionLabel
          category {
            id
            title
          }
          developer {
            id
            title
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  header: {
    height: 100,
    padding: 18,
    borderBottom: "solid 1px #2b2b2b",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
    fontFamily: "HelveticaNeue",
    fontWeight: 500,
    lineHeight: 1.42,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      display: "none",
    },
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
  },
  iframeContainer: {
    width: "100%",
    height: "calc(100vh - 220px)",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      height: "calc(100vh - 208px)",
    },
  },
  iframeContainerReferral: {
    width: "100%",
    height: "calc(100vh - 266px)",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      height: "calc(100vh - 248px)",
    },
  },
});

export const InstallableDetailPage: React.FC<
  IInstallableDetailPageProps
> = () => {
  const { hasReferralBanner } = useReferralContext();
  const params = useParams<IRouteMatch>();
  const classes = useStyles();
  const history = useHistory();
  const { tenantId } = useAppState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [pollInterval, setPollInterval] = useState(0);

  const { data } = useQuery<
    InstallableDetailQuery,
    InstallableDetailQueryVariables
  >(INSTALLABLE_DETAIL, {
    variables: {
      subscriptionId: params.subscriptionId,
    },
    pollInterval,
  });

  const subscription = data?.subscription;
  const installable = data?.subscription.installation
    ? data?.subscription.installation.installable
    : null;
  const marketplaceListing = data?.subscription.marketplaceListing;

  const canRename = installable?.subscriptionsCanBeRenamed || false;
  React.useEffect(() => {
    if (canRename) {
      setPollInterval(5000);
    }
  }, [canRename]);

  if (!subscription) {
    return <CircularProgress />;
  }

  if (!installable) {
    return null;
  }

  const title =
    subscription?.title ??
    marketplaceListing?.title ??
    getTitle(installable?.title);

  const icon =
    subscription?.icon ??
    marketplaceListing?.icon ??
    (installable?.icon as string);

  return (
    <>
      {isDeleting && (
        <DeleteInstallable
          visible={isDeleting}
          boardId={params.boardId}
          tenantId={tenantId as string}
          subscriptionId={subscription.id}
          installableId={installable.id}
          title={title}
          icon={icon}
          handleClose={() => setIsDeleting(false)}
          handleDeleted={() => {
            history.push(`/board/${params.boardId}/installables/installed`);
          }}
        />
      )}
      <Box className={classes.header}>
        <Box className={classes.headerLeft}>
          <ButtonBase
            onClick={() => {
              history.push(`/board/${params.boardId}/installables/installed`);
            }}
          >
            <img src="/icons/icon-back.svg" alt="Back" />
          </ButtonBase>
          <Spacer width={32} />
          <InstallableIcon size="small" title={title} icon={icon} />
          <Spacer width={40} />
          <Box className={classes.title}>{title}</Box>
        </Box>
        <Box className={classes.headerRight}>
          <EnabledToggle
            enabled={!data?.subscription?.muted}
            boardId={params.boardId}
            subscriptionId={subscription.id}
            tenantId={tenantId as string}
          />
          <Spacer width={15} />
          <SquareButton
            backgroundColor="#1e2022"
            onClick={() => {
              setIsDeleting(true);
            }}
            icon="/icons/icon-trash.svg"
            label="Delete"
            size="small"
          />
        </Box>
      </Box>
      {subscription.configurationUrl ? (
        <div
          className={
            hasReferralBanner
              ? classes.iframeContainerReferral
              : classes.iframeContainer
          }
        >
          <Iframe
            title={title}
            key={`${params.subscriptionId}-${params.boardId}`}
            src={`${subscription.configurationUrl}&configurationUrl=${btoa(
              window.location.href
            )}&platform=web`}
          />
        </div>
      ) : (
        <Settings
          requiresConfiguration={subscription.requiresConfiguration}
          confirmedBody={installable.confirmedBody}
          confirmedMessage={installable.confirmedMessage}
          setupCallToActionUrl={installable.setupCallToActionUrl}
          setupMessage={installable.setupMessage}
          setupBody={installable.setupBody}
          setupCallToActionLabel={installable.setupCallToActionLabel}
        />
      )}
    </>
  );
};
