import { useQuery } from "@apollo/client";
import { GetQuietHoursQuery, GetQuietHoursQueryVariables } from "../gql";
import { GET_QUIET_HOURS } from "../pages/Settings/QuietHours";

export const useQuietHours = (boardId: string) => {
  const { data: quietHoursData, loading } = useQuery<
    GetQuietHoursQuery,
    GetQuietHoursQueryVariables
  >(GET_QUIET_HOURS, {
    variables: {
      boardId,
    },
  });
  const hasQuietHours = !!(
    quietHoursData?.board?.quietHoursBegin &&
    quietHoursData?.board?.quietHoursEnd
  );
  return {
    quietHoursData,
    loading,
    hasQuietHours,
  };
};
