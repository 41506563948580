import * as React from "react";

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "../config";

import { Auth0Provider } from "@auth0/auth0-react";

export const AuthProvider: React.FC = (props) => {
  const onRedirectCallback = React.useCallback((appState) => {
    localStorage.setItem("targetUrl", appState?.targetUrl || "");
  }, []);

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN as string}
      clientId={AUTH0_CLIENT_ID as string}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: `openid profile email`,
        audience: `https://${AUTH0_DOMAIN}/api/v2/`,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {props.children}
    </Auth0Provider>
  );
};
