import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GetTenantBoardsQuery } from "../../gql";
import { GET_TENANT_BOARDS_QUERY } from "../RedirectToBoard";

export const ComposeRedirect = () => {
  const params = useParams<{ token: string }>();
  const { data, loading } = useQuery<GetTenantBoardsQuery>(
    GET_TENANT_BOARDS_QUERY
  );
  const history = useHistory();
  const lastBoardId = localStorage.getItem("lastBoardId");

  const tenantCount = data?.tenants?.length || 0;
  const characters = atob(params.token);

  useEffect(() => {
    if (!loading) {
      const boardIds =
        data && tenantCount > 0
          ? data.tenants.reduce((prev: Array<string>, current) => {
              return [
                ...prev,
                ...(current.boards || []).map((board) => board.id),
              ];
            }, [])
          : [];

      if (lastBoardId) {
        history.push(`/board/${lastBoardId}/compose/duplicate/${characters}`);
      } else if (boardIds.length > 0) {
        history.push(`/board/${boardIds[0]}/compose/duplicate/${characters}`);
      } else {
        history.push("/login");
      }
    }
  }, [data, history, lastBoardId, loading]);
  return <></>;
};
