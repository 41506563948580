import { DeleteDraftMutation, DeleteDraftMutationVariables } from "../gql";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

export const DELETE_DRAFT = gql`
  mutation DeleteDraft($input: DeleteDraftInput!) {
    deleteDraft(input: $input) {
      deleted
    }
  }
`;

export const useDeleteDraft = () => {
  return useMutation<DeleteDraftMutation, DeleteDraftMutationVariables>(
    DELETE_DRAFT
  );
};
