import * as React from "react";

import { Button, CircularProgress, TextField } from "@mui/material";

import { PaddedTemplate } from "../../templates/PaddedTemplate";
import { Spacer } from "../../components/Spacer";
import { getBoardSimulatorUrl } from "../../components/Simulator";
import { useParams } from "react-router";
import { useToasts } from "@vestaboard/installables";

interface IPublishPageProps {}

type PublishPageProps = IPublishPageProps;

interface IRouteMatch {
  boardId: string;
}

export const PublishPage: React.FC<PublishPageProps> = (props) => {
  const params = useParams<IRouteMatch>();
  const { boardId } = params;
  const { addToast } = useToasts();

  if (!boardId) {
    return <CircularProgress />;
  }

  const boardSimulatorUrl = getBoardSimulatorUrl(boardId);

  const boardLink = `${window.location.protocol}//${window.location.hostname}/simulator/${boardId}`;
  const embedCode = `<iframe src="${boardSimulatorUrl}" style="width: 100%; border: none; aspect-ratio: 16 / 8.4;" scrolling="no"></iframe>`;

  return (
    <PaddedTemplate>
      <div className={"center w-90-ns"}>
        <div className={"center flex justify-between items-end mb2"}>
          <div>
            <h2>Publish</h2>
          </div>
        </div>
        <div className={"mt4"}>
          <div className={"flex items-stretch"}>
            <div className={"mr2 w-80"}>
              <TextField
                id="simulator-url-input"
                variant="filled"
                value={boardLink}
                fullWidth
                label={"Board Link"}
              />
            </div>
            <Button
              className={"flex-shrink-0"}
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(boardLink);
                  addToast(`Copied board link to your  clipboard.`, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                } catch (err) {
                  addToast(`Unable to copy board link to your clipboard.`, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }}
            >
              COPY
            </Button>
          </div>
          <Spacer height={24} />
          <div className={"flex items-stretch"}>
            <div className={"mr2 w-80"}>
              <TextField
                value={embedCode}
                label={"HTML Embed Code"}
                multiline
                variant="filled"
                fullWidth
              />
            </div>
            <Button
              className={"flex-shrink-0"}
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(embedCode);
                  addToast(`Copied embed code to your clipboard.`, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                } catch (err) {
                  addToast(`Unable to copy embed code to your clipboard.`, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }}
            >
              COPY
            </Button>
          </div>
        </div>
      </div>
    </PaddedTemplate>
  );
};
