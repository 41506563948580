import * as React from "react";

import MuiTab, { TabProps } from "@mui/material/Tab";

import { BREAKPOINT_EXTRA_SMALL } from "../config";
import { makeStyles } from "@mui/styles";

interface ITab extends TabProps {
  onClick?: () => any;
}

const useStyles = makeStyles({
  tab: {
    fontSize: 18,
    lineHeight: "26px",
    textTransform: "none",
    color: "#f5f5f7",
    fontWeight: "bold",
    textAlign: "left",
    paddingTop: 28,
    paddingBottom: 28,
    width: "auto",
    minWidth: 0,
    marginRight: 32,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      maxWidth: "48%",
      width: "auto",
      marginRight: 16,
      fontSize: 16,
    },
    labelContainer: {
      width: "auto",
      padding: 0,
    },
    iconLabelWrapper: {
      flexDirection: "row",
    },
    iconLabelWrapper2: {
      flexDirection: "row-reverse",
    },
  },
});

export const Tab = React.forwardRef<HTMLDivElement, ITab>((props, ref) => {
  const classes = useStyles();

  return (
    <MuiTab
      ref={ref}
      {...props}
      iconPosition="end"
      className={classes.tab}
      style={{
        color: "#f5f5f7",
        fontSize: 14
      }}
    />
  );
});
