import { useParams } from "react-router-dom";
import { SearchBar } from "./components/SearchBar";
import { useCallback, useState } from "react";
import { useSearchMessagesV2 } from "../../hooks/useSearchMesssagesV2";
import { SearchStateWrapper } from "../../components/SearchStateWrapper";
import { MessageCardsContainerV2 } from "../../components/MessageCardsContainerV2";
import { Alert, Box } from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import {
  SearchMessageSortBy,
  SearchMessageSortDirection,
  SearchMessageType,
  SearchMessageUnionV2,
} from "../../gql";
import { IBoardStyle } from "@vestaboard/installables";
import { MessageGrid } from "../../components/MessageGrid";
import { MessageCardV2 } from "../../components/MessageCardV2";
import { makeStyles } from "@mui/styles";
import useLocalStorage from "../../hooks/useLocalStorage";
import { RecentSearches } from "./components/RecentSearcher";
import { FilterType, Filters } from "./components/Filters";
import { usePaywall } from "../../providers/PaywallProvider";
import { useGetBoardStyle } from "../../hooks/useGetBoardStyle";
import { PlusMask } from "./components/PlusMask";
import { InfiniteLoader } from "../../components/InfiniteLoader";

const PER_PAGE = 24;

const useStyles = makeStyles({
  messagesContainer: {
    padding: 24,
    position: "relative",
  },
});

export const MessageSearchV2Page = () => {
  const classes = useStyles();
  const boardStyle = useGetBoardStyle();
  const { isPlus } = usePaywall();
  const [filterTypes, setFilterTypes] = useState<FilterType[]>([
    FilterType.Inspiration,
    FilterType.MyMessages,
    ...(isPlus ? [FilterType.VestaboardPlus] : []),
  ]);
  const params = useParams<{ boardId: string }>();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const types = [
    ...(filterTypes.includes(FilterType.Inspiration)
      ? [SearchMessageType.Pick, SearchMessageType.FeedItem]
      : []),
    ...(filterTypes.includes(FilterType.MyMessages)
      ? [
          SearchMessageType.MessageAppearance,
          SearchMessageType.Draft,
          SearchMessageType.Favorite,
        ]
      : []),
    ...(filterTypes.includes(FilterType.VestaboardPlus)
      ? [SearchMessageType.MessageSetMessage]
      : []),
  ];
  const { data, loading, error, fetchMore, refetch } = useSearchMessagesV2(
    {
      input: {
        boardId: params.boardId,
        limit: PER_PAGE,
        cursor: null,
        sortBy: SearchMessageSortBy.Date,
        sortDirection: SearchMessageSortDirection.Desc,
        term: searchTerm,
        types,
      },
    },
    !searchTerm
  );
  const [recentSearches, setRecentSearches] = useLocalStorage<string[]>(
    "recentHistorySearches",
    []
  );

  const onRecentSearchClicked = useCallback((search: string) => {
    setSearchTerm(search);
    setSearchValue(search);
  }, []);

  const onClearSearch = useCallback(
    (search: string) => {
      setRecentSearches(
        recentSearches.filter((recentSearch) => recentSearch !== search)
      );
    },
    [recentSearches, setRecentSearches]
  );

  return (
    <>
      <SearchBar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleSearch={(term) => {
          setSearchTerm(term);
          if (term) {
            setRecentSearches([term, ...recentSearches.slice(0, 4)]);
          }
        }}
      />
      {data ? (
        <Filters filterTypes={filterTypes} setFilterTypes={setFilterTypes} />
      ) : null}
      {!data && !loading && !error ? (
        <RecentSearches
          recentSearches={recentSearches}
          setRecentSearches={setRecentSearches}
          onClearSearch={onClearSearch}
          setSearchTerm={onRecentSearchClicked}
        />
      ) : null}
      <Box className={classes.messagesContainer}>
        {filterTypes.includes(FilterType.VestaboardPlus) && !isPlus ? (
          <PlusMask />
        ) : null}
        {!data?.listSearchMessages && loading ? (
          <SearchStateWrapper>
            <InfiniteLoader />
          </SearchStateWrapper>
        ) : error ? (
          <SearchStateWrapper>
            <Alert severity="error">
              There was an error loading the messages.
            </Alert>
          </SearchStateWrapper>
        ) : data && !data?.listSearchMessages.searchMessages.length ? (
          <SearchStateWrapper>
            <Alert severity="info">You have no history messages.</Alert>
          </SearchStateWrapper>
        ) : !filterTypes.length ? null : (
          <>
            <MessageCardsContainerV2
              refetch={async () => {
                await refetch();
              }}
            >
              {(variables) => (
                <InfiniteScroll
                  pageStart={0}
                  loadMore={async () => {
                    if (loading) {
                      return;
                    }

                    return await fetchMore({
                      updateQuery(previousQueryResult, options) {
                        const newResults =
                          options.fetchMoreResult?.listSearchMessages
                            .searchMessages || [];
                        const previousResults =
                          previousQueryResult.listSearchMessages
                            .searchMessages || [];

                        return {
                          listSearchMessages: {
                            ...previousQueryResult.listSearchMessages,
                            nextCursor:
                              options.fetchMoreResult?.listSearchMessages
                                .nextCursor || null,
                            searchMessages: [...previousResults, ...newResults],
                          },
                        };
                      },
                      variables: {
                        input: {
                          boardId: params.boardId,
                          limit: PER_PAGE,
                          cursor: data?.listSearchMessages.nextCursor || null,
                          sortBy: SearchMessageSortBy.Date,
                          sortDirection: SearchMessageSortDirection.Desc,
                          term: searchTerm,
                          types,
                        },
                      },
                    });
                  }}
                  hasMore={!!data?.listSearchMessages.nextCursor}
                  loader={<InfiniteLoader />}
                >
                  <MessageGrid>
                    {(data?.listSearchMessages?.searchMessages || []).map(
                      (searchMessage) => (
                        <MessageCardV2
                          {...variables}
                          searchMessage={searchMessage as SearchMessageUnionV2}
                          boardStyle={
                            (boardStyle?.data?.board.boardStyle ||
                              "black") as IBoardStyle
                          }
                          key={searchMessage.id}
                        />
                      )
                    )}
                  </MessageGrid>
                </InfiniteScroll>
              )}
            </MessageCardsContainerV2>
          </>
        )}
      </Box>
    </>
  );
};
