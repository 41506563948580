import { GetDraftsQuery } from "../gql";

import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/client";

export const GET_DRAFTS = gql`
  query GetDrafts($page: Int!) {
    viewer {
      id
      account {
        person {
          draftsCount
          drafts(page: $page, perPage: 30) {
            id
            message {
              id
              authorFormatted
              text
              created
              formatted
            }
          }
        }
      }
    }
  }
`;

export const useGetDrafts = (page: number = 0) => {
  return useQuery<GetDraftsQuery>(GET_DRAFTS, { variables: { page } });
};

export const useGetDraftsLazy = (): [Function, Array<any>] => {
  const [getDrafts, { data }] = useLazyQuery<GetDraftsQuery>(GET_DRAFTS, {
    fetchPolicy: "no-cache",
  });

  return [getDrafts, (data?.viewer?.account?.person?.drafts || []).reverse()];
};
