import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  ListMessageSourcesV2Query,
  ListMessageSourcesV2QueryVariables,
} from "../gql";

const QUERY = gql`
  query ListMessageSourcesV2($input: ListMessageSourcesByBoardInputV2!) {
    listMessageSources: listMessageSourcesV2(input: $input) {
      messageSources {
        id
        title
      }
      nextCursor
    }
  }
`;

export const useListMessageSourcesV2 = (
  variables: ListMessageSourcesV2QueryVariables
) => {
  return useQuery<
    ListMessageSourcesV2Query,
    ListMessageSourcesV2QueryVariables
  >(QUERY, { variables });
};
