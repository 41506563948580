import { Box, ButtonBase, Typography } from "@mui/material";
import { DeleteRecentSearchButton } from "./DeleteRecentSearchButton";
import { makeStyles } from "@mui/styles";

type RecentSearchesProps = {
  recentSearches: string[];
  setRecentSearches: (searches: string[]) => void;
  onClearSearch: (search: string) => void;
  setSearchTerm: (search: string) => void;
};

const useStyles = makeStyles({
  recentSearches: {
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    textColor: "#f5f5f7",
    textTransform: "none",
    fontFamily: "HelveticaNeue",
    textAlign: "left",
    fontSize: "16px",
    lineHeight: 1.5,
  },
  recentSearch: {
    fontFamily: "HelveticaNeue",
    fontSize: "16px",
    lineHeight: 1.5,
    textColor: "#c3c3c3",
    color: "#c3c3c3",
    alignSelf: "flex-start",
    alignContent: "flex-start",
    paddingTop: "12px",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  recentSearchContainer: {
    width: "85%",
    paddingLeft: "8%",
    alignContent: "flex-start",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    backgroundColor: "transparent",
    borderRadius: "6px",
    height: "48px",
    textAlign: "left",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  recentSearchButton: {
    color: "transparent",
  },
});

export const RecentSearches = (props: RecentSearchesProps) => {
  const classes = useStyles();

  const onDelete = (search: string) => {
    props.onClearSearch(search);
  };

  if (props.recentSearches.length) {
    return (
      <>
        <Box display="flex" flexDirection="column" marginTop="20px">
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            marginLeft="6vw"
            justifyContent={"space-between"}
          >
            <Typography className={classes.recentSearches}>
              Recent Searches
            </Typography>
          </Box>
          {props.recentSearches.map((search: string, index) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                marginTop="5px"
                key={index}
              >
                <ButtonBase
                  className={classes.recentSearchContainer}
                  onClick={() => props.setSearchTerm(search)}
                >
                  <Typography className={classes.recentSearch}>
                    {search}
                  </Typography>
                </ButtonBase>
                <DeleteRecentSearchButton onClick={() => onDelete(search)} />
              </Box>
            );
          })}
        </Box>
        <Box marginTop="20px" borderBottom="solid 1px #2b2b2b" />
      </>
    );
  }

  return null;
};
