import * as React from "react";

import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  title: {
    fontSize: 44,
    fontWeight: 500,
  },
});

interface ISectionTitle {
  children: React.ReactChild;
}

export const SectionTitle = (props: ISectionTitle) => {
  const classes = useStyles();

  return <Typography className={classes.title}>{props.children}</Typography>;
};
