import * as React from "react";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  iframe: {
    width: "100%",
    height: "100%",
    border: 0,
  },
  iframeLoaded: {
    opacity: 1,
  },
  iframeLoading: {
    opacity: 0,
  },
});

interface IIframe {
  title: string;
  src: string;
  translate?: "yes" | "no";
}

export const Iframe = (props: IIframe) => {
  const [loaded, setLoaded] = React.useState(false);
  const classes = useStyles();

  return (
    <iframe
      onLoad={() => {
        setLoaded(true);
      }}
      title={props.title}
      className={[
        classes.iframe,
        loaded ? classes.iframeLoaded : classes.iframeLoading,
      ].join(" ")}
      src={props.src}
      translate={props.translate}
      allow="clipboard-read; clipboard-write"
    />
  );
};
