import * as React from "react";

import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import MuiButton from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface ISquareButton {
  label: string;
  icon: string;
  onClick: () => void;
  disabled?: boolean;
  size?: "small" | "medium";
  backgroundColor?: string;
}

const useStyles = makeStyles({
  button: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    margin: 0,
    "& > span": {
      display: "flex",
      flexDirection: "column",
    },
  },
  smallButton: {
    backgroundColor: "#171818",
    width: 48,
    height: 48,
    minWidth: 48,
  },
  mediumButton: {
    backgroundColor: "#202425",
    width: 80,
    height: 80,
    minWidth: 80,
  },
  label: {
    fontSize: 12,
    textTransform: "none",
    display: "block",
    marginTop: 7,
  },
  icon: {
    display: "block",
    width: 24,
    height: 24,
  },
});

export const SquareButton = (props: ISquareButton) => {
  const classes = useStyles();

  const disabledStyle = {
    opacity: props.disabled ? 0.5 : 1,
  };

  const size = props.size || "medium";

  return (
    <Tooltip title={props.label} disableHoverListener={props.size !== "small"}>
      <MuiButton
        className={[
          classes.button,
          ...(size === "small" ? [classes.smallButton] : []),
          ...(size === "medium" ? [classes.mediumButton] : []),
        ].join(" ")}
        style={{
          backgroundColor: props.backgroundColor,
        }}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <img
          src={props.icon}
          alt={props.label}
          className={classes.icon}
          style={disabledStyle}
        />
        {props.size !== "small" && (
          <Typography className={classes.label} style={disabledStyle}>
            {props.label}
          </Typography>
        )}
      </MuiButton>
    </Tooltip>
  );
};
