import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  FlagFeedItemV2Mutation,
  FlagFeedItemV2MutationVariables,
} from "../gql";

const MUTATION = gql`
  mutation FlagFeedItemV2($input: FlaggedFeedItemInputV2!) {
    flagFeedItem: flagFeedItemV2(input: $input) {
      ... on FlaggedFeedItemV2 {
        feedItem {
          id
          isFlaggedByMe
        }
      }
      ... on FlaggedFeedItemErrorV2 {
        type
        error
      }
    }
  }
`;

export const useFlagFeedItemV2 = () =>
  useMutation<FlagFeedItemV2Mutation, FlagFeedItemV2MutationVariables>(
    MUTATION
  );
