import { useHistory, useParams } from "react-router-dom";
import {
  useMarketPlaceFeatureTypes,
  useMarketPlaceListingsByFeatureType,
} from "../../../../hooks/useMarketPlaceFeatureTypes";
import { useDiscoverQuery } from ".";
import { InstallableTile } from "../../components/InstallableTile";
import { useAppState } from "../../../../hooks/global";
import { Box, Button, ButtonBase, Grow, Popper } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { CardsContainer } from "../../components/CardsContainer";
import { BREAKPOINT_EXTRA_SMALL, BREAKPOINT_SMALL } from "../../../../config";
import {
  Spacer,
  SubTitle,
  SubTitle2,
  responsive,
} from "@vestaboard/installables";
import { useRef, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  featureTypeIcons,
  getIcon,
} from "../components/DiscoverFeatureTypeTag";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles({
  installable: {
    marginBottom: 48,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      width: "100%",
    },
  },
  installableContainer: {
    padding: 16,
    paddingTop: 32,
    maxWidth: 800,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      width: "100%",
    },
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 55,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      paddingLeft: 0,
    },
  },
  header: {
    padding: "20px",
    borderBottom: "solid 1px #2b2b2b",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  headerColumn: {
    flex: 1,
    display: "flex",
  },
  headerColumnCenter: {
    flex: 4,
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    // icon and padding offset
    left: -55,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      left: -22,
    },
  },

  iconLabelWrapper: {
    flexDirection: "row-reverse",
  },
  icon: {
    width: 18,
    color: "#f5f5f7",
    marginLeft: 4,
    // margin: '5px 10px 20px 10px',
    opacity: 0.5,
    marginBottom: "0px !important",
  },
  categoryChevron: {
    transition: "transform 300ms",
  },
  categoryChevronInactive: {
    transform: "rotate(180deg)",
  },
  categoriesPopover: {
    marginTop: 20,
    color: "#1e2022",
    borderRadius: 8,
    boxShadow: "0 12px 12px 0 rgba(23, 24, 24, 0.39)",
    backgroundColor: "#f5f5f7",
    padding: "29px 47px 35px 47px",
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 300,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      maxWidth: "70vw",
      padding: 16,
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      maxWidth: "70vw",
    },
  },
  categoryButtonContainer: {
    paddingLeft: 78,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      paddingLeft: 16,
    },
  },
  categoriesText: {
    display: "block",
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 3,
    color: "#1e2022",
    padding: 0,
    flex: "0 0 50%",
    textTransform: "unset",
  },
  categoriesTextSelected: {
    color: "#f5f5f7",
    backgroundColor: "#1e2022",
    borderRadius: 8,
  },
  dropdownContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});
export const DiscoverFeatureType = (props: any) => {
  const params = useParams<{ featureType: string; boardId: string }>();
  const { data } = useMarketPlaceListingsByFeatureType(params.featureType);
  const { data: discoverData } = useDiscoverQuery(params.boardId);
  const { isPlus } = useAppState();
  const classes = useStyles();
  const history = useHistory();

  const listings = data?.marketplaceListingFeatureType.items.map((item) => {
    const existingSubscription =
      discoverData?.board.subscriptionSet.channels.filter(
        (c) => c.marketplaceListing?.id === item.marketplaceListing.id
      )[0];

    return {
      id: item.marketplaceListing.id,
      title: item.marketplaceListing.title,
      free: item.marketplaceListing.free ?? false,
      icon:
        item.marketplaceListing.icon ??
        item.marketplaceListing.installable.icon,
      description:
        item.marketplaceListing.shortDescription ||
        item.marketplaceListing.description ||
        null,
      canAdd: true,
      installableIsSingleton:
        item.marketplaceListing.installable.installationsAreSingleton || null,
      subscriptionId: existingSubscription?.id || "",
      subscriptionMuted: existingSubscription?.muted ?? false,
    };
  });

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.headerColumn}>
          <ButtonBase
            onClick={() => {
              history.push(`/board/${params.boardId}/installables/discover`);
            }}
          >
            <img src="/icons/icon-back.svg" alt="Back" />
          </ButtonBase>
        </Box>
        <Box className={classes.headerColumnCenter}>
          <FeatureTypesPopover
            title={data?.marketplaceListingFeatureType.title}
          />
        </Box>
        {/* Display Flex three items to center the title */}
        <Box className={classes.headerColumn}></Box>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.installableContainer}>
          <CardsContainer>
            {listings?.map((listing) => {
              return (
                <InstallableTile
                  free={listing.free ?? false}
                  key={listing.id}
                  canAdd={listing.canAdd}
                  id={listing.id}
                  title={listing.title}
                  icon={listing.icon}
                  description={listing.description}
                  installed={!!listing?.subscriptionId}
                  enabled={true}
                  className={classes.installable}
                  isPlus={isPlus}
                  installableIsSingleton={!!listing.installableIsSingleton}
                  subscriptionId={listing.subscriptionId}
                  subscriptionMuted={listing.subscriptionMuted}
                />
              );
            })}
          </CardsContainer>
        </Box>
      </Box>
    </>
  );
};

export const FeatureTypesPopover = (props: { title?: string }) => {
  const { data } = useMarketPlaceFeatureTypes();
  const history = useHistory();
  const params = useParams() as { boardId: string };
  const classes = useStyles();

  const isMobile = useMediaQuery({
    query: `(max-width:${responsive.MOBILE}px)`,
  });

  const categoryButtonRef = useRef<HTMLDivElement | null>(null);
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const featureTypeKey = props.title
    ? props.title?.replace(" & ", "_").replace(" ", "_").toUpperCase()
    : "";
  const icon = featureTypeKey ? featureTypeIcons?.[featureTypeKey] : <></>;

  return (
    <Box className={classes.categoryButtonContainer}>
      <ButtonBase onClick={() => setCategoriesOpen(!categoriesOpen)}>
        <div ref={categoryButtonRef} className={classes.dropdownContainer}>
          {icon}
          <Spacer size={"small"} horizontal />
          {isMobile ? (
            <SubTitle2>{props.title || ""}</SubTitle2>
          ) : (
            <SubTitle>{props.title || ""}</SubTitle>
          )}
        </div>
        <KeyboardArrowUpIcon
          className={[
            classes.icon,
            classes.categoryChevron,
            !categoriesOpen && classes.categoryChevronInactive,
          ]
            .filter((e) => !!e)
            .join(" ")}
        />
      </ButtonBase>
      <Popper
        open={categoriesOpen}
        anchorEl={() => categoryButtonRef.current!!}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <div className={classes.categoriesPopover}>
              {data?.marketplaceListingFeatureTypes.map((featureType) => (
                <Button
                  variant={"text"}
                  key={featureType.id}
                  onClick={() => {
                    history.push(
                      `/board/${params.boardId}/installables/discover/${featureType.id}`
                    );
                  }}
                  className={classes.categoriesText}
                >
                  {featureType.title}
                </Button>
              ))}
            </div>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
