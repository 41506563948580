import { CircularProgress } from "@mui/material";
import { useMessageHistory } from "../../../hooks/useMessageHistory";
import { IBoard, Spacer, ToastProvider } from "@vestaboard/installables";
import { usePersonFavoritesList } from "../../../hooks/usePersonFavoritesList";
import { BORDER_COLORS } from "../../../config";
import { MessageCard } from "../../../components/MessageCard";
import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { useGetPinnedMessage } from "../../../hooks/useGetPinnedMessage";
import { useQuietHours } from "../../../hooks/useQuietHours";
import { useGetRole } from "../../../hooks/useGetRole";
import { SourcesFilterModal } from "./SourcesFilterModal";
import { SimplePagination } from "../../../components/SimplePagination";
import { EndOfList } from "../../../components/EndOfList";
import { HistoryContainer } from "../../../components/HistoryContainer";
import { MessagesContainer } from "../../../components/MessagesContainer";
import { FilterButton } from "../../../components/FilterButton";

export interface IMessagesListProps {
  boardId: string;
}

export const MessagesList = (props: IMessagesListProps) => {
  const [page, setPage] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [sources, setSources] = useState<string[]>([]);
  const { data, loading, refetch } = useMessageHistory({
    boardId: props.boardId,
    page,
    sources,
  });
  const { data: pinnedMessageData, loading: loadingPinnedMessage } =
    useGetPinnedMessage();
  const { favorites } = usePersonFavoritesList(0);
  const { quietHoursData, hasQuietHours } = useQuietHours(props.boardId);
  const { iAmOwner } = useGetRole(props.boardId);

  const pinnedMessageId = pinnedMessageData?.board?.pinnedMessage?.message?.id;
  const pinnedUntil = pinnedMessageData?.board?.pinnedMessage?.pinnedUntil;

  const hasPinnedMessage = useMemo(() => {
    return pinnedMessageId && pinnedUntil
      ? dayjs(pinnedUntil).isAfter(dayjs())
      : false;
  }, [pinnedUntil, pinnedMessageId]);

  if (loadingPinnedMessage || loading) {
    return (
      <HistoryContainer>
        <CircularProgress />
      </HistoryContainer>
    );
  }

  return (
    <ToastProvider offset={180}>
      <>
        <FilterButton isOpen={openFilter} setOpen={() => setOpenFilter(true)} />
        {data?.board.history.length === 0 ? (
          <>
            <Spacer size="extraLarge" />
            <EndOfList>No Messages</EndOfList>
          </>
        ) : null}

        <HistoryContainer>
          {data?.board.history.map((appearance, index) => {
            const { message } = appearance;

            return (
              <MessagesContainer key={`container-${appearance.id}`}>
                <MessageCard
                  borderColor={BORDER_COLORS[index % BORDER_COLORS.length]}
                  isFavorite={favorites.includes(message?.id || "")}
                  text={message?.text || ""}
                  formatted={message?.formatted as IBoard}
                  id={message?.id || ""}
                  key={`message-card-${appearance.id}`}
                  boardId={props.boardId}
                  appearenceId={appearance.id}
                  created={message?.created || ""}
                  sendAt={message?.sendAt}
                  onSend={async () => await refetch()}
                  author={message?.authorFormatted || ""}
                  hasPinnedMessage={hasPinnedMessage}
                  quietHoursData={quietHoursData}
                  hasQuietHours={hasQuietHours}
                  iAmOwner={iAmOwner}
                />
              </MessagesContainer>
            );
          })}
        </HistoryContainer>
        <SimplePagination
          disablePrevious={!page}
          previous={() => {
            setPage(page - 1);
          }}
          next={() => {
            setPage(page + 1);
          }}
          disableNext={!data?.board.history.length}
        />
        <Spacer size="extraLarge" />
        {openFilter && (
          <SourcesFilterModal
            sources={sources}
            setSources={setSources}
            visible={openFilter}
            setVisible={setOpenFilter}
            setCurrentPage={setPage}
          />
        )}
      </>
    </ToastProvider>
  );
};
