import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { InstallableIcon } from "../../../components/InstallableIcon";
import { BREAKPOINT_SMALL } from "../../../config";
import { useAppState } from "../../../hooks/global";
import { InstallButtonOrToggleSwitch } from "./InstallableListItem";

interface IRouteMatch {
  boardId: string;
}

const useStyles = makeStyles({
  container: {
    width: 350,
    marginRight: 50,
    display: "flex",
    position: "relative",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      marginRight: 18,
      width: 330,
    },
  },
  content: {
    display: "flex",
    marginLeft: 22,
    flexGrow: 1,
  },
  details: {
    flexGrow: 1,
  },
  titleBar: {
    position: "relative",
    flexGrow: 1,
    marginBottom: 12,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      marginBottom: 18,
    },
  },
  action: {
    position: "absolute",
    top: 0,
    right: 0,
    paddingLeft: 60,
    height: 50,
    backgroundImage:
      "linear-gradient(to right, rgba(30, 32, 34, 0), #1e2022 40%, #1e2022)",
    display: "flex",
    justifyContent: "flex-end",
  },
  enabledToggle: {
    display: "inline-block",
    marginTop: -20,
  },
  titleLink: {
    textDecoration: "none !important",

  },
  title: {
    fontFamily: "HelveticaNeue",
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: 1.22,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#f5f5f7 !important",
    height: 44,
    textDecoration: "none !important",
    overflowY: "hidden",
    maxWidth: 156,
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",

    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      fontSize: 16,
    },
  },
  description: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#fff",
    maxHeight: 48,
    overflowY: "hidden",
  },
});

interface IInstallableTile {
  id: string;
  title: string;
  description: string | null;
  icon: string | null;
  installed: boolean;
  enabled: boolean;
  className?: string;
  isPlus: boolean;
  canAdd: boolean;
  installableIsSingleton: boolean;
  subscriptionId: string | null;
  subscriptionMuted: boolean;
  free: boolean;
}

export const InstallableTile: React.FC<IInstallableTile> = (props) => {
  const classes = useStyles();
  const params = useParams<IRouteMatch>();
  const { boardId } = params;
  const history = useHistory();
  const { isPlus, tenantId } = useAppState();
  const isSmallScreen = useMediaQuery({
    query: `(max-width: ${BREAKPOINT_SMALL})`,
  });

  const isApiKey = (props.title || "").includes("API Key");

  const url = `/board/${boardId}/installable/${props.id}`;

  return (
    <Box
      className={[classes.container, props.className]
        .filter((e) => !!e)
        .join(" ")}
    >
      <Link to={url}>
        <InstallableIcon
          icon={isApiKey ? "/icons/icon-installable-api.svg" : props.icon}
          title={props.title || "Subscription Icon"}
          size={isSmallScreen ? "medium" : "large"}
          disabled={props.installed && !props.enabled}
        />
      </Link>
      <Box className={classes.content}>
        <Box className={classes.details}>
          <Box className={classes.titleBar}>
            <Link to={url} className={classes.titleLink}>
              <Typography style={{
              }} className={classes.title}>{props.title}</Typography>
            </Link>
            <Box className={classes.action}>
              <Box>
              <InstallButtonOrToggleSwitch
                free={props.free}
                navigateToDetail={() => history.push(url)}
                contextCopy={props.title}
                marketplaceListingId={props.id}
                muted={props.subscriptionMuted}
                boardId={boardId}
                tenantId={tenantId ?? ""}
                subscriptionId={props.subscriptionId}
                installableIsSingleton={props.installableIsSingleton ?? true}
              />
              </Box>
            </Box>
          </Box>
          <Box className={classes.description}>{props.description}</Box>
        </Box>
      </Box>
    </Box>
  );
};
