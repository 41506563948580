import { RenameBoardV2Mutation, RenameBoardV2MutationVariables } from "../gql";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const RENAME_BOARD_V2 = gql`
  mutation RenameBoardV2($input: UpdateBoardInputV2!) {
    updateBoardV2(input: $input) {
      ... on BoardV2 {
        id
        title
      }
      ... on BoardErrorV2 {
        type
        error
      }
    }
  }
`;

export const useRenameBoardV2 = () => {
  return useMutation<RenameBoardV2Mutation, RenameBoardV2MutationVariables>(
    RENAME_BOARD_V2
  );
};
