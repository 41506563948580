import * as React from "react";

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router";

import { BoardSettingsV2 } from "../../components/BoardSettingsV2";
import { CircularProgress } from "@mui/material";
import { QuietHoursV2 } from "./QuietHoursV2";
import { SectionHeader } from "../../components/SectionHeader";
import { SectionTitle } from "../../components/SectionTitle";
import { Spacer } from "../../components/Spacer";
import { Tab } from "../../components/Tab";
import { TabContent } from "../../components/TabContent";
import { Tabs } from "../../components/Tabs";
import { TimeZoneV2 } from "./TimeZoneV2";
import { UsersV2 } from "./UsersV2";
// import { Iframe } from "../../components/Iframe";
import { Billing } from "./Billing";
import { usePaywall } from "../../providers/PaywallProvider";
import { useAppState } from "../../hooks/global";
import { usePermissions } from "../../hooks/usePermissions";
import { useMyBoardsV2 } from "../../hooks/useMyBoardsV2";
import { useGetMyRoleV2, useGetRolesV2 } from "../../hooks/useGetRolesV2";
import { useGetBoardV2 } from "../../hooks/useGetBoardV2";

interface IRouteParams {
  boardId: string;
}

export const SettingsPageV2: React.FC = () => {
  const { data: myData } = usePermissions();
  const location = useLocation();
  const history = useHistory();
  const params = useParams<IRouteParams>();

  const { data: myBoardsData, loading: myBoardsLoading } = useMyBoardsV2();
  const { data: boardRolesData, loading: boardRolesLoading } = useGetRolesV2(
    params.boardId
  );
  const { data: myRoleData, loading: myRoleLoading } = useGetMyRoleV2(
    params.boardId
  );
  const { data: boardData, loading: boardLoading } = useGetBoardV2(
    params.boardId
  );

  // set app state for board id so usePaywall hook will work
  const { setBoardId } = useAppState();
  React.useEffect(() => {
    setBoardId(params.boardId);
    params.boardId && localStorage.setItem("lastBoardId", params.boardId);
  }, [params]);

  const { isPlus } = usePaywall();

  if (myBoardsLoading || boardRolesLoading || myRoleLoading) {
    return <CircularProgress />;
  }

  const tabs = [
    {
      key: "users",
      label: "Users",
    },
    {
      key: "timezone",
      label: "Timezone",
    },
    {
      key: "quiet-hours",
      label: "Quiet Hours",
    },
    // {
    //   key: "mirroring",
    //   label: "Mirroring",
    // },
    {
      // determines position of underline, this tab is 4th
      key: "billing",
      label: "Billing",
      enabled: isPlus,
    },
  ];

  const currentTab =
    tabs.reduce((prev, current, index) => {
      return location.pathname.includes(current.key) ? index : prev;
    }, 0) || 0;

  return (
    <>
      <SectionHeader>
        <SectionTitle>Settings V2</SectionTitle>
        <Spacer width={80} />
        <BoardSettingsV2
          boardId={params.boardId}
          isSelected={false}
          transparent={true}
          enableDelete={(myBoardsData?.BoardsV2?.boards?.length || 0) > 1}
        />
      </SectionHeader>
      {!!tabs.length && (
        <>
          <Tabs
            value={currentTab}
            onChange={(tab) => {
              history.push(
                `/v2/board/${params.boardId}/settings/${tabs[tab].key}`
              );
            }}
          >
            {tabs.map((tab) => (
              <Tab key={tab.key} label={tab.label}></Tab>
            ))}
          </Tabs>
          <TabContent>
            <Switch>
              <Route
                path="/v2/board/:boardId/settings/users"
                component={UsersV2}
              />
              <Route
                path="/v2/board/:boardId/settings/timezone"
                component={TimeZoneV2}
              />
              <Route
                path="/v2/board/:boardId/settings/quiet-hours"
                component={QuietHoursV2}
              />
              {/* TODO: fetch mirroring URL */}
              {/* {settingsModuleData?.board.settings.map((setting) => (
                <Route
                  key={setting.key}
                  path={`/v2/board/:boardId/settings/${setting.key}`}
                  component={() => <DynamicSettingsIframe url={setting.link} />}
                />
              ))} */}
              <Route
                path="/v2/board/:boardId/settings/billing"
                render={() => <Billing boardId={params.boardId} />}
              />
              <Redirect to={`/v2/board/:boardId/settings/${tabs[0].key}`} />
            </Switch>
          </TabContent>
        </>
      )}
    </>
  );
};

// type DynamicSettingsIframeProps = {
//   url: string;
// };

// const DynamicSettingsIframe: React.FC<DynamicSettingsIframeProps> = (props) => {
//   // https://gitlab.com/vestaboard/default-subscription-configuration-view this part of the settings code lives here
//   return (
//     <Box style={{ height: 900 }}>
//       <Iframe title="Mirroring" src={props.url}></Iframe>
//     </Box>
//   );
// };
