import { useMediaQuery } from "@mui/material";

export enum ETachyonsMediaQuery {
  MEDIUM,
  LARGE
}

export const useTachyonsMediaQuery = (): ETachyonsMediaQuery | null => {
  const matchesMedium = useMediaQuery('screen and (min-width: 30em) and (max-width: 60em)');
  const matchesLarge = useMediaQuery('screen and (min-width: 60em)');

  if (matchesMedium) {
    return ETachyonsMediaQuery.MEDIUM;
  } else if (matchesLarge) {
    return ETachyonsMediaQuery.LARGE;
  } else {
    return null;
  }
}

export const useIsMobileMediaQuery = (): boolean => {
  return useMediaQuery('(min-width:300px)')
}
