import * as React from "react";

import {
  RevokeUserInvitationMutation,
  RevokeUserInvitationMutationVariables,
} from "../../../gql";

import { Confirm } from "../../../components/Confirm";
import { useToasts } from "@vestaboard/installables";
import { useRevokRoleV2 } from "../../../hooks/useRevokeRoleV2";
import { GET_ROLES_QUERY_V2 } from "../../../hooks/useGetRolesV2";

interface IRevokeInvitation {
  removeRoleId: null | string;
  onCancel: () => void;
  title: string;
  message: string;
  boardId: string;
}

export const RevokeInvitationV2 = (props: IRevokeInvitation) => {
  const { addToast } = useToasts();
  const [revokeInvitation] = useRevokRoleV2();
  return (
    <Confirm
      title={props.title}
      message={props.message}
      open={!!props.removeRoleId}
      handleClose={props.onCancel}
      handleAccept={async () => {
        if (!props.removeRoleId) {
          return;
        }

        try {
          await revokeInvitation({
            variables: {
              input: {
                id: props.removeRoleId,
              },
            },
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_ROLES_QUERY_V2,
                variables: {
                  boardId: props.boardId,
                },
              },
            ],
          });

          props.onCancel();

          addToast(`The invitation was revoked successfully`, {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (err) {
          addToast(`There was an error revoking this invitation`, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }}
    ></Confirm>
  );
};
