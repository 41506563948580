import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Input,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Body } from "@vestaboard/installables";
import gql from "graphql-tag";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { useAppState } from "../../../../hooks/global";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { InstallablesList } from "../../../History/components/InstallablesList";
import { IRouteParams } from "../../../Messages";
import {
  MarketplaceListingSearchQuery,
  MarketplaceListingSearchQueryVariables,
} from "../../../../gql";
import { useLayoutEffect } from "react";
import { useDebounce } from "use-debounce";

const useStyles = makeStyles({
  searchBarIcon: {
    width: 40,
    color: "#f5f5f7",
    margin: "20px",
    opacity: 0.5,
    alignSelf: "flex-start",
    objectFit: "contain",
  },
  deleteRecentSearchIcon: {
    width: 25,
    height: 25,
    color: "#f5f5f7",
    opacity: 0.5,
    alignSelf: "flex-end",
    alignItems: "flex-end",
    justifyContent: "center",
    objectFit: "contain",
  },
  deleteRecentSearchButton: {
    height: "48px",
    marginLeft: "30px",
  },
  searchBarIconContainer: {
    margin: "5px",
    // flex: 1,
    alignSelf: "flex-start",
    justifyContent: "center",
    backgroundColor: "transparent",
    border: "none",
  },
  searchBarContainer: {
    width: "100%",
    height: "90px",
    // alignItems: 'flex-start',
    borderBottom: "solid 1px #2b2b2b",
    flexDirection: "row",
    // marginTop: -10,
  },
  search: {
    border: "none",
    // border: '1px solid white',
    // width: '83vw',
    height: "94%",
    paddingBottom: "10px",
    margin: "-08px 0px 0px 30px",
    fontSize: "24px",
  },
  cancel: {
    textColor: "#f5f5f7",
    textTransform: "none",
    backgroundColor: "transparent",
    fontSize: "14px",
    textAlign: "right",
    fontFamily: "HelveticaNeue",
    justifyContent: "center",
    marginTop: "30px",
    // margin: '32px 28px 28px 28px'
  },
  clearAll: {
    textColor: "#f5f5f7",
    textTransform: "none",
    backgroundColor: "transparent",
    fontSize: "14px",
    textAlign: "right",
    fontFamily: "HelveticaNeue",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
  recentSearches: {
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    textColor: "#f5f5f7",
    textTransform: "none",
    fontFamily: "HelveticaNeue",
    textAlign: "left",
    fontSize: "16px",
    lineHeight: 1.5,
  },
  recentSearch: {
    fontFamily: "HelveticaNeue",
    fontSize: "16px",
    lineHeight: 1.5,
    textColor: "#c3c3c3",
    color: "#c3c3c3",
    alignSelf: "flex-start",
    alignContent: "flex-start",
    paddingTop: "12px",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  recentSearchContainer: {
    width: "85%",
    paddingLeft: "8%",
    alignContent: "flex-start",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    // right: '10px',
    backgroundColor: "transparent",
    borderRadius: "6px",
    height: "48px",
    textAlign: "left",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  recentSearchButton: {
    color: "transparent",
  },
  emptyState: {
    marginTop: "20%",
    textAlign: "center",
  },
  emptyStateHeading: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 12,
  },
  emptyStateBody: {
    color: "#c6c6c8",
    width: "90%",
    maxWidth: 300,
    margin: "0 auto 44px",
  },
});

const SearchBarIcon = () => {
  const classes = useStyles();
  return (
    <img
      className={classes.searchBarIcon}
      src={"/icons/icon-search.svg"}
      alt="Search"
    />
  );
};

const DeleteRecentSearchButton = (props: { onClick: () => void }) => {
  const classes = useStyles();
  return (
    <Button
      onClick={props.onClick}
      className={classes.deleteRecentSearchButton}
    >
      <img
        className={classes.deleteRecentSearchIcon}
        src={"/icons/icon-close.svg"}
        alt="Delete"
      />
    </Button>
  );
};

type RecentSearchesProps = {
  recentSearches: string[];
  setRecentSearches: (searches: string[]) => void;
  onClearSearch: (search: string) => void;
  setSearchTerm: (search: string) => void;
};

const RecentSearches: React.FC<RecentSearchesProps> = (props) => {
  const classes = useStyles();

  const onDelete = (search: string) => {
    props.onClearSearch(search);
  };

  if (props.recentSearches.length) {
    return (
      <>
        <Box display="flex" flexDirection="column" marginTop="20px">
          <Box display="flex" flexDirection="row" width="100%" marginLeft="6vw">
            <Typography className={classes.recentSearches}>
              Recent Searches
            </Typography>
          </Box>
          {props.recentSearches.map((search: string, index) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                marginTop="5px"
                key={index}
              >
                <ButtonBase
                  className={classes.recentSearchContainer}
                  onClick={() => props.setSearchTerm(search)}
                >
                  <Typography className={classes.recentSearch}>
                    {search}
                  </Typography>
                </ButtonBase>
                <DeleteRecentSearchButton onClick={() => onDelete(search)} />
              </Box>
            );
          })}
        </Box>
        <Box marginTop="20px" borderBottom="solid 1px #2b2b2b" />
      </>
    );
  }

  return null;
};

const INSTALLABLE_SEARCH_QUERY = gql`
  query MarketplaceListingSearch($term: String!, $tenantId: String!) {
    marketplaceListings(search: $term) {
      id
      icon
      shortDescription
      title
      free
      installations(tenant: $tenantId) {
        id
        subscriptions {
          id
          muted
        }
      }
      installable {
        installationsAreSingleton
      }
    }
  }
`;

const useMarketplaceListingQuery = () =>
  useLazyQuery<
    MarketplaceListingSearchQuery,
    MarketplaceListingSearchQueryVariables
  >(INSTALLABLE_SEARCH_QUERY);

export const InstallablesSearch: React.FC<any> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { tenantId } = useAppState();
  const params = useParams<IRouteParams>();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [performSearch, { data, loading }] = useMarketplaceListingQuery();

  const [recentSearches, setRecentSearches] = useLocalStorage<string[]>(
    "recentInstallableHistorySearches",
    []
  );
  const cancelPressed = () => {
    history.goBack();
  };

  const search = () => {
    performSearch({
      variables: { term: searchTerm, tenantId: tenantId ?? "" },
    });
  };

  const submitSearch = (keyCode: string) => {
    if (keyCode === "Enter" && searchTerm) {
      search();
    }
  };

  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const hasData = (data?.marketplaceListings?.length || 0) > 0 && !loading;

  useLayoutEffect(() => {
    debouncedSearchTerm &&
      performSearch({
        variables: { term: debouncedSearchTerm, tenantId: tenantId ?? "" },
      });
  }, [debouncedSearchTerm, tenantId]);

  useLayoutEffect(() => {
    // autosearch returned results
    hasData &&
      debouncedSearchTerm &&
      !recentSearches.includes(debouncedSearchTerm) &&
      setRecentSearches([debouncedSearchTerm, ...recentSearches.slice(0, 4)]);
  }, [hasData, debouncedSearchTerm, recentSearches, setRecentSearches]);

  return (
    <>
      <Box flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          className={classes.searchBarContainer}
        >
          <Box className={classes.searchBarIconContainer}>
            <SearchBarIcon />
          </Box>
          <Box
            flexGrow={1}
            height="90px"
            justifyContent="flex-start"
            alignContent="flex-start"
            alignSelf="flex-start"
            margin="15px 25px 0px 0px"
          >
            <Input
              value={searchTerm}
              autoFocus
              onKeyDown={(e) => submitSearch(e.code)}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={classes.search}
              multiline={false}
              fullWidth
              disableUnderline
              placeholder="Search"
            />
          </Box>
          <Box
            height="100%"
            justifyContent="center"
            alignContent="flex-end"
            flexShrink={0}
            paddingRight={"25px"}
          >
            <Button onClick={cancelPressed} className={classes.cancel}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
      {!searchTerm && !loading && (
        <>
          <RecentSearches
            recentSearches={recentSearches}
            setRecentSearches={setRecentSearches}
            onClearSearch={(searchTermToRemove) => {
              const filteredRecentSearchTerms = recentSearches.filter(
                (term) => term !== searchTermToRemove
              );
              setRecentSearches(filteredRecentSearchTerms);
            }}
            setSearchTerm={setSearchTerm}
          />
        </>
      )}
      {loading && searchTerm ? (
        <>
          <div className={classes.emptyState}>
            <CircularProgress />
          </div>
        </>
      ) : null}
      {data && !data.marketplaceListings.length && searchTerm ? (
        <>
          <div className={classes.emptyState}>
            <h3 className={classes.emptyStateHeading}>
              Could not find "{searchTerm}"
            </h3>
            <Body>
              Try searching again using a different spelling or keyword.
            </Body>
          </div>
        </>
      ) : (
        <>
          <InstallablesList
            data={
              data?.marketplaceListings.map((ml) => ({
                id: ml.id,
                title: ml.title ?? "",
                description: ml.shortDescription ?? "",
                icon: ml.icon ?? "",
                free: ml.free ?? false,
                isSingleton: ml.installable?.installationsAreSingleton ?? true,
                existingSubscriptionId: ml.installations.length
                  ? ml.installations[0].subscriptions?.[0]?.id
                  : null,
                existingSubscriptionMuted: ml.installations.length
                  ? ml.installations[0].subscriptions?.[0]?.muted ?? false
                  : false,
              })) ?? []
            }
          />
        </>
      )}
    </>
  );
};
