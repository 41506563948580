import { useQuery } from "@apollo/client";
import { PaywallQuery, PaywallQueryVariables } from "../gql";
import gql from "graphql-tag";

const PAYWALL_QUERY = gql`
  query PaywallQuery($boardId: String!) {
    board(id: $boardId) {
      id
      devices {
        id
      }
      productApplications {
        id
        pricing {
          id
          title
          product {
            id
            title
            isPlus
          }
        }
      }
    }
  }
`;

export const usePaywallQuery = (boardId: string | null) =>
  useQuery<PaywallQuery, PaywallQueryVariables>(PAYWALL_QUERY, {
    variables: {
      boardId: boardId ?? "",
    },
    skip: !boardId,
    fetchPolicy: "no-cache",
  });
