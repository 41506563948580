import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { PinMessageMutation, PinMessageMutationVariables } from "../gql";

const PinMessage = gql`
  mutation PinMessage($input: CreateMessage2Input!) {
    createMessage2(input: $input) {
      message {
        id
        created
        text
      }
      created
      withinQuietHours
      existingPinnedMessage {
        pinnedUntil
      }
    }
  }
`;

export const usePinMessage = () => {
  return useMutation<PinMessageMutation, PinMessageMutationVariables>(
    PinMessage
  );
};
