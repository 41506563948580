import * as React from "react";

import { Box, Drawer, List } from "@mui/material";

import { BREAKPOINT_MEDIUM_MIN, BREAKPOINT_SMALL } from "../../config";
import { BoardSelector } from "../../components/BoardSelector";
import { Hamburger } from "./components/Hamburger";
import { Header } from "./components/Header";
import { Logo } from "./components/Logo";
import MediaQuery from "react-responsive";
import { NavBarLeft } from "./components/NavBarLeft";
import { NavBarRight } from "./components/NavBarRight";
import { SidebarNavItems } from "./components/SidebarNavItems";
import { TenantSelector } from "./components/TenantSelector";
import { UserSettings } from "./components/UserSettings";
import { makeStyles, CSSProperties } from "@mui/styles";
import { useParams } from "react-router";

import {
  REFERRAL_BAR_HEIGHT,
  ReferralBar,
  useReferralContext,
} from "./components/ReferralBar";

const drawerWidth = 104;

const drawer = {
  height: "100vh",
  position: "relative",
} as CSSProperties;

const useStyles = makeStyles((theme) => ({
  drawer,
  drawerReferral: {
    ...drawer,
    marginTop: REFERRAL_BAR_HEIGHT,
  },
  drawerPaper: {
    position: "absolute",
    background: "#181919",
    top: 0,
    width: drawerWidth,
    overflowY: "auto",
    overflowX: "hidden",
  },
  container: {
    background: "#1E2022",
    color: "#FFF",
    minHeight: "100vh",
    width: "100vw",
    overflowY: "auto",
    overflowX: "hidden",
  },
  avatar: {
    background: "#191A1A",
    border: "solid 1px #242829",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: 0,
    marginLeft: drawerWidth,
    width: "calc(100vw - 103px)",
    borderLeft: "solid 1px #2b2b2b",
    borderTop: "solid 1px #2b2b2b",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      marginLeft: 0,
      width: "100vw",
    },
  },
  footerSpacer: {
    width: "100%",
    height: 100,
  },
}));

interface IAppTemplateProps {}

type AppTemplateProps = IAppTemplateProps;

interface IRouteMatch {
  boardId?: string;
}

export const AppTemplate: React.FC<AppTemplateProps> = (props) => {
  const classNames = useStyles();
  const [mobileNavMenuIsOpen, setMobileNavMenuOpen] = React.useState(false);
  const params = useParams<IRouteMatch>();
  const { hasReferralBanner } = useReferralContext();

  return (
    <>
      <ReferralBar />
      <Box
        className={[
          "flex flex-column justify-between",
          classNames.container,
        ].join(" ")}
      >
        <Box className="dn">
          <TenantSelector />
        </Box>
        <Header>
          <NavBarLeft>
            <Hamburger
              setMobileNavMenuOpen={setMobileNavMenuOpen}
              isOpened={mobileNavMenuIsOpen}
            />
            <MediaQuery minWidth={BREAKPOINT_MEDIUM_MIN}>
              <Logo />
            </MediaQuery>
          </NavBarLeft>
          <MediaQuery maxWidth={BREAKPOINT_SMALL}>
            <Logo />
          </MediaQuery>
          <NavBarRight>
            <MediaQuery minWidth={BREAKPOINT_MEDIUM_MIN}>
              <BoardSelector />
            </MediaQuery>
            <UserSettings />
          </NavBarRight>
        </Header>
        {!!params.boardId && (
          <Box
            className={"fixed top-0 left-0 right-0 z-1"}
            style={{ pointerEvents: "none" }}
          >
            <Box
              style={{ width: drawerWidth, pointerEvents: "initial" }}
              className={"dn db-l"}
            >
              <Drawer
                anchor={"left"}
                variant="persistent"
                style={{ width: drawerWidth }}
                className={
                  hasReferralBanner
                    ? classNames.drawerReferral
                    : classNames.drawer
                }
                classes={{
                  paper: classNames.drawerPaper,
                }}
                open={true}
              >
                <List style={{ paddingTop: 0 }}>
                  <SidebarNavItems onClick={() => {}} />
                </List>
              </Drawer>
            </Box>
            <Drawer
              anchor={"left"}
              open={mobileNavMenuIsOpen}
              style={{ width: drawerWidth, top: 80 }}
              className={classNames.drawer}
              classes={{ paper: classNames.drawerPaper }}
              onClose={() => setMobileNavMenuOpen(false)}
            >
              <List style={{ paddingTop: hasReferralBanner ? 40 : 0 }}>
                <SidebarNavItems onClick={() => setMobileNavMenuOpen(false)} />
              </List>
            </Drawer>
          </Box>
        )}
        <Box className={classNames.content}>{props.children}</Box>
      </Box>
    </>
  );
};
