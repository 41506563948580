import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetTenantBoardsQuery,
  MessageShareTokenQuery,
  MessageShareTokenQueryVariables,
} from "../../gql";
import gql from "graphql-tag";
import { GET_TENANT_BOARDS_QUERY } from "../RedirectToBoard";

const QUERY = gql`
  query MessageShareToken($token: String!) {
    messageShareToken(token: $token) {
      id
      message {
        id
        formatted
        text
      }
    }
  }
`;

export const ShareRedirect = () => {
  const params = useParams<{ token: string }>();
  const { data: tenantsData, loading: tenantsLoading } =
    useQuery<GetTenantBoardsQuery>(GET_TENANT_BOARDS_QUERY);
  const { data, loading } = useQuery<
    MessageShareTokenQuery,
    MessageShareTokenQueryVariables
  >(QUERY, {
    variables: {
      token: params.token,
    },
  });
  const history = useHistory();
  const lastBoardId = localStorage.getItem("lastBoardId");
  const tenantCount = tenantsData?.tenants?.length || 0;

  useEffect(() => {
    if (!loading && !tenantsLoading) {
      const boardIds =
        tenantsData && tenantCount > 0
          ? tenantsData.tenants.reduce((prev: Array<string>, current) => {
              return [
                ...prev,
                ...(current.boards || []).map((board) => board.id),
              ];
            }, [])
          : [];

      const characters = data?.messageShareToken.message.formatted
        ? JSON.stringify(data?.messageShareToken.message.formatted)
        : data?.messageShareToken.message?.text || "";

      if (lastBoardId) {
        history.push(`/board/${lastBoardId}/compose/duplicate/${characters}`);
      } else if (boardIds.length > 0) {
        history.push(`/board/${boardIds[0]}/compose/duplicate/${characters}`);
      } else {
        history.push("/login");
      }
    }
  }, [data, history, lastBoardId, loading, tenantsLoading]);
  return <></>;
};
