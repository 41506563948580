import { IgnoreInviteMutation, IgnoreInviteMutationVariables } from "../gql";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const LEAVE_BOARD = gql`
  mutation IgnoreInvite($personTenantAssociationId: String!) {
    ignorePersonTenantInvitation(
      input: { personTenantAssociationId: $personTenantAssociationId }
    ) {
      ignored
    }
  }
`;

export const useLeaveBoard = () => {
  return useMutation<IgnoreInviteMutation, IgnoreInviteMutationVariables>(
    LEAVE_BOARD
  );
};
