import "./vendor/iframe-resizer";

import * as React from "react";

import { StringParam, useQueryParam } from "use-query-params";

import { WYSIWYGEditor } from "../../components/WysiwygEditor";

export const WysiwygView: React.FC = () => {
  const [config] = useQueryParam("config", StringParam);

  const configJson = config ? JSON.parse(atob(config)) : undefined;
  const initial = configJson ? configJson.initial : undefined;
  const cta = configJson ? configJson.cta : undefined;

  const sendCharacters = async (characters: number[][]) => {
    window.parent.postMessage(
      {
        type: "VESTABOARD_MESSAGE_SENT",
        messageCharacters: characters,
      },
      "*"
    );
  };

  return (
    <div data-iframe-height>
      <WYSIWYGEditor
        initial={initial}
        cta={cta}
        submitNewMessage={sendCharacters}
        ButtonsWrapper={({ children, className }) => (
          <div className={"mt3 container-fluid"}>
            <div className="flex justify-between">
              {React.Children.map(children, (child) => (
                <div>{child}</div>
              ))}
            </div>
          </div>
        )}
      />
    </div>
  );
};
