import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import { BREAKPOINT_SMALL } from "../config";

interface ISectionBody {
  children: React.ReactElement | React.ReactElement[];
}

const useStyles = makeStyles({
  body: {
    width: "100%",
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 88,
    paddingRight: 88,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
});

export const SectionBody = (props: ISectionBody) => {
  const classes = useStyles();
  return <Box className={classes.body}>{props.children}</Box>;
};
