import * as React from "react";

export const NotFoundPage = () => {
  return (
    <div className={"center w-90-ns"}>
      <h2>404</h2>
      <p>File Not Found.</p>
    </div>
  );
};
