import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback, useMemo, useState } from "react";
import {
  TenantBoardsQuery,
  TenantBoardsQueryVariables,
  ViewersAdminOwnerBoardsQuery,
  ViewersAdminOwnerBoardsQueryVariables,
} from "../gql";

const boardsQuery = gql`
  query TenantBoardsQuery {
    tenants {
      id
      boards {
        id
        title
      }
    }
  }
`;

const ownerAdminBoardsQuery = gql`
  query ViewersAdminOwnerBoards {
    tenants {
      id
      # boards where the viewer is owner and admin
      boards(roles: "Owner,Admin") {
        id
        title
        tenant {
          id
        }
      }
    }
  }
`;

export const useSelectBoards = (currentBoard: string) => {
  const { data, loading, refetch } = useQuery<
    TenantBoardsQuery,
    TenantBoardsQueryVariables
  >(boardsQuery);
  const boards = data?.tenants.flatMap((tenant) => tenant.boards);
  const options = boards?.map((board) => ({ id: board.id, name: board.title }));
  const [selectedBoards, setSelectedBoards] = useState<string[]>([
    currentBoard,
  ]);

  const selectBoard = useCallback(
    (boardId: string) => {
      if (selectedBoards.includes(boardId)) {
        const removedArray = selectedBoards.filter((id) => id !== boardId);
        setSelectedBoards([...removedArray]);
      } else {
        setSelectedBoards([...selectedBoards, boardId]);
      }
    },
    [selectedBoards]
  );

  const selectAllBoards = useCallback(() => {
    options && setSelectedBoards(options.map((opt) => opt.id));
  }, [options]);
  const clearBoards = () => setSelectedBoards([]);

  return {
    loading,
    refetch,
    boards,
    options,
    selectedBoards,
    clearBoards,
    selectAllBoards,
    selectBoard,
  };
};

export const useSelectAdminOwnerBoards = (currentBoard?: string) => {
  const { data, loading, refetch } = useQuery<
    ViewersAdminOwnerBoardsQuery,
    ViewersAdminOwnerBoardsQueryVariables
  >(ownerAdminBoardsQuery);
  const boards = data?.tenants.flatMap((tenant) => tenant.boards);
  const options = boards?.map((board) => ({ id: board.id, name: board.title }));

  const defaultSelected = currentBoard ? [currentBoard] : [];

  const [selectedBoards, setSelectedBoards] =
    useState<string[]>(defaultSelected);

  const selectBoard = useCallback(
    (boardId: string) => {
      if (selectedBoards.includes(boardId)) {
        const removedArray = selectedBoards.filter((id) => id !== boardId);
        setSelectedBoards([...removedArray]);
      } else {
        setSelectedBoards([...selectedBoards, boardId]);
      }
    },
    [selectedBoards]
  );

  const selectAllBoards = useCallback(() => {
    options && setSelectedBoards(options.map((opt) => opt.id));
  }, [options]);
  const clearBoards = () => setSelectedBoards([]);

  return {
    loading,
    refetch,
    boards,
    options,
    selectedBoards,
    clearBoards,
    selectAllBoards,
    selectBoard,
  };
};
