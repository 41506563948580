import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import {
  AddMessageSetToBlendedMutation,
  AddMessageSetToBlendedMutationVariables,
  BlendedQuery,
  BlendedQueryVariables,
  RemoveMessageSetFromBlendedMutation,
  RemoveMessageSetFromBlendedMutationVariables,
} from "../gql";
import { useCallback } from "react";

const addMessageSetToBlended = gql`
  mutation AddMessageSetToBlendedMutation(
    $input: AddMessageSetToBlendedInput!
  ) {
    addMessageSetToBlended(input: $input) {
      id
      messageSetIds
    }
  }
`;

const removeMessageSetToBlended = gql`
  mutation RemoveMessageSetFromBlendedMutation(
    $input: RemoveMessageSetFromBlendedInput!
  ) {
    removeMessageSetFromBlended(input: $input) {
      id
      messageSetIds
    }
  }
`;

const getBlendedQuery = gql`
  query BlendedQuery($boardId: String!) {
    board(id: $boardId) {
      id
      blended {
        id
        messageSetIds
      }
    }
  }
`;

export const useBlendMessageSet = (boardId: string) => {
  const [add] = useMutation<
    AddMessageSetToBlendedMutation,
    AddMessageSetToBlendedMutationVariables
  >(addMessageSetToBlended);
  const [remove] = useMutation<
    RemoveMessageSetFromBlendedMutation,
    RemoveMessageSetFromBlendedMutationVariables
  >(removeMessageSetToBlended);
  const { data, loading } = useQuery<BlendedQuery, BlendedQueryVariables>(
    getBlendedQuery,
    {
      skip: !boardId,
      variables: {
        boardId,
      },
    }
  );
  const blendedSubscriptionId = data?.board?.blended?.id;
  const blendedMessageSets = data?.board?.blended?.messageSetIds;

  const navigateToBlended = useCallback(() => {
    if (blendedSubscriptionId && boardId) {
      window.location.pathname = `/board/${boardId}/installables/subscription/${blendedSubscriptionId}`;
    }
  }, [blendedSubscriptionId, boardId]);

  const addMessageSetToBlend = async (messageSetId: string) => {
    await add({
      variables: {
        input: {
          boardId,
          messageSetId,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: getBlendedQuery,
          variables: {
            boardId,
          },
        },
      ],
    });
  };

  const removeMessageSetFromBlend = async (messageSetId: string) => {
    return await remove({
      variables: {
        input: {
          boardId,
          messageSetId,
        },
      },
      refetchQueries: [
        {
          query: getBlendedQuery,
          variables: {
            boardId,
          },
        },
      ],
    });
  };

  return {
    blendedMessageSets,
    blendedSubscriptionId,
    loadingBlended: loading,
    navigateToBlended,
    addMessageSetToBlend,
    removeMessageSetFromBlend,
  };
};
