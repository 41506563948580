import * as React from "react";

import { AppStateProvider } from "./hooks/global";
import { ToastProvider } from "@vestaboard/installables";
import { useAuth0 } from "@auth0/auth0-react";
import { ReferralProvider } from "./templates/AppTemplate/components/ReferralBar";
import { ShareProvider } from "./providers/ShareProvider";
import { FullLoader } from "./components/FullLoader";
import { PaywallProvider } from "./providers/PaywallProvider";
import { MaterialThemeProvider } from "./providers/ThemeProvider";
import { AppRouterProvider } from "./providers/AppRouterProvider";
import { AuthenticatedApolloProvider } from "./providers/AuthenticatedApolloProvider";

// This is wrapped with the AuthProvider in src/index.tsx
export const WebApp: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <MaterialThemeProvider>
        <FullLoader />
      </MaterialThemeProvider>
    );
  }

  return (
    <AppStateProvider>
      <AuthenticatedApolloProvider>
        <MaterialThemeProvider>
          <ReferralProvider>
            <ToastProvider offset={80}>
              <ShareProvider>
                <PaywallProvider>
                  <AppRouterProvider />
                </PaywallProvider>
              </ShareProvider>
            </ToastProvider>
          </ReferralProvider>
        </MaterialThemeProvider>
      </AuthenticatedApolloProvider>
    </AppStateProvider>
  );
};
