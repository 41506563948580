import { Box } from "@mui/material";
import { WifiTethering } from "@mui/icons-material";
import {
  Spacer,
  SubTitle2,
  Button,
  IBoard,
  useToasts,
  ModalDialog,
} from "@vestaboard/installables";
import { SelectMenu } from "@vestaboard/installables/lib/components/SelectMenu";
import { useBroadcastMessage } from "../hooks/useBroadcastMessage";
import { useSelectBoards } from "../hooks/useSelectBoards";

interface IBroadcastModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  boardValue: IBoard;
  boardId: string;
}

export const BroadcastMessageModal = (props: IBroadcastModal) => {
  const { addToast } = useToasts();

  const { options, selectedBoards, selectAllBoards, clearBoards, selectBoard } =
    useSelectBoards(props.boardId);
  const [mutateBroadcastMessage] = useBroadcastMessage();

  return (
    <ModalDialog
      visible={props.visible}
      onClose={() => props.setVisible(false)}
      fullScreenMobile
      header={
        <>
          <WifiTethering style={{ paddingRight: 4 }} />
          <Spacer />
          <SubTitle2>Broadcast Message To</SubTitle2>
        </>
      }
      footer={
        <>
          <Button
            buttonType="outline"
            onClick={() => {
              props.setVisible(false);
            }}
          >
            Cancel
          </Button>
          <Button
            buttonType="white"
            disabled={selectedBoards.length === 0}
            onClick={async () => {
              const numberOfBoards = selectedBoards.length;
              try {
                await mutateBroadcastMessage({
                  variables: {
                    input: {
                      boardIds: selectedBoards.join(","),
                      characters: props.boardValue,
                    },
                  },
                });
                addToast(
                  `Broadcasted message to ${numberOfBoards} board${
                    numberOfBoards > 1 ? "s" : ""
                  }.`,
                  {
                    appearance: "success",
                  }
                );
              } catch (err) {
                addToast(`Error broadcasting message.`, {
                  appearance: "error",
                });
              }
              props.setVisible(false);
            }}
          >
            Broadcast Now
          </Button>
        </>
      }
    >
      <Box
        style={{
          width: "100%",
        }}
      >
        <SelectMenu
          options={options || []}
          onPress={selectBoard}
          selected={selectedBoards}
          selectAll={selectAllBoards}
          clear={clearBoards}
        />
      </Box>
    </ModalDialog>
  );
};
