import * as React from "react";

import {
  Box,
  CircularProgress,
  Table,
  TableCell,
  TableRow,
  TextField,
  Avatar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  CreateInstallableMutation,
  CreateInstallableMutationVariables,
  DeveloperInstallableQuery,
  DeveloperInstallableQueryVariables,
  DeveloperInstallablesQuery,
  DeleteInstallableMutation,
  DeleteInstallableMutationVariables,
} from "../../gql";
import { Redirect, useHistory } from "react-router-dom";
import { Route, Switch, useLocation, useParams } from "react-router";
import { useMutation, useQuery } from "@apollo/client";

import { ApiConsole } from "./components/ApiConsole";
import {
  ApiCredentialTable,
  IApiKeyTableApiKey,
} from "./components/ApiKeysTable";
import { Confirm } from "../../components/Confirm";
import { CreateApiKeyModal } from "./modals/CreateApiKeyModal";
import { SectionHeader } from "../../components/SectionHeader";
import { SectionTitle } from "../../components/SectionTitle";
import { Spacer, Button, FlexHorizontal } from "@vestaboard/installables";
import { TabContent } from "../../components/TabContent";
import gql from "graphql-tag";
import { history } from "../../providers/AppRouterProvider";
import dayjs from "dayjs";
import { useBoardSettingsModulesQuery } from "../Settings";
import { GrayFilledButton } from "../../components/GrayFilledButton";
import { Tabs } from "../../components/Tabs";
import { Tab } from "../../components/Tab";
import { useDeleteSubscription } from "../../hooks/useDeleteSubscription";
import { Iframe } from "../../components/Iframe";

interface IAutomationsPageProps {}

type AutomationsPageProps = IAutomationsPageProps;

interface IRouteMatch {
  boardId: string;
  tab?: string;
}

const useStyles = makeStyles({
  hero: {
    padding: "4rem 2rem",
    backgroundColor: "#1A1B1B",
  },
  tabs: {
    marginTop: 80,
  },
  tabContent: {
    marginTop: 42,
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  beta: {
    fontSize: 13,
    letterSpacing: 6.5,
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  avatar: {
    width: 65,
    height: 65,
    backgroundColor: "#2b2b2b",
  },
});

export const DOCUMENTATION_BASE_URL =
  process.env.REACT_APP_DOCUMENTATION_BASE_URL;

export const DeveloperPage: React.FC<AutomationsPageProps> = (props) => {
  const classes = useStyles();
  const location = useLocation<IRouteMatch>();
  const params = useParams<IRouteMatch>();

  const { data: boardSettingsData } = useBoardSettingsModulesQuery(
    params.boardId
  );
  const { boardId } = params;
  const path = location ? location.pathname : "";

  const dynamicSettingsModules =
    boardSettingsData?.board.settings
      .filter((bsm) => bsm.location === "developer")
      .map((bsm, index) => ({
        index: 1 + index,
        key: bsm.key ?? "",
        label: bsm.title,
        url: bsm.link,
      })) ?? [];

  const tabs = [
    {
      index: 0,
      key: "installables",
      label: "Installables",
    },
    ...dynamicSettingsModules,
    {
      index: 100,
      key: "docs",
      label: "Documentation",
      href: "https://docs.vestaboard.com",
    },
  ];

  const setCurrentTab = (index: number) => {
    history.push(`/board/${boardId}/developer/${tabs[index].key}`);
  };

  const currentTab = tabs.reduce((prev: number, tab) => {
    return path.includes(tab.key) ? tab.index : prev;
  }, 0);

  return (
    <>
      <SectionHeader>
        <Box className={classes.titleBox}>
          <SectionTitle>API</SectionTitle>
        </Box>
      </SectionHeader>
      <Tabs
        value={currentTab}
        onChange={(tab) => {
          setCurrentTab(tabs[tab].index);
        }}
      >
        {tabs.map((tab) =>
          "href" in tab ? (
            <a
              key={tab.key}
              className={classes.link}
              href={tab.href}
              target="_blank"
              rel="noreferrer"
            >
              <Tab label="Documentation" />
            </a>
          ) : (
            <Tab key={tab.key} label={tab.label} />
          )
        )}
      </Tabs>

      <TabContent>
        <Switch>
          <Route
            exact
            path="/board/:boardId/developer/list/:id"
            component={InstallableDetailView}
          />
          <Route
            path="/board/:boardId/developer/list"
            component={InstallablesView}
          />
          <Route
            path="/board/:boardId/developer/api-console/:apiKey"
            component={ApiConsole}
          />
          {dynamicSettingsModules.map((sm) => (
            <Route
              path={`/board/:boardId/developer/${sm.key}`}
              component={() => <DynamicDeveloperPageView url={sm.url} />}
            />
          ))}
          <Redirect to={`/board/${boardId}/developer/list`} />
        </Switch>
      </TabContent>
    </>
  );
};

const DynamicDeveloperPageView: React.FC<{ url: string }> = (props) => {
  return (
    <Box style={{ height: "100vh", marginTop: -20, marginLeft: -40 }}>
      <Iframe title="Installable" src={props.url}></Iframe>
    </Box>
  );
};

const INSTALLABLES_QUERY = gql`
  query DeveloperInstallablesQuery {
    viewer {
      account {
        person {
          developer {
            developer {
              id
              installables {
                id
                title
                installations(me: true) {
                  id
                  subscriptions {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CREATE_INSTALLABLE_MUTATION = gql`
  mutation CreateInstallableMutation($title: String!, $developerId: String!) {
    createInstallable(input: { title: $title, developerId: $developerId }) {
      created
      installable {
        id
      }
    }
  }
`;

const DELETE_INSTALLABLE_MUTATION = gql`
  mutation DeleteInstallableMutation($input: DeleteInstallableInput!) {
    deleteInstallable(input: $input) {
      deleted
    }
  }
`;

const InstallablesView: React.FC = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [deleteSubscription] = useDeleteSubscription();
  const [confirmDeleteInstallable, setConfirmDeleteInstallable] =
    React.useState<string | null>(null);

  const { data, loading, refetch } = useQuery<DeveloperInstallablesQuery>(
    INSTALLABLES_QUERY,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [deleteInstallableMutation] = useMutation<
    DeleteInstallableMutation,
    DeleteInstallableMutationVariables
  >(DELETE_INSTALLABLE_MUTATION);

  const [createInstallableMutation] = useMutation<
    CreateInstallableMutation,
    CreateInstallableMutationVariables
  >(CREATE_INSTALLABLE_MUTATION);

  const params = useParams<IRouteMatch>();
  const { boardId } = params;
  const [installableTitle, setInstallableTitle] = React.useState("");

  const [createInstallableModalOpen, setCreateInstallableModalOpen] =
    React.useState(false);

  const deleteInstallable = React.useCallback(async () => {
    if (!confirmDeleteInstallable) {
      return;
    }

    const installable =
      data?.viewer?.account?.person.developer.developer.installables.find(
        (installable) => installable.id === confirmDeleteInstallable
      );

    if (installable) {
      await Promise.all(
        installable.installations.map(
          async (installation) =>
            await Promise.all(
              installation.subscriptions.map(
                async (subscription) =>
                  await deleteSubscription({
                    variables: { subscriptionId: subscription.id },
                  })
              )
            )
        )
      );
    }

    await deleteInstallableMutation({
      variables: {
        input: {
          id: confirmDeleteInstallable,
        },
      },
    });

    refetch();
  }, [
    deleteInstallableMutation,
    confirmDeleteInstallable,
    refetch,
    deleteSubscription,
    data,
  ]);

  if (!data || loading) return <CircularProgress />;
  if (
    !(
      data &&
      data.viewer &&
      data.viewer.account &&
      data.viewer.account.person.developer
    )
  )
    return null;

  const createInstallable = async () => {
    if (
      !(
        data &&
        data.viewer &&
        data.viewer.account &&
        data.viewer.account.person.developer
      )
    )
      throw Error();

    const response = await createInstallableMutation({
      variables: {
        developerId: data.viewer.account.person.developer.developer.id,
        title: installableTitle,
      },
    });
    refetch();
    history.push(
      `/board/${boardId}/developer/list/${
        response.data!.createInstallable!.installable!.id
      }`
    );
  };

  return (
    <div>
      <Confirm
        open={!!confirmDeleteInstallable}
        title="Delete Installable"
        declineTitle={"Cancel"}
        acceptTitle={"Delete Installable"}
        message="Are you sure you want to delete this installable? This cannot be undone."
        handleClose={() => setConfirmDeleteInstallable(null)}
        handleAccept={() => {
          deleteInstallable();
          setConfirmDeleteInstallable(null);
        }}
      ></Confirm>
      <Confirm
        open={createInstallableModalOpen}
        title={"Create Installable"}
        declineTitle={"Cancel"}
        acceptTitle={"Create Installable"}
        message=""
        handleClose={() => setCreateInstallableModalOpen(false)}
        handleAccept={() => createInstallable()}
      >
        <div className="w-100 flex mb2">
          <TextField
            label="Title"
            variant="filled"
            className={"flex-grow-1"}
            onChange={(e) => setInstallableTitle(e.target.value)}
            value={installableTitle}
            data-testid="installable-title"
            id="installable-title"
          />
        </div>
      </Confirm>
      <div className="mv3 flex justify-between flex-row">
        <h4>Installables</h4>
        <Button
          buttonType="outline"
          width={300}
          onClick={() => setCreateInstallableModalOpen(true)}
        >
          Create new Installable
        </Button>
      </div>
      {!loading &&
      !data.viewer.account.person.developer.developer.installables.length ? (
        <div className="bg-light-gray pv5 ph4">
          <h2 className={"mb3"}>You have not created any installables</h2>
          <Spacer size="large" />
          <Button
            buttonType="outline"
            width={300}
            onClick={() => setCreateInstallableModalOpen(true)}
          >
            Create your first installable
          </Button>
        </div>
      ) : null}
      <Table>
        {data.viewer.account.person.developer.developer.installables.map(
          (installable) => (
            <TableRow>
              <TableCell width={65}>
                <Avatar className={classes.avatar} variant="square">
                  {installable.title?.split(" ").map((t) => t.split("")[0])}
                </Avatar>
              </TableCell>
              <TableCell>{installable.title}</TableCell>
              <TableCell width={100}>
                <FlexHorizontal>
                  <GrayFilledButton
                    onClick={() =>
                      history.push(
                        `/board/${boardId}/developer/list/${installable.id}`
                      )
                    }
                  >
                    Settings
                  </GrayFilledButton>
                  <Box width={12} />
                  <GrayFilledButton
                    onClick={() => setConfirmDeleteInstallable(installable.id)}
                  >
                    Delete
                  </GrayFilledButton>
                </FlexHorizontal>
              </TableCell>
            </TableRow>
          )
        )}
      </Table>
      <Spacer size="extraLarge" />
    </div>
  );
};

interface IInstallableDetailsViewProps {}
type InstallableDetailViewProps = IInstallableDetailsViewProps;

const INSTALLABLE_QUERY = gql`
  query DeveloperInstallableQuery($installableId: String!) {
    viewer {
      account {
        person {
          tenants {
            id
            boards {
              id
              title
            }
          }
        }
      }
    }
    installable(id: $installableId) {
      id
      title
      createdLong
      friendlyIdentifier
      installations(me: true) {
        id
        tenant {
          id
          boards {
            id
            title
          }
        }
        principal {
          id
          ... on InstallationPrincipal {
            apiCredentials {
              id
              created
              key
            }
          }
        }
      }
    }
  }
`;

const InstallableDetailView: React.FC<InstallableDetailViewProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const params = useParams<IRouteMatch>();
  const { boardId } = params;

  const { data, loading, refetch } = useQuery<
    DeveloperInstallableQuery,
    DeveloperInstallableQueryVariables
  >(INSTALLABLE_QUERY, {
    variables: {
      installableId: id,
    },
    fetchPolicy: "no-cache",
  });

  const refetchApiKeys = () => refetch();

  const [title, setTitle] = React.useState("");

  React.useEffect(() => {
    if (data && data.installable) {
      setTitle(data.installable.title || "");
    }
  }, [data]);

  const [createApiKeyModalShown, setCreateApiKeyModalShown] =
    React.useState(false);

  if (!data || loading) return <CircularProgress />;

  const apiKeys = data.installable.installations.reduce(
    (prev: IApiKeyTableApiKey[], installation) =>
      installation?.principal.__typename === "InstallationPrincipal" &&
      installation?.principal?.apiCredentials?.length
        ? [
            ...prev,
            ...installation?.principal?.apiCredentials.map((apiCredential) => ({
              id: apiCredential.id,
              key: apiCredential.key,
              created: parseInt(apiCredential.created || ""),
              boards: installation?.tenant.boards.map((board) => board.title),
            })),
          ]
        : prev,
    []
  ) as IApiKeyTableApiKey[];

  const infoBox = (
    <Box style={{ width: "50%" }}>
      <Table>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>{data.installable.friendlyIdentifier}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Title</TableCell>
          <TableCell>{title}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Created</TableCell>
          <TableCell>
            {dayjs(data.installable.createdLong).format(
              "MMMM DD, YYYY HH:mm a"
            )}
          </TableCell>
        </TableRow>
      </Table>
    </Box>
  );

  const createApiKey = () => {
    setCreateApiKeyModalShown(true);
  };

  return (
    <Box>
      <Box style={{ marginBottom: 20 }}>
        <h4>{data.installable.title}</h4>
      </Box>
      {infoBox}
      {createApiKeyModalShown ? (
        <CreateApiKeyModal
          installableId={data.installable.id}
          show={createApiKeyModalShown}
          onHide={() => setCreateApiKeyModalShown(false)}
          onKeyCreated={() => refetchApiKeys()}
        />
      ) : null}
      <ApiCredentialTable
        boardId={boardId}
        apiKeys={apiKeys}
        loading={false}
        refreshApiKeys={refetchApiKeys}
        showApiKeyModal={createApiKey}
        onCreateApiKey={createApiKey}
      />
    </Box>
  );
};
