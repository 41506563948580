import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { FilledButton } from "../../../components/FilledButton";
import { InstallableIcon } from "../../../components/InstallableIcon";
import { OutlinedButton } from "../../../components/OutlinedButton";
import { Spacer } from "../../../components/Spacer";
import { useDeleteSubscription } from "../../../hooks/useDeleteSubscription";
import { useGetInstallableSubscriptions } from "../hooks/useGetInstallableSubscriptions";
import {
  Body,
  Modal,
  Small,
  colors,
  useToasts,
} from "@vestaboard/installables";
import { GET_INSTALLED_INSTALLABLES } from "../tabs/Installed";

const useStyles = makeStyles({
  buttonRow: {
    display: "flex",
    width: "100%",
  },
  title: {
    fontWeight: "bold",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
});

interface IDeleteInstallable {
  boardId: string;
  tenantId: string;
  subscriptionId: string;
  title: string;
  icon: string;
  installableId: string;
  handleClose: () => void;
  handleDeleted?: () => void;
  visible: boolean;
}

const MESSAGE_SET_CREATOR_IDS = [
  "3a62f5c3-b771-4ba3-b3d3-4584d49ac6fd",
  "2c04580e-e5be-4661-b232-7ea1c9e90c44",
];

const GUEST_SEND_ID = "1d7b0c8c-b6dc-4091-88a9-b099e78eeb0a";
const BLENDED_ID = "84b5096c-b9be-42d0-a88f-94226710adb5";

export const DeleteInstallable = (props: IDeleteInstallable) => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const [deleteSubscriptionMutation] = useDeleteSubscription();

  const deleteSubscription = async () => {
    try {
      await deleteSubscriptionMutation({
        variables: {
          subscriptionId: props.subscriptionId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_INSTALLED_INSTALLABLES,
            variables: {
              boardId: props.boardId,
            },
          },
          {
            query: useGetInstallableSubscriptions.query,
            variables: {
              boardId: props.boardId,
              tenantId: props.tenantId,
            },
          },
        ],
      });
      addToast(`Channel removed`, {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      addToast(`Error removing channel`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <Modal onClose={props.handleClose} visible={props.visible} fullScreenMobile>
      <Box className={classes.modalContent}>
        <Body>Are you sure you want to delete this channel?</Body>
        <Spacer height={20} />
        <InstallableIcon
          icon={props.icon}
          title={props.title as string}
          size="small"
        />
        <Spacer height={14} />
        <Typography className={classes.title}>{props.title}</Typography>
        {GUEST_SEND_ID === props.installableId ||
        BLENDED_ID === props.installableId ||
        MESSAGE_SET_CREATOR_IDS.includes(props.installableId) ? (
          <>
            <Spacer height={12} />
            <Small color={colors.white}>
              {GUEST_SEND_ID === props.installableId
                ? "All saved data will be deleted and users will lose access to it."
                : BLENDED_ID === props.installableId
                ? "All saved channels to your Blended list will be deleted and all users will lose access to it."
                : MESSAGE_SET_CREATOR_IDS.includes(props.installableId)
                ? "Any saved messages will be deleted and all users will lose access to it."
                : ""}
            </Small>
          </>
        ) : (
          <></>
        )}
        <Spacer height={36} />
        <Box className={classes.buttonRow}>
          <OutlinedButton fullWidth onClick={() => props.handleClose()}>
            Cancel
          </OutlinedButton>
          <Spacer width={80} />
          <FilledButton
            fullWidth
            onClick={async () => {
              await deleteSubscription();

              props.handleClose();

              if (props.handleDeleted) {
                props.handleDeleted();
              }
            }}
          >
            Yes, Delete
          </FilledButton>
        </Box>
      </Box>
    </Modal>
  );
};
