import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Button, Input, useToasts } from "@vestaboard/installables";
import { useCallback } from "react";

interface ICopyTextBox {
  value: string;
  label: string;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  copySpace: {
    width: 12,
  },
  label: {
    padding: 4,
  },
});

export const CopyTextBox = (props: ICopyTextBox) => {
  const classes = useStyles();
  const { addToast } = useToasts();

  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(props.value);
      addToast(`Copied ${props.label} to your clipboard.`, {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      addToast(`Unable to copy ${props.label} to your clipboard.`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [addToast, props.label, props.value]);

  return (
    <>
      <Typography className={classes.label} variant="body2">
        {props.label}
      </Typography>
      <Box className={classes.root}>
        <Input
          value={props.value}
          onValueChange={() => {}}
          placeholder={props.label}
        />
        <Box className={classes.copySpace} />
        <Button onClick={copyToClipboard} buttonType="outline" width={100}>
          Copy
        </Button>
      </Box>
    </>
  );
};
