import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { TemplatesQuery, TemplatesQueryVariables } from "../gql";

const TEMPLATES_QUERY = gql`
  # TODO: Note: cursor pagination with infinite scroll following relay spec would work better in this use case, or we can add page numbers to the bottom? Mobile app is not really paginating
  # also the ability to filter colorTemplateCategories to only the category you want to prevent overfetching
  query TemplatesQuery($page: Int, $perPage: Int) {
    colorTemplateCategories {
      id
      title
      colorTemplatesLength
      colorTemplates(page: $page, perPage: $perPage) {
        id
        message {
          id
          text
          formatted
        }
      }
    }
  }
`;

export const useTemplates = (page = 0, perPage = 10) => {
  return useQuery<TemplatesQuery, TemplatesQueryVariables>(TEMPLATES_QUERY, {
    variables: {
      page,
      perPage,
    },
  });
};
