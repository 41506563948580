import React, { useEffect, useRef } from 'react';
import SlickSlider, {ResponsiveObject} from "react-slick";
import SliderArrow from "./SliderArrow";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  slider: {
    '& .slick-prev, & .slick-next': {
      position: 'absolute',
      width: 'inherit',
      height: 'inherit',
      color: 'inherit',
      zIndex: 1
    },
    '& .slick-prev': {
      left: -10
    },
    '& .slick-next': {
      right: -10
    },
    '& .slick-prev:before, & .slick-next:before': {
      content: '""'
    },
    '& .slick-list': {
      zIndex: 0
    }
  }
})

export interface SliderProps {
  slidesToScroll?: number | undefined;
  slidesToShow?: number | undefined;
  speed?: number | undefined;
  responsive?: ResponsiveObject[] | undefined;
  noArrows?: boolean;
  animate?: boolean
}

const Slider: React.FC<SliderProps> = props => {
  const classes = useStyles();
  const ref = useRef<SlickSlider>(null);
  const animate = props.animate
  useEffect(() => {
    if (animate) {
      const interval = setInterval(() => {
      ref.current?.slickNext();
      }, 2000)
      return () => {
        clearInterval(interval)
      }
    } else {
      return
    }
  }, [ref, animate])
  return (
    <SlickSlider 
    ref={ref}
    
    {...props} prevArrow={props.noArrows ? <></> : <SliderArrow left />} nextArrow={props.noArrows ? <></> :<SliderArrow right />} className={classes.slider} />
  );
};

export default Slider;
