import { useState } from "react";

export const useModal = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal((showModal) => !showModal);

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return {
    showModal,
    openModal,
    closeModal,
    toggleModal,
  };
};
