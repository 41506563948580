import { Box, ButtonBase, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import HistoryIcon from "@mui/icons-material/History";
import NoteIcon from "@mui/icons-material/Note";

export enum ActiveMessageType {
  History = "History",
  Favorites = "Favorites",
  Drafts = "Drafts",
}

interface IMessageTypeToggle {
  active: ActiveMessageType;
  setActive: (active: ActiveMessageType) => void;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: "#303537",
    display: "flex",
    borderRadius: 8,
    maxWidth: 220,
    marginLeft: 12,
  },
  button: {
    color: "#6c6f71",
    padding: 12,
    borderRadius: 8,
  },
  activeButton: {
    borderRadius: 8,
    backgroundColor: "#3e4447",
    color: "#FFF",
    padding: 12,
    flex: 1,
    svg: {
      marginRight: 12,
    },
  },
  text: {
    marginLeft: 12,
  },
});

export const MessageTypeToggle = (props: IMessageTypeToggle) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <ButtonBase
        className={
          props.active === ActiveMessageType.History
            ? [classes.button, classes.activeButton].join(" ")
            : classes.button
        }
        onClick={() => props.setActive(ActiveMessageType.History)}
      >
        <HistoryIcon />
        {props.active === ActiveMessageType.History ? (
          <Typography className={classes.text}>History</Typography>
        ) : null}
      </ButtonBase>
      <ButtonBase
        className={
          props.active === ActiveMessageType.Favorites
            ? [classes.button, classes.activeButton].join(" ")
            : classes.button
        }
        onClick={() => props.setActive(ActiveMessageType.Favorites)}
      >
        <BookmarkIcon />
        {props.active === ActiveMessageType.Favorites ? (
          <Typography className={classes.text}>Favorites</Typography>
        ) : null}
      </ButtonBase>
      <ButtonBase
        className={
          props.active === ActiveMessageType.Drafts
            ? [classes.button, classes.activeButton].join(" ")
            : classes.button
        }
        onClick={() => props.setActive(ActiveMessageType.Drafts)}
      >
        <NoteIcon />
        {props.active === ActiveMessageType.Drafts ? (
          <Typography className={classes.text}>Drafts</Typography>
        ) : null}
      </ButtonBase>
    </Box>
  );
};
