import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  BoardMessageAppearancesQuery,
  BoardMessageAppearancesQueryVariables,
} from "../gql";

const MESSAGE_APPEARANCES_QUERY = gql`
  query BoardMessageAppearancesQuery(
    $boardId: String!
    $page: Int!
    $sources: String
  ) {
    board(id: $boardId) {
      id
      boardStyle
      history(page: $page, perPage: 30, sources: $sources) {
        id
        appeared
        message {
          id
          text
          formatted
          created
          authorFormatted
          sendAt
        }
      }
    }
  }
`;

interface IUseMessageHistory {
  boardId: string;
  page?: number;
  sources?: string[];
}

export const useMessageHistory = ({
  boardId,
  page,
  sources,
}: IUseMessageHistory) => {
  return useQuery<
    BoardMessageAppearancesQuery,
    BoardMessageAppearancesQueryVariables
  >(MESSAGE_APPEARANCES_QUERY, {
    skip: !boardId,
    variables: {
      boardId: boardId || "",
      page: page || 0,
      sources: (sources || []).join(","),
    },
    fetchPolicy: "network-only",
  });
};
