import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  GetMarketplaceListingFeatureTypeQuery,
  GetMarketplaceListingFeatureTypeQueryVariables,
  ListMarketplaceListingFeatureTypesQueryVariables,
  ListMarketplaceListingFeatureTypesQuery,
} from "../gql";

const MARKET_PLACE_LISTING_BY_TYPE_QUERY = gql`
  query GetMarketplaceListingFeatureType($id: String!) {
    marketplaceListingFeatureType(id: $id) {
      id
      title
      items {
        id
        sortOrder
        marketplaceListing {
          id
          title
          description
          shortDescription
          icon
          free
          installable {
            id
            icon
            installationsAreSingleton
          }
        }
      }
    }
  }
`;

const MARKET_PLACE_LISTINGS_QUERY = gql`
  query ListMarketplaceListingFeatureTypes {
    marketplaceListingFeatureTypes(input: { includeDynamic: true }) {
      id
      title
    }
  }
`;

export const useMarketPlaceFeatureTypes = () => {
  return useQuery<
    ListMarketplaceListingFeatureTypesQuery,
    ListMarketplaceListingFeatureTypesQueryVariables
  >(MARKET_PLACE_LISTINGS_QUERY);
};

export const useMarketPlaceListingsByFeatureType = (id: string) => {
  return useQuery<
    GetMarketplaceListingFeatureTypeQuery,
    GetMarketplaceListingFeatureTypeQueryVariables
  >(MARKET_PLACE_LISTING_BY_TYPE_QUERY, { variables: { id } });
};
