import * as React from "react";

import { CircularProgress, Tab, Tabs, TextField } from "@mui/material";

import { ApiKeySelectorQuery } from "../../../gql";
import { Field } from "../../../components/Field";
import JSONTree from "react-json-tree";
import { Select } from "../../../components/Select";
import axios from "axios";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Button, Spacer } from "@vestaboard/installables";
import { PLATFORM_BASE_URL } from "../../../config";

interface IApiConsoleProps {}

type ApiConsoleProps = IApiConsoleProps;

enum ApiConsoleRequest {
  Viewer = "GetViewer",
  Subscriptions = "GetSubscriptions",
  PostToSubscription = "PostSubscriptionMessage",
}

interface ApiConsoleResponse {
  curl: string;
  method: string;
  result: string;
}

const makeApiConsoleRequest = async (
  key: string,
  secret: string,
  method: ApiConsoleRequest,
  params: any
): Promise<ApiConsoleResponse> => {
  const response = await axios.post(`${PLATFORM_BASE_URL}/api-console`, {
    key,
    secret,
    method,
    params,
  });

  return response.data as ApiConsoleResponse;
};

interface IParams {
  apiKey: string;
  boardId: string;
}

export const ApiConsole: React.FC<ApiConsoleProps> = (props) => {
  const params = useParams<IParams>();
  const [apiSecret, setApiSecret] = React.useState("");
  const [operation, setOperation] = React.useState(ApiConsoleRequest.Viewer);
  const [parameters, setParameters] = React.useState<any>({});
  const [curl, setCurl] = React.useState("");
  const [resultTabIndex, setResultTabIndex] = React.useState(0);
  const { apiKey } = params;

  const setParameter = (key: string, value: any | null) => {
    setParameters({
      ...parameters,
      [key]: value,
    });
  };

  const credentials = [
    <TextField
      variant="filled"
      fullWidth
      label="API Key"
      value={apiKey}
      data-testid="api-key"
      id="api-key"
      onChange={() => {}}
    />,
    <TextField
      variant="filled"
      type="password"
      label={"API Secret"}
      onChange={(e) => setApiSecret(e.target.value)}
      value={apiSecret}
      data-testid="api-secret"
      id={"api-secret"}
      fullWidth
    />,
  ];

  const [apiResponse, setApiResponse] = React.useState<any | null>(null);

  const selectOperation = (operation: ApiConsoleRequest) => {
    setOperation(operation);
    setCurl("");
    setApiResponse(null);
  };

  const submitConsoleRequest = async () => {
    setApiResponse(null);
    const response = await makeApiConsoleRequest(
      apiKey,
      apiSecret,
      operation,
      parameters
    );
    setApiResponse(JSON.parse(response.result));
    setCurl(response.curl);
  };

  const credentialsPresent = !!apiKey && !!apiSecret;

  let parametersForMethod: React.ReactNode[] = [];
  let otherParamsPresent: boolean = false;

  switch (operation) {
    case ApiConsoleRequest.PostToSubscription:
      otherParamsPresent =
        !!parameters["subscriptionId"] && !!parameters["text"];
      parametersForMethod = [
        <TextField
          variant="filled"
          label={"Subscription ID"}
          onChange={(e) => setParameter("subscriptionId", e.target.value)}
          value={parameters["subscriptionId"]}
          className={"w-100"}
          data-testid="subscription-id"
          id={"subscription-id"}
        />,
        <TextField
          variant="filled"
          label={"Message Text"}
          multiline={true}
          rows={9}
          onChange={(e) => setParameter("text", e.target.value)}
          value={parameters["text"]}
          className={"w-100"}
          data-testid="message-text"
          id={"message-text"}
        />,
      ];
      break;
    default:
      otherParamsPresent = true;
      break;
  }

  const submitDisabled = !(credentialsPresent && otherParamsPresent);

  const tabProps = (currentVal: string, val: string) => ({
    value: val,
    style: {
      color: currentVal === val ? "white" : undefined,
      transition: "color 500ms",
    },
  });

  return (
    <>
      <h4>API Console</h4>
      <Spacer size="extraLarge" />
      <div className={"flex-l flex-wrap justify-between"}>
        <div>
          {/* Desktop (pill) tabs */}
          <div className={"dn db-l mr3-l"} style={{ minWidth: 250 }}>
            <Tabs
              value={operation || 0}
              indicatorColor={"secondary"}
              centered={false}
              orientation={"vertical"}
              onChange={(e, v) => selectOperation(v as ApiConsoleRequest)}
              TabIndicatorProps={{
                style: {
                  width: "100%",
                  borderRadius: 5,
                  zIndex: -1,
                },
              }}
            >
              <Tab
                {...tabProps(operation, ApiConsoleRequest.Viewer)}
                label={"GET Viewer"}
              />
              <Tab
                {...tabProps(operation, ApiConsoleRequest.Subscriptions)}
                label={"GET Subscriptions"}
              />
              <Tab
                {...tabProps(operation, ApiConsoleRequest.PostToSubscription)}
                label={"POST Message"}
              />
            </Tabs>
          </div>
          {/* Mobile tabs */}
          <div className={"db mb2 mb0-l dn-l"}>
            <Tabs
              value={operation || 0}
              onChange={(_, v) => selectOperation(v as ApiConsoleRequest)}
            >
              <Tab value={ApiConsoleRequest.Viewer} label={"GET Viewer"} />,
              <Tab
                value={ApiConsoleRequest.Subscriptions}
                label={"GET Subscriptions"}
              />
              ,
              <Tab
                value={ApiConsoleRequest.PostToSubscription}
                label={"POST Message"}
              />
            </Tabs>
          </div>
        </div>
        <div className={"flex-grow-1"}>
          <div className="bg-light-gray pv4 ph4">
            <form className={"mb4"}>
              {credentials.map((c, index) => (
                <div className={"mb2"} key={index}>
                  {c}
                </div>
              ))}
              {parametersForMethod.map((p, index) => (
                <div className={"mb2"} key={index}>
                  {p}
                </div>
              ))}
            </form>
            <Button
              disabled={submitDisabled}
              buttonType="white"
              width={180}
              onClick={() => submitConsoleRequest()}
            >
              Send
            </Button>
          </div>
          {curl && apiResponse ? (
            <div className={"mt3"}>
              <Tabs
                indicatorColor={"primary"}
                value={resultTabIndex}
                onChange={(_, value) => setResultTabIndex(value)}
              >
                <Tab value={0} label="Response" />
                <Tab value={1} label="cURL Request" />
              </Tabs>
              <div>
                {resultTabIndex === 0 && (
                  <div className="mt4">
                    <div
                      className="pa3"
                      style={{
                        background: "#171818",
                      }}
                    >
                      <JSONTree
                        shouldExpandNode={() => true}
                        sortObjectKeys
                        data={apiResponse}
                        invertTheme={false}
                        theme={{
                          scheme: "monokai",
                          author: "wimer hazenberg (http://www.monokai.nl)",
                          base00: "#171818",
                          base01: "#383830",
                          base02: "#49483e",
                          base03: "#333",
                          base04: "#49483e",
                          base05: "#181919",
                          base06: "#181919",
                          base07: "#181919",
                          base08: "#AAA",
                          base09: "#ffffff",
                          base0A: "#ffffff",
                          base0B: "#ffffff",
                          base0C: "#a1efe4",
                          base0D: "#999",
                          base0E: "#fff",
                          base0F: "#fff",
                        }}
                      />
                    </div>
                  </div>
                )}
                {resultTabIndex === 1 && (
                  <div className="pv3">
                    <textarea
                      value={curl}
                      rows={6}
                      className={"w-100"}
                      style={{
                        background: "#171818",
                        border: 0,
                        padding: 24,
                        color: "#FFF",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Spacer size="extraLarge" />
    </>
  );
};

interface IApiKeySelectorProps {
  label?: string;
  onChange: (id: string, key: string) => void;
}

type ApiKeySelectorProps = IApiKeySelectorProps;

const apiKeyQuery = gql`
  query ApiKeySelectorQuery {
    apiCredentials {
      id
      key
    }
  }
`;

const ApiKeySelector: React.FC<ApiKeySelectorProps> = (props) => {
  const { data, loading } = useQuery<ApiKeySelectorQuery>(apiKeyQuery);
  const [apiKey, setApiKey] = React.useState("");

  const selectApiKey = (id: string) => {
    if (data && !loading) {
      const matchingKey = data.apiCredentials.filter(
        (credential) => credential.id === id
      )[0];
      setApiKey(id);
      if (matchingKey) {
        props.onChange(id, matchingKey.key);
      }
    }
  };

  if (!data || loading) {
    return <CircularProgress />;
  }

  return (
    <Field label={props.label as string}>
      <Select
        fullWidth
        value={apiKey}
        onValueChange={selectApiKey}
        options={data.apiCredentials.map((apiCredential) => ({
          key: apiCredential.id,
          value: apiCredential.key,
        }))}
      />
    </Field>
  );
};
