import {
  BORDER_COLORS,
  BREAKPOINT_EXTRA_SMALL,
  BREAKPOINT_MEDIUM,
} from "../../config";
import { Box, CircularProgress } from "@mui/material";
import { makeStyles} from '@mui/styles'

import React, { useState } from "react";
import {
  IBoard,
  Spacer,
  ToastProvider,
  useToasts,
} from "@vestaboard/installables";
import { MessageCard } from "../../components/MessageCard";
import { usePersonFavoritesList } from "../../hooks/usePersonFavoritesList";
import { useParams } from "react-router";
import { useGetDrafts } from "../../hooks/useGetDrafts";
import { Confirm } from "../../components/Confirm";
import { useDeleteDraft } from "../../hooks/useDeleteDraft";
import { SimplePagination } from "../../components/SimplePagination";
import { EndOfList } from "../../components/EndOfList";

interface IMessagesListProps {
  boardId: string;
}

type MessagesListProps = IMessagesListProps;

interface IRouteMatch {
  boardId: string;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "5rem",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      padding: "1rem",
    },
  },
  boardContainer: {
    position: "relative",
    marginBottom: 24,
    width: "30%",
    [`@media(max-width: ${BREAKPOINT_MEDIUM})`]: {
      width: "48%",
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
});

export const Drafts: React.FC<MessagesListProps> = () => {
  const params = useParams<IRouteMatch>();
  const { addToast } = useToasts();
  const { favorites } = usePersonFavoritesList();
  const [deleteDraftId, setDeleteDraftId] = useState<string | null>(null);
  const [deleteDraft] = useDeleteDraft();
  const [currentPage, setCurrentPage] = React.useState(1);
  const drafts = useGetDrafts(currentPage - 1);

  const classes = useStyles();

  if (!drafts?.data) {
    return <CircularProgress />;
  }

  const messages = drafts.data.viewer?.account?.person.drafts
    ? drafts.data.viewer?.account?.person.drafts.filter(
        (m) => m && m.message && !!m.message.text && m.message.text !== " "
      )
    : [];

  const pagination = (
    <Box
      alignSelf="center"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      paddingTop={"40px"}
    >
      <SimplePagination
        disablePrevious={currentPage === 1}
        previous={() => {
          setCurrentPage((page) => page - 1);
        }}
        next={() => {
          setCurrentPage((page) => page + 1);
        }}
        disableNext={!(drafts.data.viewer?.account?.person.drafts.length || 0)}
      />
      <Spacer size="extraLarge" />
    </Box>
  );

  return (
    <ToastProvider offset={80}>
      <>
        <Confirm
          title="Discard Draft"
          message={` Are you sure you want to discard this draft? This cannot be undone.`}
          open={!!deleteDraftId}
          handleClose={() => setDeleteDraftId(null)}
          handleAccept={async () => {
            const draftId = `${deleteDraftId}`;

            setDeleteDraftId(null);

            try {
              await deleteDraft({
                variables: {
                  input: {
                    draftId: draftId,
                  },
                },
              });

              addToast(`The draft was discarded`, {
                appearance: "error",
                autoDismiss: true,
              });

              drafts.refetch();
            } catch (err) {
              addToast(`There was an error discarding this draft`, {
                appearance: "error",
                autoDismiss: true,
              });
            }
          }}
        />
        {!drafts.loading &&
        !drafts.data.viewer?.account?.person.drafts.length ? (
          <>
            <Spacer size="extraLarge" />
            <EndOfList>No Drafts</EndOfList>
          </>
        ) : null}
        {!(!drafts.loading && drafts.data) ? (
          <div className={classes.container}>
            {!drafts.loading ? null : <CircularProgress />}
          </div>
        ) : (
          <>
            <Box className={classes.container}>
              {messages.map((ma, index) => {
                if (ma && ma.message) {
                  const isFavorite = favorites.includes(ma.message.id);
                  const message = ma.message;

                  return (
                    <div className={classes.boardContainer} key={ma.id}>
                      <MessageCard
                        borderColor={
                          BORDER_COLORS[index % BORDER_COLORS.length]
                        }
                        isFavorite={isFavorite}
                        text={message.text || ""}
                        formatted={message.formatted as IBoard}
                        id={message.id}
                        boardId={params.boardId}
                        created={message.created}
                        author={message.authorFormatted}
                        isDraft={true}
                        handleDiscard={() => {
                          setDeleteDraftId(ma.id);
                        }}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              {/* Fix spacing if there are two cards on a row */}
              {messages && 3 ? (
                <div className={classes.boardContainer} />
              ) : null}
            </Box>
            {pagination}
          </>
        )}
      </>
    </ToastProvider>
  );
};
