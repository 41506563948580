import { useMutation, useQuery } from "@apollo/client";
import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  Button,
  LoadMask,
  ModalDialog,
  SubTitle2,
  useToasts,
} from "@vestaboard/installables";
import { SelectMenu } from "@vestaboard/installables/lib/components/SelectMenu";
import gql from "graphql-tag";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  AddMessageSetCreatorMessageMutation,
  AddMessageSetCreatorMessageMutationVariables,
  GetMessageSetCreatorSubscriptionsQuery,
  GetMessageSetCreatorSubscriptionsQueryVariables,
} from "../gql";

const QUERY = gql`
  query GetMessageSetCreatorSubscriptions($boardId: String!) {
    board(id: $boardId) {
      id
      title
      subscriptionSet {
        id
        subscriptions(
          input: { installableId: "2c04580e-e5be-4661-b232-7ea1c9e90c44" }
        ) {
          id
          title
          icon
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation AddMessageSetCreatorMessage(
    $input: AddMessageSetCreatorMessageInput!
  ) {
    addMessageSetCreatorMessage(input: $input) {
      success
    }
  }
`;

interface IMessageSetCreatorSendModal {
  visible: boolean;
  messageId: string;
  onClose: () => void;
}

interface IMessageSetCreatorSendContent {
  selected: string[];
  setSelected: (selected: string[]) => void;
  save: (selected?: string[]) => Promise<void>;
  setDisplayType: (displayType: string | null) => void;
}

const MessageSetCreatorSendContent = (props: IMessageSetCreatorSendContent) => {
  const params = useParams<{ boardId: string }>();
  const { data, loading } = useQuery<
    GetMessageSetCreatorSubscriptionsQuery,
    GetMessageSetCreatorSubscriptionsQueryVariables
  >(QUERY, {
    variables: {
      boardId: params.boardId,
    },
  });
  const { save, setSelected, setDisplayType } = props;

  const subscriptions = useMemo(() => {
    return data?.board.subscriptionSet.subscriptions || [];
  }, [data]);

  useEffect(() => {
    if (subscriptions.length === 1) {
      setDisplayType("hasSubscription");
      save([subscriptions[0]?.id]);
    } else if (!subscriptions.length) {
      setDisplayType("noSubscription");
    } else {
      setDisplayType("hasSubscription");
    }
  }, [subscriptions, save, setSelected, setDisplayType]);

  return loading || subscriptions.length === 1 ? (
    <Box
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : subscriptions.length !== 0 ? (
    <SelectMenu
      options={(subscriptions || []).map((subscription) => ({
        id: subscription.id || "",
        name: subscription.title || "Message Set Creator",
      }))}
      selected={props.selected}
      onPress={(id) => {
        if (props.selected.includes(id)) {
          props.setSelected(props.selected.filter((item) => item !== id));
        } else {
          props.setSelected([...props.selected, id]);
        }
      }}
    />
  ) : (
    <Box>
      <Typography>
        Build your own channel of recurring content. Add messages and set a
        frequency of your choosing.
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  modalBtnGroup: {
    display: "flex",
    gap: 16,
    justifyContent: "space-between",
    marginTop: 24,
    width: "100%",
  },
  userContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
    flexDirection: "row",
    flex: 1,
  },
  userButton: {
    width: "100%",
    height: 50,
  },
});

export const MessageSetCreatorSendModal = (
  props: IMessageSetCreatorSendModal
) => {
  const toasts = useToasts();
  const classes = useStyles();
  const [selected, setSelected] = useState<string[]>([]);
  const [displayType, setDisplayType] = useState<string | null>(null);
  const [addMessageMutation, { loading }] = useMutation<
    AddMessageSetCreatorMessageMutation,
    AddMessageSetCreatorMessageMutationVariables
  >(MUTATION);
  const { boardId } = useParams<{ boardId: string }>();
  const history = useHistory();
  const { onClose, messageId } = props;

  const save = useCallback(
    async (subscriptionIds?: string[]) => {
      const id = `${messageId}`;

      onClose();

      try {
        await addMessageMutation({
          variables: {
            input: {
              subscriptionIds: selected?.length
                ? selected
                : Array.isArray(subscriptionIds)
                ? subscriptionIds
                : [],
              messageId: id,
            },
          },
        });

        toasts.addToast(
          `Message added to your ${
            selected.length > 1 ? "message sets" : "message set"
          }`,
          { appearance: "success" }
        );
      } catch (err) {
        toasts.addToast(
          `There was an error adding this message to your ${
            selected.length > 1 ? "message sets" : "message set"
          }`,
          { appearance: "error" }
        );
      }

      setSelected([]);
    },
    [addMessageMutation, onClose, messageId, selected, toasts]
  );

  return (
    <>
      {loading ? <LoadMask /> : null}
      <ModalDialog
        visible={props.visible}
        onClose={props.onClose}
        fullScreenMobile={true}
        header={
          displayType === "noSubscription" ? (
            <SubTitle2>Vestaboard+</SubTitle2>
          ) : (
            <SubTitle2>Add Message To</SubTitle2>
          )
        }
        footer={
          <Box className={classes.modalBtnGroup}>
            <Button buttonType="outline" onClick={props.onClose}>
              Cancel
            </Button>
            {displayType === "noSubscription" ? (
              <Button
                buttonType="white"
                onClick={() => {
                  history.push(
                    `/board/${boardId}/installable/532bb70a-f36e-425b-a20b-2fdc2fbb5a6f`
                  );
                  props.onClose();
                }}
              >
                Learn More
              </Button>
            ) : (
              <Button
                buttonType="white"
                onClick={save}
                disabled={selected.length < 1}
              >
                Add
              </Button>
            )}
          </Box>
        }
      >
        {props.visible ? (
          <MessageSetCreatorSendContent
            selected={selected}
            setSelected={setSelected}
            save={save}
            setDisplayType={setDisplayType}
          />
        ) : null}
      </ModalDialog>
    </>
  );
};
