import * as React from "react";

import { Box, Button, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  BREAKPOINT_SMALL,
  BREAKPOINT_EXTRA_EXTRA_SMALL,
} from "../../../config";
import { ROLE_TYPES } from "../../../config";
import { useFeatures } from "../../../hooks/useFeatures";
import { useToasts } from "@vestaboard/installables";
import { useUpdateUserRoleV2 } from "../../../hooks/useUpdateUserRoleV2";
import { Direction } from "../../../gql";

interface IUserRoleV2 {
  role: {
    id: string;
    permission: string;
  };
  disabled?: boolean;
  boardId: string;
  personId: string;
}


const useStyles = makeStyles({
  roleBox: {
    flex: 1,
    paddingLeft: 24,
    paddingTop: 1,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      paddingLeft: 0,
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_EXTRA_SMALL})`]: {
      flex: "none",
    },
  },
  role: {
    textTransform: "none",
    color: '#ffffff',
    opacity: 0.8,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      fontSize: 14,
      paddingBottom: 8,
      paddingTop: 8,
    },
    "&:disabled": {
      color: "#ffffff",
    },
  },
  editRoleIcon: {
    height: 12,
    marginLeft: 4,
  },
});

export const UserRoleV2 = (props: IUserRoleV2) => {
  const { features } = useFeatures();
  const [updateUserRoleV2] = useUpdateUserRoleV2()
  const [role, setRole] = React.useState<string>(props.role.permission);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const { addToast } = useToasts();

  const handleRoleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isDisabledChangeRole =
    props.disabled || (!features.manageOwners && role === "Owner");

  return (
    <Box className={classes.roleBox}>
      <Button
        className={classes.role}
        onClick={handleRoleClick}
        disabled={isDisabledChangeRole}
      >
        {role}
        {!isDisabledChangeRole && (
          <img
            src="/icons/icon-edit.svg"
            alt="Edit Role"
            className={classes.editRoleIcon}
          />
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {ROLE_TYPES.filter(
          (role) => role !== "Owner" || features.manageOwners
        ).map((roleType) => (
          <MenuItem
            onClick={async () => {
              setRole(roleType);
              handleClose();

              if (role === roleType) {
                return;
              }

              try {
                await updateUserRoleV2({
                  variables: {
                    input: {
                      id:props.role.id,
                      personId: props.personId,
                      boardId: props.boardId,
                      permission: role === "Owner" ? Direction.Owner : role === "Admin" ? Direction.Admin : Direction.User,
                    },
                  },
                });

                addToast(`Updated user role`, {
                  appearance: "success",
                  autoDismiss: true,
                });
              } catch (err) {
                addToast(`There was an error updating this user role`, {
                  appearance: "error",
                  autoDismiss: true,
                });

                setRole(props.role.permission);
              }
            }}
            key={roleType}
          >
            {roleType}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
