import { ArrowDownward, Close } from "@mui/icons-material";
import {
  Body,
  Button,
  Spacer,
  fontFamily,
  responsive,
  useToasts,
} from "@vestaboard/installables";
import {
  Box,
  Collapse,
  Fade,
  Button as MUIButton,
  Typography,
} from "@mui/material";
import {
  CSSProperties,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { makeStyles } from "@mui/styles";
import { useCopy } from "../../../hooks/useCopy";
import { useMediaQuery } from "react-responsive";
import { usePermissions } from "../../../hooks/usePermissions";
import { useReferralCode } from "../../../hooks/useReferralCode";

export const REFERRAL_BAR_HEIGHT = 48;
const useStyles = makeStyles((theme) => ({
  referContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100vw",
    zIndex: 99,
    backgroundColor: "#0a0a0b",
    position: "fixed",
    top: 0,
    height: REFERRAL_BAR_HEIGHT,
    justifyContent: "center",
    alignItems: "center",
  },
  animationContainer: {
    display: "flex",
    width: "100vw",
    position: "relative",
    zIndex: 99,
    marginBottom: REFERRAL_BAR_HEIGHT,
  },
  leftContainer: {
    display: "flex",
    minWidth: 40,
    flex: 1,
    paddingLeft: 40,
    [`@media(max-width:1258px)`]: {
      paddingLeft: 30,
    },
    [`@media(max-width:960px)`]: {
      paddingLeft: 0,
    },
  },
  centerContainer: {
    display: "flex",
    flex: 12,
    justifyContent: "center",
    alignItems: "center",
    [`@media(max-width:960px)`]: {
      flex: 4,
    },
  },
  centerContent: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  rightContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingRight: 40,
    [`@media(max-width:1258px)`]: {
      paddingRight: 30,
    },
    [`@media(max-width:960px)`]: {
      paddingRight: 0,
    },
  },
  referButton: {
    backgroundColor: "#1e2022",
    textTransform: "none",
    marginRight: 8,
    padding: "6px 8px",
    fontSize: 14,
    marginLeft: 16,
    borderRadius: 8,
    color: "#ffffff",
  },
  mediumTextOverride: {
    color: "#7D878E",
    fontSize: 16,
    fontFamily: fontFamily.default,
  },
}));

const ReferralBarContext = createContext({
  hasReferralBanner: false,
  setHasReferralBanner: (_hasReferral: boolean) => {},
});

export const ReferralProvider = (props: any) => {
  const [hasReferralBanner, setHasReferralBanner] = useState(false);
  return (
    <ReferralBarContext.Provider
      value={{ hasReferralBanner, setHasReferralBanner }}
    >
      {props.children}
    </ReferralBarContext.Provider>
  );
};

export const useReferralContext = () => {
  const { hasReferralBanner, setHasReferralBanner } =
    useContext(ReferralBarContext);
  return { hasReferralBanner, setHasReferralBanner };
};

export const useReferral = () => {
  const { data: meData } = usePermissions();
  const { data } = useReferralCode(meData?.viewer?.account?.person?.id);
  const person = data?.viewer?.account?.person;
  const referralCode = person?.referralCode;
  const code = referralCode?.code;
  const referralLink =
    referralCode?.url || `https://shop.vestaboard.com/?vbref=${code}`;
  const existingCustomerMoney = referralCode?.revenueShareForExistingCustomer
    ? (referralCode?.revenueShareForExistingCustomer / 100)
        .toLocaleString("en-US", { style: "currency", currency: "USD" })
        .split(".")[0]
    : "";

  const friendSavings = referralCode?.discountForNewCustomer
    ? (referralCode?.discountForNewCustomer / 100)
        .toLocaleString("en-US", { style: "currency", currency: "USD" })
        .split(".")[0]
    : "";

  const isUsa = (person?.tenants || []).reduce((prev: boolean, current) => {
    return (
      prev ||
      current.boards.reduce((prevBoard: boolean, currentBoard) => {
        return (
          prevBoard ||
          !!currentBoard.devices.filter(
            (device) => device.latestLocationCountryCode === "US"
          ).length
        );
      }, prev)
    );
  }, false);

  return {
    hasReferral: isUsa && !!code,
    referralLink,
    existingCustomerMoney,
    friendSavings,
  };
};

export const ReferralBar = () => {
  const { copy } = useCopy();
  const { setToastOffset } = useToasts();
  const { referralLink, existingCustomerMoney, friendSavings, hasReferral } =
    useReferral();
  const { hasReferralBanner, setHasReferralBanner } = useReferralContext();
  // setting closing shows closing message
  const [closing, setClosing] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width:${responsive.MOBILE}px)`,
  });
  const isMedium = useMediaQuery({
    query: `(max-width:1258px)`,
  });
  useEffect(() => {
    if (hasReferral) {
      setHasReferralBanner(true);
      setToastOffset(120);
    }
  }, [hasReferral]);

  useEffect(() => {
    if (closing && hasReferralBanner) {
      setTimeout(() => {
        setToastOffset(80);
        setHasReferralBanner(false);
      }, 3000);
    }
  }, [closing, hasReferralBanner]);

  const classes = useStyles();

  return hasReferral ? (
    <Collapse in={hasReferralBanner}>
      <div className={classes.animationContainer}>
        <Box className={classes.referContainer}>
          <Fade in={!closing} unmountOnExit>
            <Box
              style={{
                paddingLeft: 45,
              }}
            >
              <Box className={classes.leftContainer}></Box>
              <Box className={classes.centerContainer}>
                <Box className={classes.centerContent}>
                  <GiftIcon style={{ marginRight: 8 }} />
                  <Body>Refer a friend</Body>
                  {!isMedium && (
                    <>
                      <Spacer size={"medium"} horizontal />
                      <Typography className={classes.mediumTextOverride}>
                        Get {existingCustomerMoney} when a friend purchases a
                        Vestaboard using your link. Your friend saves{" "}
                        {friendSavings} off of a Vestaboard.
                      </Typography>
                    </>
                  )}
                  <Box>
                    <MUIButton
                      className={classes.referButton}
                      onClick={() => {
                        copy({ value: referralLink });
                      }}
                    >
                      Copy Link
                    </MUIButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Fade>

          <Box className={classes.rightContainer}>
            <Fade in={closing} unmountOnExit mountOnEnter timeout={2000}>
              <div
                style={
                  isMobile ? {} : { position: "absolute", right: 20, top: 8 }
                }
              >
                <Body>
                  {isMobile
                    ? "You can access your referral link anytime"
                    : "You can access your referral link anytime on your profile dropdown"}
                  <ArrowDownward style={{ marginLeft: 4, marginRight: 16 }} />
                </Body>
              </div>
            </Fade>

            {!closing && (
              <Fade in={!closing} unmountOnExit>
                <div
                  style={
                    isMobile ? {} : { position: "absolute", right: 16, top: 4 }
                  }
                >
                  <Button
                    buttonType="ghost"
                    width={40}
                    onClick={() => {
                      setClosing(true);
                    }}
                  >
                    <Close />
                  </Button>
                </div>
              </Fade>
            )}
          </Box>
        </Box>
      </div>
    </Collapse>
  ) : (
    <></>
  );
};

export const GiftIcon = (props: { style?: CSSProperties }) => (
  <img
    src="/icons/gifticon.svg"
    width={"11px"}
    height={"14px"}
    style={{ ...props.style }}
  />
);
