import * as React from "react";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  SingleBoardsSelectorQuery,
  SingleBoardsSelectorQueryVariables,
} from "../gql";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { Button, CircularProgress, FormControl } from "@mui/material";
import gql from "graphql-tag";
import { makeStyles } from "@mui/styles";
import { useFeatures } from "../hooks/useFeatures";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FETCH_ALL_BOARDS } from "../pages/SelectBoard";
import { FetchAllBoardsQuery } from "../gql";
import { Select } from "@vestaboard/installables";
import MaterialSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const SINGLE_BOARD_SELECTOR_QUERY = gql`
  query SingleBoardsSelectorQuery($boardId: String!) {
    board(id: $boardId) {
      id
      title
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  tenantsActive: {
    padding: "10px 24px",
    textTransform: "none",
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    border: "solid 1px #FFF",
    color: "#ffffff",
  },
  tenants: {
    padding: "10px 26px",
    textTransform: "none",
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#ffffff",
  },
  boardDropdownLabel: {
    top: "-4px",
  },
}));

interface IRouteMatch {
  boardId: string;
}

interface IBoardSelector {
  onSelect?: () => void;
}

export const BoardSelector = (props: IBoardSelector) => {
  const classNames = useStyles();
  const params = useParams<IRouteMatch>();
  const location = useLocation();
  const history = useHistory();
  const isSelectingBoard = location.pathname.includes("select");
  const { features } = useFeatures();

  const [fetchBoards, { data, loading, error }] = useLazyQuery<
    SingleBoardsSelectorQuery,
    SingleBoardsSelectorQueryVariables
  >(SINGLE_BOARD_SELECTOR_QUERY);

  React.useEffect(() => {
    if (params.boardId) {
      fetchBoards({
        variables: {
          boardId: params.boardId,
        },
      });
    }
  }, [params.boardId, fetchBoards]);

  if (!features.manageBoards) {
    return null;
  }

  return loading ? (
    <CircularProgress />
  ) : !data ? null : (
    <>
      <Button
        className={
          isSelectingBoard ? classNames.tenantsActive : classNames.tenants
        }
        startIcon={<img src="/icons/icon-keyboard.svg" alt="Select" />}
        endIcon={
          isSelectingBoard ? (
            <FaChevronUp size={8} />
          ) : (
            <FaChevronDown size={8} />
          )
        }
        onClick={() => {
          if (props.onSelect) {
            props.onSelect();
          }

          if (isSelectingBoard) {
            history.goBack();
          } else if (params.boardId) {
            history.push(`/board/${params.boardId}/select`);
          } else {
            history.push("/select-board");
          }
        }}
      >
        &nbsp;{data ? data.board.title : "Select Board"}
      </Button>
    </>
  );
};
interface IBoardDropdown {
  value: string;
  onChange: (v: string) => any;
  hideVirtualBoards?: boolean;
  hidePlusActivatedBoards?: boolean;
  className?: string;
}

// warning: this dropdown was not showing menu items in a modal, even with options hardcoded
export const BoardDropdown = (props: IBoardDropdown) => {
  const { data, loading } = useQuery<FetchAllBoardsQuery>(FETCH_ALL_BOARDS, {
    fetchPolicy: "no-cache",
  });

  if (!data || loading) return <CircularProgress />;

  return (
    <FormControl fullWidth className={props.className}>
      <Select
        value={props.value}
        onValueChange={(value) => {
          props.onChange(value as string);
        }}
        options={data.tenants
          .flatMap((t) => t.boards)
          .filter((b) => !(props.hideVirtualBoards && b.devices.length === 0))
          .filter(
            (b) =>
              !(
                props.hidePlusActivatedBoards &&
                b.productApplications.length > 0
              )
          )
          .map((b) => ({
            id: b.id,
            name: b.title,
          }))}
      />
    </FormControl>
  );
};

export const LightBoardDropdown = (props: IBoardDropdown) => {
  const { data, loading } = useQuery<FetchAllBoardsQuery>(FETCH_ALL_BOARDS, {
    fetchPolicy: "no-cache",
  });

  if (!data || loading) return <CircularProgress />;

  const options = data.tenants
    .flatMap((t) => t.boards)
    .filter((b) => !(props.hideVirtualBoards && b.devices.length === 0))
    .filter(
      (b) =>
        !(props.hidePlusActivatedBoards && b.productApplications.length > 0)
    )
    .map((b) => ({
      id: b.id,
      name: b.title,
    }));

  return (
    <FormControl fullWidth className={props.className}>
      <MaterialSelect
        style={{
          color: "#000000",
        }}
        variant="outlined"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value as string)}
        fullWidth
        MenuProps={{
          color: "#000000",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          anchorPosition: {
            left: 20,
            top: -100,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  );
};
