import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  ActiveMessageType,
  MessageTypeToggle,
} from "../../components/MessageTypeToggle";
import { HistoryPageV2 } from "../HistoryV2";
import { FavoritesV2Page } from "../FavoritesV2";
import { DraftsV2Page } from "../DraftsV2";
import { SourcesFilter } from "./components/SourcesFilter";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import queryString from "query-string";

const useStyles = makeStyles({
  flex: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
});

export const MyMessagesPage = () => {
  const [sources, setSources] = useState<string[] | null>(null);
  const { boardId } = useParams<{ boardId: string }>();
  const { search } = useLocation();
  const queryStringValues = queryString.parse(search);
  const history = useHistory();
  const active = history.location.pathname.includes("favorites")
    ? ActiveMessageType.Favorites
    : history.location.pathname.includes("drafts")
    ? ActiveMessageType.Drafts
    : ActiveMessageType.History;
  const classes = useStyles();

  useEffect(() => {
    if (queryStringValues.sourceIds) {
      setSources(
        Array.isArray(queryStringValues.sourceIds)
          ? queryStringValues.sourceIds
          : (queryStringValues.sourceIds || "").split(",")
      );
    }
  }, [queryStringValues.sourceIds]);

  return (
    <>
      <Box className={classes.flex}>
        <MessageTypeToggle
          active={active}
          setActive={(active) => {
            if (active === ActiveMessageType.Favorites) {
              history.push(`/board/${boardId}/messages/my/favorites`);
              return;
            }

            if (active === ActiveMessageType.Drafts) {
              history.push(`/board/${boardId}/messages/my/drafts`);
              return;
            }

            history.push(`/board/${boardId}/messages/my/history`);
          }}
        />
        <SourcesFilter
          selected={sources}
          setSelected={setSources}
          key={JSON.stringify(sources)}
        />
      </Box>
      <Switch>
        <Route path="/board/:boardId/messages/my/favorites">
          <FavoritesV2Page
            sources={sources}
            setSource={(source) => source && setSources([source])}
          />
        </Route>
        <Route path="/board/:boardId/messages/my/drafts">
          <DraftsV2Page
            sources={sources}
            setSource={(source) => source && setSources([source])}
          />
        </Route>
        <Route path="/board/:boardId/messages/my/history">
          <HistoryPageV2
            sources={sources}
            setSource={(source) => source && setSources([source])}
          />
        </Route>
        <Redirect to={`/board/${boardId}/messages/my/history`} />
      </Switch>
    </>
  );
};
