import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { CreateBoardV2Mutation, CreateBoardV2MutationVariables } from "../gql";

const CREATE_BOARD_V2_MUTATION = gql`
  mutation CreateBoardV2($input: CreateBoardInputV2!) {
    createBoardV2(input: $input) {
      ... on BoardV2 {
        id
        title
        boardStyle
      }
      ... on BoardErrorV2 {
        type
        error
      }
    }
  }
`;

export const useCreateBoardV2 = () => {
  return useMutation<CreateBoardV2Mutation, CreateBoardV2MutationVariables>(
    CREATE_BOARD_V2_MUTATION
  );
};
