
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";


import { Spacer } from "../../../components/Spacer";

interface ISettings {
  confirmedBody?: string | null;
  confirmedMessage?: string | null;
  setupCallToActionUrl?: string | null;
  setupMessage?: string | null;
  setupBody?: string | null;
  setupCallToActionLabel?: string | null;
  requiresConfiguration?: boolean | null;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "calc(100vh - 180px)",
    color: "pink",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 452,
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    paddingBottom: 3,
    color: "#FFF",
  },
  description: {
    fontSize: 14,
    color: "#7c878e",
    textAlign: "center",
  },
  callToAction: {
    fontSize: 16,
    textTransform: "capitalize",
    padding: "8px 32px",
    borderRadius: 14,
    color: "#FFF !important",
  },
});

export const Settings = (props: ISettings) => {
  const classes = useStyles();
  const requiresConfiguration =
    props.requiresConfiguration === null ? true : props.requiresConfiguration;

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Typography className={classes.title}>
          {(requiresConfiguration
            ? props.setupMessage
            : props.confirmedMessage) || "Settings"}
        </Typography>
        <Typography className={classes.description}>
          {(requiresConfiguration ? props.setupBody : props.confirmedBody) ||
            "This channel doesn't require any settings."}
        </Typography>
        <Spacer height={50} />
        {!!props.setupCallToActionUrl && (
          <Button
            color="primary"
            fullWidth
            rel="noopener noreferrer"
            className={classes.callToAction}
            target="_blank"
            href={props.setupCallToActionUrl}
            variant="contained"
          >
            {props.setupCallToActionLabel}
          </Button>
        )}
      </Box>
    </Box>
  );
};
