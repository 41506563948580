import React from "react";
import { Simulator } from "../../components/Simulator";
import { useParams } from "react-router-dom";
import useResizeObserver from "use-resize-observer/polyfilled";

interface ILoggedOutSimulatorViewProps {}

type LoggedOutSimulatorViewProps = ILoggedOutSimulatorViewProps;

export const LoggedOutSimulatorView: React.FC<LoggedOutSimulatorViewProps> = (
  props
) => {
  const { boardId } = useParams<{ boardId: string }>();
  const { ref, width = 500 } = useResizeObserver();

  return (
    <div ref={ref as any}>
      <Simulator width={width} boardId={boardId} disableFrame={true} />
    </div>
  );
};
