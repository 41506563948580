import { Box, Button, Input } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  searchBarIcon: {
    width: 40,
    color: "#f5f5f7",
    margin: "20px",
    opacity: 0.5,
    alignSelf: "flex-start",
    objectFit: "contain",
  },
  searchBarIconContainer: {
    margin: "5px",
    alignSelf: "flex-start",
    justifyContent: "center",
    backgroundColor: "transparent",
    border: "none",
  },
  searchBarContainer: {
    width: "100%",
    height: "90px",
    borderBottom: "solid 1px #2b2b2b",
    flexDirection: "row",
  },
  search: {
    border: "none",
    height: "94%",
    paddingBottom: "10px",
    margin: "-08px 0px 0px 30px",
    fontSize: "24px",
  },
  cancel: {
    textColor: "#f5f5f7",
    textTransform: "none",
    backgroundColor: "transparent",
    fontSize: "14px",
    textAlign: "right",
    fontFamily: "HelveticaNeue",
    justifyContent: "center",
    marginTop: "30px",
  },
});

const SearchBarIcon = () => {
  const classes = useStyles();
  return (
    <img
      className={classes.searchBarIcon}
      src={"/icons/icon-search.svg"}
      alt="Search"
    />
  );
};

interface ISearchBar {
  handleSearch: (term: string) => void;
  searchValue: string;
  setSearchValue: (term: string) => void;
}

export const SearchBar = (props: ISearchBar) => {
  const classes = useStyles();
  const history = useHistory();

  const cancelPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  const { searchValue, setSearchValue } = props;

  return (
    <Box flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        className={classes.searchBarContainer}
      >
        <Box className={classes.searchBarIconContainer}>
          <SearchBarIcon />
        </Box>
        <Box
          flexGrow={1}
          height="90px"
          justifyContent="flex-start"
          alignContent="flex-start"
          alignSelf="flex-start"
          margin="15px 25px 0px 0px"
        >
          <Input
            value={searchValue}
            autoFocus
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                props.handleSearch(searchValue);
              }
            }}
            onChange={(e) => setSearchValue(e.target.value)}
            className={classes.search}
            multiline={false}
            fullWidth
            disableUnderline
          />
        </Box>
        <Box
          height="100%"
          justifyContent="center"
          alignContent="flex-end"
          flexShrink={0}
          paddingRight={"25px"}
        >
          <Button onClick={cancelPressed} className={classes.cancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
