import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import * as React from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { Button } from "@vestaboard/installables";

type PlusCheckoutWidgetProps = IPlusCheckoutWidgetProps;

interface IPlusCheckoutWidgetProps {}

const useStyles = makeStyles({
  confirmationContainer: {
    backgroundColor: "#ffffff",
  },
  activateBtn: {
    padding: 16,
    backgroundColor: "#363b3d",
    "&:hover": {
      backgroundColor: "#222222",
    },
  },
});

export const PlusCheckoutWidget: React.FC<PlusCheckoutWidgetProps> = (
  props
) => {
  const [data, setData] = React.useState<any | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const [token] = useQueryParam("token", StringParam);
  const [activating, setActivating] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    fetch();
    document.body.classList.add("transparent-body");
  }, []);

  const fetch = async () => {
    const response = await window.fetch(
      `https://platform.vestaboard.com/v3.0/shopify/plus-purchase-session/${token}`
    );
    const json = await response.json();

    if (json.missing) {
      setTimeout(() => fetch(), 1000);
    } else {
      setData(json);
      setLoaded(true);
    }
  };

  const activate = (code: string) => {
    setActivating(true);
    window.parent.location.href = `https://platform.vestaboard.com/buy-plus/${token}`;
  };

  return (
    <Box style={{ width: "100%", height: "100vh", background: "white" }}>
      <Box className={classes.confirmationContainer}>
        {!loaded && <CircularProgress />}
        {loaded && data.purchase && data.purchase.items.length > 0 && (
          <>
            <Button
              onClick={() => activate(data.purchase.items[0].code)}
              buttonType={"black"}
            >
              {activating ? <CircularProgress /> : `Activate Now`}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
