import * as React from "react";

import {
  BoardPreview,
  IBoard,
  encodeBoardCharacters,
} from "@vestaboard/installables";

interface QueryString {
  message?: string;
}

export const Preview = () => {
  const [message, setMessage] = React.useState("");
  React.useEffect(() => {
    const markSplit = window.location.search.split("?")[1];

    if (!markSplit) {
      return;
    }

    const query = markSplit.split("&").reduce((prev, current) => {
      const [key, value] = current.split("=");
      return Object.assign({}, prev, {
        [key]: value,
      });
    }, {}) as QueryString;

    if (query && query.message) {
      setMessage(query.message);
    }
  }, [setMessage]);

  let decodedMessage;

  if (message) {
    try {
      decodedMessage = JSON.parse(message) as IBoard;
    } catch (err) {}
  }

  return (
    <BoardPreview
      characters={decodedMessage ? decodedMessage : encodeBoardCharacters("")}
      borderColor="white"
    />
  );
};
