import { Button, Grow, Popper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useParams } from "react-router";
import { StringParam, useQueryParam } from "use-query-params";
import { usePaywall } from "../../../providers/PaywallProvider";
import ArrowButton from "../../../components/ArrowButton";
import CircleBlurredButton from "../../../components/CircleBlurredButton";
import { FlatButton } from "../../../components/FlatButton";
import { Modal } from "../../../components/Modal";
import { Spacer } from "../../../components/Spacer";
import { Tab } from "../../../components/Tab";
import { TabContent } from "../../../components/TabContent";
import { Tabs } from "../../../components/Tabs";
import { BREAKPOINT_EXTRA_SMALL, BREAKPOINT_SMALL } from "../../../config";
import { ScrollToTop } from "../../../utils/scroll";
import { InstallablesSearch } from "../components/InstallablesSearch";
import { Discover } from "../tabs/Discover";
import { Installed } from "../tabs/Installed";
import { colors, responsive, useOutsideClick } from "@vestaboard/installables";
import { useAppState } from "../../../hooks/global";
import { useMarketplaceListingsCategories } from "../hooks/useMarketplaceListingsCategories";

interface IInstallablesPageProps {}

interface IRouteMatch {
  boardId: string;
}

const useStyles = makeStyles({
  titleBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  icon: {
    width: 18,
    color: "#f5f5f7",
    marginLeft: 4,
    // margin: '5px 10px 20px 10px',
    opacity: 0.5,
    marginBottom: "0px !important",
  },
  categoryChevron: {
    transition: "transform 300ms",
  },
  categoryChevronInactive: {
    transform: "rotate(180deg)",
  },
  iconLabelWrapper: {
    flexDirection: "row-reverse",
  },
  searchLabel: {},
  beta: {
    fontSize: 13,
    letterSpacing: 6.5,
  },
  categoriesPopover: {
    marginTop: 20,
    color: "#1e2022",
    borderRadius: 8,
    boxShadow: "0 12px 12px 0 rgba(23, 24, 24, 0.39)",
    backgroundColor: "#f5f5f7",
    padding: "29px 47px 35px 47px",
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 300,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      maxWidth: "70vw",
    },
  },
  categoriesText: {
    display: "block",
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 3,
    color: "#1e2022",
    padding: 0,
    flex: "0 0 50%",
    textTransform: "unset",
  },
  categoriesTextSelected: {
    color: "#f5f5f7",
    backgroundColor: "#1e2022",
    borderRadius: 8,
  },
  getPlusBtn: {
    textTransform: "unset",
    backgroundColor: colors.white,
    color: colors.woodSmoke,
    // from Tabs component
    marginRight: 88,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      marginRight: 24,
    },
  },
  modal: {
    position: "relative",
    backgroundColor: "#f5f5f7",
    borderRadius: "8px",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      borderRadius: 0,
      minHeight: "100vh",
    },
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    color: "#1e2022",
    alignItems: "center",
    minHeight: "60vh",
    zIndex: 1,
    padding: "56px 164px 30px 164px",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
    "&::before": {
      content: "''",
      backgroundImage: "url('/paywall-left.png')",
      position: "absolute",
      left: 0,
      top: 0,
      width: 400,
      display: "block",
      height: "100%",
      backgroundRepeat: "no-repeat",
      zIndex: -1,
      backgroundSize: "70%",
      borderTopLeftRadius: "8px",
      [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
        display: "none",
      },
      [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
        width: 300,
      },
    },
    "&::after": {
      content: "''",
      backgroundImage: "url('/paywall-right.png')",
      position: "absolute",
      right: 0,
      top: 0,
      width: 400,
      display: "block",
      height: "100%",
      backgroundRepeat: "no-repeat",
      zIndex: -1,
      backgroundSize: "70%",
      backgroundPosition: "top right",
      borderTopRightRadius: "8px",
      [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
        width: 300,
      },
      [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
        width: 250,
      },
    },
  },
  logo: {
    marginBottom: 16,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      marginTop: 32,
      marginBottom: 32,
      alignSelf: "start",
    },
  },
  modalHeading: {
    textAlign: "center",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      textAlign: "left",
    },
  },
  tagline: {
    fontFamily: "HelveticaNeue",
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "0.15px",
    color: "#1e2022",
    marginBottom: 8,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      fontSize: 18,
      maxWidth: "90%",
      alignSelf: "start",
    },
  },
  description: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.63,
    letterSpacing: "0.15px",
    color: "#1e2022",
    marginBottom: 34,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      width: "80%",
      margin: "0 auto 34px",
    },
  },
  closeBtn: {
    position: "absolute",
    top: 20,
    right: 18,
    zIndex: 2,
    "&:hover": {
      backgroundColor: "rgba(125, 135, 142, 0.5)",
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      display: "none",
    },
  },
  backBtn: {
    display: "none",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      display: "block",
      position: "absolute",
      top: 20,
      left: 24,
      zIndex: 2,
      marginBottom: 28,
    },
  },
  activateBtn: {
    width: "100%",
    color: "white !important",
    "& p": {
      opacity: "1 !important",
      fontSize: 16,
      lineHeight: 1.63,
    },
    marginBottom: 14,
    marginTop: 16,
    borderRadius: 5,
    backgroundColor: "#1e2022",
  },
  textBtn: {
    color: "#1e2022",
    textTransform: "none",
    fontWeight: 400,
  },
});

export const GET_PLUS_CTA = "Activate";

export const InstallablesPage: React.FC<IInstallablesPageProps> = () => {
  const classes = useStyles();
  const location = useLocation<IRouteMatch>();
  const history = useHistory();
  const [categoriesOpen, setCategoriesOpen] = React.useState(false);
  const params = useParams<IRouteMatch>();
  const { boardId } = params;
  const { data: categoriesData, loading } =
    useMarketplaceListingsCategories(boardId);

  const { isPlus, setShown } = usePaywall();
  const [showActivatePlanModal, setShowActivatePlanModal] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width:${responsive.MOBILE}px)`,
  });

  // set app state for board id so usePaywall hook will work
  const { setBoardId } = useAppState();
  useEffect(() => {
    setBoardId(params.boardId);
    params.boardId && localStorage.setItem("lastBoardId", params.boardId);
  }, [params]);

  const categoryButtonRef = useRef<HTMLDivElement | null>(null);
  useOutsideClick(categoryButtonRef as any, () => setCategoriesOpen(false));

  const hasAnyChannels =
    !!categoriesData?.board.subscriptionSet.channels.filter(
      (c) => !!c.marketplaceListing
    ).length;

  const tabs = [
    {
      index: 0,
      key: "discover",
      label: "Discover",
    },
    {
      index: 1,
      key: "installed",
      label: "Manage",
      enabled: isPlus,
    },
  ];

  const path = location ? location.pathname : "";

  const currentTab = tabs.reduce((prev: number, tab) => {
    return path.includes(tab.key) ? tab.index : prev;
  }, 0);

  const [selectedCategory] = useQueryParam("category", StringParam);
  const categories = categoriesData?.marketplaceListingsCategories ?? [];

  const isSearching = location.pathname.includes("search");

  const goToInstallablesTab = (tab: string) => {
    history.push(`/board/${boardId}/installables/${tab}`);
  };

  const goToCategory = (categoryId: string) => {
    goToInstallablesTab(`category/${categoryId}`);
  };

  return (
    <>
      <ScrollToTop />
      {!isSearching ? (
        <>
          <div className={"flex justify-between items-center max-w-full"}>
            <Tabs value={currentTab} onChange={() => {}}>
              <Tab
                key={"discover"}
                onClick={() => goToInstallablesTab("discover")}
                label={"Discover"}
              />
              {isPlus || hasAnyChannels ? (
                <Tab
                  key={"installed"}
                  onClick={() => goToInstallablesTab("installed")}
                  label={"Manage"}
                />
              ) : (
                <span />
              )}

              <Tab
                ref={categoryButtonRef}
                label={"Categories"}
                icon={
                  <KeyboardArrowUpIcon
                    className={[
                      classes.icon,
                      classes.categoryChevron,
                      !categoriesOpen && classes.categoryChevronInactive,
                    ]
                      .filter((e) => !!e)
                      .join(" ")}
                  />
                }
                // classes={{
                //   wrapped: classes.iconLabelWrapper,
                // }}

                onClick={() => setCategoriesOpen(!categoriesOpen)}
              />
              <Tab
                label={<span className={classes.searchLabel}>Search</span>}
                onClick={() => goToInstallablesTab("search")}
              />
            </Tabs>
            {!isPlus && !isMobile && (
              <Button
                onClick={() => setShown(true, null)}
                variant={"contained"}
                className={classes.getPlusBtn}
              >
                {GET_PLUS_CTA}
              </Button>
            )}
          </div>
          <Popper
            open={categoriesOpen}
            anchorEl={() => categoryButtonRef.current!!}
            transition
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} timeout={350}>
                <div className={classes.categoriesPopover}>
                  {categories.map((category) => (
                    <Button
                      variant={"text"}
                      key={category.id}
                      onClick={() => {
                        goToCategory(category.id);
                      }}
                      className={classes.categoriesText}
                    >
                      {category.title}
                    </Button>
                  ))}
                </div>
              </Grow>
            )}
          </Popper>
          <TabContent onClick={() => {}}>
            <Switch>
              <Route
                path="/board/:boardId/installables/installed"
                render={() => <Installed />}
              />
              <Route
                path="/board/:boardId/installables/discover"
                render={() => (
                  <Discover category={selectedCategory ?? undefined} />
                )}
              />
              <Redirect to={`/board/${boardId}/installables/discover`} />
            </Switch>
          </TabContent>
        </>
      ) : (
        <InstallablesSearch />
      )}
      <Spacer height={80} />
      <Modal
        shown={showActivatePlanModal}
        onClose={() => setShowActivatePlanModal(false)}
        className={classes.modal}
      >
        <ArrowButton
          aria-label="Go back"
          className={classes.backBtn}
          arrowType="arrow"
          direction="left"
          background="transparent"
          onClick={() => setShowActivatePlanModal(false)}
        />
        <CircleBlurredButton
          className={classes.closeBtn}
          onClick={() => setShowActivatePlanModal(false)}
        >
          <Close />
        </CircleBlurredButton>
        <div className={classes.modalContent}>
          <img
            src={"/vestaboard-plus-logo-black.svg"}
            height={30}
            className={classes.logo}
            alt="Vestaboard logo"
            style={{ marginBottom: 40 }}
          />
          <div className={classes.modalHeading}>
            <h1 className={classes.tagline}>Your plan is not active</h1>
            <h2 className={classes.description}>
              Activate now to select and automate a growing library of content
            </h2>
          </div>
          <FlatButton
            onClick={() =>
              window.location.href === "https://plus.vestaboard.com/"
            }
            className={classes.activateBtn}
            type="button"
          >
            Activate Now
          </FlatButton>
          <Button
            variant="text"
            onClick={() => setShowActivatePlanModal(false)} // TODO: need to go "back" to manage tab
            className={classes.textBtn}
          >
            Back
          </Button>
        </div>
      </Modal>
    </>
  );
};
