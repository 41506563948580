import * as React from "react";

import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { GrayFilledButton } from "../../../components/GrayFilledButton";
import { DeleteApiKeyModal } from "../modals/DeleteApiKeyModal";
import dayjs from "dayjs";
import { Spacer, Button, FlexHorizontal } from "@vestaboard/installables";
import { useHistory } from "react-router-dom";

export interface IApiKeyTableApiKey {
  id: string;
  key: string;
  created: number;
  boards: string[];
}

interface IApiKeyTableProps {
  refreshApiKeys: any | null;
  showApiKeyModal: () => void;
  onCreateApiKey: () => void;
  apiKeys: IApiKeyTableApiKey[];
  loading: boolean;
  boardId: string;
}

type ApiKeyTableProps = IApiKeyTableProps;

export const ApiCredentialTable: React.FC<ApiKeyTableProps> = (props) => {
  const history = useHistory();
  const [apiCredentialIdToDelete, setApiCredentialIdToDelete] =
    React.useState("");
  const [apiKeyToDelete, setApiKeyToDelete] = React.useState("");
  const { loading } = props;

  const clearApiCredentialToDelete = () => {
    setApiCredentialIdToDelete("");
    setApiKeyToDelete("");
  };

  const markApiCredentialForDeletion = (id: string, key: string) => {
    setApiCredentialIdToDelete(id);
    setApiKeyToDelete(key);
  };

  return (
    <>
      <DeleteApiKeyModal
        apiCredentialId={apiCredentialIdToDelete}
        key={apiKeyToDelete}
        shown={!!apiCredentialIdToDelete && !!apiKeyToDelete}
        onCancel={() => clearApiCredentialToDelete()}
        onDelete={() => {
          clearApiCredentialToDelete();
          props.refreshApiKeys();
        }}
      />
      <Spacer size="extraLarge" />
      <div className="mv3 flex justify-between flex-row">
        <div>
          <h4>API Credentials</h4>
        </div>
        {!loading ? (
          <div>
            <Button
              buttonType="outline"
              onClick={() => props.showApiKeyModal()}
            >
              Create an API Credential
            </Button>
          </div>
        ) : null}
      </div>
      {!loading && props.apiKeys.length > 0 && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Boards</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.apiKeys.map((apiCredential) => (
                <TableRow key={apiCredential.id}>
                  <TableCell>
                    <code>{apiCredential.key}</code>
                  </TableCell>
                  <TableCell>
                    {dayjs(apiCredential.created).format(
                      "MMMM DD, YYYY HH:mm a"
                    )}
                  </TableCell>
                  <TableCell>{apiCredential.boards.join(", ")}</TableCell>
                  <TableCell>
                    <FlexHorizontal>
                      <GrayFilledButton
                        onClick={() => {
                          history.push(
                            `/board/${props.boardId}/developer/api-console/${apiCredential.key}`
                          );
                        }}
                      >
                        Api Console
                      </GrayFilledButton>
                      <Box width={12} />
                      <GrayFilledButton
                        onClick={() =>
                          markApiCredentialForDeletion(
                            apiCredential.id,
                            apiCredential.key
                          )
                        }
                      >
                        Delete
                      </GrayFilledButton>
                    </FlexHorizontal>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!loading && props.apiKeys.length === 0 && (
        <div className="bg-light-gray pv5 ph4">
          <h2 className={"mb3"}>You have not created any API credentials</h2>
          <Spacer size="large" />
          <Button
            buttonType="outline"
            width={300}
            onClick={props.onCreateApiKey}
          >
            Create your first API credential
          </Button>
        </div>
      )}

      {loading && <CircularProgress />}
      <Spacer size="extraLarge" />
    </>
  );
};
