import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface IMessageGrid {
  children: React.ReactNode | React.ReactNode[];
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
});

export const MessageGrid = (props: IMessageGrid) => {
  const classes = useStyles();
  return (
    <Box key="message-grid" className={classes.root}>
      {props.children}
    </Box>
  );
};
