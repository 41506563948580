import * as React from "react";
import {
  Button,
  Checkbox,
  ClickAwayListener,
  Grow,
  Popper,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface InstallablesFilterDropdownProps {
  options: Array<{
    id: string;
    label: string;
  }>;
  selectedOptions: Array<string>;
  onSelectOption(id: string): void;
  className?: string;
  label: string;
}

const useDropdownStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    width: 100,
  },
  btn: {
    textTransform: "unset",
  },
  filterText: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "#f5f5f7",
  },
  icon: {
    width: 18,
    color: "#f5f5f7",
    marginLeft: 10,
    opacity: 0.5,
    marginBottom: "0px !important",
    transform: "rotate(180deg)",
  },
  popover: {
    marginTop: 20,
    color: "#1e2022",
    borderRadius: 8,
    boxShadow: "0 12px 12px 0 rgba(23, 24, 24, 0.39)",
    backgroundColor: "#f5f5f7",
    padding: "0.5rem 1rem 0.5rem 0.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    maxWidth: 300,
  },
  optionCheckbox: {
    marginLeft: 0,
  },
  optionText: {
    display: "block",
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 3,
    color: "#1e2022",
    padding: 0,
    flex: "0 0 50%",
    textTransform: "unset",
    textAlign: "left",
  },
});

const InstallablesFilterDropdown: React.FC<InstallablesFilterDropdownProps> = (
  props
) => {
  const classes = useDropdownStyles();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const openBtnRef = React.useRef<HTMLButtonElement>(null);

  return (
    <div className={props.className}>
      <Button
        ref={openBtnRef}
        onClick={(e) => {
          setDropdownOpen((o) => !o);
          e.stopPropagation();
        }}
        className={classes.btn}
      >
        <span className={classes.container}>
          <span className={classes.filterText}>{props.label}</span>
          <KeyboardArrowUpIcon className={classes.icon} />
        </span>
      </Button>
      <ClickAwayListener
        onClickAway={() => dropdownOpen && setDropdownOpen(false)}
      >
        <Popper
          open={dropdownOpen}
          anchorEl={() => openBtnRef.current!!}
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={350}>
              <div className={classes.popover}>
                {props.options.map((option) => (
                  <Button
                    variant={"text"}
                    key={option.id}
                    onClick={() => props.onSelectOption(option.id)}
                    className={classes.optionText}
                  >
                    <ThemeProvider theme={checkboxTheme}>
                      <Checkbox
                        color={"primary"}
                        checked={props.selectedOptions.includes(option.id)}
                        className={classes.optionCheckbox}
                      />
                    </ThemeProvider>
                    {option.label}
                  </Button>
                ))}
              </div>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </div>
  );
};

const checkboxTheme = createTheme({
  palette: {
    primary: { main: "#171818" },
  },
});

export default InstallablesFilterDropdown;
