import React from 'react';
import ArrowButton from "./ArrowButton";

export interface SliderArrowProps {
  left?: boolean;
  right?: boolean;
  direction?: 'left' | 'right';
  onClick?(): void;
  className?: string;
}

const SliderArrow: React.FC<SliderArrowProps> = props => {
  return (
    <ArrowButton {...props} />
  );
};

export default SliderArrow;
