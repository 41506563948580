import { DeleteBoardMutation, DeleteBoardMutationVariables } from "../gql";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const DELETE_BOARD = gql`
  mutation DeleteBoard($input: DeleteBoardInput!) {
    deleteBoard(input: $input) {
      deleted
    }
  }
`;

export const useDeleteBoard = () => {
  return useMutation<DeleteBoardMutation, DeleteBoardMutationVariables>(
    DELETE_BOARD
  );
};
