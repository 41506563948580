import { useParams } from "react-router-dom";
import { ConfirmationModal } from "./ConfirmationModal";
import { useCallback, useState } from "react";
import { usePinMessageToBoardV2 } from "../hooks/usePinMessageToBoardV2";
import { useUnpinMessageFromBoardV2 } from "../hooks/useUnpinMessageFromBoardV2";
import { useCreateMessageAppearanceV2 } from "../hooks/useCreateMessageAppearanceV2";
import { MessageAppearanceErrorType } from "../gql";
import { Confirm, LoadMask, useToasts } from "@vestaboard/installables";
import { PinMessageModal } from "./PinMessageModal";
import { useDeleteMessageAppearanceV2 } from "../hooks/useDeleteMessageAppearanceV2";
import { ShareV2 } from "./ShareV2";
import { useCreateFavoriteV2 } from "../hooks/useCreateFavoriteV2";
import { useRemoveFavoriteV2 } from "../hooks/useRemoveFavoriteV2";
import { useCreateLikeV2 } from "../hooks/useCreateLikeV2";
import { useRemoveLikeV2 } from "../hooks/useRemoveLikeV2";
import { useDeleteDraftV2 } from "../hooks/useDeleteDraftV2";
import { useDeleteFeedItemV2 } from "../hooks/useDeleteFeedItemV2";
import { FlagMessage } from "./FlagMessage";

export interface IHandleSendMessage {
  messageId: string;
  pickId?: string;
  feedItemId?: string;
}

export interface IMessageCardsV2HocVariables {
  handleSendMessage: (input: IHandleSendMessage) => Promise<void>;
  handleShareMessage: (messageId: string) => void;
  handlePinMessage: (messageId: string) => void;
  handleDeleteMessage: (messageId: string) => void;
  handleDeleteFeedItem: (feedItemId: string) => void;
  handleFlagFeedItem: (feedItemId: string) => void;
  handleDeleteDraft: (messageId: string) => void;
  handleFavoriteMessage: (messageId: string, isFavorited: boolean) => void;
  handleLikeFeedItem: (
    feedItemId: string,
    isLikedByMe: boolean,
    likesCount: number
  ) => void;
  handleLikePick: (
    pickId: string,
    isLikedByMe: boolean,
    likesCount: number
  ) => void;
}

interface IMessageCardsV2Hoc {
  children: (variables: IMessageCardsV2HocVariables) => React.ReactNode;
  refetch: () => Promise<void>;
}

// This higher-order component moves most of the card logic out of the MessagesPageV2 component.
// This is for the purpose of making the MessagesPageV2 component more responsive.
export const MessageCardsContainerV2 = (props: IMessageCardsV2Hoc) => {
  const { boardId } = useParams<{ boardId: string }>();
  const [isSharing, setIsSharing] = useState<string | null>(null);
  const [showPinModal, setShowPinModal] = useState<string | null>(null);
  const [confirmDeleteDraft, setConfirmDeleteDraft] = useState<string | null>(
    null
  );
  const [confirmDeleteHistory, setConfirmDeleteHistory] = useState<
    string | null
  >(null);
  const [confirmDeleteFeedItem, setConfirmDeleteFeedItem] = useState<
    string | null
  >(null);
  const [flaggingFeedItem, setFlaggingFeedItem] = useState<string | null>(null);
  const [createLike] = useCreateLikeV2();
  const [removeLike] = useRemoveLikeV2();
  const [pinMessage, { loading: pinningMessage }] = usePinMessageToBoardV2();
  const [unPinMessage, { loading: unPinningMessage }] =
    useUnpinMessageFromBoardV2();
  const [bypassPinFor, setBypassPinFor] = useState<{
    type: string;
    secondsToPin?: number;
    messageId: string;
  } | null>(null);
  const [createMessageAppearance, { loading: creatingMessageAppearance }] =
    useCreateMessageAppearanceV2();
  const [deleteMessageAppearance, { loading: deletingMessageAppearance }] =
    useDeleteMessageAppearanceV2();
  const [deleteFeedItemMutation, { loading: deletingFeedItem }] =
    useDeleteFeedItemV2();
  const { addToast } = useToasts();
  const [favoriteMessage] = useCreateFavoriteV2();
  const [unFavoriteMessage] = useRemoveFavoriteV2();
  const [deleteDraft, { loading: deletingMessageDraft }] = useDeleteDraftV2();

  const handlePinMessageConfirm = useCallback(
    async ({
      secondsToPin,
      messageId,
    }: {
      secondsToPin: number;
      messageId: string;
    }) => {
      setShowPinModal(null);

      // Set the message appearance first, then pin it to the board
      const result = await createMessageAppearance({
        variables: {
          input: {
            boardId: boardId,
            messageId,
          },
        },
      });

      // If the message is already pinned, we can bypass the pin
      const messageIsPinned =
        result.data?.createMessageAppearance.__typename ===
          "MessageAppearanceErrorV2" &&
        result.data.createMessageAppearance.type ===
          MessageAppearanceErrorType.MessagePinned;

      if (messageIsPinned) {
        setBypassPinFor({
          type: "pin",
          secondsToPin,
          messageId,
        });
        return;
      }

      // Throw any error other than a fingerprint error
      // If it is a fingerprint error, we can update the pin time
      // without adding a new message appearance
      if (
        result.data?.createMessageAppearance.__typename ===
          "MessageAppearanceErrorV2" &&
        result.data.createMessageAppearance.type !==
          MessageAppearanceErrorType.FingerprintMatch
      ) {
        addToast(
          result.data.createMessageAppearance.error ||
            "There was an error sending the message",
          {
            appearance: "error",
          }
        );
        return;
      }

      await pinMessage({
        variables: {
          input: {
            boardId,
            messageId,
            secondsToPin,
          },
        },
      });

      addToast("Message pinned", {
        appearance: "success",
      });

      // Refetch the messages unless it was already the last message appearance
      if (
        result.data?.createMessageAppearance.__typename ===
        "MessageAppearanceV2"
      ) {
        await props.refetch();
      }
    },
    [addToast, boardId, createMessageAppearance, pinMessage, props]
  );

  const handleSendMessage = useCallback(
    async (input: IHandleSendMessage) => {
      const result = await createMessageAppearance({
        variables: {
          input: {
            boardId: boardId,
            ...input,
          },
        },
      });

      // If the message is already pinned, we can bypass the pin
      const messageIsPinned =
        result.data?.createMessageAppearance.__typename ===
          "MessageAppearanceErrorV2" &&
        result.data.createMessageAppearance.type ===
          MessageAppearanceErrorType.MessagePinned;

      if (messageIsPinned) {
        setBypassPinFor({
          type: "messageAppearance",
          messageId: input.messageId,
        });
        return;
      }

      // Display any error other than a pinned error
      if (
        result.data?.createMessageAppearance.__typename ===
        "MessageAppearanceErrorV2"
      ) {
        addToast(
          result.data?.createMessageAppearance.error ||
            "There was an error sending the message",
          {
            appearance: "error",
          }
        );

        // Success
      } else {
        addToast("Message sent", {
          appearance: "success",
        });
        await props.refetch();
      }
    },
    [addToast, boardId, createMessageAppearance, props]
  );

  const handleShareMessage = useCallback((messageId: string) => {
    setIsSharing(messageId);
  }, []);

  const handlePinMessage = useCallback((messageId: string) => {
    setShowPinModal(messageId);
  }, []);

  const handleDeleteMessage = useCallback((messageId: string) => {
    setConfirmDeleteHistory(messageId);
  }, []);

  const handleDeleteFeedItem = useCallback((feedItemId: string) => {
    setConfirmDeleteFeedItem(feedItemId);
  }, []);

  const handleFlagFeedItem = useCallback((feedItemId: string) => {
    setFlaggingFeedItem(feedItemId);
  }, []);

  const handleDeleteDraft = useCallback((messageId: string) => {
    setConfirmDeleteDraft(messageId);
  }, []);

  const handleLikeFeedItem = useCallback(
    async (feedItemId: string, isLikedByMe: boolean, likesCount: number) => {
      if (isLikedByMe) {
        await removeLike({
          variables: {
            input: {
              feedItemId,
            },
          },
          optimisticResponse: useRemoveLikeV2.optimisticFeedItem(
            feedItemId,
            isLikedByMe,
            likesCount
          ),
        });
      } else {
        await createLike({
          variables: {
            input: {
              feedItemId,
            },
          },
          optimisticResponse: useCreateLikeV2.optimisticFeedItem(
            feedItemId,
            isLikedByMe,
            likesCount
          ),
        });
      }
    },
    [createLike, removeLike]
  );

  const handleLikePick = useCallback(
    async (pickId: string, isLikedByMe: boolean, likesCount: number) => {
      if (isLikedByMe) {
        await removeLike({
          variables: {
            input: {
              pickId,
            },
          },
          optimisticResponse: useRemoveLikeV2.optimisticPick(
            pickId,
            isLikedByMe,
            likesCount
          ),
        });
      } else {
        await createLike({
          variables: {
            input: {
              pickId,
            },
          },
          optimisticResponse: useCreateLikeV2.optimisticPick(
            pickId,
            isLikedByMe,
            likesCount
          ),
        });
      }
    },
    [createLike, removeLike]
  );

  const handleFavoriteMessage = useCallback(
    async (messageId: string, isFavorited: boolean) => {
      if (isFavorited) {
        await unFavoriteMessage({
          variables: {
            input: {
              messageId,
            },
          },
          optimisticResponse: useRemoveFavoriteV2.optimistic(messageId),
        });
      } else {
        await favoriteMessage({
          variables: {
            input: {
              messageId,
            },
          },
          optimisticResponse: useCreateFavoriteV2.optimistic(messageId),
        });
      }
    },
    [favoriteMessage, unFavoriteMessage]
  );

  return (
    <>
      {flaggingFeedItem ? (
        <FlagMessage
          feedItemId={flaggingFeedItem}
          handleClose={() => setFlaggingFeedItem(null)}
        />
      ) : null}
      <ConfirmationModal
        onConfirm={async () => {
          await unPinMessage({
            variables: {
              input: {
                boardId,
              },
            },
          });

          if (bypassPinFor?.type === "pin") {
            handlePinMessageConfirm({
              secondsToPin: bypassPinFor.secondsToPin || 0,
              messageId: bypassPinFor.messageId,
            });
          }

          if (bypassPinFor?.type === "messageAppearance") {
            handleSendMessage({
              messageId: bypassPinFor?.messageId,
            });
          }

          setBypassPinFor(null);
        }}
        visible={!!bypassPinFor}
        confirmButtonText="Send"
        icon="pin"
        message="A message is currently pinned by you or another user of your account."
        subMessage="Are you sure you want to send a message?"
        onCancel={() => {
          setBypassPinFor(null);
        }}
      />
      <PinMessageModal
        visible={!!showPinModal}
        onClose={() => {
          setShowPinModal(null);
        }}
        onSend={(secondsToPin) => {
          if (typeof showPinModal === "string") {
            return handlePinMessageConfirm({
              messageId: showPinModal,
              secondsToPin,
            });
          }
        }}
      />
      <Confirm
        open={!!confirmDeleteFeedItem}
        title="Delete from Feed"
        declineTitle={"Cancel"}
        acceptTitle={"Delete"}
        message="Are you sure you want to delete this message from your feed? This cannot be undone."
        handleClose={() => setConfirmDeleteFeedItem(null)}
        handleAccept={async () => {
          setConfirmDeleteFeedItem(null);

          if (typeof confirmDeleteFeedItem !== "string") {
            return;
          }

          const result = await deleteFeedItemMutation({
            variables: {
              input: {
                id: confirmDeleteFeedItem,
              },
            },
          });

          if (result.data?.deleteFeedItem.__typename === "FeedItemErrorV2") {
            addToast(
              result.data.deleteFeedItem.error ||
                "There was an error deleting the message",
              {
                appearance: "error",
              }
            );
          } else {
            addToast("Message deleted", {
              appearance: "success",
            });
            await props.refetch();
          }
        }}
      ></Confirm>
      <Confirm
        open={!!confirmDeleteHistory}
        title="Delete Message Appearance"
        declineTitle={"Cancel"}
        acceptTitle={"Delete"}
        message="Are you sure you want to delete this message appearance? This cannot be undone."
        handleClose={() => setConfirmDeleteHistory(null)}
        handleAccept={async () => {
          if (typeof confirmDeleteHistory !== "string") {
            return;
          }

          const result = await deleteMessageAppearance({
            variables: {
              input: {
                id: confirmDeleteHistory,
              },
            },
          });

          setConfirmDeleteHistory(null);

          if (
            result.data?.deleteMessageAppearance.__typename ===
            "MessageAppearanceErrorV2"
          ) {
            addToast(
              result.data.deleteMessageAppearance.error ||
                "There was an error deleting the message appearance",
              {
                appearance: "error",
              }
            );
          } else {
            addToast("Message appearance deleted", {
              appearance: "success",
            });
            await props.refetch();
          }
        }}
      ></Confirm>
      <Confirm
        open={!!confirmDeleteDraft}
        title="Delete Message Draft"
        declineTitle={"Cancel"}
        acceptTitle={"Delete"}
        message="Are you sure you want to delete this message draft? This cannot be undone."
        handleClose={() => setConfirmDeleteHistory(null)}
        handleAccept={async () => {
          if (typeof confirmDeleteDraft !== "string") {
            return;
          }

          const result = await deleteDraft({
            variables: {
              input: {
                messageId: confirmDeleteDraft,
              },
            },
          });

          setConfirmDeleteDraft(null);

          if (result.data?.removeDraft.__typename === "DraftErrorV2") {
            addToast(
              result.data.removeDraft.error ||
                "There was an error deleting the message draft",
              {
                appearance: "error",
              }
            );
          } else {
            addToast("Message draft deleted", {
              appearance: "success",
            });
            await props.refetch();
          }
        }}
      ></Confirm>
      {(creatingMessageAppearance ||
        deletingMessageAppearance ||
        deletingFeedItem ||
        pinningMessage ||
        unPinningMessage ||
        deletingMessageDraft) && <LoadMask />}
      <ShareV2
        messageId={isSharing}
        onClose={() => {
          setIsSharing(null);
        }}
      />
      {props.children({
        handleSendMessage,
        handleShareMessage,
        handlePinMessage,
        handleDeleteMessage,
        handleFavoriteMessage,
        handleLikeFeedItem,
        handleLikePick,
        handleDeleteDraft,
        handleDeleteFeedItem,
        handleFlagFeedItem,
      })}
    </>
  );
};
