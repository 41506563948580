import { Box } from "@mui/material";
import { Modal, SubTitle2, Spacer } from "@vestaboard/installables";
import { SelectMenu } from "@vestaboard/installables/lib/components/SelectMenu";
import { useParams } from "react-router-dom";

interface ITemplateFilterModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  options: Array<{ name: string; id: string }>;
  category?: string;
  setCategory?: (source: string) => void;
}

export const TemplateFilterModal = (props: ITemplateFilterModal) => {
  const params = useParams<{
    boardId: string;
  }>();

  return (
    <Modal
      visible={props.visible}
      onClose={() => props.setVisible(false)}
      fullScreenMobile={true}
    >
      <Box style={{ paddingLeft: 12, paddingRight: 12 }}>
        <SubTitle2>Template Categories</SubTitle2>
        <Spacer size={"medium"} />
        <SelectMenu
          key={`source-filter-options-${params.boardId}`}
          options={props.options}
          selected={[props.category || ""]}
          onPress={(category) => {
            props.setCategory && props.setCategory(category);
            props.setVisible(false);
          }}
        />
      </Box>
    </Modal>
  );
};
