import * as React from "react";

import { Button, useMediaQuery } from "@mui/material";
import {
  ETachyonsMediaQuery,
  useTachyonsMediaQuery,
} from "../../hooks/media-queries";
import { ModalBody, ModalFooter } from "../../components/Modal";
import { QueryResult } from "@apollo/react-common";

import { Container } from "@mui/material";
import { Simulator } from "../../components/Simulator";
import { WYSIWYGEditor } from "../../components/WysiwygEditor";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import {
  BoardMessageAppearancesQuery,
  BoardMessageAppearancesQueryVariables,
} from "../../gql";
import { useAppState } from "../../hooks/global";
import { useMessageHistory } from "../../hooks/useMessageHistory";
import { useMessageHistorySearch } from "../../hooks/useMessageHistorySearch";
import { useCreateMessage } from "../../hooks/useCreateMessage";

const useStyles = makeStyles((theme) => ({
  close: {
    color: "#000",
  },
  modal: {
    backgroundColor: "#191A1A",
  },
}));

interface IBoardViewTemplateProps {
  wysiwygEditorIsOpen?: boolean;
  onWysiwygEditorClosed?(): void;
}

interface IRouteMatch {
  boardId?: string;
}

type BoardViewTemplateProps = IBoardViewTemplateProps;

export type UseBoardMessageAppearancesResult = {
  messageAppearances: QueryResult<
    BoardMessageAppearancesQuery,
    BoardMessageAppearancesQueryVariables
  >;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  count: number;
  boardStyle?: string;
  sources?: Array<string>;
  setSources?: (sources: Array<string>) => void;
};

export type UseBoardMessageSearchResult = UseBoardMessageAppearancesResult & {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
};

export const useBoardMessageSearch = (
  boardId: string
): UseBoardMessageSearchResult => {
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const messageAppearances = useMessageHistorySearch({
    boardId,
    page: currentPage,
    term: searchTerm,
  });

  return {
    messageAppearances,
    currentPage,
    setCurrentPage,
    searchTerm,
    setSearchTerm,
    count: 0,
  };
};

interface IBoardSimulatorViewProps {
  boardId: string;
  wysiwygOpened: boolean;
  dismissWysiwyg(): void;
}

type BoardSimulatorViewProps = IBoardSimulatorViewProps;

const BoardSimulatorView: React.FC<BoardSimulatorViewProps> = ({
  boardId,
  wysiwygOpened,
  dismissWysiwyg,
}) => {
  const classNames = useStyles();
  const [mutateCreateMessage] = useCreateMessage();

  const sendCharacters = async (characters: number[][]) => {
    if (boardId) {
      await mutateCreateMessage({
        variables: {
          boardId,
          characters,
        },
      });
    }
  };

  const tachyonsMediaQuery = useTachyonsMediaQuery();
  const inBetweenMediumAndSmallMediaQuery = useMediaQuery(
    "screen and (min-width: 30em) and (max-width: 40em)"
  );
  const xlMediaQuery = useMediaQuery("screen and (min-width: 90em)");

  const simulatorJSX = (
    <div className="flex flex-row justify-center">
      <Simulator
        hoverable
        width={
          xlMediaQuery
            ? 820
            : tachyonsMediaQuery === ETachyonsMediaQuery.LARGE
            ? 710
            : tachyonsMediaQuery === ETachyonsMediaQuery.MEDIUM
            ? !inBetweenMediumAndSmallMediaQuery
              ? 600
              : 400
            : 350
        }
        boardId={boardId}
      />
    </div>
  );

  return (
    <div className="w-100">
      <div className="mv3 mt5">
        {simulatorJSX}
        <div
          className={"fixed top-0 left-0 right-0 flex justify-center pt5 z-max"}
          style={{
            visibility: wysiwygOpened ? "visible" : "hidden",
            // marginTop: -50
          }}
        >
          <div
            className={
              "fixed top-0 left-0 right-0 bottom-0 bg-black o-90 z-999 pointer"
            }
            onClick={() => {
              dismissWysiwyg();
            }}
          />
          <div className={`relative pt3 pt4-l br3 z-9999 ${classNames.modal}`}>
            <WYSIWYGEditor
              BodyWrapper={ModalBody}
              ButtonsWrapper={({ children, className }) => (
                <ModalFooter className={className}>
                  <div className="w-100 flex justify-between">
                    <Button
                      variant={"outlined"}
                      onClick={() => dismissWysiwyg()}
                      className={classNames.close}
                    >
                      Close
                    </Button>
                    <div className="flex justify-end">
                      {React.Children.map(children, (child) => (
                        <div className={"ml2"}>{child}</div>
                      ))}
                    </div>
                  </div>
                </ModalFooter>
              )}
              submitNewMessage={(characterCodes) => {
                sendCharacters(characterCodes);
                dismissWysiwyg();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface IBoardViewTemplateContext {
  wysiwygEditorIsOpen: boolean;
  setWysiwygEditorIsOpen(isOpen: boolean): void;
}

const BoardViewTemplateContext = React.createContext<IBoardViewTemplateContext>(
  {
    wysiwygEditorIsOpen: false,
    setWysiwygEditorIsOpen(isOpen: boolean): void {},
  }
);

export const useBoardViewTemplate = () =>
  React.useContext(BoardViewTemplateContext);

export const BoardViewTemplate: React.FC<BoardViewTemplateProps> = (props) => {
  const routeMatch = useParams<IRouteMatch>();
  const boardId = routeMatch.boardId;
  const propsWysiwygIsOpen = props.wysiwygEditorIsOpen || false;
  const [wysiwygEditorIsOpen, setWysiwygEditorIsOpen] =
    React.useState(propsWysiwygIsOpen);
  return (
    <BoardViewTemplateContext.Provider
      value={{ wysiwygEditorIsOpen, setWysiwygEditorIsOpen }}
    >
      <Container className="mt4">
        <div>
          {boardId && (
            <BoardSimulatorView
              boardId={boardId}
              wysiwygOpened={wysiwygEditorIsOpen}
              dismissWysiwyg={() => {
                setWysiwygEditorIsOpen(false);
                props.onWysiwygEditorClosed && props.onWysiwygEditorClosed();
              }}
            />
          )}
        </div>
        <div>{props.children}</div>
      </Container>
    </BoardViewTemplateContext.Provider>
  );
};
