import { Box, ButtonBase, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Body, Small, Spacer, SubTitle2, colors } from "@vestaboard/installables";
import { BREAKPOINT_EXTRA_SMALL, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL } from "../../../../config";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles({
  container: {
    height: 130,
    paddingTop: 25,
    paddingBottom: 8,
    paddingLeft: 28,
    paddingRight: 28,
    borderRadius: 8,
    backgroundColor: "#141414",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    margin: 4,
    width: 100,
  },
  imageContainer: {
    display: "flex",
    flex: 1,
    height: 44,
    width: 44,
    // marginBottom: 15,
  },
  textContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    // justifyContent: "flex-end",
    alignItems: "flex-end",
  },
});


export enum DISCOVER_FEATURE_TYPES {
  HOME_ESSENTIALS = "homeessentials",
  WORK_ESSENTIALS = "workessentials",
  NEW_NOTABLE = "newnotable",
  TOP_CHANNELS = "topchannels",
  TRENDING_CHANNELS = "trendingchannels",
}

interface IDiscoverCategoryTag {
  onClick: () => void;
  title: string;
  index: number;
}

export const DISCOVER_FEATURE_TYPE_KEYS = Object.keys(
  DISCOVER_FEATURE_TYPES
) as Array<keyof typeof DISCOVER_FEATURE_TYPES>;

export const getIcon = (index: number) =>
  [
    <img
      src={`/icons/discover-homeessentials.svg`}
      alt={`homeessentials-icon`}
      width={18.7}
      height={16}
    />,
    <img
      src={`/icons/discover-workessentials.svg`}
      alt={`workessentials-icon`}
      width={14}
      height={17}
    />,
    <img
      src={`/icons/discover-newnotable.svg`}
      alt={`newnotable-icon`}
      width={15.4}
      height={18.5}
    />,
    <img
      src={`/icons/discover-topchannels.svg`}
      alt={`topchannels-icon`}
      width={16}
      height={17}
    />,
    <img
      src={`/icons/discover-trending.svg`}
      alt={`trending-icon`}
      width={22}
      height={22}
    />,
  ][index];

const getLargeIcon = (index: number) =>
  [
    <img
      src={`/icons/discover-homeessentials.svg`}
      alt={`homeessentials-icon`}
      width={37.4}
      height={32}
    />,
    <img
      src={`/icons/discover-workessentials.svg`}
      alt={`workessentials-icon`}
      width={28}
      height={34}
    />,
    <img
      src={`/icons/discover-newnotable.svg`}
      alt={`newnotable-icon`}
      width={30.8}
      height={37}
    />,
    <img
      src={`/icons/discover-topchannels.svg`}
      alt={`topchannels-icon`}
      width={42}
      height={34}
    />,
    <img
      src={`/icons/discover-trending.svg`}
      alt={`trending-icon`}
      width={44}
      height={44}
    />,
  ][index];

export const featureTypeIcons = DISCOVER_FEATURE_TYPE_KEYS.reduce(
  (acc, key, i) => ({
    ...acc,
    [key]: getIcon(i),
  }),
  {}
) as { [key: string]: React.Component };

export const DiscoverFeatureTypeTag = (props: IDiscoverCategoryTag) => {
  const classes = useStyles();

  return (
    <ButtonBase onClick={props.onClick} style={{ borderRadius: 24, }}>
      <Box className={classes.container}>
        <Box className={classes.imageContainer}>
          {getLargeIcon(props.index)}
        </Box>
        {/* <Box className={classes.space} /> */}
        <Box className={classes.imageContainer}>
          <Body>{props.title}</Body>
        </Box>
      </Box>
    </ButtonBase>
  );
};


const useSmallStyles = makeStyles({
  container: {
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 24,
    margin: 4,
    backgroundColor: "#141414",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: 'center',
    textAlign: "center",
    minWidth: 120,
    maxWidth: 220
  },
  imageContainer: {
    height: 15,
    width: 15,
  },
  textContainer: {
    marginLeft: 4
  }});


export const DiscoverFeatureTypeTagSmall = (props: IDiscoverCategoryTag) => {
  const classes = useSmallStyles();
  const isExtraSmallScreen = useMediaQuery({
    query: `(max-width: ${BREAKPOINT_EXTRA_SMALL})`,
  });
  const isSmall = useMediaQuery({
    query: `(max-width: ${BREAKPOINT_SMALL})`,
  });
  const isMedium = useMediaQuery({
    query: `(max-width: ${BREAKPOINT_MEDIUM})`,
  });
  return (
      <Box borderColor={'#393b3e'} border={1} className={classes.container}>
        {getIcon(props.index)}
        <Box className={classes.textContainer}>
          {isSmall || isExtraSmallScreen || isMedium ? <Small color={colors.white}>{props.title.replace('\n', '')}</Small> :  <Body color={colors.white}>{props.title.replace('\n', '')}</Body>}
        </Box>
      </Box>
  );
}