import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useHistory, useParams } from "react-router-dom";
import {
  BoardGalleryV2,
  Button,
  IBoard,
  IBoardStyle,
  Spacer,
  useToasts,
} from "@vestaboard/installables";

import {
  BoardV2,
  LiveV2Query,
  LiveV2QueryVariables,
  MyBoardsV2Query,
  MyBoardsV2QueryVariables,
} from "../../gql";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useCopy } from "../../hooks/useCopy";
import relativeTime from "dayjs/plugin/relativeTime";
import { useCreateShareToken } from "../../hooks/useCreateShareToken";
import { useCreateFavoriteV2 } from "../../hooks/useCreateFavoriteV2";
import { useRemoveFavoriteV2 } from "../../hooks/useRemoveFavoriteV2";
import { LiveV2Modals } from "./LiveV2Modals";
import { usePermissions } from "../../hooks/usePermissions";
import { Box } from "@mui/material";
import { useShareMessageV2 } from "../../hooks/useShareMessageV2";
import { useMyBoardsV2 } from "../../hooks/useMyBoardsV2";

dayjs.extend(relativeTime);

const LIVE_V2_QUERY = gql`
  query LiveV2Query($id: String!) {
    BoardV2(id: $id) {
      ... on BoardV2 {
        id
        title
        createdAt
        listMessageAppearances(input: { limit: 1 }) {
          messageAppearances {
            id
            attribution
            message {
              id
              characters
              isFavorited
            }
          }
        }
        listFlagshipDevices {
          flagshipDevices {
            id
            heartbeat {
              lastSeen
            }
          }
        }
      }
      ... on BoardErrorV2 {
        error
        type
      }
    }
  }
`;

const LIVE_V2_NEIGHBOR_QUERY = gql`
  query LiveV2Query($id: String!) {
    BoardV2(id: $id) {
      ... on BoardV2 {
        id
        title
        createdAt
        listMessageAppearances(input: { limit: 1 }) {
          messageAppearances {
            id
            attribution
            message {
              id
              characters
              isFavorited
            }
          }
        }
        listFlagshipDevices {
          flagshipDevices {
            id
            heartbeat {
              lastSeen
            }
          }
        }
      }
      ... on BoardErrorV2 {
        error
        type
      }
    }
  }
`;

export const LiveV2 = () => {
  const history = useHistory();
  const { copy } = useCopy();
  // TODO: migrate share token to v2 services?
  // const [createShareToken] = useCreateShareToken();

  // v2 hooks
  const [favoriteMessage] = useCreateFavoriteV2();
  const [unFavoriteMessage] = useRemoveFavoriteV2();
  const [shareMessage] = useShareMessageV2();

  // modals
  const { addToast } = useToasts();
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);

  const params = useParams<{
    boardId: string;
  }>();
  useEffect(() => {
    params.boardId && localStorage.setItem("lastBoardId", params.boardId);
  }, [params.boardId]);

  // GraphQL
  const {
    data: currentBoardQuery,
    loading: currentBoardLoading,
    error: liveError,
  } = useQuery<LiveV2Query, LiveV2QueryVariables>(LIVE_V2_QUERY, {
    variables: {
      id: params.boardId,
    },
    // poll every ten seconds
    pollInterval: 10000,
  });

  const { data: myBoardsQuery, loading: myBoardsLoading } = useMyBoardsV2();

  const myBoards = myBoardsQuery?.BoardsV2?.boards as BoardV2[];

  const currentBoard = currentBoardQuery?.BoardV2 as BoardV2;
  const currentPage = myBoards
    ? myBoards.findIndex((board) => board.id === params.boardId)
    : 0;

  const { data: leftNeighborBoardQuery, loading: leftNeigborLoading } =
    useQuery(LIVE_V2_NEIGHBOR_QUERY, {
      skip:
        !myBoards?.[currentPage - 1]?.id ||
        myBoards?.length === 0 ||
        myBoards?.length === 1,
      variables: {
        id: myBoards?.[currentPage - 1]?.id,
      },
    });

  const { data: rightNeighborBoardQuery, loading: rightNeighborLoading } =
    useQuery(LIVE_V2_NEIGHBOR_QUERY, {
      skip:
        !myBoards?.[currentPage + 1]?.id ||
        myBoards?.length === 0 ||
        myBoards?.length === 1,
      variables: {
        id: myBoards?.[currentPage + 1]?.id,
      },
    });

  const currentMessageAppearance =
    currentBoard?.listMessageAppearances?.messageAppearances[0];
  const leftMessageAppearance =
    leftNeighborBoardQuery?.BoardV2?.listMessageAppearances
      ?.messageAppearances[0];
  const rightMessageAppearance =
    rightNeighborBoardQuery?.BoardV2?.listMessageAppearances
      ?.messageAppearances[0];

  const boards = useMemo(
    () =>
      myBoards
        ? myBoards.map((board) => {
            const isCurrentBoard = board.id === params.boardId;
            const isLeftNeighborBoard =
              board.id === leftNeighborBoardQuery?.BoardV2?.id;
            const isRightNeighborBoard =
              board.id === rightNeighborBoardQuery?.BoardV2?.id;
            const messsageApperance = isCurrentBoard
              ? currentMessageAppearance
              : isLeftNeighborBoard
              ? leftMessageAppearance
              : isRightNeighborBoard
              ? rightMessageAppearance
              : null;
            return {
              title: board?.title,
              message: messsageApperance
                ? messsageApperance.message.characters
                : (Array(6).fill(Array(22).fill(0)) as IBoard),
              author: messsageApperance?.attribution || "",
              id: board?.id,
              friendlyIdentifier: board?.id?.slice(0, 11).toUpperCase(),
              status:
                currentBoard?.listFlagshipDevices?.flagshipDevices?.length === 0
                  ? "virtual"
                  : currentBoard?.listFlagshipDevices?.flagshipDevices[0]
                      .heartbeat?.lastSeen
                  ? "online"
                  : ("offline" as any),
              created: dayjs(board?.createdAt).fromNow(),
              isOwner: true,
              isFavorite:
                currentMessageAppearance?.message?.isFavorited || false,
              messageId: messsageApperance?.message?.id || "",
              boardStyle: board.boardStyle as IBoardStyle,
              // muting is deprecate in favor of quiet hours
              isMuted: false,
            };
          })
        : [],
    [
      params.boardId,
      currentBoardLoading,
      myBoardsLoading,
      leftNeigborLoading,
      rightNeighborLoading,
      currentMessageAppearance?.message?.isFavorited,
    ]
  );

  return (
    <>
      {currentBoard && (
        <LiveV2Modals
          currentBoard={currentBoard}
          setShowDeleteModal={setShowDeleteModal}
          setShowLeaveModal={setShowLeaveModal}
          setShowRenameModal={setShowRenameModal}
          showDeleteModal={showDeleteModal}
          showLeaveModal={showLeaveModal}
          showRenameModal={showRenameModal}
        />
      )}
      <Spacer size="large" />
      {boards.length > 0 && (
        <BoardGalleryV2
          currentPage={currentPage}
          boards={boards}
          setPage={(pageNumber) => {
            history.push(`/v2/board/${boards[pageNumber].id}/live`);
          }}
          handleRename={() => {
            setShowRenameModal(true);
          }}
          handleLeave={() => {
            setShowLeaveModal(true);
          }}
          handlePublish={(board) => {
            history.push(`/board/${board.id}/publish`);
          }}
          handleSettings={(board) => {
            history.push(`/v2/board/${board.id}/settings`);
          }}
          handleCopy={async (board) => {
            await copy({
              value: board?.id || "",
            });
          }}
          handleDelete={() => {
            setShowDeleteModal(true);
          }}
          handleDuplicate={(board) => {
            history.push(
              `/board/${board.id}/compose/duplicate/${JSON.stringify(
                board.message
              )}`
            );
          }}
          handleFavorite={async (_board) => {
            const messageId = currentMessageAppearance?.message?.id;
            if (!messageId) {
              return addToast("This board has no messages yet", {
                appearance: "error",
              });
            }
            if (!currentMessageAppearance?.message?.isFavorited) {
              await favoriteMessage({
                variables: {
                  input: {
                    messageId,
                  },
                },
                optimisticResponse: useCreateFavoriteV2.optimistic(messageId),
              });
              addToast("Message added to favorites", {
                appearance: "success",
              });
            } else {
              await unFavoriteMessage({
                variables: {
                  input: {
                    messageId,
                  },
                },
                optimisticResponse: useRemoveFavoriteV2.optimistic(messageId),
              });
              addToast("Message removed from favorites", {
                appearance: "success",
              });
            }
          }}
          handleShare={async (_board) => {
            if (!currentMessageAppearance.message.id) {
              return addToast("This board has no messages yet", {
                appearance: "error",
              });
            }
            const { data } = await shareMessage({
              variables: {
                input: {
                  messageId: currentMessageAppearance.message.id,
                },
              },
            });
            if (data?.shareMessage.__typename === "ShareV2") {
              // const link = document.createElement("a");
              // link.setAttribute("href", data.shareMessage.imageUrl);
              // link.setAttribute("target", "_blank");
              // link.setAttribute("rel", "noreferrer");
              // link.click();

              await copy({
                value: data.shareMessage.imageUrl,
                successMessage: "Copied the share URL to your clipboard",
              });
            } else {
              addToast("Something went wrong. Please try again.", {
                appearance: "error",
              });
            }
          }}
          // baord.pausedUntil appears to be deprecated in favor of quiet hours
          handleMuted={(_board) => {
            // setShowUnmute(true);
          }}
        />
      )}
    </>
  );
};
