import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Body, Button, Spacer } from "@vestaboard/installables";
import { useHistory, useParams } from "react-router-dom";
import { usePaywall } from "../../providers/PaywallProvider";
import { BREAKPOINT_SMALL } from "../../config";

const useStyles = makeStyles({
  container: {
    background: "linear-gradient(to right bottom, #4a4c4d, #282b2c)",
    width: "100%",
    heigh: "100%",
    maxHeight: 400,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
  },

  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flex: 1,
    padding: 64,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      flex: 3,
      padding: 32,
    },
  },

  decoration: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flex: 1,
    paddingTop: 32,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      flex: 2,
    },
  },
});

export const PlusCTA = (
  props: { noGradient?: boolean; alwaysShow?: boolean; message?: string } = {
    noGradient: false,
    alwaysShow: false,
    message: "",
  }
) => {
  //
  const { isPlus } = usePaywall();
  const classes = useStyles();
  const params = useParams() as { boardId: string };
  const history = useHistory();
  return (
    <>
      {(!isPlus || props.alwaysShow) && (
        <Box
          className={classes.container}
          style={{
            background: props.noGradient
              ? "#141414"
              : "linear-gradient(to right bottom, #4a4c4d, #282b2c)",
          }}
        >
          <Box className={classes.textContainer}>
            <Box>
              <img
                src={"/vestaboardLogoPlusWhite@3x.png"}
                alt={"vestaboar+"}
                width={"200px"}
              />
              <Spacer size="medium" />
              <Body>
                {props.message ||
                  "Access even more content like this, plus hundreds of automated channels, with Vestaboard+"}
              </Body>
            </Box>
            <Spacer size="medium" />

            <Box>
              <Button
                buttonType={"outline"}
                onClick={() => {
                  history.push(
                    `/board/${params.boardId}/installables/discover`
                  );
                }}
              >
                Discover
              </Button>
            </Box>
          </Box>
          <Box className={classes.decoration}>
            <img src={"/plusAd2@3x.png"} alt={"vestaboar+"} width={"200px"} />
          </Box>
        </Box>
      )}
    </>
  );
};
