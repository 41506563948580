//like FeatureTypesPopover
import { useHistory, useParams } from "react-router-dom";

import { Box, Button, ButtonBase, Grow, Popper } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { SubTitle, SubTitle2, responsive } from "@vestaboard/installables";
import { useRef, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useMediaQuery } from "react-responsive";
import { BREAKPOINT_EXTRA_SMALL, BREAKPOINT_SMALL } from "../../config";
import { useDiscoverCategories } from "../../hooks/useDiscoverCategories";

const useStyles = makeStyles({
  icon: {
    width: 18,
    color: "#f5f5f7",
    marginLeft: 4,
    // margin: '5px 10px 20px 10px',
    opacity: 0.5,
    marginBottom: "0px !important",
  },
  categoryChevron: {
    transition: "transform 300ms",
  },
  categoryChevronInactive: {
    transform: "rotate(180deg)",
  },
  categoriesPopover: {
    marginTop: 20,
    color: "#1e2022",
    borderRadius: 8,
    boxShadow: "0 12px 12px 0 rgba(23, 24, 24, 0.39)",
    backgroundColor: "#f5f5f7",
    padding: "29px 47px 35px 47px",
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 300,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      maxWidth: "70vw",
      padding: 16,
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      maxWidth: "70vw",
    },
  },

  categoriesText: {
    display: "block",
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 3,
    color: "#1e2022",
    padding: 0,
    flex: "0 0 50%",
    textTransform: "unset",
  },

  dropdownContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

export const InstallableCategoryPopover = (props: { title?: string }) => {
  const { data } = useDiscoverCategories();
  const history = useHistory();
  const params = useParams() as { boardId: string };
  const classes = useStyles();

  const isMobile = useMediaQuery({
    query: `(max-width:${responsive.MOBILE}px)`,
  });

  const categoryButtonRef = useRef<HTMLDivElement | null>(null);
  const [categoriesOpen, setCategoriesOpen] = useState(false);

  return (
    <Box>
      <ButtonBase onClick={() => setCategoriesOpen(!categoriesOpen)}>
        <div ref={categoryButtonRef} className={classes.dropdownContainer}>
          {isMobile ? (
            <SubTitle2>{props.title || ""}</SubTitle2>
          ) : (
            <SubTitle>{props.title || ""}</SubTitle>
          )}
        </div>
        <KeyboardArrowUpIcon
          className={[
            classes.icon,
            classes.categoryChevron,
            !categoriesOpen && classes.categoryChevronInactive,
          ]
            .filter((e) => !!e)
            .join(" ")}
        />
      </ButtonBase>
      <Popper
        open={categoriesOpen}
        anchorEl={() => categoryButtonRef.current!!}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <div className={classes.categoriesPopover}>
              {data?.marketplaceListingsCategories.map((mplc) => (
                <Button
                  variant={"text"}
                  key={mplc.id}
                  onClick={() => {
                    setCategoriesOpen(false); 
                    history.push(
                      `/board/${params.boardId}/installables/category/${mplc.id}`
                    );
                  }}
                  className={classes.categoriesText}
                >
                  {mplc.title}
                </Button>
              ))}
            </div>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
