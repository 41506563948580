import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { onError } from "@apollo/client/link/error";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  from,
} from "@apollo/client";
import { GRAPHQL_API_URL } from "../config";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { FullLoader } from "../components/FullLoader";
import { useEffect, useState } from "react";

if (process.env.NODE_ENV !== "production") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

type AuthenticatedApolloProviderProps = {};

const httpLink = new HttpLink({
  uri: GRAPHQL_API_URL,
});

export const AuthenticatedApolloProvider: React.FC<
  AuthenticatedApolloProviderProps
> = (props) => {
  const [client, setClient] = useState<ApolloClient<any> | null>(null);
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated } =
    useAuth0();

  useEffect(() => {
    if (!client) {
      const authLink = setContext(async (_, { headers }) => {
        try {
          const token = await getAccessTokenSilently();

          return {
            compress: false,
            headers: {
              ...headers,
              "X-Vestaboard-Token": token,
            },
          };
        } catch (err: any) {
          if (err?.message === "Login required") {
            loginWithRedirect({
              appState: { targetUrl: window.location.pathname },
            });
          }
        }
      });

      const errorLink = onError(
        ({ graphQLErrors, networkError, forward, operation }) => {
          if (graphQLErrors) {
            console.error(graphQLErrors);
          }
          if (networkError) {
            console.warn(networkError);
          }
          //forward(operation);
        }
      );

      setClient(
        new ApolloClient({
          uri: GRAPHQL_API_URL,
          link: from([authLink, errorLink, httpLink]),
          cache: new InMemoryCache(),
        })
      );
    }
  }, [isAuthenticated, client, getAccessTokenSilently, loginWithRedirect]);

  return client ? (
    <ApolloProvider client={client}>{props.children}</ApolloProvider>
  ) : (
    <FullLoader />
  );
};
