import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  ListPicksCategoriesV2Query,
  ListPicksCategoriesV2QueryVariables,
} from "../gql";

const QUERY = gql`
  query ListPicksCategoriesV2($input: ListPickCategoriesInputV2!) {
    listPickCategories: listPickCategoriesV2(input: $input) {
      pickCategories {
        id
        title
      }
      nextCursor
    }
  }
`;

export const useListPickCategoriesV2 = (
  variables: ListPicksCategoriesV2QueryVariables
) => {
  return useQuery<
    ListPicksCategoriesV2Query,
    ListPicksCategoriesV2QueryVariables
  >(QUERY, {
    fetchPolicy: "cache-first",
    variables,
  });
};
