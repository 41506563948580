import { gql, useQuery } from "@apollo/client";
import { MyBoardsV2Query } from "../generated/graphql";
import { MyAdminBoardsV2Query } from "../generated/graphql";

export const MY_BOARDS_QUERY_V2 = gql`
  query MyBoardsV2Query {
    BoardsV2 {
      boards {
        id
        title
        boardStyle
        createdAt
      }
    }
  }
`;

export const MY_BOARDS_ADMIN_QUERY_V2 = gql`
  query MyAdminBoardsV2Query {
    BoardsV2(permissionFilter: ["Admin", "Owner"]) {
      boards {
        id
        title
        boardStyle
        createdAt
      }
    }
  }
`;

export const useMyBoardsV2 = () => {
  return useQuery<MyBoardsV2Query>(MY_BOARDS_QUERY_V2);
};

export const useMyAdminBoardsV2 = () => {
  return useQuery<MyAdminBoardsV2Query>(MY_BOARDS_ADMIN_QUERY_V2);
};
