import { makeStyles } from "@mui/styles";
import {
  Button,
  LoadMask,
  Padding,
  SlideUpMenu,
  SlideUpMenuList,
  SlideUpMenuListItem,
  SlideUpMenuTitle,
  useToasts,
} from "@vestaboard/installables";
import { useCallback, useState } from "react";
import { useCopy } from "../hooks/useCopy";
import { MessageSetCreatorSendModal } from "./MessageSetCreatorSendModal";
import { useShareMessageV2 } from "../hooks/useShareMessageV2";
import { useCreateFeedItemV2 } from "../hooks/useCreateFeedItem";

const useStyles = makeStyles({
  icon: {
    width: 18,
    filter: `invert(100%)`,
  },
  link: {
    color: "#000000 !important",
    textDecoration: "none !important",
    display: "block",
  },
});

interface IShareV2 {
  messageId: string | null;
  onClose: () => void;
}

export const ShareV2 = (props: IShareV2) => {
  const [addToChannel, setAddToChannel] = useState<string | null>(null);
  const [shareMutation, { loading }] = useShareMessageV2();
  const [shareToFeedMutation, { loading: savingShareToFeed }] =
    useCreateFeedItemV2();
  const { copy } = useCopy();
  const classes = useStyles();
  const { addToast } = useToasts();

  const generateSharePage = useCallback(async () => {
    props.onClose();

    if (!props.messageId) {
      return;
    }

    const { data } = await shareMutation({
      variables: {
        input: {
          messageId: props.messageId,
        },
      },
    });

    if (data?.shareMessage.__typename === "ShareV2") {
      await copy({
        value: data?.shareMessage?.shareUrl,
        successMessage: "Copied the share URL to your clipboard",
      });
    } else {
      addToast("Something went wrong. Please try again.", {
        appearance: "error",
      });
    }
  }, [copy, shareMutation, addToast, props]);

  const shareToFeed = useCallback(async () => {
    props.onClose();

    if (!props.messageId) {
      return;
    }

    const shareToFeed = await shareToFeedMutation({
      variables: {
        input: {
          messageId: props.messageId,
        },
      },
    });

    if (shareToFeed.data?.createFeedItem.__typename === "FeedItemErrorV2") {
      return addToast(
        shareToFeed.data.createFeedItem.error ||
          "Something went wrong. Please try again.",
        {
          appearance: "error",
        }
      );
    }

    addToast("Message added to your feed", {
      appearance: "success",
    });
  }, [props, shareToFeedMutation, addToast]);

  const generateDownload = useCallback(async () => {
    props.onClose();

    if (!props.messageId) {
      return;
    }

    const { data } = await shareMutation({
      variables: {
        input: {
          messageId: props.messageId,
        },
      },
    });

    if (data?.shareMessage.__typename === "ShareV2") {
      const link = document.createElement("a");

      link.setAttribute("href", data.shareMessage.imageUrl);
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noreferrer");
      link.click();
    } else {
      addToast("Something went wrong. Please try again.", {
        appearance: "error",
      });
    }
  }, [addToast, props, shareMutation]);

  return (
    <>
      {loading || (savingShareToFeed && <LoadMask />)}
      <SlideUpMenu
        visible={!!props.messageId}
        onClose={() => {
          props.onClose();
        }}
      >
        <SlideUpMenuTitle>Share</SlideUpMenuTitle>
        <SlideUpMenuList>
          <SlideUpMenuListItem onClick={generateDownload}>
            <img
              alt="Save"
              className={classes.icon}
              src="/icons/icon-download.svg"
            />{" "}
            &nbsp; Save Image
          </SlideUpMenuListItem>
          <SlideUpMenuListItem onClick={generateSharePage}>
            <img
              alt="Copy Link"
              className={classes.icon}
              src="/icons/icon-copy.svg"
            />{" "}
            &nbsp; Copy Link
          </SlideUpMenuListItem>
          <SlideUpMenuListItem
            onClick={() => {
              setAddToChannel(props.messageId);
              props.onClose();
            }}
          >
            <img
              alt="Copy Link"
              className={classes.icon}
              src="/icons/icon-publish.svg"
            />{" "}
            &nbsp; Add to My Channel
          </SlideUpMenuListItem>
          <SlideUpMenuListItem onClick={shareToFeed}>
            <img
              alt="Share to Feed"
              className={classes.icon}
              src="/icons/icon-copy.svg"
            />{" "}
            &nbsp; Share to Feed
          </SlideUpMenuListItem>
        </SlideUpMenuList>
        <Padding>
          <Button
            onClick={() => {
              props.onClose();
            }}
            buttonType="outlineBlack"
          >
            Cancel
          </Button>
        </Padding>
      </SlideUpMenu>
      <MessageSetCreatorSendModal
        messageId={addToChannel || ""}
        visible={!!addToChannel}
        onClose={() => {
          setAddToChannel(null);
        }}
      />
    </>
  );
};
