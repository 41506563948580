import { gql, useMutation, useQuery } from "@apollo/client";

import { useEffect } from "react";

const ACCEPT_INVITE_MUTATION = gql`
  mutation AcceptInvite($personTenantAssociationId: String!) {
    acceptPersonTenantInvitation(
      input: { personTenantAssociationId: $personTenantAssociationId }
    ) {
      accepted
    }
  }
`;

const BOARD_INVITE_QUERY = gql`
  query GetBoardRoles2($boardId: String!) {
    board(id: $boardId) {
      id
      title
      tenant {
        id
        members {
          id
          role
          invitationStatus
          person {
            id
            account {
              id
              emailAddress
            }
          }
        }
      }
    }
  }
`;

export const useAcceptInviteEffect = (boardId: string, accountId?: string) => {
  const { data } = useQuery(BOARD_INVITE_QUERY, {
    variables: {
      boardId: boardId,
    },
  });
  const [acceptInvite] = useMutation(ACCEPT_INVITE_MUTATION);
  const accountRecord = data?.board.tenant.members.find(
    (tenantMember: {
      invitationStatus: string;
      person: { account: { id: string } };
    }) => accountId === tenantMember.person.account.id
  );
  const invitationStatus = accountRecord?.invitationStatus;

  useEffect(() => {
    if (invitationStatus === "Invited" && accountId) {
      acceptInvite({
        variables: {
          personTenantAssociationId: accountRecord.id,
        },
      });
    }
  }, [invitationStatus, accountId]);
};
