import gql from "graphql-tag";
import {
  ListSearchMessagesV2Query,
  ListSearchMessagesV2QueryVariables,
} from "../gql";
import { useQuery } from "@apollo/client";

const QUERY = gql`
  query ListSearchMessagesV2($input: SearchMessageInputV2!) {
    listSearchMessages: listSearchMessagesV2(input: $input) {
      searchMessages {
        __typename
        ... on MessageSetMessageV2 {
          id
          attribution
          created
          messageSetId
          message {
            id
            characters
            text
            isFavorited
          }
        }
        ... on PickV2 {
          id
          date
          sortOrder
          public
          attribution
          created
          isLikedByMe
          likeCount
          message {
            id
            text
            characters
            isFavorited
          }
          categories {
            id
            title
          }
        }
        ... on FavoriteV2 {
          id
          attribution
          created
          sourceId
          message {
            id
            characters
            text
            isFavorited
          }
        }
        ... on FeedItemV2 {
          id
          attribution
          created
          likeCount
          isLikedByMe
          sourceId
          personId
          isSharedByMe
          isFlaggedByMe
          message {
            id
            characters
            text
            isFavorited
          }
        }
        ... on DraftV2 {
          id
          attribution
          created
          sourceId
          message {
            id
            characters
            text
            isFavorited
          }
        }
        ... on MessageAppearanceV2 {
          id
          attribution
          created
          sourceId
          personId
          subscriptionId
          marketplaceListingId
          message {
            id
            characters
            text
            isFavorited
          }
        }
      }
      nextCursor
    }
  }
`;

export const useSearchMessagesV2 = (
  variables: ListSearchMessagesV2QueryVariables,
  skip?: boolean
) => {
  return useQuery<
    ListSearchMessagesV2Query,
    ListSearchMessagesV2QueryVariables
  >(QUERY, {
    variables,
    fetchPolicy: "cache-and-network",
    skip,
  });
};
