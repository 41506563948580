import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  deleteRecentSearchIcon: {
    width: 25,
    height: 25,
    color: "#f5f5f7",
    opacity: 0.5,
    alignSelf: "flex-end",
    alignItems: "flex-end",
    justifyContent: "center",
    objectFit: "contain",
  },
  deleteRecentSearchButton: {
    height: "48px",
    marginLeft: "30px",
  },
});

export const DeleteRecentSearchButton = (props: { onClick: () => void }) => {
  const classes = useStyles();

  return (
    <Button
      onClick={props.onClick}
      className={classes.deleteRecentSearchButton}
    >
      <img
        className={classes.deleteRecentSearchIcon}
        src={"/icons/icon-close.svg"}
        alt="Delete"
      />
    </Button>
  );
};
