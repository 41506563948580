import { useQuery } from "@apollo/client";
import { Box, ButtonBase } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Check } from "@mui/icons-material";
import {
  TextButton,
  Button,
  Input,
  Modal,
  SubTitle2,
  Spacer,
  useToasts,
  ModalDialog,
} from "@vestaboard/installables";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { BOARD_MEMBERS_QUERY } from "../../Settings/Users";
import {
  BoardMembersQuery,
  BoardMembersQueryVariables,
  Direction,
} from "../../../gql";
import { useTransferOwnership } from "../../../hooks/useTransferOwnership";
import { useTransferOwnershipV2 } from "../../../hooks/useTransferOwnershipV2";
import {
  GET_MY_ROLE_QUERY_V2,
  GET_ROLES_QUERY_V2,
  useGetMyRoleV2,
  useGetRolesV2,
} from "../../../hooks/useGetRolesV2";
import { useInviteRoleV2 } from "../../../hooks/useInviteRoleV2";
import { useUpdateUserRoleV2 } from "../../../hooks/useUpdateUserRoleV2";

interface ITransferModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const useStyles = makeStyles({
  modalBtnGroup: {
    display: "flex",
    gap: 16,
    justifyContent: "space-between",
    marginTop: 24,
    width: "100%",
  },
  userContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
    flexDirection: "row",
    flex: 1,
  },
  userButton: {
    width: "100%",
    height: 50,
  },
});

export const TransferOwnershipModalV2 = (props: ITransferModal) => {
  const { addToast } = useToasts();

  const [createNewOwner, setCreateNewOwner] = useState(false);

  const [email, setEmail] = useState("");
  const [newOwnerPersonId, setNewOwnerPersonId] = useState("");
  const [newOwnerRoleId, setNewOwnerRoleId] = useState("");

  const [newOwnerPersonName, setNewOwnerPersonName] = useState("");

  const [mutateTransferOwnership] = useTransferOwnershipV2();
  const [updateRole] = useUpdateUserRoleV2();
  const [addOwner] = useInviteRoleV2();
  const params = useParams<{
    boardId: string;
  }>();

  const { data: boardData } = useGetRolesV2(params.boardId);
  const { data: viewerData } = useGetMyRoleV2(params.boardId);
  const classes = useStyles();
  const myPerson =
    viewerData?.viewerV2?.__typename === "PersonV2"
      ? viewerData?.viewerV2
      : undefined;
  const myPersonId = myPerson?.id;
  const myRoleId =
    myPerson?.myRoleForBoard?.__typename === "RoleV2"
      ? myPerson?.myRoleForBoard?.id
      : undefined;

  const transferToOwner = useCallback(
    async (
      transfer: {
        newOwnerPersonId?: string;
        newOwnerRoleId?: string;
        emailAddress?: string;
      },
      name?: string
    ) => {
      if (!transfer.newOwnerPersonId) {
        addToast(`Unable to transfer ownership to user`, {
          appearance: "error",
        });
        return;
      }
      try {
        if (
          transfer.newOwnerPersonId &&
          transfer.newOwnerRoleId &&
          myPersonId &&
          myRoleId
        ) {
          await mutateTransferOwnership({
            variables: {
              toInput: {
                personId: transfer.newOwnerPersonId,
                id: transfer.newOwnerRoleId as string,
                permission: Direction.Owner,
                boardId: params.boardId,
              },
              fromInput: {
                boardId: params.boardId,
                personId: myPersonId as string,
                id: myRoleId,
                permission: Direction.Admin,
              },
            },
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_ROLES_QUERY_V2,
                variables: {
                  boardId: params.boardId,
                },
              },
              {
                query: GET_MY_ROLE_QUERY_V2,
                variables: {
                  boardId: params.boardId,
                },
              },
            ],
          });
        }
        if (
          !transfer.newOwnerPersonId &&
          transfer.emailAddress &&
          !transfer.newOwnerRoleId &&
          myPersonId &&
          myRoleId
        ) {
          await addOwner({
            variables: {
              input: {
                email: transfer.emailAddress,
                boardId: params.boardId,
                permission: Direction.Owner,
              },
            },
          });

          await updateRole({
            variables: {
              input: {
                boardId: params.boardId,
                personId: myPersonId as string,
                permission: Direction.Admin,
                id: myRoleId as string,
              },
            },
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_ROLES_QUERY_V2,
                variables: {
                  boardId: params.boardId,
                },
              },
              {
                query: GET_MY_ROLE_QUERY_V2,
                variables: {
                  boardId: params.boardId,
                },
              },
            ],
          });
        }
        addToast(`Transfered ownership to ${name || transfer?.emailAddress}`, {
          appearance: "success",
        });
      } catch (err) {
        console.log(err, ":::error");
        return addToast(
          `Failed to transfer ownership to ${name || transfer?.emailAddress}`,
          {
            appearance: "error",
          }
        );
      }
    },
    [myPersonId, myRoleId, params.boardId]
  );

  const board =
    boardData?.BoardV2?.__typename === "BoardV2"
      ? boardData?.BoardV2
      : undefined;

  return (
    <>
      <ModalDialog
        visible={props.visible && !createNewOwner}
        onClose={() => props.setVisible(false)}
        fullScreenMobile={true}
        header={<SubTitle2>Transfer {board?.title} Ownership To</SubTitle2>}
        footer={
          <Box className={classes.modalBtnGroup}>
            <Button
              buttonType="outline"
              onClick={() => {
                props.setVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!newOwnerPersonId}
              buttonType="white"
              onClick={async () => {
                await transferToOwner(
                  { newOwnerRoleId, newOwnerPersonId },
                  newOwnerPersonName
                );
                props.setVisible(false);
              }}
            >
              Transfer
            </Button>
          </Box>
        }
      >
        <Box
          style={{
            flexDirection: "column",
            width: "100%",
          }}
        >
          {boardData?.BoardV2?.__typename === "BoardV2" ? (
            boardData?.BoardV2?.listRoles?.roles
              .filter((member) => myPersonId !== member.person.id)
              .map((member) => {
                const username = member.person.firstName
                  ? `${member.person.firstName} ${member.person.lastName}`
                  : member.person.emailAddress;
                return (
                  <Box key={`${member.id}-transfer-button`} borderBottom={1}>
                    <ButtonBase
                      className={classes.userButton}
                      onClick={() => {
                        setNewOwnerPersonId(member.person.id);
                        setNewOwnerRoleId(member.id);
                        setNewOwnerPersonName(username);
                      }}
                    >
                      <Box className={classes.userContainer}>
                        <Box style={{ display: "flex", flex: 1 }}>
                          {username}
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-end",
                          }}
                        >
                          {member.person.id === newOwnerPersonId ? (
                            <Check />
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                    </ButtonBase>
                  </Box>
                );
              })
          ) : (
            <></>
          )}
          <Box borderBottom={1}>
            <ButtonBase
              className={classes.userButton}
              type="button"
              onClick={() => {
                setCreateNewOwner(true);
              }}
            >
              <Box className={classes.userContainer}>Create New Owner +</Box>
            </ButtonBase>
          </Box>
        </Box>
      </ModalDialog>
      <Modal
        visible={props.visible && createNewOwner}
        onClose={() => setCreateNewOwner(false)}
        fullScreenMobile={true}
      >
        <Input value={email} onValueChange={setEmail} label="Email" />
        <Box className={classes.modalBtnGroup}>
          <Button
            buttonType="outline"
            onClick={() => {
              setCreateNewOwner(false);
            }}
          >
            Back
          </Button>
          <Button
            buttonType="white"
            disabled={!email || !email.includes(".") || !email.includes("@")}
            onClick={async () => {
              await transferToOwner({ emailAddress: email });
              setCreateNewOwner(false);
              props.setVisible(false);
            }}
          >
            Add and Transfer Ownership
          </Button>
        </Box>
      </Modal>
    </>
  );
};
