import * as React from "react";

import { Box, Button, MenuItem } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import { BREAKPOINT_SMALL } from "../../../config";
import { BoardSelector } from "../../../components/BoardSelector";
import MediaQuery from "react-responsive";
import Popover from "@mui/material/Popover";
import { getInitials } from "../utils/getInitials";
import { makeStyles } from "@mui/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useFeatures } from "../../../hooks/useFeatures";
import { GiftIcon, useReferral } from "./ReferralBar";
import { useCopy } from "../../../hooks/useCopy";
import { Body } from "@vestaboard/installables";
import { usePermissions } from "../../../hooks/usePermissions";

interface IRouteMatch {
  boardId: string;
}

interface IUserSettings {}

const useStyles = makeStyles((theme) => ({
  avatar: {
    background: "#191A1A",
    border: "solid 1px #242829",
    fontFamily: "VestaboardWYSIWYG, Roboto, sans-serif",
    letterSpacing: -10,
    textIndent: -10,
  },
  avatarActive: {
    background: "#191A1A",
    border: "solid 1px #FFF",
    fontFamily: "VestaboardWYSIWYG, Roboto, sans-serif",
    letterSpacing: -10,
    textIndent: -10,
  },
  popoverPaper: {
    border: "solid 1px #FFF",
    width: 280,
    marginTop: 30,
    position: "relative",
    justifyContent: "flex-end",
    background: "#191A1A",
    paddingBottom: 20,
    paddingTop: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  popoverItem: {
    background: "#191A1A",
    justifyContent: "flex-end",
    width: "auto",
    "&:hover": {
      background: "#242829",
    },
  },
  userEmail: {
    margin: 8,
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 1.63,
    textAlign: "right",
    maxWidth: "100%",
    overflowWrap: "anywhere",
  },
  userInfo: {
    margin: 8,
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 1.63,
    textAlign: "right",
    color: "#5c5f64",
  },
  editProfile: {
    backgroundColor: "#1e2022",
    textTransform: "none",
    marginRight: 8,
    padding: "12px 22px",
    fontSize: 14,
    marginBottom: 8,
    marginTop: 8,
    borderRadius: 8,
    color: "white",
  },
  logout: {
    textAlign: "right",
    justifyContent: "flex-end",
  },
  children: {
    marginRight: 8,
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  referButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  referButton: {
    backgroundColor: "#1e2022",
    textTransform: "none",
    marginRight: 8,
    padding: "12px 16px",
    fontSize: 14,
    marginBottom: 8,
    marginTop: 8,
    marginLeft: 16,
    borderRadius: 8,
    color: "white",
  },
}));

export const UserSettings = (props: IUserSettings) => {
  const { data, loading } = usePermissions();
  const params = useParams<IRouteMatch>();
  const { boardId } = params;
  const history = useHistory();
  const classNames = useStyles();
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { features } = useFeatures();
  const { copy } = useCopy();
  const { referralLink, hasReferral } = useReferral();

  const showSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const hideSettings = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-settings" : undefined;

  React.useEffect(() => {
    if (
      data &&
      data.viewer &&
      data.viewer.account &&
      data.viewer.account.person
    ) {
      ((window as any).pendo as any).initialize({
        visitor: {
          id: data.viewer.account.person.id,
          email: data.viewer.account?.emailAddress,
          full_name: `${data.viewer.account.person?.firstName ?? ""} ${
            data.viewer.account.person?.lastName ?? ""
          }`,
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: "ACCOUNT-UNIQUE-ID", // Required if using Pendo Feedback
          // name:         // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's notone of the above reserved names.
        },
      });
    }
  }, [data]);

  if (loading || !data || !data.viewer || !data.viewer.account) {
    return null;
  }

  const emailAddress = data.viewer.account.emailAddress;
  const firstName = data.viewer.account.person.firstName || "";
  const lastName = data.viewer.account.person.lastName || "";

  return (
    <>
      <Button onClick={showSettings}>
        <Avatar className={open ? classNames.avatarActive : classNames.avatar}>
          {getInitials(`${firstName} ${lastName}`)}
        </Avatar>
      </Button>
      <Popover
        classes={{
          paper: classNames.popoverPaper,
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={hideSettings}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classNames.children}>
          <MediaQuery maxWidth={BREAKPOINT_SMALL}>
            <BoardSelector
              onSelect={() => {
                setAnchorEl(null);
              }}
            />
          </MediaQuery>
        </div>
        <div className={classNames.userEmail}>{emailAddress}</div>
        <div className={classNames.userInfo}>
          {firstName === emailAddress ? "" : firstName} {lastName}
        </div>
        <div className={classNames.userInfo}>
          {data.viewer.account.person.tenantAssociations.length
            ? data.viewer.account.person.tenantAssociations[0].role
            : ""}
        </div>
        {features.userProfile && (
          <Button
            onClick={() => {
              hideSettings();
              history.push(`/board/${boardId}/profile`);
            }}
            className={classNames.editProfile}
          >
            Edit Profile
          </Button>
        )}
        {hasReferral && (
          <>
            <Box className={classNames.referButtonContainer}>
              <GiftIcon style={{ marginRight: 8 }} />

              <Body>Refer a friend</Body>

              <Button
                onClick={() => {
                  copy({ value: referralLink });
                  hideSettings();
                }}
                className={classNames.referButton}
              >
                Copy Link
              </Button>
            </Box>
          </>
        )}
        <MenuItem
          className={classNames.logout}
          onClick={() => {
            hideSettings();
            logout();
          }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
};
