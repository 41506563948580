import {
  Box,
  Button as MUIButton,
  ButtonBase,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { KeyboardArrowDown } from "@mui/icons-material";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import {
  BoardPreview,
  BoardPreviewMask,
  encodeBoardCharacters,
  IBoard,
  IBoardStyle,
  Spacer,
  SubTitle,
} from "@vestaboard/installables";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PlusCTA } from "./PlusCTA";
import { usePaywall } from "../../providers/PaywallProvider";
import { BREAKPOINT_SMALL } from "../../config";
import { useGetBoardStyle } from "../../hooks/useGetBoardStyle";
import { useTemplates } from "../../hooks/useTemplates";
import { TemplateFilterModal } from "./TemplateFilterModal";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
  filterButton: {
    paddingLeft: 0,
  },
  filterBorder: {
    borderColor: "#7d878e",
    borderWidth: 1,
  },
  categoryContainer: {
    padding: 32,
    maxWidth: "2000px",
  },
  boardPreviewsContainer: {
    flexDirection: "row",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
  },
  boardPreviewContainer: {
    width: "300px",
    minWidth: "300px",
    maxWidth: "300px",
    marginBottom: 20,
    marginRight: 20,
  },
  header: {
    padding: "20px",
    borderBottom: "solid 1px #2b2b2b",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  headerColumn: {
    flex: 1,
    display: "flex",
  },
  headerColumnCenter: {
    flex: 1,
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    // icon and padding offset
    left: -55,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      left: -22,
    },
  },
});

export const TempalteCategory = () => {
  const classes = useStyles();
  const params = useParams() as { boardId: string; category: string };
  const history = useHistory();
  const [openFilter, setOpenFilter] = useState(false);
  // only plus members get to see every template
  const { isPlus, setShown } = usePaywall();
  const perPage = isPlus ? 200 : 15;
  const { data, loading: loadingTemplates } = useTemplates(0, perPage);
  const { data: boardData, loading: loadingBoard } = useGetBoardStyle();

  const categoryOptions = data?.colorTemplateCategories
    ? data?.colorTemplateCategories.map((category) => ({
        name: category.title,
        id: category.id,
      }))
    : [];

  const category = data?.colorTemplateCategories.filter(
    (category) => category.id === params.category
  )?.[0];

  if (loadingTemplates || loadingBoard) {
    return (
      <Box className={classes.categoryContainer}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.headerColumn}>
          <ButtonBase
            onClick={() => {
              history.push(`/board/${params.boardId}/templates`);
            }}
          >
            <img src="/icons/icon-back.svg" alt="Back" />
          </ButtonBase>
        </Box>
        <Box className={classes.headerColumnCenter}>
          <SubTitle>Templates</SubTitle>
        </Box>
        {/* Display Flex three items to center the title */}
        <Box className={classes.headerColumn}></Box>
      </Box>
      <Box className={classes.categoryContainer}>
        <MUIButton
          onClick={() => setOpenFilter(true)}
          className={classes.filterButton}
        >
          <Box borderBottom={1} className={classes.filterBorder}>
            {
              data?.colorTemplateCategories.filter(
                (category) => category.id === params.category
              )?.[0]?.title
            }
            {openFilter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Box>
        </MUIButton>

        {category && (
          <Box>
            <Spacer size={"medium"} />
            <Box className={classes.boardPreviewsContainer}>
              {category.colorTemplates.map((template) => (
                <Box className={classes.boardPreviewContainer}>
                  <BoardPreviewMask
                    handleDuplicateTitle={"Use Template"}
                    handleDuplicate={() => {
                      history.push(
                        `/board/${
                          params.boardId
                        }/compose/duplicate/${JSON.stringify(
                          template.message.formatted
                        )}`
                      );
                    }}
                  >
                    <BoardPreview
                      boardStyle={
                        (boardData?.board?.boardStyle as IBoardStyle) ?? "black"
                      }
                      characters={
                        template.message.formatted
                          ? (template.message.formatted as IBoard)
                          : encodeBoardCharacters("")
                      }
                    />
                  </BoardPreviewMask>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <PlusCTA />
      </Box>
      {openFilter && (
        <TemplateFilterModal
          category={params.category}
          setCategory={(category) => {
            history.push(`/board/${params.boardId}/templates/${category}`);
          }}
          visible={openFilter}
          setVisible={setOpenFilter}
          options={[...categoryOptions]}
        />
      )}
    </>
  );
};
