import { IBoard, useToasts } from "@vestaboard/installables";
import { useCallback } from "react";
import { useCreateMessageV2 } from "../../../hooks/useCreateMessageV2";
import { useCreateDraftV2 } from "../../../hooks/useCreateDraftV2";

export const useHandleSaveDraft = (characters: IBoard) => {
  const { addToast } = useToasts();
  const [createMessage, { loading: creatingMessage }] = useCreateMessageV2();
  const [createDraft, { loading: creatingDraft }] = useCreateDraftV2();

  const handleSaveDraft = useCallback(async () => {
    const message = await createMessage({
      variables: {
        input: {
          characters,
        },
      },
    });

    if (message.data?.createMessage?.__typename === "MessageErrorV2") {
      return addToast(
        message.data?.createMessage?.error || "Unable to save message",
        {
          appearance: "error",
        }
      );
    }

    if (message?.data?.createMessage?.__typename === "MessageV2") {
      const draft = await createDraft({
        variables: {
          input: {
            messageId: message?.data?.createMessage?.id,
          },
        },
      });

      if (draft?.data?.createDraft?.__typename === "DraftErrorV2") {
        return addToast("Unable to save draft", {
          appearance: "error",
        });
      }

      return addToast("Draft saved", {
        appearance: "success",
      });
    }

    return addToast("Unable to save message", {
      appearance: "error",
    });
  }, [addToast, characters, createDraft, createMessage]);

  return {
    handleSaveDraft,
    creatingDraft: creatingDraft || creatingMessage,
  };
};
