import {
  BoardGallery,
  Button,
  FlexHorizontal,
  IBoard,
  IGalleryBoard,
  Icon,
  Input,
  Modal,
  Spacer,
  SubTitle2,
  createBoard,
  useToasts,
} from "@vestaboard/installables";
import {
  BoardOnlineOfflineStatus,
  useBoardOnlineOfflineStatus,
} from "../../hooks/useBoardOnlineOfflineStatus";
import {
  GET_INSTALLED_INSTALLABLES,
  MuteAllInstallablesModal,
  usePauseInstallablesMutation,
} from "../Installables/tabs/Installed";
import React, { useCallback, useState } from "react";
import { useGetLive, useGetLiveBoardSuperadmin } from "../../hooks/useGetLive";

import { Confirm } from "../../components/Confirm";
import { LoadMask } from "../../components/LoadMask";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAcceptInviteEffect } from "../../hooks/useAcceptInviteEffect";
import { useAppState } from "../../hooks/global";
import { useCopy } from "../../hooks/useCopy";
import { useCreateShareToken } from "../../hooks/useCreateShareToken";
import { useDeleteBoard } from "../../hooks/useDeleteBoard";
import { useHistory } from "react-router";
import { useLeaveBoard } from "../../hooks/useLeaveBoard";
import { useParams } from "react-router-dom";
import { usePermissions } from "../../hooks/usePermissions";
import { usePersonFavoritesList } from "../../hooks/usePersonFavoritesList";
import { useRenameBoard } from "../../hooks/useRenameBoard";

dayjs.extend(relativeTime);
interface IRoute {
  boardId: string;
}

type IBoardWithOwner = any;

interface IGalleryBoardWithAdmin extends IGalleryBoard {
  isAdmin: boolean;
}

export const Live = () => {
  const params = useParams<IRoute>();
  // super admin navigating to boardid for debugging
  const [firstLoadedBoardId] = useState(params.boardId);
  const { isSuperAdmin, data: permissionData } = usePermissions();
  const { data: liveBoardQuery } = useGetLiveBoardSuperadmin(
    firstLoadedBoardId,
    isSuperAdmin()
  );
  const { data, loading } = useGetLive();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const history = useHistory();
  const [createShareToken] = useCreateShareToken();
  const { copy } = useCopy();
  const { addToast } = useToasts();
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [renameTitle, setRenameTitle] = useState("");
  const [deleteBoard] = useDeleteBoard();
  const [leaveBoard] = useLeaveBoard();
  const [renameBoard] = useRenameBoard();
  const [showUnmute, setShowUnmute] = useState(false);

  const { addFavoritedMessage, removeFavoritedMessage, favorites } =
    usePersonFavoritesList();
  const onlineOfflineStatus = useBoardOnlineOfflineStatus(params.boardId);
  const personId = data?.viewer?.account?.person?.id;
  const closeLeaveModal = () => setShowLeaveModal(false);
  const closeDeleteModal = () => setShowDeleteModal(false);
  const { setSourcesHistoryFilter } = useAppState();

  const [pauseInstallables] = usePauseInstallablesMutation();
  const unmuteBoard = useCallback(async () => {
    await pauseInstallables({
      variables: {
        board: params.boardId,
        duration: 0,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_INSTALLED_INSTALLABLES,
          variables: {
            boardId: params.boardId,
          },
        },
      ],
    });
    setShowUnmute(false);
  }, [params.boardId]);

  useAcceptInviteEffect(params.boardId, permissionData?.viewer?.account?.id);

  const tenantBoardsObject = (data?.tenants || [])
    .reduce(
      (prev: Array<IBoardWithOwner>, current) => [
        ...prev,
        ...current.boards.map((board) => {
          const member = current.members.find(
            (member) => member?.person?.id === personId
          );
          return {
            ...board,
            personTenantAssociationId: member?.id || "",
            isOwner: member?.role === "Owner",
            isAdmin: member?.role === "Admin",
          };
        }),
      ],
      []
    )
    .reduce((prev, current) => {
      return {
        ...prev,
        [current.id]: current,
      };
    }, {});

  const tenantBoardsMap = [...Object.keys(tenantBoardsObject)].map(
    (key) => tenantBoardsObject[key]
  );

  React.useEffect(() => {
    // dont save lastboard id if super doesn't have access to it
    const hasRoleOnBoard = !!tenantBoardsMap?.find(
      (board) => board?.id === params.boardId
    );
    hasRoleOnBoard &&
      params.boardId &&
      localStorage.setItem("lastBoardId", params.boardId);
  }, [params.boardId, tenantBoardsMap]);

  // super admin board for debugging if sent a link
  const liveSuperAdminBoard =
    liveBoardQuery?.board &&
    isSuperAdmin() &&
    !tenantBoardsMap.map((board) => board.id).includes(firstLoadedBoardId)
      ? [liveBoardQuery?.board]
      : [];

  const tenantBoards: Array<IBoardWithOwner> = [
    ...tenantBoardsMap,
    ...liveSuperAdminBoard,
  ];

  const boards: Array<IGalleryBoardWithAdmin> = tenantBoards.map((board) => {
    const messageAppearance = board.history[0];
    const isVirtual = board.devices.length === 0;
    const isOnline =
      onlineOfflineStatus &&
      onlineOfflineStatus === BoardOnlineOfflineStatus.Online;

    return {
      id: board.id,
      title: board.title,
      isMuted: board.pausedUntil > Date.now(),
      pausedUntil: board.pausedUntil,
      message:
        (messageAppearance?.message?.formatted as IBoard) || createBoard(),
      messageId: messageAppearance?.message?.id as string,
      author: messageAppearance?.message?.authorFormatted,
      friendlyIdentifier: board.friendlyIdentifier,
      status: isVirtual ? "virtual" : isOnline ? "online" : "offline",
      created: messageAppearance
        ? dayjs(
            new Date(
              messageAppearance?.message.sendAt
                ? +messageAppearance?.message.sendAt
                : messageAppearance?.message?.created
                ? +messageAppearance?.message?.created
                : Date.now()
            )
          ).fromNow()
        : "",
      isOwner: board.isOwner,
      isAdmin: board.isAdmin,
      isFavorite: favorites.includes(messageAppearance?.message?.id || ""),
      boardStyle: board.boardStyle,
    };
  });

  const currentPage = boards.map((board) => board.id).indexOf(params.boardId);

  const currentBoard = boards[currentPage];

  if (loading) {
    return <LoadMask />;
  }

  if (!currentBoard) {
    return <LoadMask />;
  }

  if (data) {
    return (
      <>
        <Confirm
          title="Delete Board"
          message={`You are the owner of this Vestaboard. Removing "${currentBoard?.title}" will mean it is deleted and all users will lose access. Are you sure?`}
          open={showDeleteModal}
          handleClose={closeDeleteModal}
          handleAccept={async () => {
            closeDeleteModal();

            try {
              await deleteBoard({
                variables: {
                  input: {
                    boardId: currentBoard?.id,
                  },
                },
              });

              addToast(`The board was delete successfully`, {
                appearance: "success",
                autoDismiss: true,
              });

              history.push(`/`);
            } catch (err) {
              history.push(`/`);
            }
          }}
        />
        <Confirm
          title="Leave Board"
          message={`Leaving "${currentBoard?.title}" will mean you no longer have access to this Vestaboard. Are you sure?`}
          open={showLeaveModal}
          handleClose={closeLeaveModal}
          handleAccept={async () => {
            closeLeaveModal();

            const currentTenantBoard = tenantBoards[currentPage];

            if (!currentTenantBoard.personTenantAssociationId) {
              return;
            }

            try {
              await leaveBoard({
                variables: {
                  personTenantAssociationId:
                    currentTenantBoard.personTenantAssociationId,
                },
              });

              addToast(`The board was left successfully`, {
                appearance: "success",
                autoDismiss: true,
              });

              window.location.reload();

              history.push(`/`);
            } catch (err) {
              history.push(`/`);
            }
          }}
        />
        <Modal
          visible={showRenameModal}
          onClose={() => setShowRenameModal(false)}
        >
          <Spacer size="extraLarge" />
          <FlexHorizontal center>
            <SubTitle2>
              Rename {currentBoard?.title} &nbsp;
              <Icon type="edit" color="white" />
            </SubTitle2>
          </FlexHorizontal>
          <Spacer size="extraLarge" />
          <Input value={renameTitle} onValueChange={setRenameTitle} />
          <Spacer size="extraLarge" />
          <FlexHorizontal center>
            <Button
              buttonType="white"
              width={300}
              onClick={async () => {
                const response = await renameBoard({
                  variables: {
                    input: {
                      boardId: currentBoard?.id,
                      updateTitle: {
                        value: renameTitle,
                      },
                    },
                  },
                });
                // @ts-ignore
                if (response.errors && response.errors.length) {
                  addToast(`There was an error saving your board name`, {
                    appearance: "error",
                    autoDismiss: true,
                  });

                  return;
                }

                setRenameTitle("");
                setShowRenameModal(false);

                addToast(`The board was renamed successfully`, {
                  appearance: "success",
                  autoDismiss: true,
                });
              }}
            >
              Rename
            </Button>
          </FlexHorizontal>
        </Modal>
        {isPageLoading && <LoadMask />}
        <Spacer size="extraLarge" />
        <Spacer size="extraLarge" />
        <Spacer size="extraLarge" />
        <BoardGallery
          boards={boards}
          currentPage={currentPage}
          setPage={(pageNumber) => {
            setSourcesHistoryFilter([]);
            history.push(`/board/${boards[pageNumber].id}/live`);
          }}
          handleRename={() => {
            setShowRenameModal(true);
          }}
          handleLeave={() => {
            setShowLeaveModal(true);
          }}
          handlePublish={
            currentBoard?.isOwner || currentBoard?.isAdmin
              ? (board) => {
                  history.push(`/board/${board.id}/publish`);
                }
              : undefined
          }
          handleSettings={(board) => {
            history.push(`/board/${board.id}/settings`);
          }}
          handleCopy={async (board) => {
            await copy({
              value: board?.id || "",
            });
          }}
          handleDelete={() => {
            setShowDeleteModal(true);
          }}
          handleDuplicate={(board) => {
            history.push(
              `/board/${board.id}/compose/duplicate/${JSON.stringify(
                board.message
              )}`
            );
          }}
          handleFavorite={async (board) => {
            setIsPageLoading(true);

            if (!board.isFavorite) {
              await addFavoritedMessage(board?.messageId || "");
              addToast("Message added to favorites", {
                appearance: "success",
              });
            } else {
              await removeFavoritedMessage(board?.messageId || "");
              addToast("Message removed from favorites", {
                appearance: "success",
              });
            }

            setIsPageLoading(false);
          }}
          handleShare={async (board) => {
            if (!board.messageId) {
              return addToast("This board has no messages yet", {
                appearance: "error",
              });
            }

            setIsPageLoading(true);

            const { data } = await createShareToken({
              variables: {
                input: {
                  message: board.messageId,
                },
              },
            });

            await copy({
              value: `https://share.vestaboard.com/${data?.createMessageShareToken?.rawToken}`,
              successMessage: "Copied the share URL to your clipboard",
            });

            setIsPageLoading(false);
          }}
          handleMuted={(board) => {
            setShowUnmute(true);
          }}
        />
        <MuteAllInstallablesModal
          shown={showUnmute}
          onClose={() => setShowUnmute(false)}
          onMuteAll={() => unmuteBoard()}
          isMuted={true}
          // @ts-ignore
          pausedUntil={currentBoard.pausedUntil}
        />
      </>
    );
  }

  return null;
};
