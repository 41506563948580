import * as React from "react";

import { Box, Typography } from "@mui/material";
import { HOURS, MINUTES } from "../../config";

import { BoardV2 } from "../../gql";
import { Field } from "../../components/Field";
import { FilledButton } from "../../components/FilledButton";
import { OutlinedButton } from "../../components/OutlinedButton";
import { Select } from "../../components/Select";
import { Spacer } from "../../components/Spacer";
import { Switch } from "../../components/Switch";
import { ToggleButton } from "../../components/ToggleButton";
import { duration } from "./duration";
import { getFormattedTime } from "../../utils/time";
import { leftPad } from "../../utils/left-pad";
import { makeStyles } from "@mui/styles";
import { useGetBoardQuietHoursV2 } from "../../hooks/useGetBoardV2";
import { useGetMyRoleV2 } from "../../hooks/useGetRolesV2";
import { useParams } from "react-router";
import { useToasts } from "@vestaboard/installables";
import { useUpdateBoardV2 } from "../../hooks/useUpdateBoardV2";

interface IRouteParams {
  boardId: string;
}

const formatForMilitary = (amPm: string, timeString: string): string => {
  if (amPm === "AM" && timeString.startsWith("12")) {
    return `00${timeString.slice(2, 4)}`;
  }
  return timeString;
};

const useStyles = makeStyles({
  toggleContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    left: -24,
  },
  toggleLabel: {
    display: "block",
    fontSize: 24,
    fontWeight: 500,
  },
  colon: {
    fontSize: 24,
    fontWeight: 500,
    paddingLeft: 8,
    paddingRight: 8,
  },
  buttons: {
    display: "flex",
  },
  button: {
    minWidth: 150,
    marginTop: 26,
  },
  timePickers: {
    position: "relative",
    display: "flex",
    [`@media(max-width: 1080px)`]: {
      flexDirection: "column",
    },
  },
  timePickersMask: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    zIndex: 1,
  },
  timePicker: {
    marginBottom: 8,
  },
  timePickerInner: {
    display: "flex",
  },
  disabledTimePickers: {
    opacity: 0.5,
  },
  totalHours: {
    color: "#FFF",
    paddingTop: 37,
    paddingLeft: 32,
    [`@media(max-width: 1080px)`]: {
      paddingLeft: 0,
      paddingTop: 12,
    },
  },
});

export const QuietHoursV2 = () => {
  const params = useParams<IRouteParams>();

  const { data } = useGetBoardQuietHoursV2(params.boardId);
  const board = data?.BoardV2 as BoardV2;

  const hasQuietHours =
    (!!board?.quietHours.start && !!board?.quietHours.end) || false;
  const quietHoursBegin = board.quietHours.start || "0800";
  const quietHoursEnd = board.quietHoursEnd || "2000";
  const [enabled, setEnabled] = React.useState<boolean>(hasQuietHours);
  const [isDirty, setIsDirty] = React.useState(false);
  const [startTime, setStartTime] = React.useState(quietHoursBegin);
  const [endTime, setEndTime] = React.useState(quietHoursEnd);

  const classes = useStyles();
  const { addToast } = useToasts();
  const { data: myRoleData } = useGetMyRoleV2(params.boardId);
  const myRole =
    myRoleData?.viewerV2?.__typename === "PersonV2"
      ? myRoleData?.viewerV2?.myRoleForBoard
      : undefined;
  const iAmAdmin =
    myRole?.permission === "Owner" || myRole?.permission === "Admin";

  const [updateBoardV2] = useUpdateBoardV2();

  const {
    hour: startHour,
    minutes: startMinutes,
    amPm: startAmPm,
  } = getFormattedTime(startTime);

  const {
    hour: endHour,
    minutes: endMinutes,
    amPm: endAmPm,
  } = getFormattedTime(endTime);

  const { hours, minutes } = duration(startTime, endTime);

  const updateQuietHours = async () => {
    const startTimeString = formatForMilitary(startAmPm, startTime);
    const endTimeString = formatForMilitary(endAmPm, endTime);
    await updateBoardV2({
      variables: {
        input: {
          id: params.boardId,
          quietHoursStart: enabled ? startTimeString : "",
          quietHoursEnd: enabled ? endTimeString : "",
        },
      },
    });
  };

  return (
    <>
      <Box className={classes.toggleContainer}>
        <Switch
          onClick={() => {
            setEnabled((enabled) => !enabled);
            setIsDirty(true);
          }}
          checked={enabled}
          disabled={!iAmAdmin}
          size="small"
        />
        <Typography className={classes.toggleLabel}>
          {enabled ? "Enabled" : "Disabled"}
        </Typography>
      </Box>
      <Spacer height={12} />
      <Box
        className={[
          classes.timePickers,
          ...(!enabled ? [classes.disabledTimePickers] : []),
        ].join(" ")}
      >
        {!enabled && <Box className={classes.timePickersMask} />}
        <Box className={classes.timePicker}>
          <Field label="Starts">
            <Box className={classes.timePickerInner}>
              <Select
                disabled={!iAmAdmin}
                value={startHour}
                onValueChange={(startHour) => {
                  if (!startHour) return;
                  const startHourString =
                    startAmPm === "PM" ? +startHour + 12 : startHour;
                  setStartTime(`${startHourString}${startMinutes}`);
                  setIsDirty(true);
                }}
                options={HOURS.map((key) => ({
                  key,
                  value: key,
                }))}
              />
              <Typography className={classes.colon}> : </Typography>
              <Select
                disabled={!iAmAdmin}
                value={startMinutes}
                onValueChange={(startMinutes) => {
                  if (!startMinutes) return;
                  setStartTime(
                    `${
                      startAmPm === "PM" ? +startHour + 12 : startHour
                    }${startMinutes}`
                  );
                  setIsDirty(true);
                }}
                options={MINUTES.map((key) => ({
                  key,
                  value: key,
                }))}
              />
              <Spacer width={12} />
              <ToggleButton
                disabled={!iAmAdmin}
                options={[
                  {
                    key: "AM",
                    value: "AM",
                  },
                  {
                    key: "PM",
                    value: "PM",
                  },
                ]}
                value={startAmPm}
                onToggle={(startAmPm) => {
                  if (!startAmPm) return;
                  setStartTime(
                    `${
                      +startHour === 12
                        ? startAmPm === "AM"
                          ? +startHour + 12
                          : startHour
                        : startAmPm === "PM"
                        ? +startHour + 12
                        : startHour
                    }${startMinutes}`
                  );
                  setIsDirty(true);
                }}
              />
            </Box>
          </Field>
        </Box>
        <Spacer width={48} />
        <Box className={classes.timePicker}>
          <Field label="Ends">
            <Box className={classes.timePickerInner}>
              <Select
                disabled={!iAmAdmin}
                value={endHour}
                onValueChange={(endHour) => {
                  if (!endHour) return;
                  setEndTime(
                    `${leftPad(
                      +(endAmPm === "PM" ? +endHour + 12 : endHour)
                    )}${leftPad(+endMinutes)}`
                  );
                  setIsDirty(true);
                }}
                options={HOURS.map((key) => ({
                  key,
                  value: key,
                }))}
              />
              <Typography className={classes.colon}> : </Typography>
              <Select
                disabled={!iAmAdmin}
                value={endMinutes}
                onValueChange={(endMinutes) => {
                  if (!endMinutes) return;
                  setEndTime(
                    `${leftPad(
                      +(endAmPm === "PM" ? +endHour + 12 : endHour)
                    )}${leftPad(+endMinutes)}`
                  );
                  setIsDirty(true);
                }}
                options={MINUTES.map((key) => ({
                  key,
                  value: key,
                }))}
              />
              <Spacer width={12} />
              <ToggleButton
                disabled={!iAmAdmin}
                options={[
                  {
                    key: "AM",
                    value: "AM",
                  },
                  {
                    key: "PM",
                    value: "PM",
                  },
                ]}
                value={endAmPm}
                onToggle={(endAmPm) => {
                  if (!endAmPm) return;
                  setEndTime(
                    `${
                      +endHour === 12
                        ? endAmPm === "AM"
                          ? +endHour + 12
                          : endHour
                        : endAmPm === "PM"
                        ? +endHour + 12
                        : endHour
                    }${endMinutes}`
                  );
                  setIsDirty(true);
                }}
              />
            </Box>
          </Field>
        </Box>
        <Typography className={classes.totalHours}>
          {hours} {hours === 1 ? "hour" : "hours"}{" "}
          {!!minutes &&
            `and ${minutes} ${minutes === 1 ? "minute" : "minutes"}`}{" "}
          set
        </Typography>
      </Box>
      {!iAmAdmin && (
        <>
          <Spacer height={20} />
          <Typography>Only Admins can set Quiet Hours</Typography>
        </>
      )}
      {
        <>
          <Spacer height={12} />
          <Box className={classes.buttons}>
            <OutlinedButton
              type="button"
              disabled={!isDirty || !iAmAdmin}
              onClick={() => {
                setEnabled(hasQuietHours);
                setStartTime(quietHoursBegin);
                setEndTime(quietHoursEnd);
                setIsDirty(false);
              }}
              className={classes.button}
            >
              Cancel
            </OutlinedButton>
            <Spacer width={32} />
            <FilledButton
              variant="contained"
              className={classes.button}
              type="submit"
              disabled={!isDirty || !iAmAdmin}
              onClick={async () => {
                try {
                  await updateQuietHours();
                  addToast(`Quiet hours saved`, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                } catch (err) {
                  addToast(`There was an error saving your quiet hours`, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }}
            >
              Save
            </FilledButton>
          </Box>
        </>
      }
    </>
  );
};
