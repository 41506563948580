import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  CreateMessageAppearanceV2Mutation,
  CreateMessageAppearanceV2MutationVariables,
} from "../gql";

const MUTATION = gql`
  mutation CreateMessageAppearanceV2($input: CreateMessageAppearanceInputV2!) {
    createMessageAppearance: createMessageAppearanceV2(input: $input) {
      ... on MessageAppearanceV2 {
        id
        attribution
        sentAt
        created
        subscriptionId
        personId
        boardId
        message {
          id
          characters
          text
        }
      }
      ... on MessageAppearanceErrorV2 {
        type
        error
      }
    }
  }
`;

export const useCreateMessageAppearanceV2 = () => {
  return useMutation<
    CreateMessageAppearanceV2Mutation,
    CreateMessageAppearanceV2MutationVariables
  >(MUTATION);
};
