import { usePicksCategories, useTodaysPicks } from "../../hooks/usePicks";
import {
  Box,
  Popper,
  Grow,
  Button,
  ButtonBase,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  IBoard,
  SubTitle,
  SubTitle2,
  ToastProvider,
  useOutsideClick,
} from "@vestaboard/installables";
import { BREAKPOINT_EXTRA_SMALL, BREAKPOINT_MEDIUM } from "../../config";
import { useHistory, useParams } from "react-router-dom";
import { MessageCard } from "../../components/MessageCard";
import { usePersonFavoritesList } from "../../hooks/usePersonFavoritesList";
import { useGetPinnedMessage } from "../../hooks/useGetPinnedMessage";
import { useQuietHours } from "../../hooks/useQuietHours";
import { useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import { useGetRole } from "../../hooks/useGetRole";
import { PlusCTA } from "../Templates/PlusCTA";
import { useAppState } from "../../hooks/global";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles({
  header: {
    paddingLeft: "20px",
    paddingRight: "20px",
    padding: "20px",
    borderBottom: "solid 1px #2b2b2b",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingTop: "2rem",
    paddingLeft: "5rem",
    paddingRight: "5rem",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      padding: "1rem",
    },
  },
  categoryContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingBottom: "2rem",
    paddingLeft: "5rem",
    paddingRight: "5rem",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      padding: "1rem",
    },
  },
  categoryBoardContainer: {
    display: "flex",
    marginBottom: 24,
    position: "relative",
    cursor: "pointer",
    textAlign: "left",
    backgroundColor: "#181818",
    borderRadius: 8,
    width: "20%",
    borderWidth: 0,
    borderLeftWidth: 7,
    border: "solid",
    paddingLeft: 32,
    justifyContent: "flex-start",
    alignItems: "center",
    height: 100,
    [`@media(max-width: ${BREAKPOINT_MEDIUM})`]: {
      width: "44%",
      paddingLeft: 16,
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      width: "100%",
      paddingLeft: 32,
    },
  },
  ctaContainer: {
    width: "30%",
    height: "100%",
    [`@media(max-width: ${BREAKPOINT_MEDIUM})`]: {
      width: "100%",
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      width: "100%",
    },
  },
  boardContainer: {
    position: "relative",
    width: "30%",
    [`@media(max-width: ${BREAKPOINT_MEDIUM})`]: {
      width: "48%",
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      width: "100%",
    },
  },
  iconLabelWrapper: {
    flexDirection: "row-reverse",
  },
  icon: {
    width: 18,
    color: "#f5f5f7",
    marginLeft: 4,
    // margin: '5px 10px 20px 10px',
    opacity: 0.5,
    marginBottom: "0px !important",
  },
  categoryChevron: {
    transition: "transform 300ms",
  },
  categoryChevronInactive: {
    transform: "rotate(180deg)",
  },
  categoriesPopover: {
    marginTop: 20,
    color: "#1e2022",
    borderRadius: 8,
    boxShadow: "0 12px 12px 0 rgba(23, 24, 24, 0.39)",
    backgroundColor: "#f5f5f7",
    padding: "29px 47px 35px 47px",
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 300,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      maxWidth: "70vw",
    },
  },
  categoryButtonContainer: {
    paddingLeft: 78,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      paddingLeft: 16,
    },
  },
  categoriesText: {
    display: "block",
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 3,
    color: "#1e2022",
    padding: 0,
    flex: "0 0 50%",
    textTransform: "unset",
  },
  categoriesTextSelected: {
    color: "#f5f5f7",
    backgroundColor: "#1e2022",
    borderRadius: 8,
  },
});
const CATEGORY_BORDER_COLORS = [
  "yellow",
  "#0185D5",
  "#D92A1D",
  "white",
  "violet",
  "orange",
  "green",
  "grey",
];
export const TodaysPicks = () => {
  const { data } = usePicksCategories();
  const { data: todaysPicksData, loading } = useTodaysPicks();
  const classes = useStyles();
  const categories = data?.picksCategories;
  const params = useParams() as { boardId: string };
  const history = useHistory();
  const { iAmOwner } = useGetRole(params.boardId);

  const { favorites } = usePersonFavoritesList();
  const { quietHoursData, hasQuietHours } = useQuietHours(params.boardId);
  const { data: pinnedMessageData, loading: loadingPinnedMessage } =
    useGetPinnedMessage();
  const pinnedMessageId = pinnedMessageData?.board?.pinnedMessage?.message?.id;
  const pinnedUntil = pinnedMessageData?.board?.pinnedMessage?.pinnedUntil;
  const hasPinnedMessage = useMemo(() => {
    return pinnedMessageId && pinnedUntil
      ? dayjs(pinnedUntil).isAfter(dayjs())
      : false;
  }, [pinnedUntil, pinnedMessageId]);

  const messages =
    todaysPicksData?.todaysPicks.map((pick) => pick!.message) ||
    [];

  // set app state for board id so usePaywall hook will work
  const { setBoardId } = useAppState();
  useEffect(() => {
    setBoardId(params.boardId);
    params.boardId &&
      localStorage.setItem("lastBoardId", params.boardId);
  }, [params]);

  if (loading) {
    return (
      <Box className={classes.container}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ToastProvider offset={80}>
      <>
        <Box>
          <Box>
            <Box className={classes.categoryButtonContainer}>
              <SubTitle>Today's Picks</SubTitle>
            </Box>
            <Box className={classes.container}>
              {messages.map((message, index: number) => {
                const isFavorite = favorites.includes(message!.id);
                return (
                  <div className={classes.boardContainer} key={message.id}>
                    <MessageCard
                      // borderColor={BORDER_COLORS[index % BORDER_COLORS.length]}
                      isFavorite={isFavorite}
                      text={message.text || ""}
                      formatted={message.formatted as IBoard}
                      id={message.id}
                      key={message.id}
                      boardId={params.boardId}
                      onSend={async () => {}}
                      hasPinnedMessage={hasPinnedMessage}
                      quietHoursData={quietHoursData}
                      hasQuietHours={hasQuietHours}
                      iAmOwner={iAmOwner}
                      created={""}
                      author={""}
                    />
                  </div>
                );
              })}
            </Box>
            {/* <CatgegoriesPopover />
          <Spacer size={"large"} /> */}
            <Box className={classes.categoryContainer}>
              {categories &&
                categories.map((category, index) => (
                  <div
                    style={{
                      borderColor:
                        CATEGORY_BORDER_COLORS[
                          index % CATEGORY_BORDER_COLORS.length
                        ],
                    }}
                    className={classes.categoryBoardContainer}
                    role="button"
                    key={`${category.id}-button`}
                    onClick={() => {
                      history.push(
                        `/board/${params.boardId}/messages/picks/${category.id}`
                      );
                    }}
                  >
                    <SubTitle2>{category.title}</SubTitle2>
                  </div>
                ))}
              <PlusCTA
                alwaysShow
                noGradient
                message={"Take your enjoyment of Vestaboard to new levels"}
              />
            </Box>
          </Box>
        </Box>
      </>
    </ToastProvider>
  );
};

export const CatgegoriesPopover = (props: { title?: string }) => {
  const { data } = usePicksCategories();
  const history = useHistory();
  const params = useParams() as { boardId: string };
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  const categoryButtonRef = useRef<HTMLDivElement | null>(null);
  const [categoriesOpen, setCategoriesOpen] = useState(false);

  useOutsideClick(ref as any, () => {
    setCategoriesOpen(false);
  });

  return (
    <div className={classes.categoryButtonContainer} ref={ref}>
      <ButtonBase onClick={() => setCategoriesOpen(!categoriesOpen)}>
        <div ref={categoryButtonRef}>
          <SubTitle>{props.title || "Categories"}</SubTitle>
        </div>
        <KeyboardArrowUpIcon
          className={[
            classes.icon,
            classes.categoryChevron,
            !categoriesOpen && classes.categoryChevronInactive,
          ]
            .filter((e) => !!e)
            .join(" ")}
        />
      </ButtonBase>
      <Popper
        open={categoriesOpen}
        anchorEl={() => categoryButtonRef.current!!}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <div className={classes.categoriesPopover}>
              {data?.picksCategories.map((category) => (
                <Button
                  variant={"text"}
                  key={category.id}
                  onClick={() => {
                    history.push(
                      `/board/${params.boardId}/messages/picks/${category.id}`
                    );
                  }}
                  className={classes.categoriesText}
                >
                  {category.title}
                </Button>
              ))}
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
