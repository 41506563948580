import { useToasts } from "@vestaboard/installables";

interface ICopy {
  value: string;
  successMessage?: string;
  errorMessage?: string;
}

export const useCopy = () => {
  const { addToast } = useToasts();

  const copy = async (data: ICopy) => {
    try {
      await navigator.clipboard.writeText(data.value);
      addToast(data.successMessage || `Copied to your clipboard.`, {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      addToast(data.errorMessage || `Unable to copy to your clipboard.`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return {
    copy,
  };
};
