import * as React from "react";

import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import {
  CreateBoardFromPageMutation,
  CreateBoardFromPageMutationVariables,
} from "../../gql";

import { FETCH_ALL_BOARDS } from "../SelectBoard";
import { gql } from "@apollo/client";
import { history } from "../../providers/AppRouterProvider";
import { useMutation } from "@apollo/client";

interface ICreateBoardPageProps {}

type CreateBoardPageProps = ICreateBoardPageProps;

const createBoardFromPageMutation = gql`
  mutation CreateBoardFromPageMutation($input: CreateBoardInput!) {
    createBoard(input: $input) {
      created
      board {
        id
        boardSet {
          id
          title
          boards {
            id
            title
          }
        }
      }
    }
  }
`;

export const CreateBoardPage: React.FC<CreateBoardPageProps> = (props) => {
  const [title, setTitle] = React.useState("");
  const [boardStyle, setBoardStyle] = React.useState("black");

  const variables: CreateBoardFromPageMutationVariables = {
    input: {
      title,
      boardStyle,
    },
  };

  const [createBoard] = useMutation<
    CreateBoardFromPageMutation,
    CreateBoardFromPageMutationVariables
  >(createBoardFromPageMutation);

  const submit = async () => {
    const created = await createBoard({
      variables,
      refetchQueries: [
        {
          query: FETCH_ALL_BOARDS,
        },
      ],
    });
    if (
      created &&
      created.data &&
      created.data.createBoard &&
      created.data.createBoard.board
    ) {
      history.push(`/board/${created.data.createBoard.board.id}/compose`);
    }
  };

  const [checkboxValue, setCheckboxValue] = React.useState(false);

  const submitDisabled = !title || !checkboxValue;

  return (
    <div>
      <h3>Create a new Vestaboard</h3>
      <p>
        Create and name your new Vestaboard so that you can begin sending
        messages.
      </p>
      <div className="mt4 w-50-ns mt3 mb3">
        <TextField
          label={"Vestaboard name"}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          multiline
          variant="filled"
          fullWidth
        />
      </div>
      <div className="mt4 w-50-ns mt3 mb3">
        <InputLabel>Display Type</InputLabel>
        <Select
          label={"Display Type"}
          value={boardStyle}
          onChange={(e) => setBoardStyle(e.target.value as string)}
          variant="filled"
          fullWidth
        >
          {["Black", "White"].map((style) => (
            <MenuItem key={style} value={style.toLocaleLowerCase()}>
              {style}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="w-50-md mb4 mb4">
        <div className="f6 mb3">
          <i>
            The use of the Vestaboard Website, Mobile Applications, Web Services
            and API is conditioned on your agreement with us to the Terms of
            Service for the Vestaboard Service and Privacy Policy found at{" "}
            <a href="https://www.vestaboard.com/legal">
              https://www.vestaboard.com/legal
            </a>
          </i>
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxValue}
              onChange={(e) => setCheckboxValue(e.target.checked)}
              color={"primary"}
            />
          }
          label="I agree"
        />
      </div>
      <Button
        variant={"contained"}
        color={"primary"}
        disabled={submitDisabled}
        onClick={() => submit()}
      >
        Create Vestaboard
      </Button>
    </div>
  );
};
