
import { useMutation,gql } from '@apollo/client'
import { UpdateRoleV2Mutation, UpdateRoleV2MutationVariables } from '../gql';

const UPDATE_USER_ROLE_V2 = gql`
  mutation UpdateRoleV2($input: UpdateRoleInputV2!) {
    updateRoleV2(input: $input) {
      ... on RoleV2 {
        id
        permission
      }
      ... on RoleErrorV2 {
        type
        error
      }
    }
  }
`

export enum PermissionV2 {
    "Owner" = "Owner",
    "Admin" = "Admin",
    "User" = "User",
  }

export const useUpdateUserRoleV2 = () => {
    return useMutation<UpdateRoleV2Mutation, UpdateRoleV2MutationVariables>(UPDATE_USER_ROLE_V2)
};