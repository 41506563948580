import * as React from "react";

import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  GetQuietHoursQuery,
  GetQuietHoursQueryVariables,
  UpdateQuietHoursMutation,
  UpdateQuietHoursMutationVariables,
} from "../../gql";
import { HOURS, MINUTES } from "../../config";
import { useMutation, useQuery } from "@apollo/client";

import { Field } from "../../components/Field";
import { FilledButton } from "../../components/FilledButton";
import { OutlinedButton } from "../../components/OutlinedButton";
import { Select } from "../../components/Select";
import { Spacer } from "../../components/Spacer";
import { Switch } from "../../components/Switch";
import { ToggleButton } from "../../components/ToggleButton";
import { duration } from "./duration";
import gql from "graphql-tag";
import { useFeatures } from "../../hooks/useFeatures";
import { useParams } from "react-router";
import { useToasts } from "@vestaboard/installables";
import { getFormattedTime } from "../../utils/time";
import { leftPad } from "../../utils/left-pad";
import { useGetRole } from "../../hooks/useGetRole";

interface IRouteParams {
  boardId: string;
}

interface IQuietHoursForm {
  data: any;
  boardId: string;
}

export const GET_QUIET_HOURS = gql`
  query GetQuietHours($boardId: String!) {
    board(id: $boardId) {
      id
      quietHoursBegin
      quietHoursEnd
    }
  }
`;

const UPDATE_QUIET_HOURS = gql`
  mutation UpdateQuietHours($input: UpdateBoardInput!) {
    updateBoard(input: $input) {
      board {
        id
        quietHoursBegin
        quietHoursEnd
      }
    }
  }
`;

const formatForMilitary = (amPm: string, timeString: string): string => {
  if (amPm === "AM" && timeString.startsWith("12")) {
    return `00${timeString.slice(2, 4)}`;
  }
  return timeString;
};

const useStyles = makeStyles({
  toggleContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    left: -24,
  },
  toggleLabel: {
    display: "block",
    fontSize: 24,
    fontWeight: 500,
  },
  colon: {
    fontSize: 24,
    fontWeight: 500,
    paddingLeft: 8,
    paddingRight: 8,
  },
  buttons: {
    display: "flex",
  },
  button: {
    minWidth: 150,
    marginTop: 26,
  },
  timePickers: {
    position: "relative",
    display: "flex",
    [`@media(max-width: 1080px)`]: {
      flexDirection: "column",
    },
  },
  timePickersMask: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    zIndex: 1,
  },
  timePicker: {
    marginBottom: 8,
  },
  timePickerInner: {
    display: "flex",
  },
  disabledTimePickers: {
    opacity: 0.5,
  },
  totalHours: {
    color: "#FFF",
    paddingTop: 37,
    paddingLeft: 32,
    [`@media(max-width: 1080px)`]: {
      paddingLeft: 0,
      paddingTop: 12,
    },
  },
});

const QuietHoursForm = (props: IQuietHoursForm) => {
  const hasQuietHours = !!(
    props.data.quietHoursBegin || props.data.quietHoursEnd
  );
  const quietHoursBegin = props.data.quietHoursBegin || "0800";
  const quietHoursEnd = props.data.quietHoursEnd || "2000";
  const [enabled, setEnabled] = React.useState<boolean>(hasQuietHours);
  const [isDirty, setIsDirty] = React.useState(false);
  const [startTime, setStartTime] = React.useState(quietHoursBegin);
  const [endTime, setEndTime] = React.useState(quietHoursEnd);
  const [updateQuietHoursMutation] = useMutation<
    UpdateQuietHoursMutation,
    UpdateQuietHoursMutationVariables
  >(UPDATE_QUIET_HOURS);
  const classes = useStyles();
  const { addToast } = useToasts();
  const { features } = useFeatures();
  const { iAmAdmin } = useGetRole(props.boardId);

  const {
    hour: startHour,
    minutes: startMinutes,
    amPm: startAmPm,
  } = getFormattedTime(startTime);

  const {
    hour: endHour,
    minutes: endMinutes,
    amPm: endAmPm,
  } = getFormattedTime(endTime);

  const { hours, minutes } = duration(startTime, endTime);

  const updateQuietHours = async () => {
    const startTimeString = formatForMilitary(startAmPm, startTime);
    const endTimeString = formatForMilitary(endAmPm, endTime);
    await updateQuietHoursMutation({
      variables: {
        input: {
          boardId: props.boardId,
          quietHoursBegin: enabled ? startTimeString : "",
          quietHoursEnd: enabled ? endTimeString : "",
        },
      },
    });
  };

  return (
    <>
      <Box className={classes.toggleContainer}>
        <Switch
          onClick={() => {
            setEnabled((enabled) => !enabled);
            setIsDirty(true);
          }}
          checked={enabled}
          disabled={!features.settings.modifyQuietHours || !iAmAdmin}
          size="small"
        />
        <Typography className={classes.toggleLabel}>
          {enabled ? "Enabled" : "Disabled"}
        </Typography>
      </Box>
      <Spacer height={12} />
      <Box
        className={[
          classes.timePickers,
          ...(!enabled ? [classes.disabledTimePickers] : []),
        ].join(" ")}
      >
        {!enabled && <Box className={classes.timePickersMask} />}
        <Box className={classes.timePicker}>
          <Field label="Starts">
            <Box className={classes.timePickerInner}>
              <Select
                disabled={!features.settings.modifyQuietHours || !iAmAdmin}
                value={startHour}
                onValueChange={(startHour) => {
                  const startHourString =
                    startAmPm === "PM" ? +startHour + 12 : startHour;
                  setStartTime(`${startHourString}${startMinutes}`);
                  setIsDirty(true);
                }}
                options={HOURS.map((key) => ({
                  key,
                  value: key,
                }))}
              />
              <Typography className={classes.colon}> : </Typography>
              <Select
                disabled={!features.settings.modifyQuietHours || !iAmAdmin}
                value={startMinutes}
                onValueChange={(startMinutes) => {
                  setStartTime(
                    `${
                      startAmPm === "PM" ? +startHour + 12 : startHour
                    }${startMinutes}`
                  );
                  setIsDirty(true);
                }}
                options={MINUTES.map((key) => ({
                  key,
                  value: key,
                }))}
              />
              <Spacer width={12} />
              <ToggleButton
                disabled={!features.settings.modifyQuietHours || !iAmAdmin}
                options={[
                  {
                    key: "AM",
                    value: "AM",
                  },
                  {
                    key: "PM",
                    value: "PM",
                  },
                ]}
                value={startAmPm}
                onToggle={(startAmPm) => {
                  setStartTime(
                    `${
                      +startHour === 12
                        ? startAmPm === "AM"
                          ? +startHour + 12
                          : startHour
                        : startAmPm === "PM"
                        ? +startHour + 12
                        : startHour
                    }${startMinutes}`
                  );
                  setIsDirty(true);
                }}
              />
            </Box>
          </Field>
        </Box>
        <Spacer width={48} />
        <Box className={classes.timePicker}>
          <Field label="Ends">
            <Box className={classes.timePickerInner}>
              <Select
                disabled={!features.settings.modifyQuietHours || !iAmAdmin}
                value={endHour}
                onValueChange={(endHour) => {
                  setEndTime(
                    `${leftPad(
                      +(endAmPm === "PM" ? +endHour + 12 : endHour)
                    )}${leftPad(+endMinutes)}`
                  );
                  setIsDirty(true);
                }}
                options={HOURS.map((key) => ({
                  key,
                  value: key,
                }))}
              />
              <Typography className={classes.colon}> : </Typography>
              <Select
                disabled={!features.settings.modifyQuietHours || !iAmAdmin}
                value={endMinutes}
                onValueChange={(endMinutes) => {
                  setEndTime(
                    `${leftPad(
                      +(endAmPm === "PM" ? +endHour + 12 : endHour)
                    )}${leftPad(+endMinutes)}`
                  );
                  setIsDirty(true);
                }}
                options={MINUTES.map((key) => ({
                  key,
                  value: key,
                }))}
              />
              <Spacer width={12} />
              <ToggleButton
                disabled={!features.settings.modifyQuietHours || !iAmAdmin}
                options={[
                  {
                    key: "AM",
                    value: "AM",
                  },
                  {
                    key: "PM",
                    value: "PM",
                  },
                ]}
                value={endAmPm}
                onToggle={(endAmPm) => {
                  setEndTime(
                    `${
                      +endHour === 12
                        ? endAmPm === "AM"
                          ? +endHour + 12
                          : endHour
                        : endAmPm === "PM"
                        ? +endHour + 12
                        : endHour
                    }${endMinutes}`
                  );
                  setIsDirty(true);
                }}
              />
            </Box>
          </Field>
        </Box>
        <Typography className={classes.totalHours}>
          {hours} {hours === 1 ? "hour" : "hours"}{" "}
          {!!minutes &&
            `and ${minutes} ${minutes === 1 ? "minute" : "minutes"}`}{" "}
          set
        </Typography>
      </Box>
      {!iAmAdmin && (
        <>
          <Spacer height={20} />
          <Typography>Only Admins can set Quiet Hours</Typography>
        </>
      )}
      {features.settings.modifyQuietHours && (
        <>
          <Spacer height={12} />
          <Box className={classes.buttons}>
            <OutlinedButton
              type="button"
              disabled={!isDirty || !iAmAdmin}
              onClick={() => {
                setEnabled(hasQuietHours);
                setStartTime(quietHoursBegin);
                setEndTime(quietHoursEnd);
                setIsDirty(false);
              }}
              className={classes.button}
            >
              Cancel
            </OutlinedButton>
            <Spacer width={32} />
            <FilledButton
              variant="contained"
              className={classes.button}
              type="submit"
              disabled={!isDirty || !iAmAdmin}
              onClick={async () => {
                try {
                  await updateQuietHours();
                  addToast(`Quiet hours saved`, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                } catch (err) {
                  addToast(`There was an error saving your quiet hours`, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }}
            >
              Save
            </FilledButton>
          </Box>
        </>
      )}
    </>
  );
};

export const QuietHours = () => {
  const params = useParams<IRouteParams>();
  const { data, loading } = useQuery<
    GetQuietHoursQuery,
    GetQuietHoursQueryVariables
  >(GET_QUIET_HOURS, {
    variables: {
      boardId: params.boardId,
    },
  });

  if (loading || !data) {
    return <CircularProgress />;
  }

  return <QuietHoursForm data={data.board} boardId={params.boardId} />;
};
