import * as React from "react";

import { BoardV2 } from "../../gql";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Field } from "../../components/Field";
import { FilledButton } from "../../components/FilledButton";
import { OutlinedButton } from "../../components/OutlinedButton";
import { Select } from "../../components/Select";
import { Spacer } from "../../components/Spacer";
import { useFeatures } from "../../hooks/useFeatures";
import { useParams } from "react-router-dom";
import { useToasts } from "@vestaboard/installables";
import { useIsMobileMediaQuery } from "../../hooks/media-queries";
import { useGetMyRoleV2 } from "../../hooks/useGetRolesV2";
import { useGetBoardQuietHoursV2 } from "../../hooks/useGetBoardV2";
import { useUpdateBoardV2 } from "../../hooks/useUpdateBoardV2";

const useStyles = makeStyles({
  form: {
    display: "flex",
    "@media(max-width: 600px)": {
      flexDirection: "column",
    },
  },
  buttons: {
    display: "flex",
  },
  button: {
    minWidth: 150,
    marginTop: 26,
  },
  container: {
    maxWidth: 736,
  },
});

interface IParams {
  boardId: string;
}

export const TimeZoneV2 = () => {
  const isMobile = useIsMobileMediaQuery();
  const classes = useStyles();
  const [timezone, setTimezone] = React.useState("");
  const [country, setCountry] = React.useState("");

  const { boardId } = useParams<IParams>();
  const { addToast } = useToasts();

  const { data: boardData } = useGetBoardQuietHoursV2(boardId);
  const board = boardData?.BoardV2 as BoardV2;
  const { data: myRoleData } = useGetMyRoleV2(boardId);
  const myRole =
    myRoleData?.viewerV2?.__typename === "PersonV2"
      ? myRoleData?.viewerV2?.myRoleForBoard
      : undefined;
  const iAmAdmin =
    myRole?.permission === "Owner" || myRole?.permission === "Admin";

  const [updateTimezone] = useUpdateBoardV2();

  const setRemoteTimezone = () => {
    const timeZoneCity = board?.timezone || "";
    if (timeZoneCity) {
      setTimezone(timeZoneCity);
      setCountry(timeZoneCity.split("/")[0]);
    }
  };

  React.useEffect(setRemoteTimezone, [board, setTimezone]);

  const countries = React.useMemo(
    () =>
      Array.from(
        new Set(
          Intl.supportedValuesOf("timeZone").map((tz) => tz.split("/")?.[0])
        )
      ),
    []
  );
  const timezones = React.useMemo(
    () =>
      Intl.supportedValuesOf("timeZone").filter((tz) => tz.includes(country)),
    [country]
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.form}>
        <Field label="Country">
          <Select
            disabled={!iAmAdmin}
            value={country}
            onValueChange={setCountry}
            options={countries.map((country) => ({
              key: country,
              value: country,
            }))}
            fullWidth
          />
        </Field>
        {isMobile ? <Spacer height={20} /> : <Spacer width={24} />}
        <Field label="Timezone">
          <Select
            disabled={!iAmAdmin}
            value={timezone}
            onValueChange={setTimezone}
            options={timezones.map((timezone: string) => ({
              key: timezone,
              value: timezone,
            }))}
            fullWidth
          />
        </Field>
      </Box>
      {!iAmAdmin && (
        <>
          <Spacer height={20} />
          <Typography>Only Admins can update the Timezone</Typography>
        </>
      )}

      <Box className={classes.buttons}>
        <OutlinedButton
          type="button"
          onClick={setRemoteTimezone}
          className={classes.button}
          disabled={!iAmAdmin}
        >
          Cancel
        </OutlinedButton>
        <Spacer width={32} />

        <FilledButton
          variant="contained"
          className={classes.button}
          type="submit"
          disabled={!iAmAdmin}
          onClick={async () => {
            try {
              await updateTimezone({
                variables: {
                  input: {
                    id: boardId,
                    timezone,
                  },
                },
              });

              addToast(`Timezone saved`, {
                appearance: "success",
                autoDismiss: true,
              });
            } catch (err) {
              addToast(`There was an error saving your timezone`, {
                appearance: "error",
                autoDismiss: true,
              });
            }
          }}
        >
          Save
        </FilledButton>
      </Box>
    </Box>
  );
};
