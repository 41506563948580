import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  DeleteMessageAppearanceV2Mutation,
  DeleteMessageAppearanceV2MutationVariables,
} from "../gql";

const MUTATION = gql`
  mutation DeleteMessageAppearanceV2($input: DeleteMessageAppearanceInputV2!) {
    deleteMessageAppearance: deleteMessageAppearanceV2(input: $input) {
      ... on MessageAppearanceV2 {
        id
      }
      ... on MessageAppearanceErrorV2 {
        type
        error
      }
    }
  }
`;

export const useDeleteMessageAppearanceV2 = () => {
  return useMutation<
    DeleteMessageAppearanceV2Mutation,
    DeleteMessageAppearanceV2MutationVariables
  >(MUTATION);
};
