import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  BroadcastMessageMutation,
  BroadcastMessageMutationVariables,
} from "../gql";

const BroadcastMessage = gql`
  mutation BroadcastMessage($input: BroadcastMessageInput!) {
    broadcastMessage(input: $input) {
      messages {
        created
      }
    }
  }
`;

export const useBroadcastMessage = () => {
  return useMutation<
    BroadcastMessageMutation,
    BroadcastMessageMutationVariables
  >(BroadcastMessage);
};
