import { Skeleton } from "@mui/material";
import { MessageGrid } from "./MessageGrid";
import { makeStyles } from "@mui/styles";
import { MessageGridItem } from "./MessageGridItem";

const useStyles = makeStyles({
  cardPreview: {
    border: `1px solid #313437`,
    display: `flex`,
    maxWidth: `600px`,
    minWidth: `290px`,
    boxShadow: `0px 60px 85px -22px rgba(0,0,0,0.83)`,
    borderRadius: `6px`,
    flexDirection: `column`,
    minHeight: `200px`,
  },
});

export const InfiniteLoader = () => {
  const classes = useStyles();
  return (
    <MessageGrid>
      {[0].map((index) => (
        <MessageGridItem key={index}>
          <Skeleton variant="rectangular" className={classes.cardPreview} />
        </MessageGridItem>
      ))}
    </MessageGrid>
  );
};
