import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import ToggleButton from "@mui/lab/ToggleButton";
import ToggleButtonGroup from "@mui/lab/ToggleButtonGroup";
import * as React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  GetInstalledInstallablesQuery,
  GetInstalledInstallablesQueryVariables,
} from "../../../gql";

import { useMutation, useQuery } from "@apollo/client";
import { Close } from "@mui/icons-material";
import gql from "graphql-tag";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { usePaywall } from "../../../providers/PaywallProvider";
import ArrowButton from "../../../components/ArrowButton";
import { BlurModal } from "../../../components/BlurModal";
import CircleBlurredButton from "../../../components/CircleBlurredButton";
import { FilledButton } from "../../../components/FilledButton";
import { FlatButton } from "../../../components/FlatButton";
import { GrayFilledButton } from "../../../components/GrayFilledButton";
import { Modal } from "../../../components/Modal";
import { OutlinedButton } from "../../../components/OutlinedButton";
import { BREAKPOINT_EXTRA_SMALL, BREAKPOINT_SMALL } from "../../../config";
import { useAppState } from "../../../hooks/global";
import { InstallableListItem } from "../components/InstallableListItem";
import { ListItemsContainer } from "../components/ListItemsContainer";
import InstallablesFilterDropdown from "./components/InstallablesFilterDropdown";
import InstallablesSortByDropdown from "./components/InstallablesSortByDropdown";
import {
  PauseInstallablesMutation,
  PauseInstallablesMutationVariables,
} from "../../../gql";
import { Spacer } from "../../../components/Spacer";
import { useMemo } from "react";
import dayjs from "dayjs";
import { Progress } from "@vestaboard/installables";
import { useBlendMessageSet } from "../../../hooks/useBlendMessageSet";

const FREQUENCY_NOT_SET = "Frequency Not Set";

export const GET_INSTALLED_INSTALLABLES = gql`
  query GetInstalledInstallables($boardId: String!) {
    board(id: $boardId) {
      id
      pausedUntil
      subscriptionSet {
        id
        channels {
          id
          runOfShow {
            id
            description
            firstAppearance
          }
          configurationUrl
          title
          icon
          muted
          created
          marketplaceListing {
            id
            title
            icon
            hiddenFromProduction
            free
            installable {
              id
              title
              icon
              created
              public
              installationsAreSingleton
              hideFromInstallablesList
            }
          }
        }
      }
    }
  }
`;

const PAUSE_INSTALLABLES_MUTATION = gql`
  mutation PauseInstallables($board: String!, $duration: Int!) {
    pauseInstallables(input: { board: $board, duration: $duration }) {
      paused
    }
  }
`;

export const usePauseInstallablesMutation = () =>
  useMutation<PauseInstallablesMutation, PauseInstallablesMutationVariables>(
    PAUSE_INSTALLABLES_MUTATION
  );

interface IRouteMatch {
  boardId: string;
}

const getTime = (time: string) => {
  const hour = time ? time.split(":")[0] : "00";
  const minutes = time ? time.split(":")[1] : "00";
  const result = time ? +`${hour === "24" ? "00" : hour}${minutes}` : 999999;

  return isNaN(result) ? 999999 : result;
};

const useStyles = makeStyles({
  listItem: {
    paddingBottom: 32,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      paddingBottom: 48,
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emptyState: {
    textAlign: "center",
  },
  logo: {
    marginBottom: 32,
  },
  emptyStateHeading: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 12,
  },
  emptyStateBody: {
    color: "#c6c6c8",
    width: "90%",
    maxWidth: 300,
    margin: "0 auto 44px",
  },
  linkBtn: {
    padding: "8px 28px",
    borderRadius: 8,
    "-webkit-backdrop-filter": "blur(10px)",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(125, 135, 142, 0.2)",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: 1.67,
    color: "#f5f5f7 !important",
    textTransform: "none",
    boxShadow: "none",
    display: "inline-block",
    "&:hover": {
      textDecoration: "none",
    },
    "&:focus": {
      backgroundColor: "rgba(125, 135, 142, 0.2)",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 810,
    flexGrow: 1,
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "solid 1px #2b2b2b",
    marginBottom: 57,
    paddingBottom: 16,
  },
  muteBtn: {
    marginRight: 16,
  },
  listItems: {
    paddingLeft: 18,
    paddingRight: 18,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      padding: 0,
    },
  },
  modal: {
    position: "relative",
    backgroundColor: "#f5f5f7",
    borderRadius: "8px",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      borderRadius: 0,
      minHeight: "100vh",
    },
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    color: "#1e2022",
    alignItems: "center",
    minHeight: "60vh",
    zIndex: 1,
    padding: "56px 164px 30px 164px",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
    "&::before": {
      content: "''",
      backgroundImage: "url('/paywall-left.png')",
      position: "absolute",
      left: 0,
      top: 0,
      width: 400,
      display: "block",
      height: "100%",
      backgroundRepeat: "no-repeat",
      zIndex: -1,
      backgroundSize: "70%",
      borderTopLeftRadius: "8px",
      [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
        display: "none",
      },
      [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
        width: 300,
      },
    },
    "&::after": {
      content: "''",
      backgroundImage: "url('/paywall-right.png')",
      position: "absolute",
      right: 0,
      top: 0,
      width: 400,
      display: "block",
      height: "100%",
      backgroundRepeat: "no-repeat",
      zIndex: -1,
      backgroundSize: "70%",
      backgroundPosition: "top right",
      borderTopRightRadius: "8px",
      [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
        width: 300,
      },
      [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
        width: 250,
      },
    },
  },
  activateLogo: {
    marginBottom: 16,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      marginTop: 32,
      marginBottom: 32,
      alignSelf: "start",
    },
  },
  modalHeading: {
    textAlign: "center",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      textAlign: "left",
    },
  },
  tagline: {
    fontFamily: "HelveticaNeue",
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "0.15px",
    color: "#1e2022",
    marginBottom: 8,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      fontSize: 18,
      maxWidth: "90%",
      alignSelf: "start",
    },
  },
  description: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.63,
    letterSpacing: "0.15px",
    color: "#1e2022",
    marginBottom: 34,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      lineHeight: 1.25,
      width: "100%",
    },
  },
  closeBtn: {
    position: "absolute",
    top: 20,
    right: 18,
    zIndex: 2,
    "&:hover": {
      backgroundColor: "rgba(125, 135, 142, 0.5)",
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      display: "none",
    },
  },
  backBtn: {
    display: "none",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      display: "block",
      position: "absolute",
      top: 20,
      left: 24,
      zIndex: 2,
      marginBottom: 28,
    },
  },
  activateBtn: {
    width: "100%",
    color: "white !important",
    "& p": {
      opacity: "1 !important",
      fontSize: 16,
      lineHeight: 1.63,
    },
    marginBottom: 14,
    marginTop: 16,
    borderRadius: 5,
    backgroundColor: "#1e2022",
  },
  textBtn: {
    color: "#1e2022",
    textTransform: "none",
    fontWeight: 400,
  },
});

export const Installed = () => {
  const classes = useStyles();
  const params = useParams<IRouteMatch>();
  const { boardId } = params;
  // set app state for board id so usePaywall hook will work // TODO: add effect to usePaywall?
  const { tenantId, setBoardId } = useAppState();

  const [showActivatePlanModal, setShowActivatePlanModal] =
    React.useState(false);

  const { data, loading } = useQuery<
    GetInstalledInstallablesQuery,
    GetInstalledInstallablesQueryVariables
  >(GET_INSTALLED_INSTALLABLES, {
    variables: {
      boardId: boardId,
    },
    fetchPolicy: "network-only",
  });

  const [pauseInstallables] = usePauseInstallablesMutation();

  const [prioritizing, setPrioritizing] = React.useState(false);
  const [channelSort, setChannelSort] = React.useState("daily_schedule");

  const isMuted = useMemo(
    () => data?.board.pausedUntil && data?.board.pausedUntil > Date.now(),
    [data?.board.pausedUntil]
  );
  const { isPlus, setShown } = usePaywall();

  // Pre fetch to prevent blend button loading state
  useBlendMessageSet(boardId);

  const paywall = (action: () => any) => {
    if (!isPlus) {
      setShowActivatePlanModal(true);
    } else {
      action();
    }
  };

  const items =
    data?.board.subscriptionSet.channels
      .filter(
        (subscription) =>
          !!subscription?.marketplaceListing &&
          !!(subscription.marketplaceListing
            ? subscription.marketplaceListing.installable
            : null)
      )
      .map((subscription) => {
        const installable = (
          subscription.marketplaceListing
            ? subscription.marketplaceListing.installable
            : null
        )!!;

        return {
          ...subscription,
          installableId: installable.id,
        };
      }) ?? [];

  const [itemIdOrder, setItemIdOrder] = React.useState<Array<string>>(
    items.map((i) => i.id)
  );

  React.useEffect(() => {
    setBoardId(params.boardId);
    params.boardId && localStorage.setItem("lastBoardId", params.boardId);
  }, [params]);

  React.useEffect(() => {
    setItemIdOrder(items.map((i) => i.id));
  }, [items.length]);

  const [visibleGroups, setVisibleGroups] = React.useState("all");

  const orderedItems = itemIdOrder
    .map((itemId) => items.find((i) => i.id === itemId)!!)
    .filter((item) => {
      switch (visibleGroups) {
        case "enabled":
          return !item.muted;
        case "disabled":
          return item.muted;
        case "all":
          return true;
      }
    });

  const [mutingAll, setMutingAll] = React.useState(false);

  if (!data || loading) {
    return <CircularProgress />;
  }

  const muteAll = async (duration: number) => {
    await pauseInstallables({
      variables: {
        board: boardId,
        duration,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_INSTALLED_INSTALLABLES,
          variables: {
            boardId: params.boardId,
          },
        },
      ],
    });
    setMutingAll(false);
  };

  return (
    <>
      <Modal
        shown={showActivatePlanModal}
        onClose={() => setShowActivatePlanModal(false)}
        className={classes.modal}
      >
        <ArrowButton
          aria-label="Go back"
          className={classes.backBtn}
          arrowType="arrow"
          direction="left"
          background="transparent"
          onClick={() => setShowActivatePlanModal(false)}
        />
        <CircleBlurredButton
          className={classes.closeBtn}
          onClick={() => setShowActivatePlanModal(false)}
        >
          <Close />
        </CircleBlurredButton>
        <div className={classes.modalContent}>
          <img
            src={"/vestaboard-plus-logo-black.svg"}
            height={30}
            className={classes.activateLogo}
            alt="Vestaboard logo"
            style={{ marginBottom: 40 }}
          />
          <div className={classes.modalHeading}>
            <h1 className={classes.tagline}>Your plan is not active</h1>
            <h2 className={classes.description}>
              Activate now to select and automate a growing library of content
            </h2>
          </div>
          <FlatButton
            onClick={() => setShown(true, null)}
            className={classes.activateBtn}
            type="button"
          >
            Activate Now
          </FlatButton>
          <Button
            variant="text"
            onClick={() => setShowActivatePlanModal(false)} // TODO: need to go "back" to manage tab
            className={classes.textBtn}
          >
            Back
          </Button>
        </div>
      </Modal>
      <MuteAllInstallablesModal
        shown={mutingAll}
        onClose={() => setMutingAll(false)}
        onMuteAll={async (duration) => await muteAll(duration)}
        isMuted={isMuted}
        pausedUntil={data?.board.pausedUntil}
      />
      <Box className={classes.contentWrapper}>
        <Box className={classes.content}>
          <div className={classes.header}>
            <span>
              <InstallablesFilterDropdown
                label={(() => {
                  switch (visibleGroups) {
                    case "all":
                      return "Filter";
                    case "enabled":
                      return "Enabled";
                    case "disabled":
                      return "Disabled";
                    default:
                      return "Filter";
                  }
                })()}
                options={[
                  {
                    id: "all",
                    label: "All",
                  },
                  {
                    id: "enabled",
                    label: "Enabled",
                  },
                  {
                    id: "disabled",
                    label: "Disabled",
                  },
                ]}
                selectedOptions={(() => {
                  switch (visibleGroups) {
                    case "all":
                      return ["all", "enabled", "disabled"];
                    case "enabled":
                      return ["enabled"];
                    case "disabled":
                      return ["disabled"];
                    default:
                      return [];
                  }
                })()}
                onSelectOption={(optionId) =>
                  paywall(() => setVisibleGroups(optionId))
                }
              />
            </span>
            <span>
              <GrayFilledButton
                onClick={() => paywall(() => setMutingAll(true))}
                startIcon={<MuteIcon isMuted={isMuted} />}
                // rerender on mute
                key={`mutebtn-${isMuted}`}
                className={classes.muteBtn}
                active={isMuted}
              >
                {isMuted ? "Unmute" : "Mute All"}
              </GrayFilledButton>
              <InstallablesSortByDropdown
                selected={channelSort}
                options={[
                  { id: "date", label: "Most Recently Installed" },
                  { id: "daily_schedule", label: "Daily Schedule" },
                  { id: "alpha", label: "Alphabetically" },
                ]}
                handleOnSelectOption={(option) =>
                  paywall(() => setChannelSort(option))
                }
              />
            </span>
          </div>
          <div className={classes.listItems}>
            {loading ? (
              <div className={classes.emptyState}>
                <Progress />
              </div>
            ) : orderedItems.length === 0 && items.length === 0 ? (
              <>
                <div className={classes.emptyState}>
                  <img
                    src="/vestaboard-plus-logo-white.svg"
                    height={24}
                    className={classes.logo}
                  />
                  <h5 className={classes.emptyStateHeading}>
                    Thanks for activating
                  </h5>
                  <Typography
                    color="textSecondary"
                    variant={"body2"}
                    className={classes.emptyStateBody}
                  >
                    You can now automate content from a growing library of
                    channels for your Vestaboard
                  </Typography>
                  <Link
                    to={`/board/${boardId}/installables/discover`}
                    className={classes.linkBtn}
                  >
                    Discover Channels
                  </Link>
                </div>
              </>
            ) : (
              <ListItemsContainer
                reordering={prioritizing}
                onReorder={(itemIds) => {
                  paywall(() => setItemIdOrder(itemIds));
                }}
                children={orderedItems
                  .sort((a, b) => {
                    if (!a || !b) return 0;

                    const aTitle =
                      a.title ??
                      a.marketplaceListing?.title ??
                      a.marketplaceListing?.installable?.title;

                    const bTitle =
                      b.title ??
                      b.marketplaceListing?.title ??
                      b.marketplaceListing?.installable?.title;

                    switch (channelSort) {
                      case "alpha":
                        if (!aTitle || !bTitle) return 0;
                        return aTitle.localeCompare(bTitle);
                      case "date":
                        return parseInt(b.created) - parseInt(a.created);
                      case "daily_schedule":
                        // Push Muted to bottom, with "Frequency Not Set" just above the muted channels
                        if (a.muted && b.muted) {
                          return (aTitle || "").localeCompare(bTitle || "");
                        }
                        let aDate = a.muted
                          ? 1000002
                          : a.runOfShow.description === FREQUENCY_NOT_SET
                          ? 1000001
                          : getTime(a.runOfShow.firstAppearance as string);
                        let bDate = b.muted
                          ? 1000002
                          : b.runOfShow.description === FREQUENCY_NOT_SET
                          ? 1000001
                          : getTime(b.runOfShow.firstAppearance as string);

                        // If the next appearance is the same, sort by title
                        if (aDate - bDate === 0) {
                          return (aTitle || "").localeCompare(bTitle || "");
                        }

                        // Sort by next appearance
                        return aDate - bDate;
                      default:
                        return 0;
                    }
                  })
                  .map((subscription, index) => {
                    const marketplaceListing = subscription.marketplaceListing;
                    const installable =
                      subscription.marketplaceListing?.installable;
                    const enabled = !subscription.muted;

                    return {
                      id: subscription.id,
                      render: (
                        dragging: boolean,
                        dragHandleProps:
                          | DraggableProvidedDragHandleProps
                          | undefined
                      ) => (
                        <InstallableListItem
                          publicInstallable={installable?.public ?? true}
                          isPlus={true}
                          free={marketplaceListing?.free ?? false}
                          installableIsSingleton={
                            installable?.installationsAreSingleton ?? undefined
                          }
                          key={subscription.id}
                          id={subscription.id}
                          icon={
                            subscription.icon ??
                            marketplaceListing?.icon ??
                            installable?.icon ??
                            ""
                          }
                          title={
                            (subscription.title ??
                              marketplaceListing?.title ??
                              installable?.title)!!
                          }
                          description={
                            subscription?.runOfShow?.description || ""
                            /*subscription.description ??
                            marketplaceListing?.description ??
                            installable?.description ??
                            ""*/
                          }
                          installed={true}
                          enabled={enabled}
                          prioritizing={prioritizing}
                          priority={index + 1}
                          dragging={dragging}
                          dragHandleProps={dragHandleProps}
                          className={classes.listItem}
                          configurationButton={true}
                          subscriptionId={subscription.id}
                          tenantId={tenantId as string}
                          paywall={paywall}
                        />
                      ),
                    };
                  })}
              />
            )}
          </div>
        </Box>
      </Box>
    </>
  );
};

interface MuteAllInstallablesModalProps {
  shown: boolean;
  onClose(): void;
  onMuteAll(duration: number): void;
  isMuted: boolean;
  pausedUntil?: number;
}

const useMuteModalStyles = makeStyles({
  modal: {
    display: "flex",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      height: "100%",
      width: "100%",
    },
  },
  container: {
    flexGrow: 1,
    padding: "56px 40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 450,
    margin: "0 auto",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      height: "100%",
      maxWidth: 376,
    },
  },
  icon: {
    marginBottom: 37,
    color: "#f5f5f7",
    width: 42,
  },
  detailsContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      flexGrow: 1,
    },
  },
  heading: {
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#f5f5f7",
    marginBottom: 16,
  },
  muteOptions: {
    marginTop: 10,
    width: "100%",
  },
  customToggleStyles: {
    color: "white",
    marginBottom: 40,
    width: "100%",

    "& .MuiToggleButton-root": {
      backgroundColor: "transparent",
      color: "#979797",
      textTransform: "none",
      borderRadius: 0,
      justifyContent: "start",
      fontSize: 18,
      letterSpacing: "normal",
      padding: 8,
      "&.Mui-selected": {
        color: "#ffffff",
        position: "relative",
        "&::before": {
          content: "url(/icons/icon-check.svg)",
          position: "absolute",
          right: 4,
          top: "50%",
          transform: "translateY(-50%) scale(0.75)",
          lineHeight: 0,
        },
      },
      "&:not(:last-child)": {
        borderBottom: "1px solid #979797",
      },
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      },
    },
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
  },
  btn: {
    margin: "0 20px",
    flexBasis: "50%",
    flexShrink: 0,
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      margin: "0 8px",
    },
  },
});

export const MuteAllInstallablesModal: React.FC<
  MuteAllInstallablesModalProps
> = (props) => {
  const classes = useMuteModalStyles();
  const [muteTime, setMuteTime] = React.useState("halfHour");
  const pausedEnds = props.pausedUntil
    ? dayjs(props.pausedUntil).format("h:mm A")
    : "";

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextMuteTime: string
  ) => {
    setMuteTime(nextMuteTime);
  };

  return (
    <BlurModal
      shown={props.shown}
      onClose={props.onClose}
      className={classes.modal}
    >
      <div className={classes.container}>
        <div className={classes.detailsContainer}>
          <img className={classes.icon} src="/icons/icon-mute.svg" />
          <div className={classes.heading}>
            {props.isMuted
              ? `This Vestaboard is currently muted till ${pausedEnds}`
              : "Want to mute all your channels?"}
          </div>
          {props.isMuted ? (
            <>
              <div>
                <p>
                  Do you want to unmute to continue sending messages to this
                  Vestaboard?
                </p>
              </div>
              <Spacer height={16} />
            </>
          ) : (
            <span />
          )}
          {!props.isMuted ? (
            <div className={classes.muteOptions}>
              <ToggleButtonGroup
                orientation="vertical"
                value={muteTime}
                exclusive
                onChange={handleChange}
                className={classes.customToggleStyles}
              >
                <ToggleButton disableRipple value={String(0.5 * 60 * 60)}>
                  30 Minutes
                </ToggleButton>
                <ToggleButton disableRipple value={String(1 * 60 * 60)}>
                  1 Hour
                </ToggleButton>
                <ToggleButton disableRipple value={String(25 * 60 * 60)}>
                  Rest of the day
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          ) : (
            <span />
          )}
        </div>
        <div className={classes.buttons}>
          <OutlinedButton onClick={props.onClose} className={classes.btn}>
            Cancel
          </OutlinedButton>
          <FilledButton
            onClick={async () =>
              await props.onMuteAll(props.isMuted ? 0 : parseInt(muteTime))
            }
            className={classes.btn}
          >
            Yes, {props.isMuted ? "Unmute" : "Mute Now"}
          </FilledButton>
        </div>
      </div>
    </BlurModal>
  );
};

const MuteIcon = ({ isMuted }: { isMuted: boolean }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginLeft: 6 }}
  >
    <g stroke={isMuted ? "#3a3b3f" : "#F5F5F7"} fill="none" fillRule="evenodd">
      <path d="M18.286 2.286v27.428L5.224 19.43H0V12.57h5.224zM22.857 11.429 32 20.571M32 11.429l-9.143 9.142" />
    </g>
  </svg>
);
