import { ExpandMore } from "@mui/icons-material";
import {
  Alert,
  Box,
  ButtonBase,
  CircularProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Button, ModalDialog, SubTitle2 } from "@vestaboard/installables";
import { SelectMenu } from "@vestaboard/installables/lib/components/SelectMenu";
import { useCallback, useState } from "react";
import { useListMessageSourcesV2 } from "../../../hooks/useListMessageSourcesV2";
import { useParams } from "react-router-dom";
import { Tag } from "../../../components/Tag";

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
  button: {
    backgroundColor: "#303537",
    padding: 12,
    borderRadius: 8,
    display: "flex",
    paddingLeft: 18,
    paddingRight: 18,
  },
  modalBtnGroup: {
    display: "flex",
    gap: 16,
    justifyContent: "space-between",
    marginTop: 24,
    width: "100%",
  },
});

interface ISourcesFilter {
  selected: Array<string> | null;
  setSelected: (source: Array<string> | null) => void;
}

export const SourcesFilter = (props: ISourcesFilter) => {
  const params = useParams<{ boardId: string }>();
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(props.selected);
  const classes = useStyles();
  const { data, loading, error } = useListMessageSourcesV2({
    input: {
      boardId: params.boardId,
    },
  });
  const selectedSource =
    props?.selected?.length === 1
      ? data?.listMessageSources.messageSources.find(
          (source) => source.id === props?.selected?.[0]
        )
      : null;

  const selectSource = useCallback(
    (source) => {
      const currentlySelected =
        selected ||
        (data?.listMessageSources?.messageSources || []).map(
          (source) => source.id
        );

      if (currentlySelected.includes(source)) {
        setSelected(currentlySelected.filter((s) => s !== source));
      } else {
        setSelected([...currentlySelected, source]);
      }
    },
    [data?.listMessageSources?.messageSources, selected]
  );

  return (
    <Box className={classes.container}>
      {selectedSource ? (
        <Tag
          onClick={() => {
            props.setSelected(null);
          }}
        >
          {selectedSource.title}
        </Tag>
      ) : null}
      <ButtonBase onClick={() => setVisible(true)} className={classes.button}>
        <Typography>Filter</Typography>
        <ExpandMore />
      </ButtonBase>
      <ModalDialog
        visible={visible}
        onClose={() => setVisible(false)}
        fullScreenMobile={true}
        header={<SubTitle2>Source</SubTitle2>}
        footer={
          <Box className={classes.modalBtnGroup}>
            <Button
              buttonType="outline"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              buttonType="white"
              onClick={async () => {
                setVisible(false);
                if (
                  selected?.length ===
                  data?.listMessageSources.messageSources.length
                ) {
                  props.setSelected(null);
                } else {
                  props.setSelected(selected || null);
                }
              }}
            >
              Apply
            </Button>
          </Box>
        }
      >
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">
            {error.message || "There was an error loading your message sources"}
          </Alert>
        ) : (
          <SelectMenu
            options={(data?.listMessageSources?.messageSources || []).map(
              (source) => ({
                id: source.id,
                name: source.title,
              })
            )}
            selected={
              selected ||
              (data?.listMessageSources?.messageSources || []).map(
                (source) => source.id
              )
            }
            onPress={selectSource}
          />
        )}
      </ModalDialog>
    </Box>
  );
};
