import {
  CreateMessageMutation,
  CreateMessageMutationVariables,
} from "../../../gql";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const CREATE_MESSAGE = gql`
  mutation CreateMessage($input: CreateMessage2Input!) {
    createMessage2(input: $input) {
      created
      message {
        id
        formatted
        sendAt
      }
    }
  }
`;

export const useCreateMessage = () => {
  return useMutation<CreateMessageMutation, CreateMessageMutationVariables>(
    CREATE_MESSAGE
  );
};
