import { Box } from "@mui/material";
import { BREAKPOINT_EXTRA_SMALL, BREAKPOINT_MEDIUM } from "../config";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    position: "relative",
    marginBottom: 24,
    width: "30%",
    [`@media(max-width: ${BREAKPOINT_MEDIUM})`]: {
      width: "48%",
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      width: "100%",
    },
  },
});

interface IMessagesContainer {
  children?: React.ReactNode;
}

export const MessagesContainer = (props: IMessagesContainer) => {
  const classes = useStyles();
  return <Box className={classes.container}>{props.children || null}</Box>;
};
