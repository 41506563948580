import { useQuery } from "@apollo/client";
import { Box, ButtonBase } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Check } from "@mui/icons-material";
import {
  TextButton,
  Button,
  Input,
  Modal,
  SubTitle2,
  Spacer,
  useToasts,
  ModalDialog,
} from "@vestaboard/installables";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { BOARD_MEMBERS_QUERY } from ".";
import { BoardMembersQuery, BoardMembersQueryVariables } from "../../../gql";
import { useTransferOwnership } from "../../../hooks/useTransferOwnership";

interface ITransferModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const useStyles = makeStyles({
  modalBtnGroup: {
    display: "flex",
    gap: 16,
    justifyContent: "space-between",
    marginTop: 24,
    width: "100%",
  },
  userContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
    flexDirection: "row",
    flex: 1,
  },
  userButton: {
    width: "100%",
    height: 50,
  },
});

export const TransferOwnershipModal = (props: ITransferModal) => {
  const { addToast } = useToasts();

  const [createNewOwner, setCreateNewOwner] = useState(false);

  const [email, setEmail] = useState("");
  const [newOwnerPersonId, setNewOwnerPersonId] = useState("");
  const [newOwnerPersonName, setNewOwnerPersonName] = useState("");

  const [mutateTransferOwnership] = useTransferOwnership();
  const params = useParams<{
    boardId: string;
  }>();

  const { data: boardData } = useQuery<
    BoardMembersQuery,
    BoardMembersQueryVariables
  >(BOARD_MEMBERS_QUERY, {
    variables: {
      boardId: params.boardId,
    },
  });
  const classes = useStyles();
  const myId = boardData?.viewer?.account?.id;
  const tenant = boardData?.board.tenant.id;

  const transferToOwner = useCallback(
    async (
      transfer: { newOwnerEmailAddress?: string; newOwnerPersonId?: string },
      name?: string
    ) => {
      if (!transfer.newOwnerEmailAddress && !transfer.newOwnerPersonId) {
        addToast(`Unable to transfer ownership to user`, {
          appearance: "error",
        });
        return;
      }
      try {
        await mutateTransferOwnership({
          variables: {
            input: {
              transfers: [{ ...transfer, tenant: tenant || "" }],
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: BOARD_MEMBERS_QUERY,
              variables: {
                boardId: params.boardId,
              },
            },
          ],
        });
        addToast(
          `Transfered ownership to ${name || transfer?.newOwnerEmailAddress}`,
          {
            appearance: "success",
          }
        );
      } catch (err) {
        return addToast(
          `Failed to transfer ownership to ${
            name || transfer?.newOwnerEmailAddress
          }`,
          {
            appearance: "error",
          }
        );
      }
    },
    [tenant]
  );

  return (
    <>
      <ModalDialog
        visible={props.visible && !createNewOwner}
        onClose={() => props.setVisible(false)}
        fullScreenMobile={true}
        header={
          <SubTitle2>Transfer {boardData?.board?.title} Ownership To</SubTitle2>
        }
        footer={
          <Box className={classes.modalBtnGroup}>
            <Button
              buttonType="outline"
              onClick={() => {
                props.setVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!newOwnerPersonId}
              buttonType="white"
              onClick={async () => {
                await transferToOwner({ newOwnerPersonId }, newOwnerPersonName);
                props.setVisible(false);
              }}
            >
              Transfer
            </Button>
          </Box>
        }
      >
        <Box
          style={{
            flexDirection: "column",
            width: "100%",
          }}
        >
          {boardData?.board.tenant.members
            .filter((member) => myId !== member.person.account.id)
            .map((member) => {
              const username = member.person.firstName
                ? `${member.person.firstName} ${member.person.lastName}`
                : member.person.account.emailAddress;
              return (
                <Box key={`${member.id}-transfer-button`} borderBottom={1}>
                  <ButtonBase
                    className={classes.userButton}
                    onClick={() => {
                      setNewOwnerPersonId(member.person.id);
                      setNewOwnerPersonName(username);
                    }}
                  >
                    <Box className={classes.userContainer}>
                      <Box style={{ display: "flex", flex: 1 }}>{username}</Box>
                      <Box
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "flex-end",
                        }}
                      >
                        {member.person.id === newOwnerPersonId ? (
                          <Check />
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Box>
                  </ButtonBase>
                </Box>
              );
            })}
          <Box borderBottom={1}>
            <ButtonBase
              className={classes.userButton}
              type="button"
              onClick={() => {
                setCreateNewOwner(true);
              }}
            >
              <Box className={classes.userContainer}>Create New Owner +</Box>
            </ButtonBase>
          </Box>
        </Box>
      </ModalDialog>
      <Modal
        visible={props.visible && createNewOwner}
        onClose={() => setCreateNewOwner(false)}
        fullScreenMobile={true}
      >
        <Input value={email} onValueChange={setEmail} label="Email" />
        <Box className={classes.modalBtnGroup}>
          <Button
            buttonType="outline"
            onClick={() => {
              setCreateNewOwner(false);
            }}
          >
            Back
          </Button>
          <Button
            buttonType="white"
            disabled={!email || !email.includes(".") || !email.includes("@")}
            onClick={async () => {
              await transferToOwner({ newOwnerEmailAddress: email });
              setCreateNewOwner(false);
              props.setVisible(false);
            }}
          >
            Add and Transfer Ownership
          </Button>
        </Box>
      </Modal>
    </>
  );
};
