import { useHistory, useParams } from "react-router-dom";
import {
  IBoard,
  IBoardStyle,
  IconType,
  VestaboardPreview,
} from "@vestaboard/installables";
import { MessageGridItem } from "./MessageGridItem";
import dayjs from "dayjs";
import { DeleteForever, FlagOutlined } from "@mui/icons-material";
import { SearchMessageUnionV2 } from "../gql";
import { LikeIcon } from "./LikeIcon";
import { IMessageCardsV2HocVariables } from "./MessageCardsContainerV2";
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
interface IMessageCardV2 extends IMessageCardsV2HocVariables {
  searchMessage: SearchMessageUnionV2;
  boardStyle: IBoardStyle;
}

export const MessageCardV2 = ({
  searchMessage,
  boardStyle,
  handleSendMessage,
  handleShareMessage,
  handlePinMessage,
  handleDeleteMessage,
  handleFavoriteMessage,
  handleLikePick,
  handleLikeFeedItem,
  handleDeleteDraft,
  handleDeleteFeedItem,
  handleFlagFeedItem,
}: IMessageCardV2) => {
  const history = useHistory();
  const { boardId } = useParams<{ boardId: string }>();
  const messageId = searchMessage.message.id;

  return (
    <>
      <MessageGridItem>
        <VestaboardPreview
          boardStyle={boardStyle}
          isFavorite={searchMessage.message.isFavorited}
          messageId={messageId}
          characters={searchMessage.message.characters as IBoard}
          likeCount={
            searchMessage.__typename === "FeedItemV2" ||
            searchMessage.__typename === "PickV2"
              ? searchMessage?.likeCount
              : 0
          }
          liked={
            searchMessage.__typename === "FeedItemV2" ||
            searchMessage.__typename === "PickV2"
              ? searchMessage.isLikedByMe
              : false
          }
          bottomActions={[
            searchMessage.__typename === "FeedItemV2"
              ? {
                  ariaLabel: "Like",
                  icon: <LikeIcon liked={searchMessage.isLikedByMe} />,
                  onClick: () => {
                    return handleLikeFeedItem(
                      searchMessage.id,
                      searchMessage.isLikedByMe,
                      searchMessage.likeCount
                    );
                  },
                  actionType: "like",
                }
              : searchMessage.__typename === "PickV2"
              ? {
                  ariaLabel: "Like",
                  icon: <LikeIcon liked={searchMessage.isLikedByMe} />,
                  actionType: "like",
                  onClick: () => {
                    return handleLikePick(
                      searchMessage.id,
                      searchMessage.isLikedByMe,
                      searchMessage.likeCount
                    );
                  },
                }
              : searchMessage.__typename === "DraftV2"
              ? {
                  text: "Edit",
                  ariaLabel: "Edit",
                  icon: "edit" as IconType,
                  onClick: () => {
                    history.push(
                      `/board/${boardId}/compose/duplicate/${JSON.stringify(
                        searchMessage.message.characters
                      )}`
                    );
                  },
                }
              : {
                  text: "Duplicate",
                  ariaLabel: "Duplicate",
                  icon: "copy" as IconType,
                  onClick: () => {
                    history.push(
                      `/board/${boardId}/compose/duplicate/${JSON.stringify(
                        searchMessage.message.characters
                      )}`
                    );
                  },
                },
            {
              ariaLabel: "Send",
              icon: "send",
              onClick: () =>
                handleSendMessage({
                  messageId,
                  feedItemId:
                    searchMessage.__typename === "FeedItemV2"
                      ? searchMessage.id
                      : undefined,
                  pickId:
                    searchMessage.__typename === "PickV2"
                      ? searchMessage.id
                      : undefined,
                }),
            },
            {
              ariaLabel: "Share",
              icon: "share",
              onClick: () => {
                handleShareMessage(messageId);
              },
            },
          ]}
          popoverActions={
            searchMessage.__typename === "DraftV2"
              ? [
                  {
                    text: "Delete",
                    ariaLabel: "Delete",
                    icon: <DeleteForever />,
                    onClick: () => {
                      handleDeleteDraft(messageId);
                    },
                  },
                  {
                    text: "Pin",
                    ariaLabel: "Pin",
                    icon: "pin",
                    onClick: () => {
                      handlePinMessage(messageId);
                    },
                  },
                ]
              : searchMessage.__typename === "MessageAppearanceV2"
              ? [
                  {
                    text: "Delete",
                    ariaLabel: "Delete",
                    icon: <DeleteForever />,
                    onClick: () => {
                      handleDeleteMessage(searchMessage.id);
                    },
                  },
                  {
                    text: "Pin",
                    ariaLabel: "Pin",
                    icon: "pin",
                    onClick: () => {
                      handlePinMessage(messageId);
                    },
                  },
                ]
              : searchMessage.__typename === "FeedItemV2"
              ? [
                  searchMessage.isSharedByMe
                    ? {
                        text: "Delete",
                        ariaLabel: "Delete",
                        icon: <DeleteForever />,
                        onClick: () => {
                          handleDeleteFeedItem(searchMessage.id);
                        },
                      }
                    : {
                        text: "Flag",
                        ariaLabel: "Flag",
                        icon: <FlagOutlined />,
                        onClick: () => {
                          handleFlagFeedItem(searchMessage.id);
                        },
                      },
                  {
                    text: "Pin",
                    ariaLabel: "Pin",
                    icon: "pin",
                    onClick: () => {
                      handlePinMessage(messageId);
                    },
                  },
                ]
              : [
                  {
                    text: "Pin",
                    ariaLabel: "Pin",
                    icon: "pin",
                    onClick: () => {
                      handlePinMessage(messageId);
                    },
                  },
                ]
          }
          sourceId={
            searchMessage.__typename === "MessageAppearanceV2"
              ? searchMessage.subscriptionId || searchMessage.personId || ""
              : ""
          }
          source={searchMessage.attribution}
          onSourcePress={() => {
            switch (searchMessage.__typename) {
              case "MessageAppearanceV2":
                if (searchMessage.subscriptionId) {
                  history.push(
                    `/board/${boardId}/installables/subscription/${searchMessage.subscriptionId}`
                  );
                } else {
                  history.push(
                    `/board/${boardId}/messages/my/history?sourceIds=${searchMessage.sourceId}`
                  );
                }
                break;
              case "DraftV2":
                history.push(
                  `/board/${boardId}/messages/my/drafts?sourceIds=${searchMessage.sourceId}`
                );
                break;
              case "FavoriteV2":
                history.push(
                  `/board/${boardId}/messages/my/favorites?sourceIds=${searchMessage.sourceId}`
                );
                break;
              case "FeedItemV2":
                history.push(
                  `/board/${boardId}/messages/feed?sourceId=${searchMessage.sourceId}&sourceName=${searchMessage.attribution}&sourceType=FeedItem`
                );
                break;
              case "PickV2":
                history.push(`/board/${boardId}/messages/feed?sourceType=Pick`);
                break;
              case "MessageSetMessageV2":
                history.push(
                  `/board/${boardId}/messages/feed?sourceId=${searchMessage.messageSetId}&sourceName=${searchMessage.attribution}&sourceType=MessageSetMessage`
                );
                break;
            }
          }}
          ariaMessageText={searchMessage.message.text || ""}
          sentAt={dayjs(searchMessage.created).fromNow()}
          onFavoritePress={() =>
            handleFavoriteMessage(messageId, searchMessage.message.isFavorited)
          }
        />
      </MessageGridItem>
    </>
  );
};
