import { Box } from "@mui/material";
import { Body, Spacer } from "@vestaboard/installables";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#181919",
    width: "100%",
    display: "flex",
    paddingTop: 80,
    paddingBottom: 80,
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  image: {
    width: 300,
    boxShadow: "0px 5px 10px #111",
    marginLeft: 24,
    marginRight: 24,
  },
});

interface IEndOfList {
  children: React.ReactNode;
}

export const EndOfList = (props: IEndOfList) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <img src="/the-end.png" alt="The End" className={classes.image} />
        <Spacer size="extraLarge" />
        <Body>You have reached the end.</Body>
      </Box>
    </Box>
  );
};
