import { RenameBoardMutation, RenameBoardMutationVariables } from "../gql";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const RENAME_BOARD = gql`
  mutation RenameBoard($input: UpdateBoardInput!) {
    updateBoard(input: $input) {
      board {
        id
        title
      }
    }
  }
`;

export const useRenameBoard = () => {
  return useMutation<RenameBoardMutation, RenameBoardMutationVariables>(
    RENAME_BOARD
  );
};
