import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSearchMessagesV2 } from "../../hooks/useSearchMesssagesV2";
import { Alert, Box, CircularProgress } from "@mui/material";
import { MessageGrid } from "../../components/MessageGrid";
import { MessageCardV2 } from "../../components/MessageCardV2";
import {
  SearchMessageUnionV2,
  SearchMessageType,
  SearchMessageSortBy,
  SearchMessageSortDirection,
} from "../../gql";
import { useGetBoardStyle } from "../../hooks/useGetBoardStyle";
import { IBoardStyle } from "@vestaboard/installables";
import InfiniteScroll from "react-infinite-scroller";
import { MessageCardsContainerV2 } from "../../components/MessageCardsContainerV2";
import { SearchStateWrapper } from "../../components/SearchStateWrapper";
import queryString from "query-string";
import { Tag } from "../../components/Tag";
import { makeStyles } from "@mui/styles";
import { InfiniteLoader } from "../../components/InfiniteLoader";
import { SourcesFilter } from "./components/SourcesFilter";
import { useState } from "react";

const PER_PAGE = 24;

const useStyles = makeStyles({
  flex: {
    paddingLeft: 12,
    paddingRight: 12,
    width: "calc(100% - 24px)",
    display: "flex",
    justifyContent: "space-between",
  },
});

export const FeedPageV2 = () => {
  const classes = useStyles();
  const [tags, setTags] = useState<string[] | null>(null);
  const [sortBy, setSortBy] = useState<SearchMessageSortBy>(
    SearchMessageSortBy.Date
  );
  const { boardId } = useParams<{ boardId: string }>();
  const { data: boardStyleData } = useGetBoardStyle();
  const { search } = useLocation();
  const queryStringValues = queryString.parse(search);
  const history = useHistory();
  const input = {
    boardId,
    limit: PER_PAGE,
    cursor: null,
    sortBy,
    sortDirection: SearchMessageSortDirection.Desc,
    tags: tags ? tags : undefined,
    types:
      queryStringValues.sourceType === "Pick"
        ? [SearchMessageType.Pick]
        : queryStringValues.sourceType === "FeedItem"
        ? [SearchMessageType.FeedItem]
        : queryStringValues.sourceType === "MessageSetMessage"
        ? [SearchMessageType.MessageSetMessage]
        : [SearchMessageType.FeedItem, SearchMessageType.Pick],
    sources:
      typeof queryStringValues.sourceId === "string"
        ? [queryStringValues.sourceId]
        : undefined,
  };
  const { data, loading, error, refetch, fetchMore } = useSearchMessagesV2({
    input,
  });

  return (
    <>
      <Box className={classes.flex}>
        <Box>
          {data && queryStringValues.sourceId ? (
            <Tag
              onClick={() => {
                history.push(`/board/${boardId}/messages/feed`);
              }}
            >
              {queryStringValues.sourceName}
            </Tag>
          ) : null}
          {data && queryStringValues.sourceType === "Pick" ? (
            <Tag
              onClick={() => {
                history.push(`/board/${boardId}/messages/feed`);
              }}
            >
              Today's Picks
            </Tag>
          ) : null}
        </Box>
        <SourcesFilter
          tags={tags}
          setTags={setTags}
          sortBy={sortBy === SearchMessageSortBy.Likes ? "likes" : "date"}
          setSortBy={(value) => {
            if (value === "likes") {
              setSortBy(SearchMessageSortBy.Likes);
            } else {
              setSortBy(SearchMessageSortBy.Date);
            }
          }}
        />
      </Box>
      {!data?.listSearchMessages && loading ? (
        <SearchStateWrapper>
          <InfiniteLoader />
        </SearchStateWrapper>
      ) : error ? (
        <SearchStateWrapper>
          <Alert severity="error">
            There was an error loading the messages.
          </Alert>
        </SearchStateWrapper>
      ) : !data?.listSearchMessages.searchMessages.length ? (
        <SearchStateWrapper>
          <Alert severity="info">You have no feed messages.</Alert>
        </SearchStateWrapper>
      ) : (
        <MessageCardsContainerV2
          refetch={async () => {
            await refetch();
          }}
        >
          {(variables) => (
            <InfiniteScroll
              pageStart={0}
              loadMore={async () => {
                if (loading) {
                  return;
                }

                return await fetchMore({
                  updateQuery(previousQueryResult, options) {
                    const newResults =
                      options.fetchMoreResult?.listSearchMessages
                        .searchMessages || [];
                    const previousResults =
                      previousQueryResult.listSearchMessages.searchMessages ||
                      [];

                    return {
                      listSearchMessages: {
                        ...previousQueryResult.listSearchMessages,
                        nextCursor:
                          options.fetchMoreResult?.listSearchMessages
                            .nextCursor || null,
                        searchMessages: [...previousResults, ...newResults],
                      },
                    };
                  },
                  variables: {
                    input: {
                      ...input,
                      cursor: data?.listSearchMessages.nextCursor || null,
                    },
                  },
                });
              }}
              hasMore={!!data?.listSearchMessages.nextCursor}
              loader={<InfiniteLoader />}
            >
              <MessageGrid>
                {(data?.listSearchMessages?.searchMessages || [])
                  .filter((message) => {
                    if (message.__typename === "FeedItemV2") {
                      return !message.isFlaggedByMe;
                    } else {
                      return true;
                    }
                  })
                  .map((searchMessage, index) => (
                    <MessageCardV2
                      {...variables}
                      searchMessage={searchMessage as SearchMessageUnionV2}
                      boardStyle={
                        (boardStyleData?.board.boardStyle ||
                          "black") as IBoardStyle
                      }
                      key={`${searchMessage.id}-${index}`}
                    />
                  ))}
              </MessageGrid>
            </InfiniteScroll>
          )}
        </MessageCardsContainerV2>
      )}
    </>
  );
};
