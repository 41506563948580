import {
  Button,
  FlexHorizontal,
  Icon,
  Input,
  Modal,
  Spacer,
  SubTitle2,
  useToasts,
} from "@vestaboard/installables";

import { BoardV2 } from "../../gql";
import { Confirm } from "../../components/Confirm";
import { getBoardSimulatorUrl } from "../../components/Simulator";
import { useCopy } from "../../hooks/useCopy";
import { useState } from "react";

interface ILiveV2ModalsProps {
  showLeaveModal: boolean;
  setShowLeaveModal: (show: boolean) => void;
  showDeleteModal: boolean;
  setShowDeleteModal: (show: boolean) => void;
  showRenameModal: boolean;
  setShowRenameModal: (show: boolean) => void;
  currentBoard: BoardV2;
}

export const LiveV2Modals = (props: ILiveV2ModalsProps) => {
  const {
    showLeaveModal,
    setShowLeaveModal,
    showDeleteModal,
    setShowDeleteModal,
    showRenameModal,
    setShowRenameModal,
    currentBoard,
  } = props;
  const [renameTitle, setRenameTitle] = useState("");

  const { addToast } = useToasts();
  const { copy } = useCopy();
  const boardSimulatorUrl = getBoardSimulatorUrl(currentBoard.id);
  const simulatorLink = `${window.location.protocol}//${window.location.hostname}/simulator/${currentBoard?.id}`;
  const simulatorCode = `<iframe src="${boardSimulatorUrl}" style="width: 100%; border: none; aspect-ratio: 16 / 8.4;" scrolling="no"></iframe>`;

  return (
    <>
      <Confirm
        title="Delete Board"
        message={`You are the owner of this Vestaboard. Removing "${currentBoard?.title}" will mean it is deleted and all users will lose access. Are you sure?`}
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleAccept={async () => {}}
      />
      <Confirm
        title="Leave Board"
        message={`Leaving "${currentBoard?.title}" will mean you no longer have access to this Vestaboard. Are you sure?`}
        open={showLeaveModal}
        handleClose={() => setShowLeaveModal(false)}
        handleAccept={async () => {}}
      />
      <Modal
        visible={showRenameModal}
        onClose={() => setShowRenameModal(false)}
      >
        <Spacer size="extraLarge" />
        <FlexHorizontal center>
          <SubTitle2>
            Rename {currentBoard?.title} &nbsp;
            <Icon type="edit" color="white" />
          </SubTitle2>
        </FlexHorizontal>
        <Spacer size="extraLarge" />
        <Input value={renameTitle} onValueChange={setRenameTitle} />
        <Spacer size="extraLarge" />
        <FlexHorizontal center>
          <Button
            buttonType="white"
            width={300}
            onClick={async () => {
              // const response = await renameBoard({
              //   variables: {
              //     input: {
              //       boardId: currentBoard?.id,
              //       updateTitle: {
              //         value: renameTitle,
              //       },
              //     },
              //   },
              // });
              // @ts-ignore
              if (response.errors && response.errors.length) {
                addToast(`There was an error saving your board name`, {
                  appearance: "error",
                  autoDismiss: true,
                });

                return;
              }

              setRenameTitle("");
              setShowRenameModal(false);

              addToast(`The board was renamed successfully`, {
                appearance: "success",
                autoDismiss: true,
              });
            }}
          >
            Rename
          </Button>
        </FlexHorizontal>
      </Modal>
    </>
  );
};
