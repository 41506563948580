import { Box, ButtonBase } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ArrowForward } from "@mui/icons-material";
import { Body, Spacer } from "@vestaboard/installables";

const useStyles = makeStyles({
  moreButtonContainer: {
    marginBottom: 20,
    marginRight: 20,
  },
  circle: {
    display: "flex",
    borderRadius: "50%",
    border: "#ffffff 2px solid",
    width: 50,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
  },
});

export const MoreButton = (props: { onClick: () => void }) => {
  const classes = useStyles();
  return (
    <Box className={classes.moreButtonContainer}>
      <ButtonBase
        style={{
          flexDirection: "column",
        }}
        onClick={props.onClick}
      >
        <div className={classes.circle}>
          <ArrowForward />
        </div>
        <Spacer size={"small"} />
        <Body>More</Body>
      </ButtonBase>
    </Box>
  );
};
