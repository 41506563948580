import {
  LDFlagSet,
  useFlags,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

interface IFeatureFlags {
  children: (flags: LDFlagSet) => React.ReactNode;
}

export const FeatureFlags = (props: IFeatureFlags) => {
  const [ready, setReady] = useState(false);
  const client = useLDClient();
  const flags = useFlags();

  useEffect(() => {
    client?.waitUntilReady().then(() => {
      setReady(true);
    });
  }, [client]);

  if (!ready) {
    return null;
  }

  return <>{props.children(flags)}</>;
};
