import * as React from "react";

import { Box } from "@mui/material";

interface ISpacer {
  width?: number;
  height?: number;
}

export const Spacer = (props: ISpacer) => {
  return (
    <Box
      style={{
        width: props.width || 1,
        height: props.height || 1,
      }}
    />
  );
};
