import { Box, CircularProgress } from "@mui/material";
import { useMessageHistory } from "../../../hooks/useMessageHistory";
import { IBoard, Spacer, ToastProvider } from "@vestaboard/installables";
import { usePersonFavoritesList } from "../../../hooks/usePersonFavoritesList";
import {
  BORDER_COLORS,
  BREAKPOINT_EXTRA_SMALL,
  BREAKPOINT_MEDIUM,
} from "../../../config";
import { MessageCard } from "../../../components/MessageCard";
import { useLayoutEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useGetPinnedMessage } from "../../../hooks/useGetPinnedMessage";
import { useQuietHours } from "../../../hooks/useQuietHours";
import { useGetRole } from "../../../hooks/useGetRole";
import { SimplePagination } from "../../../components/SimplePagination";
import { EndOfList } from "../../../components/EndOfList";
import { useMessageHistorySearch } from "../../../hooks/useMessageHistorySearch";
import { HistoryContainer } from "../../../components/HistoryContainer";
import { MessagesContainer } from "../../../components/MessagesContainer";

export interface IMessagesSearchList {
  boardId: string;
  term: string;
  setRecentSearches: (searchTerms: Array<string>) => void,
  recentSearches: Array<string>
}

export const MessagesSearchList = (props: IMessagesSearchList) => {
  const [page, setPage] = useState(0);
  const {term, recentSearches, setRecentSearches, boardId} = props

  const { data, loading, refetch } = useMessageHistorySearch({
    boardId,
    page,
    term,
  });
  const { data: pinnedMessageData, loading: loadingPinnedMessage } =
    useGetPinnedMessage();
  const { favorites } = usePersonFavoritesList(0);
  const { quietHoursData, hasQuietHours } = useQuietHours(props.boardId);
  const { iAmOwner } = useGetRole(props.boardId);

  const pinnedMessageId = pinnedMessageData?.board?.pinnedMessage?.message?.id;
  const pinnedUntil = pinnedMessageData?.board?.pinnedMessage?.pinnedUntil;

  const hasPinnedMessage = useMemo(() => {
    return pinnedMessageId && pinnedUntil
      ? dayjs(pinnedUntil).isAfter(dayjs())
      : false;
  }, [pinnedUntil, pinnedMessageId]);

  const hasData = (data?.board?.history?.length || 0) > 0 && !loading

  useLayoutEffect(() => {
    // autosearch returned results
    hasData && term && !recentSearches.includes(term) &&  setRecentSearches([term, ...recentSearches.slice(0,4)]);
  }, [hasData, term, recentSearches, setRecentSearches])


  if (loadingPinnedMessage || loading) {
    return (
      <HistoryContainer>
        <CircularProgress />
      </HistoryContainer>
    );
  }

  return (
    <ToastProvider offset={180}>
      <>
        <HistoryContainer>
          {data?.board.history.length === 0 ? (
            <>
              <Spacer size="extraLarge" />
              <EndOfList>No Messages</EndOfList>
            </>
          ) : null}
          {data?.board.history.map((appearance, index) => {
            const { message } = appearance;
            return (
              <MessagesContainer key={`container-${appearance.id}`}>
                <MessageCard
                  borderColor={BORDER_COLORS[index % BORDER_COLORS.length]}
                  isFavorite={favorites.includes(message?.id || "")}
                  text={message?.text || ""}
                  formatted={message?.formatted as IBoard}
                  id={message?.id || ""}
                  key={`message-card-${appearance.id}`}
                  boardId={boardId}
                  appearenceId={appearance.id}
                  created={message?.created || ""}
                  sendAt={message?.sendAt}
                  onSend={async () => await refetch()}
                  author={message?.authorFormatted || ""}
                  hasPinnedMessage={hasPinnedMessage}
                  quietHoursData={quietHoursData}
                  hasQuietHours={hasQuietHours}
                  iAmOwner={iAmOwner}
                />
              </MessagesContainer>
            );
          })}
          {/* Fix spacing if there are two cards on a row */}
          {data?.board.history.length && 3 ? <MessagesContainer /> : null}
        </HistoryContainer>
        <SimplePagination
          disablePrevious={!page}
          previous={() => {
            setPage(page - 1);
          }}
          next={() => {
            setPage(page + 1);
          }}
          disableNext={!data?.board.history.length}
        />
        <Spacer size="extraLarge" />
      </>
    </ToastProvider>
  );
};
