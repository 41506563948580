import { usePermissions } from "./usePermissions";

const features = {
  live: true,
  compose: true,
  history: true,
  favorites: true,
  installables: true,
  api: true,
  picks: true,
  settings: {
    viewUsers: false,
    viewTimezone: false,
    viewQuietHours: false,
    viewNetwork: false,
    modifyUsers: false,
    modifyTimezone: false,
    modifyQuietHours: false,
    modifyNetwork: false,
  },
  userProfile: true,
  manageBoards: true,
  helpCenter: true,
  renameBoard: true,
  deleteBoard: false,
  manageOwners: false,
  leaveBoard: true,
  drafts: true,
  shared: false,
};

interface IFeatures {
  features: {
    live: boolean;
    compose: boolean;
    history: boolean;
    favorites: boolean;
    installables: boolean;
    drafts: boolean;
    shared: boolean;
    api: boolean;
    picks: boolean;
    settings: {
      viewUsers: boolean;
      viewTimezone: boolean;
      viewQuietHours: boolean;
      viewNetwork: boolean;
      modifyUsers: boolean;
      modifyTimezone: boolean;
      modifyQuietHours: boolean;
      modifyNetwork: boolean;
    };
    userProfile: boolean;
    manageBoards: boolean;
    helpCenter: boolean;
    renameBoard: boolean;
    deleteBoard: boolean;
    manageOwners: boolean;
    leaveBoard: boolean;
    messages: boolean;
  };
}

export const useFeatures = (): IFeatures => {
  const { isAdmin, isOwner } = usePermissions();

  return {
    features: {
      ...features,
      settings: {
        viewUsers: true,
        viewTimezone: true,
        viewQuietHours: true,
        viewNetwork: false,
        modifyUsers: isOwner() || isAdmin(),
        modifyTimezone: isOwner() || isAdmin(),
        modifyQuietHours: isOwner() || isAdmin(),
        modifyNetwork: false,
      },
      messages: true,
      deleteBoard: isOwner(),
      leaveBoard: !isOwner(),
      renameBoard: isOwner(),
      manageOwners: false,
    },
  };
};
