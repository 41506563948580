import { Button, Spacer, useToasts } from "@vestaboard/installables";
import { useMigrateBoardV2 } from "../../hooks/useMigrateBoardV2";
import { usePermissions } from "../../hooks/usePermissions";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

export const MigrateBoardV2 = () => {
  const { isSuperAdmin } = usePermissions();
  const [migrateBoard] = useMigrateBoardV2();
  const { addToast } = useToasts();
  // State effects
  const params = useParams<{
    boardId: string;
  }>();

  return (
    <>
      {isSuperAdmin() && (
        <>
          <Spacer size="large" />
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              buttonType="outline"
              width={600}
              onClick={async () => {
                const result = await migrateBoard({
                  variables: {
                    id: params.boardId,
                  },
                });

                if (result.data?.migrateBoardV2?.success) {
                  addToast(
                    "Board, devices, persons, & roles imported from GCP",
                    {
                      appearance: "success",
                    }
                  );
                } else {
                  // @ts-ignore
                  addToast(result?.data?.migrateBoardV2?.description, {
                    appearance: "error",
                  });
                }
              }}
            >{`Override ${params.boardId} ampt data with prod GCP data`}</Button>
          </Box>
        </>
      )}
    </>
  );
};
