import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  CreateMessageV2Mutation,
  CreateMessageV2MutationVariables,
} from "../gql";

const QUERY = gql`
  mutation CreateMessageV2($input: CreateMessageInputV2!) {
    createMessage: createMessageV2(input: $input) {
      ... on MessageV2 {
        id
        characters
        text
      }
      ... on MessageErrorV2 {
        type
        error
      }
    }
  }
`;

export const useCreateMessageV2 = () => {
  return useMutation<CreateMessageV2Mutation, CreateMessageV2MutationVariables>(
    QUERY
  );
};
