import * as React from "react";

import {
  BoardTimezoneQuery,
  BoardTimezoneQueryVariables,
  UpdateBoardTimezoneMutation,
  UpdateBoardTimezoneMutationVariables,
} from "../../gql";
import { Box, Typography } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";

import { Field } from "../../components/Field";
import { FilledButton } from "../../components/FilledButton";
import { OutlinedButton } from "../../components/OutlinedButton";
import { Select } from "../../components/Select";
import { Spacer } from "../../components/Spacer";
import gql from "graphql-tag";
import { useFeatures } from "../../hooks/useFeatures";
import { useParams } from "react-router-dom";
import { useToasts } from "@vestaboard/installables";
import { useIsMobileMediaQuery } from "../../hooks/media-queries";
import { useGetRole } from "../../hooks/useGetRole";

const useStyles = makeStyles({
  form: {
    display: "flex",
    "@media(max-width: 600px)": {
      flexDirection: "column",
    },
  },
  buttons: {
    display: "flex",
  },
  button: {
    minWidth: 150,
    marginTop: 26,
  },
  container: {
    maxWidth: 736,
  },
});

const GET_TIMEZONE = gql`
  query BoardTimezone($boardId: String!) {
    board(id: $boardId) {
      id
      timezone {
        id
        timezoneCity
        created
      }
    }
    countries {
      country
      id
      timezones {
        id
        timezoneCity
      }
    }
  }
`;

const UPDATE_TIMEZONE = gql`
  mutation UpdateBoardTimezone($input: UpdateBoardInput!) {
    updateBoard(input: $input) {
      board {
        id
        timezone {
          id
          timezoneCity
          created
        }
      }
    }
  }
`;

interface IParams {
  boardId: string;
}

export const TimeZone = () => {
  const isMobile = useIsMobileMediaQuery();
  const classes = useStyles();
  const [country, setCountry] = React.useState("");
  const [timezone, setTimezone] = React.useState("");
  const { boardId } = useParams<IParams>();
  const { addToast } = useToasts();
  const { iAmAdmin } = useGetRole(boardId);

  const { data } = useQuery<BoardTimezoneQuery, BoardTimezoneQueryVariables>(
    GET_TIMEZONE,
    {
      variables: {
        boardId,
      },
    }
  );
  const [updateTimezone] = useMutation<
    UpdateBoardTimezoneMutation,
    UpdateBoardTimezoneMutationVariables
  >(UPDATE_TIMEZONE);
  const { features } = useFeatures();

  const setRemoteTimezone = () => {
    if (data) {
      const timezoneId = data.board.timezone
        ? data.board.timezone.id || ""
        : "";
      setTimezone(timezoneId);

      if (timezoneId) {
        const selectedCountry =
          data.countries &&
          data.countries.find((country) =>
            country.timezones.find((timezone) => timezone.id === timezoneId)
          );

        if (selectedCountry) {
          setCountry(selectedCountry.id);
        }
      }
    }
  };

  React.useEffect(setRemoteTimezone, [data, setTimezone, setCountry]);

  const countries = React.useMemo(() => {
    if (!data?.countries) {
      return [];
    }

    return [...data.countries].sort((a, b) =>
      (a?.country || "").localeCompare(b?.country || "")
    );
  }, [data]);

  const timezones = countries.reduce(
    (prev: Array<{ timezoneCity: string; id: string }>, current) => {
      if (country && country !== current.id) {
        return prev;
      }

      return [...prev, ...current.timezones];
    },
    []
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.form}>
        <Field label="Country">
          <Select
            disabled={!features.settings.modifyTimezone || !iAmAdmin}
            value={country}
            onValueChange={setCountry}
            options={countries.map((country) => ({
              key: country.id,
              value: country.country,
            }))}
            fullWidth
          />
        </Field>
        {isMobile ? <Spacer height={20} /> : <Spacer width={24} />}
        <Field label="Timezone">
          <Select
            disabled={!features.settings.modifyTimezone || !iAmAdmin}
            value={timezone}
            onValueChange={setTimezone}
            options={timezones.map((timezone) => ({
              key: timezone.id,
              value: timezone.timezoneCity,
            }))}
            fullWidth
          />
        </Field>
      </Box>
      {!iAmAdmin && (
        <>
          <Spacer height={20} />
          <Typography>Only Admins can update the Timezone</Typography>
        </>
      )}
      {features.settings.modifyTimezone && (
        <Box className={classes.buttons}>
          <OutlinedButton
            type="button"
            onClick={setRemoteTimezone}
            className={classes.button}
            disabled={!iAmAdmin}
          >
            Cancel
          </OutlinedButton>
          <Spacer width={32} />

          <FilledButton
            variant="contained"
            className={classes.button}
            type="submit"
            disabled={!iAmAdmin}
            onClick={async () => {
              try {
                await updateTimezone({
                  variables: {
                    input: {
                      boardId,
                      timezone,
                    },
                  },
                });

                addToast(`Timezone saved`, {
                  appearance: "success",
                  autoDismiss: true,
                });
              } catch (err) {
                addToast(`There was an error saving your timezone`, {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            }}
          >
            Save
          </FilledButton>
        </Box>
      )}
    </Box>
  );
};
