import { Box, ButtonBase, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch as RouteSwitch,
  useHistory,
  useLocation,
  useParams,
} from "react-router";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import dayjs from "dayjs";
import { InstallableIcon } from "../../components/InstallableIcon";
import { Spacer } from "../../components/Spacer";
import { SquareButton } from "../../components/SquareButton";
import { Switch } from "../../components/Switch";
import { Tab } from "../../components/Tab";
import { TabContent } from "../../components/TabContent";
import { Tabs } from "../../components/Tabs";
import { BREAKPOINT_SMALL } from "../../config";
import { useAppState } from "../../hooks/global";
import { Info } from "./tabs/Info";
import { Settings } from "./tabs/Settings";
import {
  MarketplaceListingDetailQuery,
  MarketplaceListingDetailQueryVariables,
} from "../../gql";

interface IInstallableDetailPageProps {}

interface IRouteMatch {
  // subscriptionId: string;
  boardId: string;
  installableId: string;
}

const useStyles = makeStyles({
  header: {
    padding: "20px 18px",
    borderBottom: "solid 1px #2b2b2b",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 18,
    fontFamily: "HelveticaNeue",
    fontWeight: 500,
    lineHeight: 1.44,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      display: "none",
    },
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "30%",
  },
  headerCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "30%",
  },
  headerIcon: {
    marginRight: 21,
  },
});

const MARKETPLACE_LISTING_DETAIL_QUERY = gql`
  query MarketplaceListingDetailQuery(
    $id: String!
    $tenantId: String!
    $boardId: String!
  ) {
    board(id: $boardId) {
      id
      subscriptionSet {
        channels {
          id
          marketplaceListing {
            id
            free
          }
          muted
        }
      }
    }
    marketplaceListing(id: $id) {
      id
      releaseDate
      version
      free
      installations(tenant: $tenantId) {
        id
        subscriptions {
          id
          muted
        }
      }
      language {
        id
        language
      }
      category {
        id
        title
        marketplaceListings {
          id
          free
          title
          icon
          shortDescription
          installable {
            id
            installationsAreSingleton
          }
        }
      }
      developer {
        id
        title
      }
      installable {
        id
        created
        title
        icon
        description
        hideFromInstallablesList
        installationsAreSingleton
      }
      icon
      messageSet {
        id
        title
      }
      title
      description
      shortDescription
      stagingOnly
      carouselMedia1
      carouselMedia2
      carouselMedia3
    }
  }
`;

const useMarketplaceListingsDetailQuery = (
  id: string,
  tenantId: string,
  boardId: string
) =>
  useQuery<
    MarketplaceListingDetailQuery,
    MarketplaceListingDetailQueryVariables
  >(MARKETPLACE_LISTING_DETAIL_QUERY, {
    variables: {
      id,
      tenantId,
      boardId,
    },
  });

export const InstallableDetailPage: React.FC<IInstallableDetailPageProps> = (
  props
) => {
  const { boardId, installableId } = useParams<IRouteMatch>();
  const location = useLocation<IRouteMatch>();

  const { isPlus, tenantId, setBoardId } = useAppState();

  // set app state for board id so usePaywall hook will work
  useEffect(() => {
    setBoardId(boardId);
    params.boardId && localStorage.setItem("lastBoardId", boardId);
  }, [boardId]);

  const classes = useStyles();
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState(false);

  const [enabled, setEnabled] = useState(false);

  const { data, refetch, loading } = useMarketplaceListingsDetailQuery(
    installableId,
    tenantId ?? "",
    boardId
  );
  const params = useParams<IRouteMatch>();

  if (!data || loading) return <CircularProgress />;

  const existingSubscription = data.board.subscriptionSet.channels.filter(
    (c) => c.marketplaceListing?.id == installableId
  )[0];

  const installableData = {
    id: data.marketplaceListing.id,
    title: data.marketplaceListing.title,
    icon: data.marketplaceListing.icon ?? "",
    category: data.marketplaceListing.category?.title ?? "",
    categoryId: data.marketplaceListing.category?.id ?? "",
    author: data.marketplaceListing.developer?.title ?? "",
    tagline: "",
    tenantId,
    free: data.marketplaceListing.free ?? false,
    description: data.marketplaceListing.description ?? "",
    shortDescription: data.marketplaceListing.shortDescription ?? "",
    images: [
      {
        id: "i1",
        url: data.marketplaceListing.carouselMedia1 ?? "",
      },
      {
        id: "i2",
        url: data.marketplaceListing.carouselMedia2 ?? "",
      },
      {
        id: "i3",
        url: data.marketplaceListing.carouselMedia3 ?? "  ",
      },
    ],
    metadata: [
      {
        id: "date",
        label: "Updated",
        value: dayjs(data.marketplaceListing.releaseDate).format("D MMM YYYY"),
      },
    ],
    installed: false,
    enabled: enabled,
    otherInCategory:
      data.marketplaceListing.category?.marketplaceListings ?? [],
    installableIsSingleton:
      data.marketplaceListing.installable.installationsAreSingleton || null,
    subscriptionId: existingSubscription?.id,
    subscriptionMuted: existingSubscription?.muted ?? false,
    // Only message set player installables can blend for now
    messageSetId:
      data.marketplaceListing.installable.id ===
      "491f89c7-7b0b-4085-bb2d-ab8680818377"
        ? data.marketplaceListing.messageSet?.id
        : "",
  };

  const tabs = [
    {
      index: 0,
      key: "settings",
      label: "Settings",
    },
    {
      index: 1,
      key: "info",
      label: "App Info",
    },
  ];

  const setCurrentTab = (index: number) => {
    history.push(
      `/board/${boardId}/installable/${installableId}/${tabs[index].key}`
    );
  };

  const path = location ? location.pathname : "";

  const currentTab = tabs.reduce((prev: number, tab) => {
    return path.includes(tab.key) ? tab.index : prev;
  }, 0);

  const info = (
    <Info paywall={() => {}} data={installableData} isProUser={isPlus} />
  );

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.headerLeft}>
          <ButtonBase
            onClick={() => {
              // go to discover if page came from deeplink
              if (window.location.href.includes("?deeplink")) {
                history.replace(`/board/${boardId}/installables/discover`);
              } else {
                history.goBack();
              }
            }}
          >
            <img src="/icons/icon-back.svg" alt="Back" />
          </ButtonBase>
        </Box>
        {isPlus && installableData.installed && (
          <>
            <Box className={classes.headerCenter}>
              <Box className={classes.headerIcon}>
                <InstallableIcon
                  size="small"
                  title={installableData.title}
                  icon={installableData.icon}
                />
              </Box>
              <Box className={classes.title}>{installableData.title}</Box>
            </Box>
            <Box className={classes.headerRight}>
              <Switch
                checked={installableData.enabled}
                onChange={() => setEnabled((o) => !o)}
                size={"small"}
              />
              <Spacer width={15} />
              <SquareButton
                backgroundColor="#1e2022"
                onClick={() => {
                  setIsDeleting(true);
                }}
                icon="/icons/icon-trash.svg"
                label="Delete"
                size="small"
              />
            </Box>
          </>
        )}
      </Box>
      {isPlus && installableData.installed ? (
        <>
          <Tabs value={currentTab} onChange={setCurrentTab}>
            {tabs.map((tab) => (
              <Tab
                key={tab.key}
                label={tab.label}
                style={{
                  textEmphasisColor: "#fff",
                }}
              />
            ))}
          </Tabs>
          <TabContent>
            <RouteSwitch>
              <Route
                path="/board/:boardId/installable/:installableId/settings"
                render={() => <Settings />}
              />
              <Route
                path="/board/:boardId/installable/:installableId/info"
                render={() => info}
              />
              <Redirect to={`/board/${boardId}/installable/${installableId}`} />
            </RouteSwitch>
          </TabContent>
        </>
      ) : (
        <TabContent>{info}</TabContent>
      )}
    </>
  );
};
