import {  Button, Box } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  filterButton: {
    paddingLeft: 12,
  },
  filterBorder: {
    borderColor: "#7d878e",
    borderWidth: 1,
    color: "#ffffff"

  },
});

interface IFilterButton {
  isOpen: boolean;
  setOpen: () => void;
}

export const FilterButton = (props: IFilterButton) => {
  const classes = useStyles();
  return (
    <Button onClick={props.setOpen} className={classes.filterButton}>
      <Box borderBottom={1} className={classes.filterBorder}>
        Filter {props.isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Box>
    </Button>
  );
};
