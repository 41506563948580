import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { DeleteDraftV2Mutation, DeleteDraftV2MutationVariables } from "../gql";

const MUTATION = gql`
  mutation DeleteDraftV2($input: RemoveDraftInputV2!) {
    removeDraft: removeDraftV2(input: $input) {
      __typename
      ... on DraftV2 {
        id
        created
        attribution
        message {
          id
          text
          characters
        }
      }
      ... on DraftErrorV2 {
        type
        error
      }
    }
  }
`;

export const useDeleteDraftV2 = () => {
  return useMutation<DeleteDraftV2Mutation, DeleteDraftV2MutationVariables>(
    MUTATION
  );
};
