import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  PicksCategoriesQuery,
  PicksCategoriesQueryVariables,
  PicksQuery,
  PicksQueryVariables,
  TodaysPicksQuery,
  TodaysPicksQueryVariables,
} from "../gql";

const PICKS_CATEGORIES_QUERY = gql`
  query PicksCategoriesQuery {
    picksCategories {
      id
      title
    }
  }
`;

const PICKS_FOR_CATEGORY_QUERY = gql`
  query PicksQuery($categoryId: String!, $page: Int, $perPage: Int) {
    pickCategory(id: $categoryId) {
      id
      picksLength
      picks(page: $page, perPage: $perPage) {
        id
        message {
          id
          text
          formatted
        }
      }
    }
  }
`;

const TODAYS_PICKS_QUERY = gql`
  query TodaysPicksQuery {
    todaysPicks {
      id
      recommendBefore
      recommendAfter
      message {
        id
        text
        formatted
      }
    }
  }
`;

export const usePicksCategories = () => {
  return useQuery<PicksCategoriesQuery, PicksCategoriesQueryVariables>(
    PICKS_CATEGORIES_QUERY
  );
};

export const usePicks = (categoryId: string, page: number, perPage: number) => {
  return useQuery<PicksQuery, PicksQueryVariables>(PICKS_FOR_CATEGORY_QUERY, {
    variables: {
      categoryId,
      page,
      perPage,
    },
  });
};

export const useTodaysPicks = () => {
  return useQuery<TodaysPicksQuery, TodaysPicksQueryVariables>(
    TODAYS_PICKS_QUERY
  );
};
