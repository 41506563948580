import * as React from "react";

import {
  TextField,
  ThemeProvider,
  createTheme,
  Box,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add, Remove } from "@mui/icons-material";

const useStyles = makeStyles({
  container: {
    border: `solid 1px black`,
    borderRadius: 6,
    fontSize: 16,
    textTransform: "capitalize",
    display: "flex",
    flexDirection: "row",
    color: "black",
    alignItems: 'center'
  },
  input: {
    label: {
      display: "none",
    },
    "& input": {
      textAlign: "center",
    },
    // hide default arrows
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  iconBtn: {
    minWidth: "unset",
  },
  icon: {}
});

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        inputProps: {
          style: {
            color: "#000000", // This sets the text field font color
          },
        },
      },
      styleOverrides: {
        root: {
          marginTop: 0,
          color: "#000",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        formControl: {
          border: "none",
          height: 46,
          borderRadius: 3,
          backgroundColor: "transparent",
          color: "#000",
          "&$disabled": {
            opacity: 0.5,
          },
        },
        input: {
          color: "black",
          padding: "0px 4px",
          minWidth: "8px",
        },
        underline: {
          "&:before": {
            display: "none",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#000",
    },
    text: {
      primary: "#000",
      secondary: "#000",
    },
  },
});

interface OutlinedNumberStepperProps {
  value: number;
  onChange?(newValue: number): void;
  onBlur?(): void;
  maxInputWidth?: number;
  className?: string;
}

export const OutlinedNumberStepper = (props: OutlinedNumberStepperProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Button
        color={"inherit"}
        className={classes.iconBtn}
        onClick={() => props.onChange?.(props.value - 1)}
      >
        <Remove color={"inherit"} className={classes.icon} />
      </Button>
      <ThemeProvider theme={theme}>
        <TextField
          type={"number"}
          variant={"standard"}
          color={"secondary"}
          value={props.value}
          onChange={({ target: { value } }) => props.onChange?.(Number(value))}
          InputLabelProps={{
            shrink: true,
          }}
          className={[classes.input || "", props.className || ""].join(" ")}
          style={{
            maxWidth: props.maxInputWidth,
            minWidth: 50,
            color: "black",
          }}
          sx={{
            color: "black",
          }}
        />
      </ThemeProvider>
      <Button
        color={"inherit"}
        className={classes.iconBtn}
        onClick={() => props.onChange?.(props.value + 1)}
      >
        <Add color={"inherit"} className={classes.icon} />
      </Button>
    </Box>
  );
};
