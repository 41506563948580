import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { RemoveLikeV2Mutation, RemoveLikeV2MutationVariables } from "../gql";

const MUTATION = gql`
  mutation RemoveLikeV2($input: RemoveLikeInputV2!) {
    removeLike: removeLikeV2(input: $input) {
      __typename
      ... on LikeV2 {
        feedItem {
          id
          isLikedByMe
          likeCount
        }
        pick {
          id
          isLikedByMe
          likeCount
        }
      }
      ... on LikeErrorV2 {
        type
        error
      }
    }
  }
`;

export const useRemoveLikeV2 = () => {
  return useMutation<RemoveLikeV2Mutation, RemoveLikeV2MutationVariables>(
    MUTATION
  );
};

useRemoveLikeV2.optimisticFeedItem = (
  feedItemId: string,
  isLikedByMe: boolean,
  likeCount: number
) =>
  ({
    removeLike: {
      pick: null,
      feedItem: {
        __typename: "FeedItemV2",
        id: feedItemId,
        isLikedByMe: !isLikedByMe,
        likeCount: likeCount - 1,
      },
      __typename: "LikeV2",
    },
  } as RemoveLikeV2Mutation);

useRemoveLikeV2.optimisticPick = (
  pickId: string,
  isLikedByMe: boolean,
  likeCount: number
) =>
  ({
    removeLike: {
      feedItem: null,
      pick: {
        __typename: "PickV2",
        id: pickId,
        isLikedByMe: !isLikedByMe,
        likeCount: likeCount - 1,
      },
      __typename: "LikeV2",
    },
  } as RemoveLikeV2Mutation);
