import { useParams } from "react-router-dom";
import { useSearchMessagesV2 } from "../../hooks/useSearchMesssagesV2";
import { Alert } from "@mui/material";
import { MessageGrid } from "../../components/MessageGrid";
import { MessageCardV2 } from "../../components/MessageCardV2";
import {
  SearchMessageUnionV2,
  SearchMessageType,
  SearchMessageSortBy,
  SearchMessageSortDirection,
} from "../../gql";
import { useGetBoardStyle } from "../../hooks/useGetBoardStyle";
import { IBoardStyle } from "@vestaboard/installables";
import InfiniteScroll from "react-infinite-scroller";
import { MessageCardsContainerV2 } from "../../components/MessageCardsContainerV2";
import { SearchStateWrapper } from "../../components/SearchStateWrapper";
import { InfiniteLoader } from "../../components/InfiniteLoader";

const PER_PAGE = 24;

interface IHistoryPage {
  sources: string[] | null;
  setSource: (source: string) => void;
}

export const HistoryPageV2 = (props: IHistoryPage) => {
  const { boardId } = useParams<{ boardId: string }>();
  const { data: boardStyleData } = useGetBoardStyle();
  const { data, loading, error, refetch, fetchMore } = useSearchMessagesV2({
    input: {
      boardId,
      limit: PER_PAGE,
      cursor: null,
      sortBy: SearchMessageSortBy.Date,
      sortDirection: SearchMessageSortDirection.Desc,
      types: [SearchMessageType.MessageAppearance],
      sources: props.sources || undefined,
    },
  });

  return !data?.listSearchMessages && loading ? (
    <SearchStateWrapper>
      <InfiniteLoader />
    </SearchStateWrapper>
  ) : error ? (
    <SearchStateWrapper>
      <Alert severity="error">There was an error loading the messages.</Alert>
    </SearchStateWrapper>
  ) : !data?.listSearchMessages.searchMessages.length ? (
    <SearchStateWrapper>
      <Alert severity="info">You have no history messages.</Alert>
    </SearchStateWrapper>
  ) : (
    <MessageCardsContainerV2
      refetch={async () => {
        await refetch();
      }}
    >
      {(variables) => (
        <InfiniteScroll
          pageStart={0}
          loadMore={async () => {
            if (loading) {
              return;
            }

            return await fetchMore({
              updateQuery(previousQueryResult, options) {
                const newResults =
                  options.fetchMoreResult?.listSearchMessages.searchMessages ||
                  [];
                const previousResults =
                  previousQueryResult.listSearchMessages.searchMessages || [];

                return {
                  listSearchMessages: {
                    ...previousQueryResult.listSearchMessages,
                    nextCursor:
                      options.fetchMoreResult?.listSearchMessages.nextCursor ||
                      null,
                    searchMessages: [...previousResults, ...newResults],
                  },
                };
              },
              variables: {
                input: {
                  boardId,
                  limit: PER_PAGE,
                  cursor: data?.listSearchMessages.nextCursor || null,
                  sortBy: SearchMessageSortBy.Date,
                  sortDirection: SearchMessageSortDirection.Desc,
                  types: [SearchMessageType.MessageAppearance],
                  sources: props.sources || undefined,
                },
              },
            });
          }}
          hasMore={!!data?.listSearchMessages.nextCursor}
          loader={<InfiniteLoader />}
        >
          <MessageGrid>
            {(data?.listSearchMessages?.searchMessages || []).map(
              (searchMessage) => (
                <MessageCardV2
                  {...variables}
                  searchMessage={searchMessage as SearchMessageUnionV2}
                  boardStyle={
                    (boardStyleData?.board.boardStyle || "black") as IBoardStyle
                  }
                  key={searchMessage.id}
                />
              )
            )}
          </MessageGrid>
        </InfiniteScroll>
      )}
    </MessageCardsContainerV2>
  );
};
