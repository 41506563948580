import {
  BackButton,
  Button,
  Confirm,
  Spacer,
  Title,
} from "@vestaboard/installables";
import { Box, ButtonBase, CircularProgress, Typography } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { CopyTextBox } from "./components/CopyTextBox";
import { FullLoader } from "../../components/FullLoader";
import { PaddedTemplate } from "../../templates/PaddedTemplate";
import { makeStyles } from "@mui/styles";
import { useCreateSimulatorToken } from "./hooks/useCreateSimulatorToken";
import { useDeleteSimulatorToken } from "./hooks/useDeleteSimulatorToken";
import { useGetPublicSimulatorToken } from "./hooks/useGetPublicSimulatorToken";

const useStyles = makeStyles({
  back: {
    padding: 12,
  },
});

export const PublishPageV2 = () => {
  const { boardId } = useParams<{
    boardId: string;
  }>();
  const classes = useStyles();
  const { data, loading } = useGetPublicSimulatorToken(boardId);
  const [generateToken, { loading: generating }] = useCreateSimulatorToken();
  const [deleteToken, { loading: revoking }] = useDeleteSimulatorToken();
  const history = useHistory();
  const [simulatorUrl, setSimulatorUrl] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isConfirmingRevoke, setIsConfirmingRevoke] = useState(false);

  useEffect(() => {
    if (data?.publicSimulatorTokenV2.__typename === "SimulatorTokenV2") {
      setSimulatorUrl(data.publicSimulatorTokenV2.url);
      setToken(data.publicSimulatorTokenV2.token);
    }
  }, [data]);

  const generateSimulatorToken = useCallback(async () => {
    const result = await generateToken({
      variables: {
        input: {
          boardId,
          isPublic: true,
        },
      },
    });

    if (result.data?.createSimulatorToken.__typename === "SimulatorTokenV2") {
      setSimulatorUrl(result.data.createSimulatorToken.url);
      setToken(result.data.createSimulatorToken.token);
    }
  }, [boardId, generateToken]);

  const goBack = useCallback(() => {
    history.push(`/board/${boardId}/settings`);
  }, [boardId, history]);

  const revokeToken = useCallback(async () => {
    if (!token) {
      return;
    }

    const result = await deleteToken({
      variables: {
        token,
      },
    });

    if (result.data?.deleteSimulatorToken.__typename === "SimulatorTokenV2") {
      setSimulatorUrl(null);
      setToken(null);
    }
  }, [deleteToken, token]);

  return (
    <>
      <Box className={classes.back}>
        <BackButton onClick={goBack} />
      </Box>
      <PaddedTemplate>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Title>Publish Digitally</Title>
            <Spacer size="large" />
            {simulatorUrl ? (
              <>
                <Confirm
                  open={isConfirmingRevoke}
                  title="Are you sure you want to revoke your digital link?"
                  message="This will make your digital link and embed code invalid. If you generate a new simulator link, it will be a different URL than the one you have now."
                  handleClose={() => {
                    setIsConfirmingRevoke(false);
                  }}
                  handleAccept={async () => {
                    setIsConfirmingRevoke(false);
                    await revokeToken();
                  }}
                />
                <CopyTextBox value={simulatorUrl} label="Simulator Link" />
                <Spacer size="large" />
                <CopyTextBox
                  value={`<iframe src="${simulatorUrl}" style="width: 100%; border: none; aspect-ratio: 16 / 8.4;" scrolling="no"></iframe>`}
                  label="HTML Embed Code"
                />
                <Spacer size="extraLarge" />
                <Typography>
                  Use the simulator link above to access to what's currently on
                  this Vestaboard. You have full control and can revoke access
                  at any time.
                </Typography>
                <Spacer size="extraLarge" />
                <Button
                  disabled={revoking}
                  buttonType="danger"
                  onClick={() => {
                    setIsConfirmingRevoke(true);
                  }}
                  width={200}
                >
                  Revoke Link
                </Button>
              </>
            ) : (
              <>
                <Typography>
                  Make your Vestaboard more accessible by enabling a digital
                  simulator that mirrors what's currently displayed. When you
                  generate a simulator link, you have full control and can
                  revoke the link at any time.
                </Typography>
                <Spacer size="large" />
                <Button
                  disabled={generating}
                  buttonType="outline"
                  onClick={generateSimulatorToken}
                  width={250}
                >
                  Generate Link
                </Button>
              </>
            )}
          </>
        )}
      </PaddedTemplate>
    </>
  );
};
