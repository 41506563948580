import { Drafts, IBoard, LoadMask } from "@vestaboard/installables";
import { SearchMessageType } from "../../../gql";
import { useSearchMessagesV2 } from "../../../hooks/useSearchMesssagesV2";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

interface IDraftsModal {
  onClose: () => void;
  onSelect: (board: IBoard) => void;
}

export const DraftsModal = (props: IDraftsModal) => {
  const { data, loading } = useSearchMessagesV2({
    input: {
      types: [SearchMessageType.Draft],
    },
  });

  return loading && !data?.listSearchMessages.searchMessages ? (
    <LoadMask />
  ) : (
    <Drafts
      onClose={props.onClose}
      onSelect={(characters) => {
        props.onClose();
        props.onSelect(characters);
      }}
      drafts={
        data?.listSearchMessages?.searchMessages.map((draft) => ({
          message: draft.message?.characters as IBoard,
          timeStamp: dayjs(new Date(+draft?.created)).fromNow(),
        })) || []
      }
    />
  );
};
