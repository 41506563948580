import * as React from "react";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BREAKPOINT_SMALL } from "../../../config";
import { CSSProperties } from "@mui/styles";

import { REFERRAL_BAR_HEIGHT, useReferralContext } from "./ReferralBar";

const navbarStyle = {
  background: "#181919",
  pointerEvents: "initial",
  height: 80,
  justifyContent: "space-between",
  position: "fixed",
  width: "100%",
  zIndex: 999,
  [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
    height: 64,
  },
} as CSSProperties;

const useStyles = makeStyles((theme) => ({
  navbarStyle: {
    ...navbarStyle,
  },
  navbarStyleReferral: {
    ...navbarStyle,
    top: REFERRAL_BAR_HEIGHT,
  },
  headerSpacer: {
    height: 80,
    width: "100%",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      height: 64,
    },
  },
}));

interface IHeader {
  children: Array<React.ReactElement>;
}

export const Header = (props: IHeader) => {
  const classNames = useStyles();
  const { hasReferralBanner } = useReferralContext();

  const navBarStyle = hasReferralBanner
    ? classNames.navbarStyleReferral
    : classNames.navbarStyle;
  return (
    <>
      <nav className={[navBarStyle, "flex items-center"].join(" ")}>
        {props.children}
      </nav>
      <Box className={classNames.headerSpacer} />
    </>
  );
};
