import * as React from "react";

import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Spacer } from "../../../components/Spacer";

interface ISettings {}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: 'column'
  },
  content: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // maxWidth: 452,
  }
});

export const Settings = (props: ISettings) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        {/*<Typography className={classes.description}>
          {props.description}
        </Typography>*/}
      </Box>
    </Box>
  );
};
