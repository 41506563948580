import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { LeaveBoardV2Mutation, LeaveBoardV2MutationVariables } from "../gql";



const LEAVE_BOARD_MUTATION = gql`
  mutation LeaveBoardV2($input: LeaveBoardInputV2!) {
    board: leaveBoardV2(input: $input) {
      ... on BoardV2 {
        id
      }
      ... on BoardErrorV2 {
        type
        error
      }
    }
  }
`;

export const useLeaveBoardV2 = () => {
  return useMutation<LeaveBoardV2Mutation, LeaveBoardV2MutationVariables>(
    LEAVE_BOARD_MUTATION
  );
};