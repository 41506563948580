import * as React from "react";

import { Container } from "@mui/material";

interface IPaddedTemplate {
  children: React.ReactElement;
}

export const PaddedTemplate = (props: IPaddedTemplate) => (
  <Container className="mt4">{props.children}</Container>
);
