import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { MigrateBoardV2Mutation, MigrateBoardV2MutationVariables } from "../gql";

const MIGRATE_BOARD_MUTATION = gql`
  mutation MigrateBoardV2($id: String!) {
    migrateBoardV2(boardId: $id) {
      success
      description
    }
  }
`;


export const useMigrateBoardV2 = () => {
  return useMutation<MigrateBoardV2Mutation, MigrateBoardV2MutationVariables>(MIGRATE_BOARD_MUTATION);
};