import * as React from "react";

import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface IInstallableIcon {
  icon?: string | null;
  title: string;
  size: "small" | "medium" | "large";
  disabled?: boolean;
}

const useStyles = makeStyles({
  avatar: {
    backgroundColor: "#0A0A0B",
    width: 88,
    height: 88,
    boxShadow: "0 24px 18px 0 rgba(0, 0, 0, 0.35)",
    borderRadius: 7,
    "& > img": {
      width: "100%",
      height: "auto",
    },
  },
  disabled: {
    filter: "grayscale(1)",
  },
});

export const InstallableIcon = (props: IInstallableIcon) => {
  const classes = useStyles();
  let tileSize = 0;
  const renderTileSize = () => {
    switch (props.size) {
      case "large":
        return (tileSize = 104);
      case "medium":
        return (tileSize = 88);
      case "small":
        return (tileSize = 40);
    }
  };

  return (
    <Avatar
      className={[classes.avatar, props.disabled && classes.disabled]
        .filter((e) => !!e)
        .join(" ")}
      src={props.icon || "/vestaboard-square.png"}
      alt={props.title as string}
      variant="rounded"
      style={{
        width: renderTileSize(),
        height: renderTileSize(),
      }}
    />
  );
};
