import * as React from "react";

import { Box, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useHistory, useParams } from "react-router";

import { FaTimes } from "react-icons/fa";
import { Spacer } from "../../components/Spacer";
import { Title } from "@vestaboard/installables";
import { useMyBoardsV2 } from "../../hooks/useMyBoardsV2";
import { BoardSettingsV2 } from "../../components/BoardSettingsV2";

interface IRouteParams {
  boardId: string;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 50,
  },
  closeContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 80,
    width: "100%",
  },
  closeButton: {
    color: "#f5f5f7",
    height: 43,
    margin: "0 15px 0 0",
    opacity: 0.5,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    textTransform: "none",
    border: "solid 1px #7e878e",
    padding: "11px 22px 11px 21px",
  },
  createContainer: {
    width: 448,
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const SelectBoardPageV2 = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<IRouteParams>();
  const { data, loading } = useMyBoardsV2();

  if (loading) {
    return <CircularProgress />;
  }

  const boards =
    data?.BoardsV2?.__typename === "ListBoards" ? data?.BoardsV2?.boards : [];

  return (
    <Box className={classes.container}>
      <Box className={classes.closeContainer}>
        <Button
          onClick={() => {
            history.goBack();
          }}
          className={classes.closeButton}
          startIcon={<FaTimes />}
        >
          Close
        </Button>
      </Box>
      {boards.map((board) => (
        <BoardSettingsV2
          boardId={board.id}
          isSelected={board?.id === params.boardId}
          enableDelete={boards.length > 1}
        />
      ))}
      {!boards || boards?.length === 0 && (
        <>
          <Box className={classes.createContainer}>
            <Title>There are no boards associated with your account.</Title>
          </Box>
          <Spacer height={80} />
        </>
      )}
      <Box className={classes.createContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(`/v2/board/${params.boardId}/new`);
          }}
        >
          Create New Vestaboard
        </Button>
      </Box>
    </Box>
  );
};
