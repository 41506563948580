import {
  UpdatePersonTenantAssociationMutation,
  UpdatePersonTenantAssociationMutationVariables,
} from "../../../gql";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const UPDATE_USER_TENANT_ROLE = gql`
  mutation UpdatePersonTenantAssociationMutation(
    $input: UpdatePersonTenantAssociationInput!
  ) {
    updatePersonTenantAssociationMutation(input: $input) {
      id
      role
    }
  }
`;

export const useUpdateUserRole = () => {
  const [updateUserRole] = useMutation<
    UpdatePersonTenantAssociationMutation,
    UpdatePersonTenantAssociationMutationVariables
  >(UPDATE_USER_TENANT_ROLE);

  return { updateUserRole };
};
