import * as React from "react";

import { BREAKPOINT_SMALL } from "../../../config";
import LogoImage from "../../../logo.webp";
import { history } from "../../../providers/AppRouterProvider";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  logoStyle: {
    height: "16px",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      height: 12,
      position: "relative",
      top: "-1px",
    },
  },
}));

export const Logo = () => {
  const classNames = useStyles();

  return (
    <div>
      <span
        style={{ cursor: "pointer", marginLeft: "0px" }}
        onClick={() => history.push("/")}
      >
        <img
          src={LogoImage}
          className={classNames.logoStyle}
          alt="Vestaboard"
        />
      </span>
    </div>
  );
};
