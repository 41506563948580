import { Close } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  tag: {
    backgroundColor: "#303537",
    paddingTop: 8,
    paddingBottom: 8,
    marginRight: 12,
    borderRadius: 8,
    display: "flex",
    paddingLeft: 18,
    paddingRight: 18,
  },
  clearIcon: {
    marginLeft: 24,
  },
});

interface ITag {
  children: React.ReactNode;
  onClick: () => void;
}

export const Tag = (props: ITag) => {
  const classes = useStyles();

  return (
    <ButtonBase className={classes.tag} onClick={props.onClick}>
      {props.children}
      <Close className={classes.clearIcon} />
    </ButtonBase>
  );
};
