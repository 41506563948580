import { CreateMessageShareTokenMutation } from "../gql";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const CREATE_SHARE_TOKEN = gql`
  mutation CreateMessageShareToken($input: CreateMessageShareTokenInput!) {
    createMessageShareToken(input: $input) {
      rawToken
      messageShareToken {
        id
        created
      }
    }
  }
`;

export const useCreateShareToken = () => {
  return useMutation<CreateMessageShareTokenMutation>(CREATE_SHARE_TOKEN);
};
