import { IBoard, useToasts } from "@vestaboard/installables";
import { useCallback } from "react";
import { useCreateMessageV2 } from "../../../hooks/useCreateMessageV2";
import { useCreateMessageAppearanceV2 } from "../../../hooks/useCreateMessageAppearanceV2";
import { MessageAppearanceErrorType } from "../../../gql";
import { useHistory } from "react-router-dom";

interface IHandleSendMessageProps {
  characters: IBoard;
  setShowBypass: (showBypass: boolean) => void;
}

interface IHandleSendMessage {
  boardId: string;
  preventRedirect?: boolean;
}

export const useHandleSendMessage = ({
  characters,
  setShowBypass,
}: IHandleSendMessageProps) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [createMessage, { loading: creatingMessage }] = useCreateMessageV2();
  const [createMessageAppearance, { loading: creatingMessageAppearance }] =
    useCreateMessageAppearanceV2();

  const handleSendMessage = useCallback(
    async ({ boardId, preventRedirect }: IHandleSendMessage) => {
      const message = await createMessage({
        variables: {
          input: {
            characters,
          },
        },
      });

      if (message.data?.createMessage?.__typename === "MessageV2") {
        const messageAppearance = await createMessageAppearance({
          variables: {
            input: {
              messageId: message.data.createMessage.id,
              boardId: boardId,
            },
          },
        });

        // If the message is already pinned, we can bypass the pin
        const messageIsPinned =
          messageAppearance.data?.createMessageAppearance.__typename ===
            "MessageAppearanceErrorV2" &&
          messageAppearance.data.createMessageAppearance.type ===
            MessageAppearanceErrorType.MessagePinned;

        if (messageIsPinned) {
          setShowBypass(true);
          return;
        }

        if (
          messageAppearance.data?.createMessageAppearance.__typename ===
          "MessageAppearanceErrorV2"
        ) {
          addToast(
            messageAppearance.data?.createMessageAppearance.error ||
              `We were unable to send this message`,
            {
              appearance: "error",
            }
          );
          return;
        }

        if (
          messageAppearance.data?.createMessageAppearance.__typename ===
          "MessageAppearanceV2"
        ) {
          if (preventRedirect) {
            return;
          }

          addToast(`Message created`, {
            appearance: "success",
          });
          history.push(`/board/${boardId}/messages/my/history`);
          return;
        }
      } else if (message.data?.createMessage?.__typename === "MessageErrorV2") {
        addToast(
          message.data?.createMessage.error ||
            `We were unable to send this message`,
          {
            appearance: "error",
          }
        );
      }
    },
    [
      addToast,
      characters,
      createMessage,
      createMessageAppearance,
      history,
      setShowBypass,
    ]
  );

  return {
    handleSendMessage,
    creatingMessageAppearance: creatingMessageAppearance || creatingMessage,
  };
};
