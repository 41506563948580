import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GetTenantBoardsQuery } from "../../../gql";
import { GET_TENANT_BOARDS_QUERY } from "../../RedirectToBoard";

export const InstallableRedirect = (props: { marketplaceListing?: string }) => {
  const { data, loading } = useQuery<GetTenantBoardsQuery>(
    GET_TENANT_BOARDS_QUERY
  );
  const history = useHistory();
  const params = useParams<{ marketplaceid?: string }>();
  const lastBoardId = localStorage.getItem("lastBoardId");
  const tenantCount = data?.tenants?.length || 0;
  useEffect(() => {
    if (!loading) {
      const boardIds =
        data && tenantCount > 0
          ? data.tenants.reduce((prev: Array<string>, current) => {
              return [
                ...prev,
                ...(current.boards || []).map((board) => board.id),
              ];
            }, [])
          : [];

      const marketplaceid = props.marketplaceListing || params.marketplaceid;
      const isDeeplink = window.location.href.includes("?deeplink");
      if (lastBoardId) {
        const url = `/board/${lastBoardId}/installable/${marketplaceid}/`;
        history.push(`${url}${isDeeplink ? "?deeplink" : ""}}`);
      } else if (boardIds.length > 0) {
        const url = `/board/${boardIds[0]}/installable/${marketplaceid}/`;
        history.push(`${url}${isDeeplink ? "?deeplink" : ""}}`);
      } else {
        history.push("/login");
      }
    }
  }, [data, history, lastBoardId, loading]);
  return <></>;
};
