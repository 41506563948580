import * as React from "react";

import { Box, BoxProps } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { BREAKPOINT_SMALL } from "../config";

const useStyles = makeStyles({
  routeContainer: {
    borderTop: "solid 1px #2b2b2b",
    // boxShadow: "0px -5px 10px rgb(23, 24, 24, 0.5)",
    flex: 1,
    paddingLeft: 88,
    paddingRight: 88,
    paddingTop: 44,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 24,
      paddingBottom: 24,
    },
  },
});

export const TabContent = (props: BoxProps) => {
  const classes = useStyles();

  return <Box className={classes.routeContainer} {...props} />;
};
