import * as React from "react";

import { BoardSettings } from "../../components/BoardSettings";
import { FETCH_ALL_BOARDS } from "./";
import { usePermissions } from "../../hooks/usePermissions";

interface IBoard {
  isSelected: boolean;
  board: any;
  enableDelete: boolean;
  members: Array<any>;
  tenants: Array<any>;
}

export const Board = (props: IBoard) => {
  const { data: myData } = usePermissions();

  const { isOwner, isAdmin } = props.tenants
    .filter((tenant) => {
      const boardIds = tenant.boards.map((board: any) => board.id);

      if (boardIds.includes(props.board.id)) {
        return true;
      }

      return false;
    })
    .reduce(
      (prev, tenant) => {
        return (
          tenant.members
            .filter((member: any) => {
              return member.person.id === myData?.viewer?.account?.person?.id;
            })
            .reduce(
              (prevMember: any, member: any) => {
                return {
                  isOwner: prev.isOwner || member.role === "Owner",
                  isAdmin: prev.isAdmin || member.role === "Admin",
                };
              },
              { isOwner: false, isAdmin: false }
            )
        );
      },
      { isOwner: false, isAdmin: false }
    );

  return (
    <BoardSettings
      isOwner={isOwner}
      isAdmin={isAdmin}
      board={props.board}
      isSelected={props.isSelected}
      enableDelete={props.enableDelete}
      refetchQueries={[
        {
          query: FETCH_ALL_BOARDS,
        },
      ]}
      personTenantAssociationId={
        props.members.find(
          (member) => member.person.id === myData?.viewer?.account?.person.id
        )?.id
      }
    />
  );
};
