import { Avatar, Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useState } from "react";

import { FilledButton } from "../../components/FilledButton";
import { Input } from "@vestaboard/installables";
import { OutlinedButton } from "../../components/OutlinedButton";
import { SectionBody } from "../../components/SectionBody";
import { SectionHeader } from "../../components/SectionHeader";
import { SectionTitle } from "../../components/SectionTitle";
import { Spacer } from "../../components/Spacer";
import gql from "graphql-tag";
import { useHistory } from "react-router";
import { useToasts } from "@vestaboard/installables";
import { usePermissions } from "../../hooks/usePermissions";
import { useGetMyRoleV2 } from "../../hooks/useGetRolesV2";
import { useParams } from "react-router-dom";
import { useUpdatePersonV2 } from "../../hooks/useUpdatePersonV2";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 384,
  },
  formButtons: {
    display: "flex",
    paddingTop: 42,
  },
  avatar: {
    background: "#191A1A",
    border: "solid 1px #f5f5f7",
    fontFamily: "VestaboardWYSIWYG, Roboto, sans-serif",
    height: 80,
    width: 80,
    fontSize: 24,
    letterSpacing: -10,
    textIndent: -10,
  },
});

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($input: UpdatePersonInput!) {
    updatePerson(input: $input) {
      person {
        id
        firstName
        lastName
      }
    }
  }
`;

const UPDATE_EMAIL_ADDRESS = gql`
  mutation UpdateEmailAddress($input: UpdateEmailAddressInput!) {
    updateEmailAddress(input: $input) {
      id
      emailAddress
    }
  }
`;

interface IProfileForm {
  firstName?: string;
  lastName?: string;
  emailAddress: string;
  id: string;
}

const ProfileForm = (props: IProfileForm) => {
  const { addToast } = useToasts();
  const [updateProfile] = useUpdatePersonV2();

  const [email, setEmail] = useState(props.emailAddress);
  const [firstName, setFirstName] = useState(props.firstName || "");
  const [lastName, setLastName] = useState(props.lastName || "");
  const classes = useStyles();
  const history = useHistory();
  const avatarText =
    `${firstName.charAt(0) || ""}${lastName.charAt(0) || ""}` || "?";

  const disabled = !firstName || !lastName || !email;

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        if (disabled) {
          return;
        }

        try {
          await updateProfile({
            variables: {
              input: {
                id: props.id,
                firstName,
                lastName,
                emailAddress: props.emailAddress,
              },
            },
          }).catch((error) => {
            addToast("There was an error saving your profile", {
              appearance: "error",
              autoDismiss: true,
            });

            return Promise.reject(error);
          });

          if (email !== props.emailAddress) {
            await updateProfile({
              variables: {
                input: {
                  id: props.id,
                  firstName,
                  lastName,
                  emailAddress: email || props.emailAddress,
                },
              },
            }).catch((error) => {
              addToast(
                "That email address already exists in our system. Please enter another email or invite that email address as a new user.",
                {
                  appearance: "error",
                  autoDismiss: false,
                }
              );

              return Promise.reject(error);
            });
          }

          addToast("Profile saved", {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (err) {}
      }}
    >
      <SectionHeader>
        <SectionTitle>Profile</SectionTitle>
      </SectionHeader>
      <SectionBody>
        <Box className={classes.container}>
          <Avatar className={classes.avatar}>{avatarText}</Avatar>
          <Spacer height={42} />
          <Input
            placeholder="john@doe.com"
            value={email}
            label="Email"
            disabled
            onValueChange={(value) => {
              setEmail(value);
            }}
          />
          <Spacer height={18} />
          <Input
            placeholder="John"
            value={firstName}
            label="First Name"
            onValueChange={(value) => {
              setFirstName(value);
            }}
          />
          <Spacer height={18} />
          <Input
            placeholder="Doe"
            value={lastName}
            label="Last Name"
            onValueChange={(value) => {
              setLastName(value);
            }}
          />
          <Box className={classes.formButtons}>
            <OutlinedButton
              fullWidth
              type="button"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </OutlinedButton>
            <Spacer width={32} />
            <FilledButton type="submit" fullWidth disabled={disabled}>
              Save
            </FilledButton>
          </Box>
        </Box>
        <Spacer height={80} />
      </SectionBody>
    </form>
  );
};

export const ProfileV2 = () => {
  const params = useParams<{
    boardId: string;
  }>();
  const { data } = useGetMyRoleV2(params.boardId);
  const person =
    data?.viewerV2?.__typename === "PersonV2" ? data.viewerV2 : null;
  if (!person) {
    return <CircularProgress />;
  }
  return (
    <ProfileForm
      id={person?.id}
      firstName={person?.firstName || ""}
      lastName={person?.lastName || ""}
      emailAddress={person.emailAddress}
    />
  );
};
