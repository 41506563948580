import * as React from "react";

import { Box, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { FetchAllBoardsQuery } from "../../gql";
import { useHistory, useParams } from "react-router";

import { Board } from "./Board";
import { FaTimes } from "react-icons/fa";
import { Spacer } from "../../components/Spacer";
import { Title } from "@vestaboard/installables";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

interface IRouteParams {
  boardId: string;
}

export const FETCH_ALL_BOARDS = gql`
  query FetchAllBoards {
    tenants {
      id
      members {
        id
        isCurrentMember
        role
        person {
          id
        }
      }
      boards {
        id
        title
        friendlyIdentifier
        devices {
          id
        }
        productApplications {
          id
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 50,
  },
  closeContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 80,
    width: "100%",
  },
  closeButton: {
    color: "#f5f5f7",
    height: 43,
    margin: "0 15px 0 0",
    opacity: 0.5,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    textTransform: "none",
    border: "solid 1px #7e878e",
    padding: "11px 22px 11px 21px",
  },
  createContainer: {
    width: 448,
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const SelectBoardPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<IRouteParams>();
  const { data, loading } = useQuery<FetchAllBoardsQuery>(FETCH_ALL_BOARDS);

  if (loading) {
    return <CircularProgress />;
  }

  const boardsWrapperObject = (data ? data.tenants : [])
    .reduce((prev: any, current: any) => {
      return [
        ...prev,
        ...(current.boards.map((board: any) => ({
          board,
          members: current.members,
        })) || []),
      ];
    }, [])
    .filter(
      (boardWrapper: any) =>
        !!boardWrapper.members.find((member: any) => member.isCurrentMember)
    )
    .reduce((prev, current) => {
      return {
        ...prev,
        [current.board.id]: current,
      };
    }, {});

  const boardsWrapper = Object.keys(boardsWrapperObject).map(
    (key) => boardsWrapperObject[key]
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.closeContainer}>
        <Button
          onClick={() => {
            history.goBack();
          }}
          className={classes.closeButton}
          startIcon={<FaTimes />}
        >
          Close
        </Button>
      </Box>
      {boardsWrapper.map((boardWrapper) => (
        <Board
          members={boardWrapper.members}
          tenants={data?.tenants || []}
          board={boardWrapper.board}
          key={boardWrapper.board.id}
          isSelected={boardWrapper.board.id === params.boardId}
          enableDelete={boardsWrapper.length > 1}
        />
      ))}
      {!boardsWrapper.length && (
        <>
          <Box className={classes.createContainer}>
            <Title>There are no boards associated with your account.</Title>
          </Box>
          <Spacer height={80} />
        </>
      )}
      <Box className={classes.createContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(`/board/${params.boardId}/new`);
          }}
        >
          Create New Vestaboard
        </Button>
      </Box>
    </Box>
  );
};
