export const isApiKey = (title?: string | null) =>
  (title || "").includes("API Key");

export const getDeveloperTitle = (
  developer?: { title: string; id: string } | null
) => (developer ? developer.title : "Vestaboard, Inc.");

export const getTitle = (title?: string | null) =>
  isApiKey(title) ? (title ? `${title.substring(0, 16)}...` : "") : (title as string);

export const getIcon = (title?: string | null, icon?: string | null) =>
  icon ||
  (isApiKey(title)
    ? "/icons/icon-installable-api.svg"
    : "/vestaboard-square.png");
