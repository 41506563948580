import * as React from "react";

import { Button, ButtonProps } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  button: {
    border: "none",
    padding: "5px 9px",
    borderRadius: 8,
    "-webkit-backdrop-filter": "blur(10px)",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(125, 135, 142, 0.2)",
    fontFamily: "HelveticaNeue",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: 1.67,
    color: "#f5f5f7",
    textTransform: "none",
    boxShadow: "none",
    "&:focus": {
      backgroundColor: "rgba(125, 135, 142, 0.2)",
    },
  },
  active: {
    backgroundColor: "white",
    color: "#171818",
    "&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
    },
  },
  fullWidth: {
    width: "100%",
  },
  large: {
    padding: 16,
    fontSize: 18,
  },
});

interface GrayFilledButtonProps extends ButtonProps {
  active?: boolean;
  fullWidth?: boolean;
  size?: "small" | "medium" | "large";
}

export const GrayFilledButton = ({
  active,
  fullWidth,
  size,
  ...props
}: GrayFilledButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      className={[
        classes.button,
        fullWidth && classes.fullWidth,
        active && classes.active,
        props.className,
        size === "large" ? classes.large : undefined,
      ]
        .filter((e) => !!e)
        .join(" ")}
    >
      {props.children}
    </Button>
  );
};
