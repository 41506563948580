import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  GetPublicSimulatorTokenQuery,
  GetPublicSimulatorTokenQueryVariables,
} from "../../../gql";

const QUERY = gql`
  query GetPublicSimulatorToken($boardId: String!) {
    publicSimulatorTokenV2(boardId: $boardId) {
      ... on SimulatorTokenV2 {
        id
        token
        url
      }
      ... on SimulatorTokenErrorV2 {
        type
        error
      }
    }
  }
`;

export const useGetPublicSimulatorToken = (boardId: string) =>
  useQuery<GetPublicSimulatorTokenQuery, GetPublicSimulatorTokenQueryVariables>(
    QUERY,
    { variables: { boardId }, fetchPolicy: "network-only" }
  );
