import React from "react";
import { Modal, ModalFooter } from "./Modal";
import { Box } from "@mui/material";
import { OutlinedButton } from "./OutlinedButton";
import { FilledButton } from "./FilledButton";
import { Icon, IconType } from "@vestaboard/installables";
import { makeStyles } from "@mui/styles";

interface IConfirmationModal {
  confirmButtonText?: string;
  icon: string;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  visible: boolean;
  subMessage?: string;
}

const useStyles = makeStyles({
  messageIcon: {
    color: "#000",
    width: "53px",
    height: "44px",
    objectFit: "contain",
    margin: `20px 181px 0px 182px`,
    "@media(max-width: 700px)": {
      margin: `20px 150px 0px 150px`,
    },
    "@media(max-width: 500px)": {
      margin: `20px 150px 0px 145px`,
    },
  },
  messageText: {
    textAlign: "center",
    fontFamily: "HelveticaNeue",
    lineHeight: 1.33,
    fontSize: "18px",
    margin: `40px 0 32px`,
    "@media(max-width: 700px)": {
      fontSize: "15px",
      paddingHorizontal: "5px",
    },
    "@media(max-width: 500px)": {
      fontSize: "13px",
      paddingHorizontal: "3px",
    },
  },
  messageContainer: {
    paddingTop: "-12px",
    "@media(max-width: 700px)": {
      padding: 10,
    },
    "@media(max-width: 500px)": {
      padding: 10,
    },
  },
  modalButton: {
    width: `184px`,
    height: `48px`,
    margin: `15px 32px 0 8px`,
    padding: `11px 0px`,
    borderRadius: `6px`,
    border: "solid 1px #FFF",
    backgroundColor: "transparent",
    color: "#000",
    fontSize: 16,
    fontFamily: "HelveticaNeue",
    "&:disabled": {
      opacity: 0.25,
    },
    "@media(max-width: 700px)": {
      width: `95px`,
      height: `45px`,
      margin: `15px 32px 0 8px`,
    },
    "@media(max-width: 500px)": {
      width: `88px`,
      height: `45px`,
    },
  },
  modal: {
    backgroundColor: `rgba(125, 135, 142, 0.2)`,
    backdropFilter: `blur(20px)`,
    borderRadius: "6px",
  },
});

export const ConfirmationModal: React.FC<IConfirmationModal> = (
  props: IConfirmationModal
) => {
  const classes = useStyles();
  return (
    <Modal
      shown={props.visible}
      onClose={props.onCancel}
      className={classes.modal}
    >
      <div
        style={{
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.icon.includes(".svg") ? (
          <img
            className={classes.messageIcon}
            src={`/icons/${props.icon}`}
            alt="Copy"
          />
        ) : (
          <Box marginTop={4}>
            <Icon type={props.icon as IconType} size={38} color="white" />
          </Box>
        )}
      </div>
      <div className={classes.messageContainer}>
        <h4 className={classes.messageText}>{props.message}</h4>
        {props.subMessage && (
          <h4 className={classes.messageText}>{props.subMessage}</h4>
        )}
      </div>
      <ModalFooter
        customStyles={{
          border: "none",
          justifyContent: "center",
          marginLeft: "28px",
          bottom: "50px",
        }}
      >
        <OutlinedButton
          className={classes.modalButton}
          onClick={props.onCancel}
        >
          Cancel
        </OutlinedButton>
        <FilledButton
          onClick={async () => await props.onConfirm()}
          className={classes.modalButton}
          style={{
            marginLeft: "30px",
            backgroundColor: "white",
            color: "black",
          }}
        >
          {props.confirmButtonText || "OK"}
        </FilledButton>
      </ModalFooter>
    </Modal>
  );
};
