import * as React from "react";

import { Box, ThemeProvider, createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { BREAKPOINT_SMALL } from "../config";
import MuiTabs from "@mui/material/Tabs";

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          borderBottom: "solid 2px #FFF",
          maxWidth: 170,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          justifyContent: "flex-start",
          marginRight: "0 !important",
          [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
            paddingTop: "18px !important",
            paddingBottom: "18px !important",
          },
        },
        wrapped: {
          textAlign: "left",
          width: "auto !important",
          fontSize: 14,
        },
      },
    },
  },
});

const useStyles = makeStyles({
  tabContainer: {
    paddingTop: 0,
    paddingLeft: 88,
    display: "flex",
    width: "calc(100% - 176px)",
    justifyContent: "space-between",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      paddingLeft: 14,
      paddingRight: 14,
      width: "90vw",
      overflowX: "visible",
    },
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    borderBottom: "none",
    "& > span": {
      maxWidth: 24,
      width: "100%",
      borderBottom: "solid 2px #FFF",
    },
  },
});

interface ITabs {
  onChange: (tab: number) => void;
  children: React.ReactElement | React.ReactElement[];
  value: number;
  endAdornment?: React.ReactElement;
}

export const Tabs = (props: ITabs) => {
  const classes = useStyles();

  return (
    <Box className={classes.tabContainer}>
      <ThemeProvider theme={theme}>
        <MuiTabs
          scrollButtons="auto"
          variant="scrollable"
          value={props.value}
          onChange={(_e, tab) => {
            props.onChange(tab);
          }}
          classes={{
            indicator: classes.indicator,
          }}
          TabIndicatorProps={{
            children: <span />,
          }}
        >
          {props.children}
        </MuiTabs>
        {props.endAdornment || null}
      </ThemeProvider>
    </Box>
  );
};
