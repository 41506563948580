import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { DragHandle } from "@mui/icons-material";
import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useMediaQuery } from "react-responsive";
import { useHistory, useParams } from "react-router";
import { usePaywall } from "../../../providers/PaywallProvider";
import { GrayFilledButton } from "../../../components/GrayFilledButton";
import { InstallableIcon } from "../../../components/InstallableIcon";
import { BREAKPOINT_SMALL } from "../../../config";
import { useAppState } from "../../../hooks/global";
import { EnabledToggle } from "../components/MutedToggle";
import { useCreateSubscription } from "../hooks/useCreateSubscription";
import { MiniMutedToggle } from "./MutedToggle";

interface IRouteMatch {
  boardId: string;
}

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
  },
  container: {
    display: "flex",
    padding: 16,
    position: "relative",
    borderRadius: 8,
    "-webkit-backdrop-filter": "blur(10px)",
    backdropFilter: "blur(10px)",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      padding: 0,
    },
  },
  disabled: {
    opacity: 0.5,
  },
  content: {
    display: "flex",
    marginLeft: 22,
    flexGrow: 1,
  },
  details: {
    flexGrow: 1,
  },
  titleBar: {
    position: "relative",
    flexGrow: 1,
    marginBottom: 25,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      marginBottom: 18,
    },
  },
  action: {
    position: "absolute",
    top: 0,
    right: 0,
    paddingLeft: 60,
    backgroundImage:
      "linear-gradient(to right, rgba(30, 32, 34, 0), #1e2022 40%, #1e2022)",
    display: "flex",
    justifyContent: "flex-end",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      right: "-20px",
    },
  },
  enabledToggle: {
    display: "inline-block",
    marginTop: -20,
  },
  title: {
    paddingTop: 3,
    fontFamily: "HelveticaNeue",
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: 1.44,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#f5f5f7 !important",
    maxHeight: 26,
    overflowY: "hidden",
    textDecoration: "none !important",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      fontSize: 16,
    },
  },
  description: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#fff",
  },
  prioritizing: {
    backgroundColor: "rgba(23, 24, 24, 0.5)",
  },
  priority: {
    position: "absolute",
    zIndex: 1,
    top: 9,
    left: 10,
    minWidth: 32,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    "-webkit-backdrop-filter": "blur(10px)",
    backdropFilter: "blur(10px)",
    boxShadow: "0 6px 6px 0 rgba(23, 24, 24, 0.46)",
    backgroundColor: "rgba(125, 135, 142, 0.2)",
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "center",
  },
  reorderIcon: {
    minHeight: 24,
    marginLeft: 8,
  },
  dragging: {
    border: "solid 1px #2b2b2b",
    boxShadow: "0 16px 16px 0 rgba(0, 0, 0, 0.3)",
  },
});

interface IInstallableListItem {
  id: string;
  title: string;
  description: string | null;
  icon: string | null;
  installed: boolean;
  enabled: boolean;
  prioritizing: boolean;
  priority: number;
  dragging?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  className?: string;
  onClick?: () => void;
  configurationButton?: boolean;
  subscriptionId?: string;
  tenantId?: string;
  isPlus: boolean;
  installableIsSingleton?: boolean;
  paywall?: (action: () => any) => any;
  publicInstallable: boolean;
  free: boolean;
}

export const InstallableListItem: React.FC<IInstallableListItem> = (props) => {
  const classes = useStyles();
  const params = useParams<IRouteMatch>();
  const { boardId } = params;
  const history = useHistory();
  const isSmallScreen = useMediaQuery({
    query: `(max-width: ${BREAKPOINT_SMALL})`,
  });
  const paywall =
    props.paywall && !props.free && props.publicInstallable
      ? props.paywall
      : (action: () => any) => {
          action();
        };

  const isApiKey = (props.title || "").includes("API Key");

  const url = `/board/${boardId}/installable/${props.id}`;
  const { isPlus } = usePaywall();

  const navigateToDetail = () => {
    paywall(() => {
      history.push(
        `/board/${params.boardId}/installables/subscription/${
          props.subscriptionId ?? ""
        }`
      );
    });
  };

  return (
    <Box
      className={[props.className, classes.wrapper]
        .filter((e) => !!e)
        .join(" ")}
    >
      <Box
        className={[
          classes.container,
          props.prioritizing && classes.prioritizing,
          props.installed && !props.enabled && classes.disabled,
          props.dragging && classes.dragging,
        ]
          .filter((e) => !!e)
          .join(" ")}
        onClick={() => navigateToDetail()}
      >
        {props.prioritizing && (
          <Box className={classes.priority}>{props.priority}</Box>
        )}
        <span onClick={() => paywall(() => history.push(url))}>
          <InstallableIcon
            icon={isApiKey ? "/icons/icon-installable-api.svg" : props.icon}
            title={props.title || ""}
            size={isSmallScreen ? "medium" : "large"}
            disabled={props.installed && !props.enabled}
          />
        </span>
        <Box className={classes.content}>
          <Box className={classes.details}>
            <Box className={classes.titleBar}>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigateToDetail()}
                className={classes.title}
              >
                {props.title}
              </span>
            </Box>
            <Box className={classes.description}>{props.description}</Box>
          </Box>

          {props.prioritizing && (
            <span {...props.dragHandleProps}>
              <DragHandle className={classes.reorderIcon} />
            </span>
          )}
        </Box>
      </Box>
      {!props.prioritizing && (
        <Box className={classes.action}>
          {!props.installed ? (
            <InstallButton
              free={props.free}
              contextCopy={props.title}
              marketplaceListingId={props.id}
              navigateToDetail={navigateToDetail}
            />
          ) : (
            <span className={classes.enabledToggle}>
              <EnabledToggle
                enabled={props.enabled}
                boardId={params.boardId}
                subscriptionId={props.subscriptionId as string}
                tenantId={props.tenantId as string}
                hideLabel
                paywall={props.paywall}
              />
            </span>
          )}
        </Box>
      )}
    </Box>
  );
};

export type InstallButtonRenderFn = (
  disabled: boolean,
  onClick: () => any,
  spinning: boolean
) => React.ReactElement;

export type ToggleSwitchRenderFn = (
  disabled: boolean,
  onChange: (value: boolean) => any,
  value: boolean
) => React.ReactElement;

export interface IInstallButtonProps {
  marketplaceListingId: string;
  free: boolean;
  canAdd?: boolean;
  contextCopy?: string | null;
  render?: InstallButtonRenderFn;
  navigateToDetail?: () => void;
  installText?: string;
}

export const InstallButton: React.FC<IInstallButtonProps> = (props) => {
  const { marketplaceListingId } = props;
  const params = useParams<IRouteMatch>();
  const { boardId } = params;
  const { tenantId } = useAppState();
  const [spinning, setSpinning] = React.useState(false);
  const [canAdd, setCanAdd] = React.useState(props.canAdd ?? true);
  const history = useHistory();
  const { setShown, isPlus } = usePaywall();

  const { createSubscription } = useCreateSubscription({
    tenantId: tenantId ?? "",
    boardId: boardId ?? "",
  });

  const defaultRenderFn: InstallButtonRenderFn = (
    disabled,
    onClick,
    spinning
  ) => (
    <GrayFilledButton {...{ disabled, onClick }}>
      {spinning ? <CircularProgress size={20} /> : props.installText || "Add"}
    </GrayFilledButton>
  );

  const renderFn: InstallButtonRenderFn = props.render
    ? props.render
    : defaultRenderFn;

  const click = async () => {
    if (props.navigateToDetail) {
      return props.navigateToDetail();
    }
    if (isPlus || props.free) {
      setSpinning(true);
      const subscription = await createSubscription({ marketplaceListingId });
      setSpinning(false);
      setCanAdd(false);
      if (subscription) {
        history.push(
          `/board/${boardId}/installables/subscription/${subscription}`
        );
      }
    } else {
      setShown(true, props.contextCopy ?? null);
    }
  };

  return renderFn(!canAdd, click, spinning);
};

type InstallButtonOrToggleSwitchProps = IInstallButtonOrToggleSwitchProps;

interface IInstallButtonOrToggleSwitchProps {
  renderInstallButton?: InstallButtonRenderFn;
  marketplaceListingId: string;
  subscriptionId: string | null;
  contextCopy?: string | null;
  canAdd?: boolean;
  installableIsSingleton: boolean;
  tenantId: string;
  boardId: string;
  muted: boolean;
  showButtonInsteadOfToggle?: boolean;
  free: boolean;
  navigateToDetail?: () => void;
  installText?: string;
}

export const InstallButtonOrToggleSwitch: React.FC<
  InstallButtonOrToggleSwitchProps
> = (props) => {
  const history = useHistory();
  const params = useParams<IRouteMatch>();
  const { boardId } = params;

  const toggleProps = {
    enabled: !props.muted,
    boardId: props.boardId,
    tenantId: props.tenantId,
    subscriptionId: props.subscriptionId ?? "",
  };
  const classes = useStyles();

  const addState = (
    <InstallButton
      free={props.free}
      render={props.renderInstallButton}
      canAdd={props.canAdd}
      marketplaceListingId={props.marketplaceListingId}
      navigateToDetail={props.navigateToDetail}
      installText={props.installText}
    />
  );
  const settingsState = (
    <InstallButton
      free={props.free}
      render={(disabled, onClick, spinning) => (
        <GrayFilledButton
          onClick={() =>
            history.push(
              `/board/${boardId}/installables/subscription/${
                props.subscriptionId ?? ""
              }`
            )
          }
          {...{ disabled }}
        >
          Settings
        </GrayFilledButton>
      )}
      canAdd={props.canAdd}
      marketplaceListingId={props.marketplaceListingId}
    />
  );
  const toggleState = (
    <span className={classes.enabledToggle}>
      <MiniMutedToggle {...toggleProps} />
    </span>
  );

  if (
    props.showButtonInsteadOfToggle &&
    props.installableIsSingleton &&
    props.subscriptionId
  ) {
    return settingsState;
  }
  if (props.installableIsSingleton && props.subscriptionId) {
    return toggleState;
  }
  return addState;
};
