import * as React from "react";

import {
  RevokeUserInvitationMutation,
  RevokeUserInvitationMutationVariables,
} from "../../../gql";

import { Confirm } from "../../../components/Confirm";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useToasts } from "@vestaboard/installables";

interface IRevokeInvitation {
  personTenantAssociationId: null | string;
  onCancel: () => void;
  onComplete: () => void;
  title: string;
  message: string;
}

const REVOKE_INVITATION = gql`
  mutation RevokeUserInvitation(
    $input: RevokeInvitationForPersonAndTenantInput!
  ) {
    revokePersonTenantInvitation(input: $input) {
      revoked
    }
  }
`;

export const RevokeInvitation = (props: IRevokeInvitation) => {
  const { addToast } = useToasts();
  const [revokeInvitation] = useMutation<
    RevokeUserInvitationMutation,
    RevokeUserInvitationMutationVariables
  >(REVOKE_INVITATION);

  return (
    <Confirm
      title={props.title}
      message={props.message}
      open={!!props.personTenantAssociationId}
      handleClose={props.onCancel}
      handleAccept={async () => {
        if (!props.personTenantAssociationId) {
          return;
        }

        try {
          await revokeInvitation({
            variables: {
              input: {
                personTenantAssociationId: props.personTenantAssociationId,
              },
            },
          });

          props.onCancel();

          addToast(`The invitation was revoked successfully`, {
            appearance: "success",
            autoDismiss: true,
          });

          props.onComplete();
        } catch (err) {
          addToast(`There was an error revoking this invitation`, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }}
    ></Confirm>
  );
};
