import * as React from "react";

import { Box, Button, Card, Paper } from "@mui/material";

type FactoryPageProps = IFactoryPageProps;

interface IFactoryPageProps {}

const getFactoryPayload = async (): Promise<string> => {
  const payload = await fetch(
    `https://platform.vestaboard.com/factory/device`,
    { method: "POST" }
  );
  const json = await payload.json();
  return json;
};

export const FactoryPage: React.FC<FactoryPageProps> = (props) => {
  const [payloads, setPayloads] = React.useState<any[]>([]);

  const provision = async () => {
    setPayloads([...payloads, await getFactoryPayload()]);
  };

  return (
    <Box>
      <Button variant="outlined" onClick={() => provision()}>
        Provision
      </Button>
      <Paper>
        {payloads.map((payload) => (
          <Box mt={3}>
            <Card>
              <code>
                {btoa(
                  JSON.stringify({
                    deviceId: payload.id,
                    broadcastNetworkSsid: payload.ssid,
                    broadcastNetworkWpa2Key: payload.wpa2Key,
                    pairingCode: payload.pairingCode,
                  })
                )}
              </code>
            </Card>
          </Box>
        ))}
      </Paper>
    </Box>
  );
};
