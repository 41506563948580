import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GetTenantBoardsQuery } from "../../../gql";
import { GET_TENANT_BOARDS_QUERY } from "../../RedirectToBoard";

export const PlusRedirect = (props: { tab?: string; section?: string }) => {
  const { data, loading } = useQuery<GetTenantBoardsQuery>(
    GET_TENANT_BOARDS_QUERY
  );
  const history = useHistory();
  const lastBoardId = localStorage.getItem("lastBoardId");
  const redirectSection = props.section || "installables";

  const tenantCount = data?.tenants?.length || 0;

  useEffect(() => {
    if (!loading) {
      const boardIds =
        data && tenantCount > 0
          ? data.tenants.reduce((prev: Array<string>, current) => {
              return [
                ...prev,
                ...(current.boards || []).map((board) => board.id),
              ];
            }, [])
          : [];
      if (lastBoardId) {
        history.push(`/board/${lastBoardId}/${redirectSection}/${props.tab}`);
      } else if (boardIds.length > 0) {
        history.push(`/board/${boardIds[0]}/${redirectSection}/${props.tab}`);
      } else {
        history.push("/login");
      }
    }
  }, [tenantCount, history, lastBoardId, loading]);
  return <></>;
};
