import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { CreateLikeV2Mutation, CreateLikeV2MutationVariables } from "../gql";

const MUTATION = gql`
  mutation CreateLikeV2($input: CreateLikeInputV2!) {
    createLike: createLikeV2(input: $input) {
      ... on LikeV2 {
        __typename
        feedItem {
          id
          isLikedByMe
          likeCount
        }
        pick {
          id
          isLikedByMe
          likeCount
        }
      }
      ... on LikeErrorV2 {
        type
        error
      }
    }
  }
`;

export const useCreateLikeV2 = () => {
  return useMutation<CreateLikeV2Mutation, CreateLikeV2MutationVariables>(
    MUTATION
  );
};

useCreateLikeV2.optimisticFeedItem = (
  feedItemId: string,
  isLikedByMe: boolean,
  likeCount: number
) =>
  ({
    createLike: {
      pick: null,
      feedItem: {
        __typename: "FeedItemV2",
        id: feedItemId,
        isLikedByMe: !isLikedByMe,
        likeCount: likeCount + 1,
      },
      __typename: "LikeV2",
    },
  } as CreateLikeV2Mutation);

useCreateLikeV2.optimisticPick = (
  pickId: string,
  isLikedByMe: boolean,
  likeCount: number
) =>
  ({
    createLike: {
      feedItem: null,
      pick: {
        __typename: "PickV2",
        id: pickId,
        isLikedByMe: !isLikedByMe,
        likeCount: likeCount + 1,
      },
      __typename: "LikeV2",
    },
  } as CreateLikeV2Mutation);
