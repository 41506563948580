import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React, { useState } from "react";

import { Switch } from "../../../components/Switch";
import { useGetInstallableSubscriptions } from "../hooks/useGetInstallableSubscriptions";
import { useToasts } from "@vestaboard/installables";
import { useUpdateSubscription } from "../hooks/useUpdateSubscription";

interface IMutedToggle {
  enabled: boolean;
  boardId: string;
  subscriptionId: string;
  tenantId: string;
  hideLabel?: boolean;
  paywall?: (action : () => any) => any;
}

const useStyles = makeStyles({
  enabledLabel: {
    fontFamily: "HelveticaNeue",
    fontSize: 12,
    lineHeight: 1.67,
  },
});

export const useEnabledToggle = (initiallyEnabled: boolean, subscriptionId: string, boardId: string, tenantId: string) => {
  const [enabled, setEnabled] = useState(initiallyEnabled);
  const [updateSubscription] = useUpdateSubscription();
  const { addToast } = useToasts();
  const saveSubscriptionMutedStatus = async (enabled: boolean) => {
    try {
      await updateSubscription({
        variables: {
          subscriptionId,
          muted: !enabled,
        },
        refetchQueries: [
          {
            query: useGetInstallableSubscriptions.query,
            variables: {
              boardId: boardId,
              tenantId: tenantId,
            },
          },
        ],
      });

      addToast(`Channel ${enabled ? "enabled" : "disabled"}`, {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      addToast(`Error ${enabled ? "enabling" : "disabling"} channel`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return {
    enabled,
    setEnabled: async (value: boolean) => {
      setEnabled(value)
      await saveSubscriptionMutedStatus(value)
    }
  }
}

export const EnabledToggle = (props: IMutedToggle) => {
  const { enabled, setEnabled } = useEnabledToggle(props.enabled, props.subscriptionId, props.boardId, props.tenantId)
  const classes = useStyles()
  const paywall = props.paywall ? props.paywall : (action: () => any) => {action()}

  return (
    <>
      {!props.hideLabel && (
        <Typography className={classes.enabledLabel}>
          {enabled ? "Enabled" : "Disabled"}
        </Typography>
      )}
      <Box>
        <Switch size="small" checked={enabled} onClick={() => paywall(() => { setEnabled(!enabled)})} />
      </Box>
    </>
  );
};

export const MiniMutedToggle: React.FC<IMutedToggle> = (props) => {
  const { enabled, setEnabled } = useEnabledToggle(props.enabled, props.subscriptionId, props.boardId, props.tenantId)
  const classes = useStyles()

  return (
    <>
      <Box>
        <Switch size="small" checked={enabled} onClick={() => setEnabled(!enabled)} />
      </Box>
    </>
  );
}