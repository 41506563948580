import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  PinMessageToBoardV2Mutation,
  PinMessageToBoardV2MutationVariables,
} from "../gql";

const MUTATION = gql`
  mutation PinMessageToBoardV2($input: CreatePinnedMessageInputV2!) {
    pinMessageToBoard: pinMessageToBoardV2(input: $input) {
      ... on PinnedMessageV2 {
        id
        boardId
        message {
          id
        }
      }
      ... on PinnedMessageErrorV2 {
        type
        error
      }
    }
  }
`;

export const usePinMessageToBoardV2 = () => {
  return useMutation<
    PinMessageToBoardV2Mutation,
    PinMessageToBoardV2MutationVariables
  >(MUTATION);
};
