import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  DeleteFeedItemV2Mutation,
  DeleteFeedItemV2MutationVariables,
} from "../gql";

const MUTATION = gql`
  mutation DeleteFeedItemV2($input: DeleteFeedItemInputV2!) {
    deleteFeedItem: deleteFeedItemV2(input: $input) {
      ... on FeedItemV2 {
        id
      }
      ... on FeedItemErrorV2 {
        type
        error
      }
    }
  }
`;

export const useDeleteFeedItemV2 = () => {
  return useMutation<
    DeleteFeedItemV2Mutation,
    DeleteFeedItemV2MutationVariables
  >(MUTATION);
};
