import "./style.css";

import * as React from "react";

import { Button } from "@mui/material";
import CharacterUnit from "./assets/CharacterUnit.png";
import VestaboardWithNoCharacterUnits from "./assets/VestaboardWithNoCharacterUnits.png";

// from https://platform.vestaboard.com/documentation/characters
type ICharactersReference = Array<{
  name: string;
  naturalValue: string | null;
  integerValue: number;
  description: string | null;
  color: string | null;
}>;
const characterReferenceLocalStorageKey = "vestaboard-characters-reference";

const useCharacterCodeConversion = () => {
  const [
    charactersReference,
    setCharactersReference,
  ] = React.useState<ICharactersReference>(() => {
    const existingReferenceJson = localStorage.getItem(
      characterReferenceLocalStorageKey
    );
    return existingReferenceJson !== null
      ? JSON.parse(existingReferenceJson)
      : [];
  });
  React.useEffect(() => {
    fetch("https://platform.vestaboard.com/documentation/characters")
      .then((res) => res.json())
      .then((data) => {
        const reference = data["characters"];
        setCharactersReference(reference);
        localStorage.setItem(
          characterReferenceLocalStorageKey,
          JSON.stringify(reference)
        );
      });
  }, []);

  const getCharacterCode = ({
    naturalValue,
    color,
  }: {
    naturalValue?: string;
    color?: string;
  }): number => {
    const characterReference = charactersReference.find((c) =>
      naturalValue !== undefined
        ? c.naturalValue === naturalValue.toUpperCase()
        : color !== undefined
        ? c.color === color
        : false
    );
    return characterReference ? characterReference.integerValue : 0;
  };

  const getNaturalValue = (characterCode: number): string => {
    const characterReference = charactersReference.find(
      (c) => c.integerValue === characterCode
    );

    const resolvedNaturalValue =
      characterReference && characterReference.naturalValue !== null
        ? characterReference.naturalValue
        : "";
    return resolvedNaturalValue !== " " ? resolvedNaturalValue : "";
  };

  const getColor = (characterCode: number): string | null => {
    const characterReference = charactersReference.find(
      (c) => c.integerValue === characterCode
    );

    return characterReference ? characterReference.color : null;
  };

  return { charactersReference, getCharacterCode, getNaturalValue, getColor };
};

const rows = 6;
const emptyRowArr = new Array(rows).fill(null);
const columns = 22;
const emptyColArr = new Array(columns).fill(null);

const totalVestaBits = rows * columns;
const emptyVestaBitArr = new Array(totalVestaBits).fill(null);

interface IControlledWYSIWYGEditorProps {
  characterCodes: {
    [vestaBit: number]: number;
  };
  setColorMultiSelecting?: (colorMultiSelecting: boolean) => void;
  characterGridDivProps?: Partial<React.HTMLProps<HTMLDivElement>>;
  inputProps?(
    currentVestaBit: number
  ): Partial<Omit<Omit<React.HTMLProps<HTMLInputElement>, "value">, "name">>;
}

type ControlledWYSIWYGEditorProps = IControlledWYSIWYGEditorProps;

export const ControlledWYSIWYGEditor: React.FC<ControlledWYSIWYGEditorProps> = (
  props
) => {
  const { getNaturalValue, getColor } = useCharacterCodeConversion();

  return (
    <div
      className={"wysiwyg"}
      onTouchStart={() => {
        if (props.setColorMultiSelecting) {
          props.setColorMultiSelecting(true);
        }
      }}
      onTouchEnd={() => {
        if (props.setColorMultiSelecting) {
          props.setColorMultiSelecting(false);
        }
      }}
    >
      <div className="container-fluid">
        <div className={"relative"}>
          <img
            className="background"
            src={VestaboardWithNoCharacterUnits}
            alt="Background"
          />
          <div
            className={"character-grid flex flex-column"}
            {...(props.characterGridDivProps || {})}
          >
            {emptyRowArr.map((_, rowI) => {
              const currentRow = rowI + 1;

              return (
                <div
                  key={rowI}
                  className={`row${currentRow} flex`}
                  style={{ height: `calc(100% / ${rows})` }}
                >
                  {emptyColArr.map((_, colI) => {
                    const currentVestaBit = colI + columns * rowI;
                    const characterCode = props.characterCodes[currentVestaBit];
                    const naturalValue = getNaturalValue(characterCode);
                    const color = getColor(characterCode);

                    const inputProps = props.inputProps
                      ? props.inputProps(currentVestaBit)
                      : {};

                    return (
                      <div
                        key={colI}
                        className={"column"}
                        style={{ width: `calc(100% / ${columns})` }}
                      >
                        <div className={"letter"}>
                          <img
                            className={"overlay"}
                            src={CharacterUnit}
                            alt="Overlay"
                          />
                          <input
                            key={colI}
                            type={"text"}
                            value={naturalValue || ""}
                            {...inputProps}
                            name={`letter${currentVestaBit}`}
                            className={[
                              `letter${currentVestaBit}`,
                              inputProps.className,
                            ]
                              .filter((e) => !!e)
                              .join(" ")}
                            maxLength={1}
                            style={{
                              ...inputProps.style,
                              backgroundColor: color || undefined,
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const initialState: IInputsState = emptyVestaBitArr.reduce<IInputsState>(
  (acc, _, vestaBit) => {
    return {
      ...acc,
      [vestaBit]: 0,
    };
  },
  {}
);

const initialRef: IInputsRef = emptyVestaBitArr.reduce((acc, _, vestaBit) => {
  return {
    ...acc,
    [vestaBit]: null,
  };
});

interface IWYSIWYGEditorProps {
  submitNewMessage(characterCodes: Array<Array<number>>): void;
  BodyWrapper?: React.ComponentType<{ className?: string }>;
  ButtonsWrapper?: React.ComponentType<{ className?: string }>;
  className?: string;
  initial?: Array<Array<number>>;
  cta?: string;
}

type WYSIWYGEditorProps = IWYSIWYGEditorProps;

interface IInputsState {
  [vestaBit: number]: number;
}

interface IInputsRef {
  [vestaBit: number]: HTMLInputElement | null;
}

export const WYSIWYGEditor: React.FC<WYSIWYGEditorProps> = (props) => {
  const [inputsState, setInputsState] = React.useState<IInputsState>(
    initialState
  );
  const setInputState = (vestaBit: number, state: IInputsState[0]) => {
    setInputsState((old) => ({
      ...old,
      [vestaBit]: state,
    }));
  };
  React.useEffect(() => {
    if (props.initial) {
      props.initial.map((row, rowIndex) =>
        row.map((column, columnIndex) => {
          return setInputState(rowIndex * 22 + columnIndex, column);
        })
      );
    }
  }, [props.initial]);

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const inputsRef = React.useRef<IInputsRef>(initialRef);
  const [, setSelectedVestaBit] = React.useState<number | null>(null);
  const [colorSelectionMode, setColorSelectionMode] = React.useState<{
    color: string;
  } | null>(null);
  const [colorMultiSelecting, setColorMultiSelecting] = React.useState<boolean>(
    false
  );
  const {
    charactersReference,
    getCharacterCode,
  } = useCharacterCodeConversion();

  const clearBoard = () => {
    setInputsState(initialState);
  };

  const onInputKeyUp = (
    vestaBit: number,
    event: React.KeyboardEvent,
    changeEvent: any
  ) => {
    const keyCode =
      event.keyCode === 229 ? changeEvent.charCodeAt() : event.keyCode;

    if (
      keyCode === 16 ||
      keyCode === 17 ||
      keyCode === 18 ||
      keyCode === 35 ||
      keyCode === 45 ||
      keyCode === 144 ||
      keyCode === 166 ||
      keyCode === 167
    ) {
      return;
    }

    // navigation keys
    if (keyCode === 38) {
      // Up
      let newVestaBitUp = vestaBit - columns;
      if (inputsRef.current[newVestaBitUp]) {
        inputsRef.current[newVestaBitUp]!.select();
      }
    } else if (keyCode === 40) {
      // Down
      let newVestaBitDown = vestaBit + columns;
      if (inputsRef.current[newVestaBitDown]) {
        inputsRef.current[newVestaBitDown]!.select();
      }
    } else if (keyCode === 37) {
      // Left
      let newVestaBitLeft = vestaBit - 1;
      if (inputsRef.current[newVestaBitLeft]) {
        inputsRef.current[newVestaBitLeft]!.select();
      }
    } else if (keyCode === 39) {
      // Right
      let newVestaBitRight = vestaBit + 1;
      if (inputsRef.current[newVestaBitRight]) {
        inputsRef.current[newVestaBitRight]!.select();
      }
    } else if (keyCode === 8 || keyCode === 46) {
      // Backspace or delete
      let eraseVestaBit = vestaBit - 1;
      setInputState(eraseVestaBit, 0); // clear last vestabit
      if (inputsRef.current[eraseVestaBit]) {
        inputsRef.current[eraseVestaBit]!.select();
      }
    } else if (keyCode === 13) {
      // enter
      const row = Math.floor(vestaBit / columns);
      const startingVestaBitInRow = row * columns;

      // find closest start of current line input
      let closestUnfilledVestaBit = null;
      for (let i = vestaBit - 1; i >= startingVestaBitInRow; i--) {
        if (inputsState[i] === 0 && closestUnfilledVestaBit === null) {
          closestUnfilledVestaBit = i;
        }
      }

      // move down a line
      let newVestaBit =
        (closestUnfilledVestaBit || startingVestaBitInRow) + columns + 1; // vestaBit + columns;
      if (inputsRef.current[newVestaBit]) {
        inputsRef.current[newVestaBit]!.select();
      }
    } else {
      // special input handling
      if (keyCode === 126) {
        // unicode tilde key
        setInputState(vestaBit, 62); // degree character code
      } else {
        // set key as-is
        setInputState(
          vestaBit,
          getCharacterCode({
            naturalValue: event.keyCode === 229 ? changeEvent : event.key,
          })
        );
      }

      // move cursor to next vestabit
      selectNextVestaBit(vestaBit);
    }
  };

  const selectNextVestaBit = (vestaBit: number) => {
    let newVestaBit = vestaBit + 1;
    if (inputsRef.current[newVestaBit]) {
      inputsRef.current[newVestaBit]!.select();
    }
  };

  const onSend = () => {
    let characterCodes: Array<Array<number>> = [];

    for (let row = 0; row < rows; row++) {
      let rowCodes: Array<number> = [];

      for (let col = 0; col < columns; col++) {
        const currentVestaBit = col + columns * row;
        const currentVal = inputsState[currentVestaBit];
        rowCodes.push(currentVal);
      }

      characterCodes.push(rowCodes);
    }

    props.submitNewMessage(characterCodes);
  };

  const BodyWrapper = props.BodyWrapper || "div";
  const ButtonsWrapper = props.ButtonsWrapper || "div";

  return (
    <div
      className={[props.className, "wysiwyg"].filter((e) => !!e).join(" ")}
      ref={containerRef}
    >
      <BodyWrapper>
        <ControlledWYSIWYGEditor
          characterCodes={inputsState}
          setColorMultiSelecting={setColorMultiSelecting}
          characterGridDivProps={{
            onMouseUp: (e) => {
              setColorMultiSelecting(false);
            },
          }}
          inputProps={(currentVestaBit) => {
            let _changeEvent: any;
            return {
              ref: (el) => {
                inputsRef.current[currentVestaBit] = el;
              },
              onMouseDown: (e) => {
                if (colorSelectionMode && colorSelectionMode) {
                  e.preventDefault();
                  setColorMultiSelecting(true);
                  setInputState(
                    currentVestaBit,
                    getCharacterCode({ color: colorSelectionMode.color })
                  );
                }
              },
              onMouseEnter: (e) => {
                if (colorMultiSelecting && colorSelectionMode) {
                  setInputState(
                    currentVestaBit,
                    getCharacterCode({ color: colorSelectionMode!.color })
                  );
                }
              },
              onFocus: (e) => {
                if (!colorSelectionMode) {
                  setSelectedVestaBit(currentVestaBit);
                }
              },
              onTouchMove: (e: any) => {
                if (colorMultiSelecting && colorSelectionMode) {
                  const { clientX, clientY } = e.touches[0];
                  const bitRefKeys = Object.keys(inputsRef.current);

                  bitRefKeys.forEach((current: any) => {
                    const el = inputsRef.current[current] as any;
                    const rect = el.getBoundingClientRect();

                    if (
                      rect.top < clientY &&
                      rect.bottom > clientY &&
                      rect.left < clientX &&
                      rect.right > clientX
                    ) {
                      setInputState(
                        current,
                        getCharacterCode({ color: colorSelectionMode!.color })
                      );
                    }
                  });
                }
              },
              onKeyUp: (e) => onInputKeyUp(currentVestaBit, e, _changeEvent),
              onChange: (e: any) => {
                _changeEvent = e.target.value;
              },
              style: {
                cursor: colorSelectionMode ? "pointer" : undefined,
              },
            };
          }}
        />
        <div className="container-fluid">
          <div className={"flex justify-end mt2"}>
            <div>
              <div className={"flex flex-wrap justify-end items-stretch"}>
                <div
                  key={`text-option`}
                  className={
                    "mv1 ml1 pointer bg-near-white flex justify-center items-center"
                  }
                  style={{
                    borderRadius: ".2rem",
                    minWidth: 20,
                    height: 26,
                    boxShadow: !colorSelectionMode
                      ? "0 0 0 2px white, 0 0 0 3px black"
                      : undefined,
                    fontSize: 12,
                    color: "#000",
                  }}
                  onClick={() => {
                    setColorSelectionMode(null);
                    setColorMultiSelecting(false);
                    inputsRef.current[0] && inputsRef.current[0].focus();
                  }}
                >
                  Aa
                </div>
                {[...charactersReference, { integerValue: 0, color: "#000000" }]
                  .reduce<any[]>((acc, curr) => {
                    if (acc.find((e) => e.color === curr.color)) {
                      return acc;
                    } else {
                      return [...acc, curr];
                    }
                  }, [])
                  .filter((c) => c.color !== null)
                  .map((c) => {
                    const isSelectedColor =
                      colorSelectionMode &&
                      colorSelectionMode.color === c.color!;

                    return (
                      <div
                        key={`color-${c.color!}`}
                        style={{
                          backgroundColor: c.color!,
                          borderRadius: ".2rem",
                          width: 20,
                          height: 26,
                          border:
                            c.color === "#000000"
                              ? "1px solid white"
                              : undefined,
                          boxShadow: isSelectedColor
                            ? "0 0 0 2px white, 0 0 0 3px white"
                            : undefined,
                        }}
                        className={"pointer mv1 ml1"}
                        onClick={() => {
                          if (
                            !colorSelectionMode ||
                            colorSelectionMode.color !== c.color!
                          ) {
                            setColorSelectionMode({ color: c.color! });
                          } else {
                            setColorSelectionMode(null);
                            setColorMultiSelecting(false);
                          }
                        }}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </BodyWrapper>
      <ButtonsWrapper className={"mt2"}>
        <Button color={"secondary"} onClick={clearBoard}>
          Clear
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            onSend();
            setInputsState(initialState);
          }}
        >
          {props.cta || "Send"}
        </Button>
      </ButtonsWrapper>
    </div>
  );
};
