import * as React from "react";

import {
  FetchSettingsQuery,
  FetchSettingsQueryVariables,
  BoardSettingsModulesQuery,
  BoardSettingsModulesQueryVariables,
} from "../../gql";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router";

import { BoardSettings } from "../../components/BoardSettings";
import { Box, CircularProgress } from "@mui/material";
import { Network } from "./Network";
import { QuietHours } from "./QuietHours";
import { SectionHeader } from "../../components/SectionHeader";
import { SectionTitle } from "../../components/SectionTitle";
import { Spacer } from "../../components/Spacer";
import { Tab } from "../../components/Tab";
import { TabContent } from "../../components/TabContent";
import { Tabs } from "../../components/Tabs";
import { TimeZone } from "./TimeZone";
import { Users } from "./Users";
import gql from "graphql-tag";
import { useFeatures } from "../../hooks/useFeatures";
import { useQuery } from "@apollo/client";
import { Iframe } from "../../components/Iframe";
import { FETCH_ALL_BOARDS } from "../SelectBoard";
import { Billing } from "./Billing";
import { usePaywall } from "../../providers/PaywallProvider";
import { useAppState } from "../../hooks/global";
import { usePermissions } from "../../hooks/usePermissions";

interface IRouteParams {
  boardId: string;
}

const BOARD_SETTINGS_MODULES = gql`
  query BoardSettingsModules($boardId: String!) {
    board(id: $boardId) {
      settings {
        __typename
        key
        title
        link
        location
      }
    }
  }
`;

export const useBoardSettingsModulesQuery = (boardId: string) =>
  useQuery<BoardSettingsModulesQuery, BoardSettingsModulesQueryVariables>(
    BOARD_SETTINGS_MODULES,
    {
      variables: {
        boardId,
      },
      fetchPolicy: "no-cache",
    }
  );

const FETCH_SETTINGS = gql`
  query FetchSettings($boardId: String!) {
    board(id: $boardId) {
      id
      title
      friendlyIdentifier
      devices {
        id
      }
      tenant {
        members {
          id
          person {
            id
          }
          role
        }
      }
    }
    tenants {
      id
      members {
        id
        isCurrentMember
        role
        person {
          id
        }
      }
      boards {
        id
      }
    }
  }
`;

export const SettingsPage: React.FC = () => {
  const { data: myData } = usePermissions();
  const location = useLocation();
  const history = useHistory();
  const { features } = useFeatures();
  const params = useParams<IRouteParams>();
  const { data: settingsModuleData } = useBoardSettingsModulesQuery(
    params.boardId
  );
  const { data, loading } = useQuery<
    FetchSettingsQuery,
    FetchSettingsQueryVariables
  >(FETCH_SETTINGS, {
    fetchPolicy: "no-cache",
    variables: {
      boardId: params.boardId,
    },
  });

  // set app state for board id so usePaywall hook will work
  const { setBoardId } = useAppState();
  React.useEffect(() => {
    setBoardId(params.boardId);
    params.boardId && localStorage.setItem("lastBoardId", params.boardId);
  }, [params]);

  const { isPlus } = usePaywall();

  if (loading || !data) {
    return <CircularProgress />;
  }

  const dynamicSettings = settingsModuleData?.board.settings
    .filter((bsm) => bsm.location == "settings")
    .map((setting) => ({
      key: setting.key ?? "",
      label: setting.title,
    }));

  const tabs = [
    ...(features.settings && features.settings.viewUsers
      ? [
          {
            key: "users",
            label: "Users",
          },
        ]
      : []),
    ...(features.settings && features.settings.viewTimezone
      ? [
          {
            key: "timezone",
            label: "Timezone",
          },
        ]
      : []),
    ...(features.settings && features.settings.viewQuietHours
      ? [
          {
            key: "quiet-hours",
            label: "Quiet Hours",
          },
        ]
      : []),

    ...(features.settings && features.settings.viewNetwork
      ? [
          {
            key: "network",
            label: "Network",
          },
        ]
      : []),
    ...(dynamicSettings ?? []),
    {
      // determines position of underline, this tab is 5th
      key: "billing",
      label: "Billing",
      enabled: isPlus,
    },
  ];

  const numberOfBoards = (data.tenants || []).reduce((prev, current) => {
    return prev + current.boards.length;
  }, 0);

 const { isOwner, isAdmin } = data.tenants
  .filter((tenant) => {
    const boardIds = tenant.boards.map((board: any) => board.id);

    if (boardIds.includes(params.boardId)) {
      return true;
    }

    return false;
  })
  .reduce(
    (prev, tenant) => {
      return (
        tenant.members
          .filter((member: any) => {
            return member.person.id === myData?.viewer?.account?.person?.id;
          })
          .reduce(
            (prevMember: any, member: any) => {
              return {
                isOwner: prev.isOwner || member.role === "Owner",
                isAdmin: prev.isAdmin || member.role === "Admin",
              };
            },
            { isOwner: false, isAdmin: false }
          )
      );
    },
    { isOwner: false, isAdmin: false }
  );

  const currentTab =
    tabs.reduce((prev, current, index) => {
      return location.pathname.includes(current.key) ? index : prev;
    }, 0) || 0;

  return (
    <>
      <SectionHeader>
        <SectionTitle>Settings</SectionTitle>
        <Spacer width={80} />
        <BoardSettings
          board={data.board}
          isSelected={false}
          transparent={true}
          enableDelete={numberOfBoards > 1}
          isOwner={isOwner}
          isAdmin={isAdmin}
          personTenantAssociationId={
            data.board.tenant.members.find(
              (member) =>
                member.person.id === myData?.viewer?.account?.person.id
            )?.id
          }
          refetchQueries={[
            {
              query: FETCH_ALL_BOARDS,
            },
          ]}
        />
      </SectionHeader>
      {!!tabs.length && (
        <>
          <Tabs
            value={currentTab}
            onChange={(tab) => {
              history.push(
                `/board/${params.boardId}/settings/${tabs[tab].key}`
              );
            }}
          >
            {tabs.map((tab) => (
              <Tab key={tab.key} label={tab.label}></Tab>
            ))}
          </Tabs>
          <TabContent>
            <Switch>
              <Route path="/board/:boardId/settings/users" component={Users} />
              <Route
                path="/board/:boardId/settings/timezone"
                component={TimeZone}
              />
              <Route
                path="/board/:boardId/settings/quiet-hours"
                component={QuietHours}
              />
              <Route
                path="/board/:boardId/settings/network"
                component={Network}
              />
              {settingsModuleData?.board.settings.map((setting) => (
                <Route
                  key={setting.key}
                  path={`/board/:boardId/settings/${setting.key}`}
                  component={() => <DynamicSettingsIframe url={setting.link} />}
                />
              ))}
              <Route
                path="/board/:boardId/settings/billing"
                render={() => <Billing boardId={params.boardId} />}
              />
              <Redirect to={`/board/:boardId/settings/${tabs[0].key}`} />
            </Switch>
          </TabContent>
        </>
      )}
    </>
  );
};

type DynamicSettingsIframeProps = {
  url: string;
};

const DynamicSettingsIframe: React.FC<DynamicSettingsIframeProps> = (props) => {
  // https://gitlab.com/vestaboard/default-subscription-configuration-view this part of the settings code lives here
  return (
    <Box style={{ height: 900 }}>
      <Iframe title="Mirroring" src={props.url}></Iframe>
    </Box>
  );
};
