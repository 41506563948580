import React from "react";
import { IBoardStyle, colors } from "@vestaboard/installables";
import { ThumbUp, ThumbUpOutlined } from "@mui/icons-material";

export const LikeIcon = (props: {
  liked: boolean;
  boardStyle?: IBoardStyle;
}) => {
  return props.liked ? (
    <div
      style={{
        color: props.boardStyle === "white" ? colors.black : colors.shuttleGray,
      }}
    >
      <ThumbUp fontSize="small" color={"inherit"} />
    </div>
  ) : (
    <div
      style={{
        color: props.boardStyle === "white" ? colors.black : colors.shuttleGray,
      }}
    >
      <ThumbUpOutlined fontSize="small" color={"inherit"} />
    </div>
  );
};
