import * as React from "react";

interface IAppState {
  tenantId: string | null;
  boardId: string | null;
  isPlus: boolean;
  setBoardId: (boardId: string) => any;
  setTenantId: (tenantId: string) => any;
  triggerRefresh: () => any;
  setIsPlus: (value: boolean) => any;
  refresh: string;
  sourcesHistoryFilter: Array<string>;
  setSourcesHistoryFilter: (sources: Array<string>) => void;
}

type AppState = IAppState;

const defaultAppState: IAppState = {
  tenantId: null,
  boardId: null,
  isPlus: false,
  setBoardId: (boardId: string) => null,
  setTenantId: (tenantId: string) => null,
  triggerRefresh: () => null,
  setIsPlus: (value: boolean) => null,
  refresh: "",
  sourcesHistoryFilter: [],
  setSourcesHistoryFilter: (sources: Array<string>) => null,
};

const GlobalStateContext = React.createContext<AppState>(defaultAppState);

export const AppStateProvider: React.FC = (props) => {
  const [tenantId, setTenantId] = React.useState<string | null>(null);
  const [boardId, setBoardId] = React.useState<string | null>(null);
  const [refresh, setRefresh] = React.useState("");
  const [isPlus, setIsPlus] = React.useState(false);
  const [sourcesHistoryFilter, setSourcesHistoryFilter] = React.useState<
    Array<string>
  >([]);

  const triggerRefresh = () => {
    setRefresh(String(new Date()));
  };

  const globalState = {
    tenantId,
    boardId,
    isPlus,
    setIsPlus,
    setBoardId,
    setTenantId,
    refresh,
    triggerRefresh,
    sourcesHistoryFilter,
    setSourcesHistoryFilter,
  };

  return (
    <GlobalStateContext.Provider value={globalState}>
      {props.children}
    </GlobalStateContext.Provider>
  );
};

export const useAppState = () => React.useContext(GlobalStateContext);
