import {
  BoardEditor,
  IBoard,
  createBoard,
  IBoardStyle,
  LoadMask,
  useToasts,
  ScheduleMessage,
} from "@vestaboard/installables";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import { useGetBoardStyle } from "../../hooks/useGetBoardStyle";
import { Schedule, WifiTethering } from "@mui/icons-material";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { useUnpinMessageFromBoardV2 } from "../../hooks/useUnpinMessageFromBoardV2";
import { useHandleSaveDraft } from "./hooks/useHandleSaveDraft";
import { useHandleSendMessage } from "./hooks/useHandleSendMessage";
import { DraftsModal } from "./components/DraftsModal";
import { BroadcastMessageModalV2 } from "./components/BroadcastMessageModalV2";
import { useSchedule } from "./hooks/useSchedule";

interface IBoardPageProps {}

type BoardPageProps = IBoardPageProps;

interface IRouteMatch {
  boardId: string;
  message?: string;
}

const useStyles = makeStyles({
  container: {
    justifyContent: "center",
    display: "flex",
    background: "#181919",

    flex: 1,
  },
  board: {
    marginTop: 50,
    width: 820,
    paddingBottom: 50,
    maxWidth: "100%",
    "@media(max-width: 1400px)": {
      width: 700,
      marginTop: 14,
    },
    "@media(max-width: 960px)": {
      width: 600,
    },
    "@media(max-width: 640px)": {
      paddingBottom: 60,
      width: "100vw",
    },
  },
});

export const ComposePageV2: React.FC<BoardPageProps> = (props) => {
  const { addToast } = useToasts();
  const { data: boardStyleData } = useGetBoardStyle();
  const [showBypass, setShowBypass] = useState(false);
  const [isShowingBroadcast, setShowBroadcast] = useState(false);
  const [isShowingDrafts, setShowDrafts] = useState(false);
  const history = useHistory();
  const params = useParams<IRouteMatch>();
  const [boardValue, setBoardValue] = useState<IBoard>(
    params.message ? JSON.parse(params.message) : createBoard()
  );
  const classes = useStyles();
  const [unPinMessage, { loading: unpinningMessage }] =
    useUnpinMessageFromBoardV2();
  const { boardId } = params;
  const { handleSaveDraft, creatingDraft } = useHandleSaveDraft(boardValue);
  const { handleSendMessage, creatingMessageAppearance } = useHandleSendMessage(
    {
      characters: boardValue,
      setShowBypass,
    }
  );
  const {
    isSchedulingLater,
    loading: isSavingSchedule,
    handleScheduling,
    handleSchedulingCancel,
    handleSchedule,
  } = useSchedule({
    boardId: params.boardId,
    characters: boardValue,
  });

  const handleShowDrafts = useCallback(() => {
    setShowDrafts(true);
  }, [setShowDrafts]);

  const handleHideDrafts = useCallback(() => {
    setShowDrafts(false);
  }, [setShowDrafts]);

  const handleHideBypass = useCallback(() => {
    setShowBypass(false);
  }, [setShowBypass]);

  const handleConfirmBypass = useCallback(async () => {
    await unPinMessage({
      variables: {
        input: {
          boardId,
        },
      },
    });

    handleSendMessage({ boardId });
    handleHideBypass();
  }, [boardId, handleSendMessage, handleHideBypass, unPinMessage]);

  const handleTemplateClick = useCallback(() => {
    history.push(`/board/${boardId}/templates`);
  }, [boardId, history]);

  const handleHideBroadcast = useCallback(() => {
    setShowBroadcast(false);
  }, []);

  const handleShowBroadcastModal = useCallback(() => {
    setShowBroadcast(true);
  }, []);

  const handleBroadcast = useCallback(
    async (boardIds: string[]) => {
      await Promise.all(
        boardIds.map((boardId) => {
          return handleSendMessage({
            boardId,
            preventRedirect: true,
          });
        })
      );

      addToast(
        `Broadcasted message to ${boardIds.length} board${
          boardIds.length > 1 ? "s" : ""
        }.`,
        {
          appearance: "success",
        }
      );
    },
    [addToast, handleSendMessage]
  );

  return (
    <>
      <BroadcastMessageModalV2
        boardId={boardId}
        visible={isShowingBroadcast}
        onClose={handleHideBroadcast}
        handleBroadcast={handleBroadcast}
      />
      <ConfirmationModal
        onConfirm={handleConfirmBypass}
        visible={showBypass}
        confirmButtonText="Send"
        icon="pin"
        message="A message is currently pinned by you or another user of your account."
        subMessage="Are you sure you want to send a message?"
        onCancel={handleHideBypass}
      />
      {isSchedulingLater && (
        <ScheduleMessage
          visible={isSchedulingLater}
          onSend={handleSchedule}
          onCancel={handleSchedulingCancel}
        />
      )}
      {isShowingDrafts && (
        <DraftsModal onSelect={setBoardValue} onClose={handleHideDrafts} />
      )}
      {unpinningMessage ||
      creatingMessageAppearance ||
      creatingDraft ||
      isSavingSchedule ? (
        <LoadMask />
      ) : null}
      <div className={classes.container}>
        <div className={classes.board}>
          <BoardEditor
            boardValue={boardValue}
            mode={params.message ? "type" : undefined}
            setBoardValue={setBoardValue}
            boardStyle={boardStyleData?.board?.boardStyle as IBoardStyle}
            onSend={async () => handleSendMessage({ boardId })}
            dropdownActions={[
              {
                onPress: handleScheduling,
                icon: <Schedule />,
                title: "Schedule",
              },
              {
                onPress: handleShowBroadcastModal,
                icon: <WifiTethering />,
                title: "Broadcast",
              },
            ]}
            onSaveDraft={handleSaveDraft}
            onSelectDraft={handleShowDrafts}
            onTemplatesClick={handleTemplateClick}
          />
        </div>
      </div>
    </>
  );
};
