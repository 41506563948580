import React from "react";
import { makeStyles } from "@mui/styles";
import { FeaturedInstallable } from "./FeaturedInstallable";
import Slider from "../../../components/Slider";

import { BREAKPOINT_EXTRA_EXTRA_SMALL } from "../../../config";

const useStyles = makeStyles({
  sectionContainer: {
    marginBottom: 20,
  },
  sectionHeader: {
    margin: 0,
    fontFamily: "HelveticaNeue",
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "0.15px",
  },
  sectionHeaderRow: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 30,
  },
  sectionHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  sliderContainer: {
    marginBottom: 48,
    "& .slick-list": {
      "& .slick-slide > div": {
        [`@media(min-width: ${BREAKPOINT_EXTRA_EXTRA_SMALL})`]: {
          padding: "0 20px",
        },
      },
    },
  },
});

export interface FeaturedInstallablesSectionProps {
  items: Array<{
    id: string;
    title: string;
    description: string | null;
    backgroundImage: string;
    textColor: string;
    subscriptionId: string | null;
    installableIsSingleton: boolean;
  }>;
}

const FeaturedInstallablesSection: React.FC<
  FeaturedInstallablesSectionProps
> = (props) => {
  const classes = useStyles();

  return (
    <section className={classes.sectionContainer}>
      <div className={classes.sectionHeaderContainer}>
        <span className={classes.sectionHeaderRow}>
          <h4 className={classes.sectionHeader}>Channels of the Week</h4>
        </span>
      </div>
      <div className={classes.sliderContainer}>
        <Slider
          speed={500}
          slidesToShow={2}
          slidesToScroll={2}
          responsive={[
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {props.items.map((listing) => (
            <div key={listing.id}>
              <FeaturedInstallable {...listing} installed={false} />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default FeaturedInstallablesSection;
