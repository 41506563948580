import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

import { BREAKPOINT_SMALL, BREAKPOINT_MEDIUM_MIN } from "../../../config";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      overflowX: "auto",
      marginRight: "-24px",
      justifyContent: "flex-start",
    },
    [`@media(min-width: ${BREAKPOINT_MEDIUM_MIN}) and (max-width: 1078px)`]: {
      justifyContent: "center",
    },
  },
});

interface ICardsContainer {}

export const CardsContainer: React.FC<ICardsContainer> = (props) => {
  const classes = useStyles();

  return <Box className={classes.container}>{props.children}</Box>;
};
