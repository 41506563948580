import { Typography, TextField, Box, Modal } from "@mui/material";
import {
  FlexHorizontal,
  Spacer,
  Button,
  useToasts,
} from "@vestaboard/installables";
import { useCallback, useState } from "react";
import { useFlagFeedItemV2 } from "../hooks/useFlagFeedItem";
import { makeStyles } from "@mui/styles";

interface IFlagMessage {
  feedItemId: string;
  handleClose: () => void;
}

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalInner: {
    maxWidth: 600,
    padding: 42,
    width: "100%",
    backgroundColor: "#222",
    color: "#FFF",
    borderRadius: 16,
  },
});

export const FlagMessage = (props: IFlagMessage) => {
  const [reason, setReason] = useState<string>("");
  const { addToast } = useToasts();
  const [flagMessage, { loading: isFlagging }] = useFlagFeedItemV2();
  const classes = useStyles();

  const handleFlagMessage = useCallback(async () => {
    const result = await flagMessage({
      variables: {
        input: {
          feedItemId: props.feedItemId,
          reason: reason,
        },
      },
    });

    if (result.data?.flagFeedItem.__typename === "FlaggedFeedItemErrorV2") {
      addToast(
        result.data.flagFeedItem.error ||
          "There was an error flagging the message",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      props.handleClose();
      return;
    } else {
      addToast("Message flagged", {
        appearance: "success",
        autoDismiss: true,
      });

      props.handleClose();
    }
  }, [addToast, flagMessage, props, reason]);

  return (
    <Modal open={true} onClose={props.handleClose} className={classes.modal}>
      <Box className={classes.modalInner}>
        <Typography variant="h4">Flag Message as Offensive</Typography>
        <Spacer size="large" />
        <Typography variant="body1">Reason</Typography>
        <Spacer size="small" />
        <TextField
          style={{
            backgroundColor: "#111",
          }}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          fullWidth
          rows={3}
          multiline
          variant="outlined"
          disabled={isFlagging}
        />
        <Spacer size="extraLarge" />
        <FlexHorizontal>
          <Button
            buttonType="danger"
            onClick={handleFlagMessage}
            disabled={isFlagging}
          >
            {isFlagging ? "Flagging..." : "Flag"}
          </Button>
          <Box width={32} />
          <Button buttonType="outline" onClick={props.handleClose}>
            Cancel
          </Button>
        </FlexHorizontal>
      </Box>
    </Modal>
  );
};
