import { makeStyles } from "@mui/styles";
import {
  Button,
  LoadMask,
  Padding,
  SlideUpMenu,
  SlideUpMenuList,
  SlideUpMenuListItem,
  SlideUpMenuTitle,
} from "@vestaboard/installables";
import { useCallback, useContext, useState } from "react";
import { useCopy } from "../hooks/useCopy";
import { useCreateShareToken } from "../hooks/useCreateShareToken";
import { Share } from "../providers/ShareProvider";
import { MessageSetCreatorSendModal } from "./MessageSetCreatorSendModal";
import { SHARE_API_URL } from "../config";

const useStyles = makeStyles({
  icon: {
    width: 18,
    filter: `invert(100%)`,
  },
  link: {
    color: "#000000 !important",
    textDecoration: "none !important",
    display: "block",
  },
});

export const ShareComponent = () => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const { shareMessage, setShareMessage } = useContext(Share);
  const [addToChannel, setAddToChannel] = useState<string | null>(null);
  const { copy } = useCopy();
  const [createShareToken] = useCreateShareToken();
  const classes = useStyles();

  const generateSharePage = useCallback(async () => {
    const message = `${shareMessage?.id}`;
    setShareMessage(null);
    setIsPageLoading(true);

    const { data } = await createShareToken({
      variables: {
        input: {
          message,
        },
      },
    });

    await copy({
      value: `https://share.vestaboard.com/${data?.createMessageShareToken?.rawToken}`,
      successMessage: "Copied the share URL to your clipboard",
    });

    setIsPageLoading(false);
  }, [copy, createShareToken, shareMessage, setShareMessage]);

  return (
    <>
      {isPageLoading && <LoadMask />}
      <SlideUpMenu
        visible={!!shareMessage}
        onClose={() => {
          setShareMessage(null);
        }}
      >
        <SlideUpMenuTitle>Share</SlideUpMenuTitle>
        <SlideUpMenuList>
          <SlideUpMenuListItem onClick={() => {}}>
            <a
              className={classes.link}
              href={`${SHARE_API_URL}/generate/image.jpg?characters=${btoa(
                JSON.stringify(shareMessage?.characters) || ""
              )}`}
              download
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Save"
                className={classes.icon}
                src="/icons/icon-download.svg"
              />{" "}
              &nbsp; Save Image
            </a>
          </SlideUpMenuListItem>
          <SlideUpMenuListItem onClick={generateSharePage}>
            <img
              alt="Copy Link"
              className={classes.icon}
              src="/icons/icon-copy.svg"
            />{" "}
            &nbsp; Copy Link
          </SlideUpMenuListItem>
          <SlideUpMenuListItem
            onClick={() => {
              if (shareMessage?.id) {
                setAddToChannel(shareMessage?.id);
              }
              setShareMessage(null);
            }}
          >
            <img
              alt="Copy Link"
              className={classes.icon}
              src="/icons/icon-publish.svg"
            />{" "}
            &nbsp; Add to My Channel
          </SlideUpMenuListItem>
        </SlideUpMenuList>
        <Padding>
          <Button
            onClick={() => {
              setShareMessage(null);
            }}
            buttonType="outlineBlack"
          >
            Cancel
          </Button>
        </Padding>
      </SlideUpMenu>
      <MessageSetCreatorSendModal
        messageId={addToChannel || ""}
        visible={!!addToChannel}
        onClose={() => {
          setAddToChannel(null);
        }}
      />
    </>
  );
};
