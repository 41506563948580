import { LDProvider } from "launchdarkly-react-client-sdk";

interface ILaunchDarkly {
  children: React.ReactNode;
  personId?: string;
  boardId?: string;
}

export const LaunchDarklyProvider = (props: ILaunchDarkly) => {
  return (
    <LDProvider
      deferInitialization={true}
      clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_KEY || ""}
      context={{
        kind: "multi",
        ...(props.boardId
          ? {
              board: { key: props.boardId },
            }
          : {}),
        ...(props.personId ? { person: { key: props.personId } } : {}),
      }}
    >
      {props.children}
    </LDProvider>
  );
};
