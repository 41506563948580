import { SaveDraftMutation, SaveDraftMutationVariables } from "../../../gql";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const SAVE_DRAFT = gql`
  mutation SaveDraft($input: SaveDraftInput!) {
    saveDraft(input: $input) {
      saved
      draftMessage {
        id
        message {
          id
          formatted
        }
      }
    }
  }
`;

export const useSaveDraft = () => {
  return useMutation<SaveDraftMutation, SaveDraftMutationVariables>(SAVE_DRAFT);
};
