import { makeStyles } from "@mui/styles";

import { Box, Button } from "@mui/material";

interface IToggleOption {
  key: string;
  value: string;
}

interface IToggleButton {
  options: [IToggleOption, IToggleOption];
  onToggle: (value: string) => void;
  value: string;
  disabled?: boolean;
}

const useStyles = makeStyles({
  container: {
    border: "solid 1px #7e878e",
    borderRadius: 6,
  },
  button: {
    height: "100%",
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "HelveticaNeue",
    paddingLeft: 0,
    paddingRight: 0,
    color: "#ffffff !important",
    "&:first-child": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:last-child": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  activeButton: {
    backgroundColor: "#7e878e",
    color: "#1e2022 !important",
    "&:hover": {
      backgroundColor: "#7e878e",
    },
  },
});

export const ToggleButton = (props: IToggleButton) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {props.options.map((option) => {
        const isSelected = option.value === props.value;

        return (
          <Button
            disabled={props.disabled}
            className={[
              classes.button,
              ...(isSelected ? [classes.activeButton] : []),
            ].join(" ")}
            key={option.key}
            onClick={() => {
              if (!isSelected) {
                props.onToggle(option.value);
              }
            }}
          >
            {option.key}
          </Button>
        );
      })}
    </Box>
  );
};
