import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useHistory, useParams } from "react-router-dom";
import {
  ExistingMarketplaceListingSubscriptionQuery,
  ExistingMarketplaceListingSubscriptionQueryVariables,
} from "../../../gql";
import { useCallback, useEffect } from "react";
import { useCreateSubscription } from "../hooks/useCreateSubscription";
import { useAppState } from "../../../hooks/global";
import { CircularProgress } from "@mui/material";

interface InstallParams {
  boardId: string;
  marketplaceListingId: string;
}

const MARKETPLACE_LISTING_DETAIL_QUERY = gql`
  query ExistingMarketplaceListingSubscription($boardId: String!) {
    board(id: $boardId) {
      id
      subscriptionSet {
        channels {
          id
          marketplaceListing {
            id
          }
        }
      }
    }
  }
`;

export const Install = () => {
  const history = useHistory();
  const params = useParams<InstallParams>();
  const { tenantId } = useAppState();
  const { data, loading } = useQuery<
    ExistingMarketplaceListingSubscriptionQuery,
    ExistingMarketplaceListingSubscriptionQueryVariables
  >(MARKETPLACE_LISTING_DETAIL_QUERY, {
    variables: {
      boardId: params.boardId,
    },
  });
  const { createSubscription } = useCreateSubscription({
    boardId: params.boardId,
    tenantId: tenantId || "",
  });

  const subscribe = useCallback(async () => {
    const subscriptionId = await createSubscription({
      marketplaceListingId: params.marketplaceListingId,
    });

    if (subscriptionId) {
      window.location.href = `/board/${params.boardId}/installables/subscription/${subscriptionId}`;
    }
  }, [createSubscription, params.boardId, params.marketplaceListingId]);

  useEffect(() => {
    if (loading) {
      return;
    }

    const installed = data?.board.subscriptionSet.channels.find((channel) => {
      return channel?.marketplaceListing?.id === params.marketplaceListingId;
    });

    if (installed) {
      return history.push(
        `/board/${params.boardId}/installables/subscription/${installed.id}`
      );
    }

    subscribe();
  }, [
    loading,
    data,
    history,
    subscribe,
    params.marketplaceListingId,
    params.boardId,
  ]);

  return <CircularProgress />;
};
