import {
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables,
} from "../../../gql";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription(
    $subscriptionId: String!
    $muted: Boolean
    $title: String
  ) {
    updateSubscription(
      input: { subscriptionId: $subscriptionId, muted: $muted, title: $title }
    ) {
      subscription {
        id
        muted
        runOfShow {
          description
          firstAppearance
        }
      }
    }
  }
`;

export const useUpdateSubscription = () => {
  return useMutation<
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables
  >(UPDATE_SUBSCRIPTION);
};
