import * as React from "react";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { BREAKPOINT_EXTRA_EXTRA_SMALL } from "../config";

interface IBorderContainer {
  children: React.ReactElement | Array<React.ReactElement>;
  active?: boolean;
  className?: string;
  responsive?: boolean;
  backgroundColor?: string;
}

const useStyles = makeStyles({
  container: {
    border: "solid 1px #FFF",
    padding: "11px 26px",
    borderRadius: 5,
    marginBottom: 32,
  },
  containerResponsive: {
    [`@media(min-width: ${BREAKPOINT_EXTRA_EXTRA_SMALL})`]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
});

export const BorderContainer = (props: IBorderContainer) => {
  const classes = useStyles();

  return (
    <Box
      className={[
        classes.container || "",
        props.className || "",
        ...(props.responsive ? [classes.containerResponsive] : []),
      ].join(" ")}
      style={{
        backgroundColor: props.active
          ? props.backgroundColor
            ? props.backgroundColor
            : "#181919"
          : "transparent",
      }}
    >
      {props.children}
    </Box>
  );
};
