import * as React from "react";

import { Simulator } from "../../components/Simulator";
import { useParams } from "react-router";

export const ViewBoard: React.FC = (props) => {
  const { boardId } = useParams<{ boardId: string }>();

  const simulatorCoverStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#202020",
  };

  const simulatorStyle = {};

  return (
    <div style={simulatorCoverStyle}>
      <div className="pt5 flex items-center justify-center flex-row">
        <div></div>
        <div style={simulatorStyle}>
          <Simulator width={1500} boardId={boardId} />
        </div>
        <div></div>
      </div>
    </div>
  );
};
