import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { InviteRoleV2Mutation, InviteRoleV2MutationVariables } from "../gql";

const INVITE_ROLE_V2 = gql`
  mutation InviteRoleV2($input: InviteInputV2!) {
    inviteV2(input: $input) {
      ... on RoleV2 {
        id
        permission
        hasPendingInvite
        person {
            id
            emailAddress
        }
      }
      ... on RoleErrorV2 {
        type
        error
      }
    }
  }
`;


export const useInviteRoleV2 = () => {
    return useMutation<InviteRoleV2Mutation, InviteRoleV2MutationVariables>(INVITE_ROLE_V2)
};
