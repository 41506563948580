import { gql, useQuery } from "@apollo/client";
import {
  GetMyRoleV2Query,
  GetMyRoleV2QueryVariables,
  GetRolesV2Query,
  GetRolesV2QueryVariables,
} from "../gql";

export const GET_ROLES_QUERY_V2 = gql`
  query GetRolesV2($boardId: String!) {
    BoardV2(id: $boardId) {
      ... on BoardV2 {
        id
        title
        listRoles {
          roles {
            id
            permission
            hasPendingInvite
            person {
              id
              emailAddress
              firstName
              lastName
            }
          }
        }
        __typename
      }
    }
  }
`;

export const GET_MY_ROLE_QUERY_V2 = gql`
  query GetMyRoleV2($boardId: String!) {
    viewerV2 {
      ... on PersonV2 {
        id
        firstName
        lastName
        emailAddress
        myRoleForBoard(boardId: $boardId) {
          id
          personId
          permission
          hasPendingInvite
        }
      }
    }
  }
`;

export const useGetRolesV2 = (boardId: string) => {
  return useQuery<GetRolesV2Query, GetRolesV2QueryVariables>(
    GET_ROLES_QUERY_V2,
    {
      skip: !boardId,
      variables: {
        boardId,
      },
    }
  );
};

export const useGetMyRoleV2 = (boardId: string) => {
  return useQuery<GetMyRoleV2Query, GetMyRoleV2QueryVariables>(
    GET_MY_ROLE_QUERY_V2,
    {
      skip: !boardId,
      variables: {
        boardId,
      },
    }
  );
};
