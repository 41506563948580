import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface ISearchStateWrapper {
  children: React.ReactNode;
}

const useStyles = makeStyles({
  container: {
    paddingLeft: 12,
    paddingTop: 24,
  },
});

export const SearchStateWrapper = (props: ISearchStateWrapper) => {
  const classes = useStyles();

  return <Box className={classes.container}>{props.children}</Box>;
};
