import { HideHistoryMutation, MutationHideHistoryMessageArgs } from "../gql";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

export const HIDE_HISTORY = gql`
  mutation HideHistory($input: HideHistoryMessageMutationInput!) {
    hideHistoryMessage(input: $input) {
      hidden
    }
  }
`;

export const useHideMessageHistory = () => {
  return useMutation<HideHistoryMutation, MutationHideHistoryMessageArgs>(
    HIDE_HISTORY
  );
};
