import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { BoardStyleQuery, BoardStyleQueryVariables } from "../gql";

interface IRouteMatch {
  boardId: string;
}

const BOARD_STYLE_QUERY = gql`
  query BoardStyleQuery($boardId: String!) {
    board(id: $boardId) {
      id
      boardStyle
      title
    }
  }
`;

export const useGetBoardStyle = () => {
  const { boardId } = useParams<IRouteMatch>();
  const result = useQuery<BoardStyleQuery, BoardStyleQueryVariables>(
    BOARD_STYLE_QUERY,
    {
      fetchPolicy: "cache-first",
      variables: {
        boardId,
      },
    }
  );

  return result;
};
