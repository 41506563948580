import { makeStyles } from "@mui/styles";
import React from "react";
import MediaQuery from "react-responsive";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import ArrowButton from "../../../components/ArrowButton";
import { BREAKPOINT_MEDIUM_MIN, BREAKPOINT_SMALL } from "../../../config";
import { useAppState } from "../../../hooks/global";
import { CardsContainer } from "./CardsContainer";
import { InstallableTile } from "./InstallableTile";

interface IRouteMatch {
  boardId: string;
}

const useStyles = makeStyles({
  sectionContainer: {
    marginBottom: 40,
  },
  sectionHeader: {
    margin: 0,
    fontFamily: "HelveticaNeue",
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "0.15px",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      fontSize: 16,
      lineHeight: 1.5,
    },
  },
  sectionHeaderRow: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 30,
  },
  sectionHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  installable: {
    marginBottom: 48,
  },
  seeAllLink: {
    fontFamily: "HelveticaNeue",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: "normal",
    color: "#7e878e !important",
    textDecoration: "none !important",
    marginLeft: 12,
  },
  cardContainerRow: {
    display: "flex",
  },
});

export interface InstallablesSectionProps {
  id: string;
  title: string;
  items: Array<{
    id: string;
    title: string;
    free: boolean;
    icon: string | null;
    description: string | null;
    canAdd: boolean;
    installableIsSingleton: boolean | null;
    subscriptionId: string | null;
    subscriptionMuted: boolean;
  }>;
}

const InstallablesSection: React.FC<InstallablesSectionProps> = (props) => {
  const classes = useStyles();
  const { boardId } = useParams<IRouteMatch>();
  const history = useHistory();
  const { isPlus } = useAppState();
  const itemsHalf = Math.ceil(props.items.length / 2);
  const firstItemsHalf = props.items.slice(0, itemsHalf);
  const secondItemsHalf = props.items.slice(-itemsHalf);

  return (
    <section className={classes.sectionContainer}>
      <div className={classes.sectionHeaderContainer}>
        <span className={classes.sectionHeaderRow}>
          <h4 className={classes.sectionHeader}>{props.title}</h4>

          <Link
            to={`/board/${boardId}/installables/category/${props.id}`}
            className={classes.seeAllLink}
          >
            See All
          </Link>
        </span>
        <ArrowButton
          right
          onClick={() => {
            history.push(`/board/${boardId}/installables/category/${props.id}`);
          }}
        />
      </div>
      <CardsContainer>
        <MediaQuery maxWidth={BREAKPOINT_SMALL}>
          <div className={classes.cardContainerRow}>
            {firstItemsHalf.map((listing) => {
              return (
                <InstallableTile
                  free={listing.free ?? false}
                  key={listing.id}
                  canAdd={listing.canAdd}
                  id={listing.id}
                  title={listing.title}
                  icon={listing.icon}
                  description={listing.description}
                  installed={false}
                  enabled={true}
                  className={classes.installable}
                  isPlus={isPlus}
                  installableIsSingleton={!!listing.installableIsSingleton}
                  subscriptionId={listing.subscriptionId}
                  subscriptionMuted={listing.subscriptionMuted}
                />
              );
            })}
          </div>
          <div className={classes.cardContainerRow}>
            {secondItemsHalf.map((listing) => {
              return (
                <InstallableTile
                  free={listing.free ?? false}
                  key={listing.id}
                  canAdd={listing.canAdd}
                  id={listing.id}
                  title={listing.title}
                  icon={listing.icon}
                  description={listing.description}
                  installed={false}
                  enabled={true}
                  className={classes.installable}
                  isPlus={isPlus}
                  installableIsSingleton={!!listing.installableIsSingleton}
                  subscriptionId={listing.subscriptionId}
                  subscriptionMuted={listing.subscriptionMuted}
                />
              );
            })}
          </div>
        </MediaQuery>
        <MediaQuery minWidth={BREAKPOINT_MEDIUM_MIN}>
          {props.items.map((listing) => {
            return (
              <InstallableTile
                free={listing.free ?? false}
                key={listing.id}
                canAdd={listing.canAdd}
                id={listing.id}
                title={listing.title}
                icon={listing.icon}
                description={listing.description}
                installed={false}
                enabled={true}
                className={classes.installable}
                isPlus={isPlus}
                installableIsSingleton={!!listing.installableIsSingleton}
                subscriptionId={listing.subscriptionId}
                subscriptionMuted={listing.subscriptionMuted}
              />
            );
          })}
        </MediaQuery>
      </CardsContainer>
    </section>
  );
};

export default InstallablesSection;
