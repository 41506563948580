import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  DeleteBoardV2Mutation,
  DeleteBoardV2MutationVariables,
} from "../generated/graphql";

const DELETE_BOARD_MUTATION_V2 = gql`
  mutation DeleteBoardV2($input: DeleteBoardInputV2!) {
    deleteBoardV2(input: $input) {
      ... on BoardV2 {
        id
        title
      }
      ... on BoardErrorV2 {
        type
        error
      }
    }
  }
`;

export const useDeleteBoardV2 = () => {
  return useMutation<DeleteBoardV2Mutation, DeleteBoardV2MutationVariables>(
    DELETE_BOARD_MUTATION_V2
  );
};
