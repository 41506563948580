import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  CreateSimulatorTokenMutation,
  CreateSimulatorTokenMutationVariables,
} from "../../../gql";

const MUTATION = gql`
  mutation CreateSimulatorToken($input: CreateSimulatorTokenInputV2!) {
    createSimulatorToken: createSimulatorTokenV2(input: $input) {
      ... on SimulatorTokenV2 {
        id
        token
        expiresAt
        isPublic
        boardId
        url
      }
      ... on SimulatorTokenErrorV2 {
        type
        error
      }
    }
  }
`;

export const useCreateSimulatorToken = () =>
  useMutation<
    CreateSimulatorTokenMutation,
    CreateSimulatorTokenMutationVariables
  >(MUTATION);
