import {
  GetBoardQuietHoursV2Query,
  GetBoardQuietHoursV2QueryVariables,
  GetBoardV2Query,
  GetBoardV2QueryVariables,
} from "../gql";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const BOARD_QUERY_V2 = gql`
  query GetBoardV2($id: String!) {
    BoardV2(id: $id) {
      ... on BoardV2 {
        id
        title
        createdAt
        quietHours {
          start
          end
        }
        isQuietHours
        timezone
        boardStyle
        listFlagshipDevices {
          flagshipDevices {
            id
            heartbeat {
              lastSeen
            }
          }
        }
      }
    }
  }
`;

const BOARD_QHS_QUERY_V2 = gql`
  query GetBoardQuietHoursV2($id: String!) {
    BoardV2(id: $id) {
      ... on BoardV2 {
        id
        title
        createdAt
        quietHours {
          start
          end
        }
        isQuietHours
        timezone
        boardStyle
      }
    }
  }
`;

export const useGetBoardV2 = (boardId: string) => {
  return useQuery<GetBoardV2Query, GetBoardV2QueryVariables>(BOARD_QUERY_V2, {
    variables: {
      id: boardId,
    },
  });
};

export const useGetBoardQuietHoursV2 = (boardId: string) => {
  return useQuery<
    GetBoardQuietHoursV2Query,
    GetBoardQuietHoursV2QueryVariables
  >(BOARD_QHS_QUERY_V2, {
    variables: {
      id: boardId,
    },
  });
};
