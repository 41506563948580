import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
} from "react-beautiful-dnd";

const useStyles = makeStyles({
  container: {
    // display: "flex",
    // justifyContent: 'space-between',
    // flexWrap: "wrap"
  },
});

interface IListItemsContainer {
  reordering?: boolean;
  children: Array<{
    id: string;
    render: (
      dragging: boolean,
      dragHandleProps: DraggableProvidedDragHandleProps | undefined
    ) => React.ReactChild;
  }>;
  onReorder(itemIds: string[]): void;
}

export const ListItemsContainer: React.FC<IListItemsContainer> = (props) => {
  const classes = useStyles();

  return !props.reordering ? (
    <Box className={classes.container}>
      {props.children.map((child) => child.render(false, undefined))}
    </Box>
  ) : (
    <DragDropContext
      onDragEnd={(result) => {
        if (!result.destination) {
          return;
        }

        props.onReorder(
          reorder(
            props.children.map((el) => el.id),
            result.source.index,
            result.destination.index
          )
        );
      }}
    >
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            // style={getListStyle(snapshot.isDraggingOver)}
          >
            {props.children.map((child, index) => (
              <Draggable key={child.id} draggableId={child.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    /*style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}*/
                  >
                    {child.render(
                      snapshot.isDragging,
                      provided.dragHandleProps
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

function reorder<T>(list: Array<T>, startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}
