import * as React from "react";

import { MenuItem, Select } from "@mui/material";

import { useAppState } from "../../../hooks/global";
import { usePermissions } from "../../../hooks/usePermissions";

export const TenantSelector: React.FC = (props) => {
  const { tenants, loading } = usePermissions();
  const { tenantId, setTenantId } = useAppState();

  React.useEffect(() => {
    if (tenants && tenants.length && !loading) {
      setTenantId(tenants[0].id);
    }
  }, [tenants, loading, setTenantId]);

  return (
    <Select
      value={tenantId || ""}
      onChange={(e: any) => setTenantId(e.target.value)}
    >
      {!loading && tenants
        ? tenants.map((tenant) => (
            <MenuItem value={tenant.id} key={tenant.id}>
              {tenant.name}
            </MenuItem>
          ))
        : null}
    </Select>
  );
};
