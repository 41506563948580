import * as React from "react";

import { GetTenantBoardsQuery } from "../../gql";

import gql from "graphql-tag";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/client";

export const GET_TENANT_BOARDS_QUERY = gql`
  query GetTenantBoards {
    tenants {
      id
      boards {
        id
      }
    }
  }
`;

export const RedirectToBoard = () => {
  const { data } = useQuery<GetTenantBoardsQuery>(GET_TENANT_BOARDS_QUERY);
  const history = useHistory();

  React.useEffect(() => {
    if (data && data.tenants && data.tenants.length) {
      const boardIds = data.tenants.reduce((prev: Array<string>, current) => {
        return [...prev, ...(current.boards || []).map((board) => board.id)];
      }, []);

      if (boardIds.length) {
        history.push(`/board/${boardIds[0]}`);
      } else {
        history.push("/new-board");
      }
    }
  }, [data, history]);

  return null;
};
