import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  ShareMessageV2Mutation,
  ShareMessageV2MutationVariables,
} from "../gql";

const MUTATION = gql`
  mutation ShareMessageV2($input: ShareInputV2!) {
    shareMessage: shareMessageV2(input: $input) {
      ... on ShareV2 {
        shareUrl
        imageUrl
      }
      ... on ShareErrorV2 {
        type
        error
      }
    }
  }
`;

export const useShareMessageV2 = () => {
  return useMutation<ShareMessageV2Mutation, ShareMessageV2MutationVariables>(
    MUTATION
  );
};
