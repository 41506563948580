import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  ChevronLeft,
  ChevronRight,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import { TransparentTextArea } from "@vestaboard/installables";

const useStyles = makeStyles({
  button: {
    border: "none",
    padding: "5px 9px",
    borderRadius: 8,
    "-webkit-backdrop-filter": "blur(10px)",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(125, 135, 142, 0.2)",
    fontFamily: "HelveticaNeue",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: 1.67,
    color: "#f5f5f7",
    textTransform: "none",
    boxShadow: "none",
    minWidth: "unset",
    "&:focus": {
      backgroundColor: "rgba(125, 135, 142, 0.2)",
    },
  },
  isTransparent: {
    backgroundColor: "transparent",
    "-webkit-backdrop-filter": "none",
    backdropFilter: "none",
    color: "#1e2022",
    padding: 0,
  },
});

export interface ArrowButtonProps {
  left?: boolean;
  right?: boolean;
  direction?: "left" | "right";
  onClick?(): void;
  className?: string;
  background?: "transparent" | "gray";
  arrowType?: "arrow" | "chevron";
}

const ArrowButton: React.FC<ArrowButtonProps> = (props) => {
  const {
    left,
    right,
    direction,
    onClick,
    className,
    background = "gray",
    arrowType = "chevron",
  } = props;
  const classes = useStyles();

  const leftDirection = direction === "left" || left || right === false;
  const transparentBackground = background === "transparent";

  return (
    <Button
      className={`${transparentBackground ? classes.isTransparent : ""} ${
        classes.button
      } ${className}`}
      onClick={props.onClick}
    >
      {arrowType === "chevron" && (
        <>{leftDirection ? <ChevronLeft /> : <ChevronRight />}</>
      )}
      {arrowType === "arrow" && (
        <>{leftDirection ? <ArrowBack /> : <ArrowForward />}</>
      )}
    </Button>
  );
};

export default ArrowButton;
