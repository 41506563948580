import { Box } from "@mui/material";
import { BREAKPOINT_EXTRA_SMALL } from "../config";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingTop: "5rem",
    paddingBottom: "2rem",
    paddingLeft: "5rem",
    paddingRight: "5rem",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      padding: "1rem",
    },
  },
});

interface IHistoryContainer {
  children: React.ReactNode;
}

export const HistoryContainer = (props: IHistoryContainer) => {
  const classes = useStyles();
  return <Box className={classes.container}>{props.children}</Box>;
};
