import * as React from "react";

import {
  BORDER_COLORS,
  BREAKPOINT_EXTRA_SMALL,
  BREAKPOINT_MEDIUM,
} from "../../../config";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { IBoard, ToastProvider } from "@vestaboard/installables";
import { MessageCard } from "../../../components/MessageCard";
import { UsePersonFavoritesListResponse } from "../../../hooks/usePersonFavoritesList";
import { useGetPinnedMessage } from "../../../hooks/useGetPinnedMessage";
import dayjs from "dayjs";
import { useQuietHours } from "../../../hooks/useQuietHours";
import { useGetRole } from "../../../hooks/useGetRole";
import { EndOfList } from "../../../components/EndOfList";

interface IFavoritesListProps {
  favoritesResponse: UsePersonFavoritesListResponse;
  boardId: string;
}

type FavoritesListProps = IFavoritesListProps;

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "5rem",
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      padding: "1rem",
    },
  },
  boardContainer: {
    position: "relative",
    marginBottom: 24,
    width: "31%",
    [`@media(max-width: ${BREAKPOINT_MEDIUM})`]: {
      width: "48%",
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
});

export const FavoritesList: React.FC<FavoritesListProps> = (props) => {
  const { favoritesResponse, boardId } = props;
  const { loading, data, favorites } = favoritesResponse;
  const { quietHoursData, hasQuietHours } = useQuietHours(props.boardId);
  const { data: pinnedMessageData, loading: loadingPinnedMessage } =
    useGetPinnedMessage();
  const pinnedMessageId = pinnedMessageData?.board?.pinnedMessage?.message?.id;
  const pinnedUntil = pinnedMessageData?.board?.pinnedMessage?.pinnedUntil;
  const hasPinnedMessage = React.useMemo(
    () => (pinnedMessageId ? dayjs(pinnedUntil).isAfter(dayjs()) : false),
    [pinnedUntil]
  );

  const classes = useStyles();

  if (!data || loading) {
    return <CircularProgress />;
  }

  const messages = (
    (data.viewer &&
      data.viewer.account &&
      data.viewer.account.person &&
      data.viewer.account.person.favorites) ||
    []
  )
    .map((favorite) => favorite.message)
    .filter((m) => favorites.indexOf(m.id) > -1);

  return (
    <ToastProvider offset={80}>
      <div className={classes.container}>
        {!loading && !loadingPinnedMessage ? null : <CircularProgress />}
        {!loading && messages.length === 0 && (
          <>
            <EndOfList>No favorites</EndOfList>
          </>
        )}
        {!(!loading && data)
          ? null
          : messages.map((message, index) => (
              <div className={classes.boardContainer} key={message.id}>
                <MessageCard
                  borderColor={BORDER_COLORS[index % BORDER_COLORS.length]}
                  isFavorite={true}
                  id={message.id}
                  text={message.text || ""}
                  formatted={message.formatted as IBoard}
                  boardId={boardId}
                  created={message.created}
                  sendAt={message.sendAt}
                  onSend={() => {}}
                  author={message.authorFormatted}
                  hasPinnedMessage={hasPinnedMessage}
                  quietHoursData={quietHoursData}
                  hasQuietHours={hasQuietHours}
                />
              </div>
            ))}
        {/* Fix spacing if there are two cards on a row */}
        {messages && 3 ? <div className={classes.boardContainer} /> : null}
      </div>
    </ToastProvider>
  );
};
