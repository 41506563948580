import { CircularProgress } from "@mui/material";
import {
  ConfigureInstallableQuery,
  ConfigureInstallableQueryVariables,
} from "../../gql";
import { makeStyles } from "@mui/styles";

import { NotFoundPage } from "../NotFound";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

interface IParams {
  subscriptionId: string;
  boardId: string;
}

const CONFIGURE_INSTALLABLE = gql`
  query ConfigureInstallable($subscriptionId: String!) {
    subscription(id: $subscriptionId) {
      id
      configurationUrl
    }
  }
`;

const useStyles = makeStyles({
  iframe: {
    border: 0,
    width: "100%",
    height: "calc(100vh - 80px)",
  },
});

export const ConfigureInstallable = () => {
  const classes = useStyles();
  const { subscriptionId } = useParams<IParams>();
  const { data, loading, error } = useQuery<
    ConfigureInstallableQuery,
    ConfigureInstallableQueryVariables
  >(CONFIGURE_INSTALLABLE, {
    variables: {
      subscriptionId,
    },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return <NotFoundPage />;
  }

  if (loading || !data) {
    return <CircularProgress />;
  }

  return (
    <iframe
      title={data.subscription.id}
      className={classes.iframe}
      src={data.subscription.configurationUrl as string}
    />
  );
};
