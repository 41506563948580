import { Route, Router } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { Routes } from "../Routes";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const AppRouterProvider = () => {
  return (
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Routes />
      </QueryParamProvider>
    </Router>
  );
};
