import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  ListColorTemplatesV2Query,
  ListColorTemplatesV2QueryVariables,
} from "../gql";

const QUERY = gql`
  query ListColorTemplatesV2($input: ListColorTemplateInputV2!) {
    listColorTemplates: listColorTemplatesV2(input: $input) {
      colorTemplates {
        id
        title
        messages {
          id
          text
          characters
        }
      }
    }
  }
`;

export const useListColorTemplatesV2 = (
  variables: ListColorTemplatesV2QueryVariables
) => {
  return useQuery<
    ListColorTemplatesV2Query,
    ListColorTemplatesV2QueryVariables
  >(QUERY, {
    variables,
  });
};
