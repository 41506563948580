export const getInitials = (name: string) => {
  const names = name.split(" ");

  if (!names) {
    return "...";
  }

  return `${names.length > 0 ? names[0].charAt(0) : ""}${
    names.length > 1 ? names[1].charAt(0) : ""
  }`;
};
