import * as React from "react";
import { CircularProgress } from "@mui/material";
import { MessagesList } from "./components/MessagesList";
import { useParams } from "react-router-dom";

interface IRouteMatch {
  boardId: string;
}

export const HistoryProvided = () => {
  const { boardId } = useParams<{ boardId: string }>();

  return (
    <>
      <MessagesList boardId={boardId} />
    </>
  );
};

export const HistorySelfLoading: React.FC = () => {
  const params = useParams<IRouteMatch>();
  const { boardId } = params;

  if (!boardId) {
    return <CircularProgress />;
  }

  return (
    <>
      <MessagesList boardId={boardId} />
    </>
  );
};
