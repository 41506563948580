import "./index.css";
import "./material-ui-fixes.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import * as serviceWorker from "./serviceWorker";

import { WebApp } from "./App";
import { AuthProvider } from "./providers/AuthProvider";
import { Preview } from "./pages/Preview";
import ReactDOM from "react-dom";

import { PlusCheckoutWidget } from "./components/plus-checkout-widget";
import { QueryParamProvider } from "use-query-params";
import * as Sentry from "@sentry/react";
import { PricingApp } from "./PricingApp";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: process.env.REACT_APP_SENTRY_KEY || "",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT || "production",
});

const isPreview = window.location.href.includes("/preview");
const isPricing = window.location.href.includes("/widget/pricing");
const isPlusCheckoutWidget = window.location.href.includes(
  "/plus-checkout-widget/"
);

if (isPreview) {
  ReactDOM.render(<Preview />, document.getElementById("root"));
} else if (isPricing) {
  ReactDOM.render(<PricingApp />, document.getElementById("root"));
} else if (isPlusCheckoutWidget) {
  ReactDOM.render(
    <QueryParamProvider>
      <PlusCheckoutWidget />
    </QueryParamProvider>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <AuthProvider>
      <WebApp />
    </AuthProvider>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
