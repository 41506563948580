import { Box, ButtonBase, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  BoardPreview,
  BoardPreviewMask,
  encodeBoardCharacters,
  IBoard,
  IBoardStyle,
  Spacer,
  SubTitle,
} from "@vestaboard/installables";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MoreButton } from "../../components/MoreButton";
import { BREAKPOINT_SMALL } from "../../config";
import { useAppState } from "../../hooks/global";
import { useGetBoardStyle } from "../../hooks/useGetBoardStyle";
import { useTemplates } from "../../hooks/useTemplates";
import { PlusCTA } from "./PlusCTA";
import { useListColorTemplatesV2 } from "../../hooks/useListColorTemplatesV2";

const useStyles = makeStyles({
  categoryContainer: {
    paddingTop: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  boardPreviewsContainer: {
    flexDirection: "row",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    alignItems: "center",
    maxWidth: 1200,

    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  boardPreviewContainer: {
    width: "300px",
    minWidth: "300px",
    maxWidth: "300px",
    marginBottom: 20,
    marginRight: 20,
  },
  header: {
    padding: "20px",
    borderBottom: "solid 1px #2b2b2b",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  headerColumn: {
    flex: 1,
    display: "flex",
  },
  headerColumnCenter: {
    flex: 1,
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    // icon and padding offset
    left: -55,
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      left: -22,
    },
  },
  plusContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      padding: 24,
    },
  },
});

export const TemplatesV2 = () => {
  const classes = useStyles();
  const params = useParams() as { boardId: string };
  const history = useHistory();
  const perPage = 3;
  const { data, loading: loadingTemplates } = useListColorTemplatesV2({
    input: {},
  });
  const { data: boardData, loading: loadingBoard } = useGetBoardStyle();
  // set app state for board id so usePaywall hook will work
  const { setBoardId } = useAppState();
  useEffect(() => {
    setBoardId(params.boardId);
    params.boardId && localStorage.setItem("lastBoardId", params.boardId);
  }, [params]);

  if (loadingTemplates || loadingBoard) {
    return (
      <Box className={classes.categoryContainer}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.headerColumn}>
          <ButtonBase
            onClick={() => {
              history.push(`/board/${params.boardId}/compose`);
            }}
          >
            <img src="/icons/icon-back.svg" alt="Back" />
          </ButtonBase>
        </Box>
        <Box className={classes.headerColumnCenter}>
          <SubTitle>Templates</SubTitle>
        </Box>
        {/* Display Flex three items to center the title */}
        <Box className={classes.headerColumn}></Box>
      </Box>
      <Box className={classes.categoryContainer}>
        {data?.listColorTemplates.colorTemplates.map((category) => (
          <Box>
            <SubTitle>{category.title}</SubTitle>
            <Spacer size={"medium"} />
            <Box className={classes.boardPreviewsContainer}>
              {category.messages
                .filter((_, index) => index < perPage)
                .map((template) => (
                  <Box className={classes.boardPreviewContainer}>
                    <BoardPreviewMask
                      handleDuplicateTitle={"Use Template"}
                      handleDuplicate={() => {
                        history.push(
                          `/board/${
                            params.boardId
                          }/compose/duplicate/${JSON.stringify(
                            template.characters
                          )}`
                        );
                      }}
                    >
                      <BoardPreview
                        boardStyle={
                          (boardData?.board?.boardStyle as IBoardStyle) ??
                          "black"
                        }
                        characters={
                          template.characters
                            ? (template.characters as IBoard)
                            : encodeBoardCharacters("")
                        }
                      />
                    </BoardPreviewMask>
                  </Box>
                ))}
              {category.messages.length > perPage && (
                <MoreButton
                  onClick={() => {
                    history.push(
                      `/board/${params.boardId}/templates/${category.id}`
                    );
                  }}
                />
              )}
            </Box>
          </Box>
        ))}
        <Spacer size={"medium"} />
      </Box>
      <Box className={classes.plusContainer}>
        <div style={{ maxWidth: "1024px", width: "100%" }}>
          <PlusCTA />
        </div>
      </Box>
    </>
  );
};
