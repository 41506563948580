import { ReferralCodeQuery, ReferralCodeQueryVariables } from "../gql";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const REFERRAL_CODE_QUERY = gql`
  query referralCodeQuery {
    viewer {
      account {
        person {
          id
          referralCode {
            code
            created
            discountForNewCustomer
            id
            revenueShareForExistingCustomer
            url
          }
          tenants {
            boards {
              devices {
                id
                latestLocationCountryCode
              }
              id
            }
          }
        }
      }
    }
  }
`;

export const useReferralCode = (personId?: string) => {
  return useQuery<ReferralCodeQuery, ReferralCodeQueryVariables>(
    REFERRAL_CODE_QUERY,
    {
      fetchPolicy: "cache-first",
      skip: !personId,
      variables: {
        personId: personId || "",
      },
    }
  );
};
