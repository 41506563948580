import { useCallback, useState } from "react";
import { useCreateScheduledMessageV2 } from "../../../hooks/useCreateScheduledMessageV2";
import { IBoard, useToasts } from "@vestaboard/installables";
import { useCreateMessageV2 } from "../../../hooks/useCreateMessageV2";

interface IUseSchedule {
  characters: IBoard;
  boardId: string;
}

export const useSchedule = (props: IUseSchedule) => {
  const { addToast } = useToasts();
  const [isSchedulingLater, setIsSchedulingLater] = useState(false);
  const [scheduleMutation, { loading: scheduling }] =
    useCreateScheduledMessageV2();
  const [createMessage, { loading: creatingMessage }] = useCreateMessageV2();

  const handleSchedule = useCallback(
    async (hours, minutes, seconds) => {
      setIsSchedulingLater(false);

      const message = await createMessage({
        variables: {
          input: {
            characters: props.characters,
          },
        },
      });

      if (message.data?.createMessage.__typename === "MessageV2") {
        await scheduleMutation({
          variables: {
            input: {
              messageId: message.data.createMessage.id,
              boardId: props.boardId,
              sendAt: new Date(
                Date.now() +
                  hours * 60 * 60 * 1000 +
                  minutes * 60 * 1000 +
                  seconds * 1000
              ).getTime(),
            },
          },
        });

        addToast("Message scheduled", {
          appearance: "success",
        });
      } else if (message.data?.createMessage.__typename === "MessageErrorV2") {
        addToast(
          message.data?.createMessage?.error || "Unable to save message",
          {
            appearance: "error",
          }
        );
      }
    },
    [addToast, createMessage, props.boardId, props.characters, scheduleMutation]
  );

  return {
    loading: creatingMessage || scheduling,
    isSchedulingLater,
    handleScheduling: () => setIsSchedulingLater(true),
    handleSchedulingCancel: () => setIsSchedulingLater(false),
    handleSchedule,
  };
};
