import * as React from "react";

import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables,
  CreateApiKeyBoardsQuery,
} from "../../../gql";
import { useMutation, useQuery } from "@apollo/client";

import { Confirm } from "../../../components/Confirm";
import gql from "graphql-tag";

interface ICreateApiKeyModalProps {
  show: boolean;
  installableId: string;
  onHide: () => any;
  onKeyCreated: () => void;
}

type CreateApiKeyModalProps = ICreateApiKeyModalProps;

const useStyles = makeStyles({
  important: {
    marginTop: 32,
  },
});

const createApiKeyMutation = gql`
  mutation CreateApiKeyMutation($boardId: String!, $installableId: String!) {
    createApiCredential(
      input: { boardId: $boardId, installableId: $installableId }
    ) {
      apiCredential {
        id
        created
        key
      }
      created
      secret
    }
  }
`;

const createApiKeyBoardsQuery = gql`
  query CreateApiKeyBoardsQuery {
    viewer {
      account {
        person {
          tenants {
            id
            boards {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export const CreateApiKeyModal: React.FC<CreateApiKeyModalProps> = (props) => {
  const classes = useStyles();
  const [createApiKey, { data, loading }] = useMutation<
    CreateApiKeyMutation,
    CreateApiKeyMutationVariables
  >(createApiKeyMutation);

  const [selectedVestaboard, setSelectedVestaboard] = React.useState("--");

  const { data: boardsData } = useQuery<CreateApiKeyBoardsQuery>(
    createApiKeyBoardsQuery,
    {
      fetchPolicy: "no-cache",
    }
  );

  return (
    <Confirm
      open={props.show}
      title="Create API credential"
      declineTitle="Cancel"
      acceptTitle={loading ? "..." : data ? "Done" : "Create API Credential"}
      message=""
      handleClose={() => {
        setSelectedVestaboard("--");
        props.onHide();
      }}
      handleAccept={async () => {
        if (data) {
          props.onHide();
          return;
        }

        await createApiKey({
          variables: {
            boardId: selectedVestaboard,
            installableId: props.installableId,
          },
        });

        props.onKeyCreated();
      }}
    >
      <>
        {!data &&
          boardsData &&
          boardsData.viewer &&
          boardsData.viewer.account && (
            <Select
              value={selectedVestaboard}
              onChange={(e) => setSelectedVestaboard(e.target.value as string)}
              data-testid="choose-board"
              id="choose-board"
            >
              <MenuItem value={"--"}>-- Select a Vestaboard --</MenuItem>
              {boardsData.viewer.account.person.tenants
                .flatMap((t) => t.boards)
                .map((b) => (
                  <MenuItem value={b.id} key={b.id} id={b.id} data-testid={b.id}>
                    {b.title}
                  </MenuItem>
                ))}
            </Select>
          )}
        {data &&
        data.createApiCredential &&
        data.createApiCredential.created ? (
          <>
            <div className="w-100 flex mb2">
              <TextField
                id="api-key"
                label="Key"
                variant="filled"
                className={"flex-grow-1"}
                value={data.createApiCredential.apiCredential.key}
                data-testid="api-key"
              />
              <Button
                onClick={() => {
                  const copyText = document.querySelector(
                    "#api-key"
                  ) as HTMLInputElement;
                  copyText.select();
                  document.execCommand("copy");
                }}
                className={"ml2"}
              >
                COPY
              </Button>
            </div>
            <div className="w-100 flex mb2">
              <TextField
                id="api-secret"
                label="Secret"
                variant="filled"
                className="flex-grow-1"
                value={data.createApiCredential.secret}
                data-testid="api-secret"
              />
              <Button
                onClick={() => {
                  const copyText = document.querySelector(
                    "#api-secret"
                  ) as HTMLInputElement;
                  copyText.select();
                  document.execCommand("copy");
                }}
                className={"ml2"}
              >
                COPY
              </Button>
            </div>
            <p className={classes.important}>
              <strong>Important:</strong> you must save the API secret before
              closing, since you will not be able to retrieve it again.
            </p>
          </>
        ) : loading ? (
          <CircularProgress />
        ) : null}
      </>
    </Confirm>
  );
};
