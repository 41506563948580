import * as React from "react";

import {
  ButtonBase,
  Modal as MaterialModal,
} from "@mui/material";
import { BREAKPOINT_EXTRA_SMALL, BREAKPOINT_SMALL } from "../config";
import {
  makeStyles,
} from '@mui/styles'

type ModalProps = IModalProps;

interface IModalProps {
  shown: boolean;
  onClose?: () => void;
  customStyles?: any;
  className?: string;
  disableBackdropClick?: boolean;
  hideBackdrop?: boolean;
}

const useStyles = makeStyles({
  modal: {
    backgroundColor: "#000",
    color: "#fff",
    width: "90%",
    maxWidth: 992,
    maxHeight: "100vh",
    overflow: "auto",
    [`@media(max-width: ${BREAKPOINT_SMALL})`]: {
      width: "90%",
      maxHeight: "90vh",
    },
    [`@media(max-width: ${BREAKPOINT_EXTRA_SMALL})`]: {
      maxHeight: "100vh",
      width: "100%",
    },
    " > *:not(:first-child)": {
      marginLeft: 10,
    },
  },
  backdrop: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    left: 0,
    top: 0,
    background: "#0A0A0B",
    opacity: 0.9,
  },
});

const BackdropComponent =
  (props: ModalProps, classes: Record<string, string>) => () => {
    return <ButtonBase className={classes.backdrop} onClick={props.onClose} />;
  };

export const Modal: React.FC<ModalProps> = (props) => {
  const classes = useStyles();

  return (
    <MaterialModal
      BackdropComponent={BackdropComponent(props, classes)}
      open={props.shown}
      onClose={props.onClose}
      className="modal-container"
      disableBackdropClick={props.disableBackdropClick}
      hideBackdrop={props.hideBackdrop}
    >
      <div
        style={{
          position: "absolute",
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`,
          outline: 0,
          ...props.customStyles,
        }}
        className={
          `${classes.modal} flex flex-column br2` + ` ${props.className}`
        }
      >
        {props.children}
      </div>
    </MaterialModal>
  );
};

type ModalSectionProps = IModalSectionProps;

interface IModalSectionProps {
  className?: string;
}

export const ModalSection: React.FC<ModalSectionProps> = (props) => {
  return (
    <div className={["pa4", props.className].filter((e) => !!e).join(" ")}>
      {props.children}
    </div>
  );
};

type ModalHeaderProps = IModalHeaderProps;

interface IModalHeaderProps {
  className?: string;
  customStyles?: any;
  children?: any;
}

export const ModalHeader: React.FC<ModalHeaderProps> = (
  props: ModalHeaderProps
) => {
  return (
    <div
      className={
        "pa4 flex flex-shrink-0 justify-between items-start bb bw1 b--light-gray"
      }
      style={props.customStyles}
    >
      <h4 className={"mb0"}>{props.children}</h4>
    </div>
  );
};

type ModalBodyProps = IModalBodyProps;

interface IModalBodyProps {
  className?: string;
}

export const ModalBody: React.FC<ModalBodyProps> = (props) => {
  return (
    <div
      className={["pv2 ph2 pv3-m ph3-m ph4-l flex-auto", props.className]
        .filter((e) => !!e)
        .join(" ")}
    >
      {props.children}
    </div>
  );
};

type ModalFooterProps = IModalFooterProps;

interface IModalFooterProps {
  className?: string;
  customStyles?: any;
}

export const ModalFooter: React.FC<ModalFooterProps> = (props) => {
  return (
    <div
      style={props.customStyles}
      className={[
        "vestaboard-modal-footer pa2 pa3-ns ph4-l flex flex-wrap flex-shrink-0 items-center justify-end bt bw1 b--light-gray",
        props.className,
      ]
        .filter((e) => !!e)
        .join(" ")}
    >
      {props.children}
    </div>
  );
};
