import React from "react";

interface IShareMessage {
  id: string;
  characters: number[][];
}

export const Share = React.createContext<{
  shareMessage: IShareMessage | null;
  setShareMessage: (messageId: IShareMessage | null) => void;
}>({
  shareMessage: null,
  setShareMessage: () => {
    throw Error("No ShareProvider found in tree");
  },
});

export const ShareProvider: React.FC = ({ children }) => {
  const [shareMessage, setShareMessage] = React.useState<IShareMessage | null>(
    null
  );

  return (
    <Share.Provider
      value={{
        shareMessage,
        setShareMessage,
      }}
    >
      {children}
    </Share.Provider>
  );
};
