import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback, useMemo, useState } from "react";
import {
  TenantBoardsQuery,
  TenantBoardsQueryVariables,
  ViewersAdminOwnerBoardsQuery,
  ViewersAdminOwnerBoardsQueryVariables,
} from "../gql";
import { useMyAdminBoardsV2, useMyBoardsV2 } from "./useMyBoardsV2";

export const useSelectBoardsV2 = (currentBoard: string) => {
  const { data, loading, refetch } = useMyBoardsV2();
  const boards = data?.BoardsV2.boards;
  const options = boards?.map((board) => ({ id: board.id, name: board.title }));
  const [selectedBoards, setSelectedBoards] = useState<string[]>([
    currentBoard,
  ]);

  const selectBoard = useCallback(
    (boardId: string) => {
      if (selectedBoards.includes(boardId)) {
        const removedArray = selectedBoards.filter((id) => id !== boardId);
        setSelectedBoards([...removedArray]);
      } else {
        setSelectedBoards([...selectedBoards, boardId]);
      }
    },
    [selectedBoards]
  );

  const selectAllBoards = useCallback(() => {
    options && setSelectedBoards(options.map((opt) => opt.id));
  }, [options]);
  const clearBoards = () => setSelectedBoards([]);

  return {
    loading,
    refetch,
    boards,
    options,
    selectedBoards,
    clearBoards,
    selectAllBoards,
    selectBoard,
  };
};

export const useSelectAdminOwnerBoardsV2 = (currentBoard?: string) => {
  const { data, loading, refetch } = useMyAdminBoardsV2();
  const boards = data?.BoardsV2.boards
  const options = boards?.map((board) => ({ id: board.id, name: board.title }));
  const [selectedBoards, setSelectedBoards] = useState<string[]>(
    currentBoard ? [currentBoard] : []
  );

  const selectBoard = useCallback(
    (boardId: string) => {
      if (selectedBoards.includes(boardId)) {
        const removedArray = selectedBoards.filter((id) => id !== boardId);
        setSelectedBoards([...removedArray]);
      } else {
        setSelectedBoards([...selectedBoards, boardId]);
      }
    },
    [selectedBoards]
  );

  const selectAllBoards = useCallback(() => {
    options && setSelectedBoards(options.map((opt) => opt.id));
  }, [options]);
  const clearBoards = () => setSelectedBoards([]);

  return {
    loading,
    refetch,
    boards,
    options,
    selectedBoards,
    clearBoards,
    selectAllBoards,
    selectBoard,
  };
};
