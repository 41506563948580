import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GetTenantBoardsQuery } from "../../../gql";
import { GET_TENANT_BOARDS_QUERY } from "../../RedirectToBoard";

interface IParams {
  marketplaceListingId: string;
}

export const InstallableInstallRedirect = () => {
  const { marketplaceListingId } = useParams<IParams>();
  const { data, loading } = useQuery<GetTenantBoardsQuery>(
    GET_TENANT_BOARDS_QUERY
  );
  const history = useHistory();

  useEffect(() => {
    if (!loading) {
      const lastBoardId = localStorage.getItem("lastBoardId");
      const tenantCount = data?.tenants?.length || 0;
      const boardIds =
        data && tenantCount > 0
          ? data.tenants.reduce((prev: Array<string>, current) => {
              return [
                ...prev,
                ...(current.boards || []).map((board) => board.id),
              ];
            }, [])
          : [];

      const boardId = lastBoardId || boardIds[0];

      if (boardId) {
        const url = `/board/${boardId}/marketplace-listing/${marketplaceListingId}/install`;
        history.push(url);
      } else {
        history.push("/login");
      }
    }
  }, [data, history, loading, marketplaceListingId]);
  return <></>;
};
