import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FavoriteV2Mutation, FavoriteV2MutationVariables } from "../gql";
import { v4 as uuid } from "uuid";

const MUTATION = gql`
  mutation FavoriteV2($input: CreateFavoriteInputV2!) {
    createFavorite: createFavoriteV2(input: $input) {
      ... on FavoriteV2 {
        id
        message {
          id
          isFavorited
        }
      }
      ... on FavoriteErrorV2 {
        type
        error
      }
    }
  }
`;

export const useCreateFavoriteV2 = () => {
  return useMutation<FavoriteV2Mutation, FavoriteV2MutationVariables>(MUTATION);
};

useCreateFavoriteV2.optimistic = (messageId: string) =>
  ({
    createFavorite: {
      id: uuid(),
      message: {
        id: messageId,
        isFavorited: true,
        __typename: "MessageV2",
      },
      __typename: "FavoriteV2",
    },
  } as FavoriteV2Mutation);
